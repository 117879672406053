import { Center, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { AddButton } from "src/components/AddButton";
import { toast } from "react-toastify";
import { MutatingDots } from "react-loader-spinner";
import { Modal } from "src/components/base/Modal";
import ChecklistEmptyState from "./EmptyState";
import { CreateChecklistForm } from "./Create";
import { Checklist, ChecklistWithQuestions } from "src/types";
import ChecklistList from "./List";
import ChecklistDetails from "./Details";
import ChecklistService from "src/services/checklist/checklist";
import { Props } from "./types";

const ChecklistTab: React.FC<Props> = ({ project_id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [checklists, setChecklists] = useState({
    isLoading: false,
    data: [] as undefined | ChecklistWithQuestions[],
  });

  const [currentChecklist, setCurrentChecklist] = useState<{
    checklist: ChecklistWithQuestions;
    startWithCreate?: boolean;
  }>();

  const [checklistFormData, setChecklistFormData] = useState<
    ChecklistWithQuestions | {} | undefined
  >();

  const refreshChecklists = useCallback(async () => {
    try {
      setChecklists({ isLoading: true, data: undefined });
      const data = await ChecklistService.getAll({ project_id });
      setChecklists({ isLoading: false, data });
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar os checklists");
    }
  }, [project_id]);

  useEffect(() => {
    refreshChecklists();
  }, [refreshChecklists]);

  const handleCreateOrUpdateChecklist: SubmitHandler<
    ChecklistWithQuestions
  > = async (checklist) => {
    setIsLoading(true);
    const isUpdate = "id" in checklist;

    try {
      if (isUpdate && checklists.data) {
        await ChecklistService.update(checklist.id, checklist);

        if (currentChecklist?.checklist.id === checklist.id) {
          setCurrentChecklist({ checklist });
        }
      } else {
        const newChecklist = await ChecklistService.create({
          ...checklist,
          is_template: !project_id,
          project_id,
        });

        setChecklists({
          ...checklists,
          data: [...(checklists.data || []), newChecklist],
        });
        setCurrentChecklist({ checklist: newChecklist, startWithCreate: true });
      }

      setChecklistFormData(undefined);
    } catch (err) {
      toast.error(
        `Ocorreu um erro ao ${isUpdate ? "Atualizar" : "Criar"} seu checklist`
      );
    }
    setIsLoading(false);
  };

  const handleDeleteChecklist = async (id: Checklist["id"]) => {
    const oldChecklists = checklists;
    try {
      setCurrentChecklist(undefined);
      setChecklists({
        isLoading: false,
        data: checklists.data?.filter((checklist) => checklist.id !== id),
      });
      await ChecklistService.delete(id);

      toast.success("Checklist removido com sucesso!");
    } catch (err) {
      setChecklists(oldChecklists);
      toast.error("Ocorreu um erro ao remover o checklist");
    }
  };

  useEffect(() => {
    if (currentChecklist?.checklist && checklists.data) {
      const checklistIndex = checklists.data?.findIndex(
        ({ id }) => id === currentChecklist.checklist?.id
      );

      if (checklists.data[checklistIndex] !== currentChecklist.checklist) {
        checklists.data[checklistIndex] = currentChecklist.checklist;
        setChecklists({ ...checklists, data: checklists.data });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChecklist]);

  return (
    <VStack flex={1} alignItems={"flex-start"} w={"100%"}>
      {currentChecklist ? (
        <ChecklistDetails
          {...currentChecklist}
          handleBack={() => setCurrentChecklist(undefined)}
          handleModifyQuestions={(questions) => {
            setCurrentChecklist({
              ...currentChecklist,
              checklist: { ...currentChecklist.checklist, questions },
            });
          }}
          handleDeleteChecklist={handleDeleteChecklist}
          handleUpdateChecklist={() =>
            setChecklistFormData(currentChecklist.checklist)
          }
        />
      ) : (
        <VStack w={"100%"} {...(project_id && { maxW: "558px" })}>
          <HStack justifyContent={"space-between"} width={"100%"} pb={"16px"}>
            <Text
              fontWeight={600}
              fontFamily={"Raleway"}
              fontSize={"RH_sm"}
              color={"Gray.$800"}
            >
              {project_id ? "Checklists" : "Templates de checklists"}
            </Text>

            {checklists.data && checklists.data.length >= 1 && (
              <AddButton
                label={`Adicionar novo ${
                  !!project_id ? "checklist" : "template"
                }`}
                onClick={() => setChecklistFormData({})}
              />
            )}
          </HStack>

          {checklists.isLoading ? (
            <Center mt={"64px !important"} w={"100%"}>
              <MutatingDots
                height="100"
                width="100"
                color="#408EC5"
                secondaryColor="#408EC5"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                visible={true}
              />
            </Center>
          ) : (
            <>
              {checklists.data && (
                <>
                  {checklists.data.length >= 1 ? (
                    <ChecklistList
                      checklists={checklists.data}
                      handleClickChecklist={(checklist) =>
                        setCurrentChecklist({ checklist })
                      }
                    />
                  ) : (
                    <ChecklistEmptyState
                      isTemplate={!project_id}
                      handleCreate={() => setChecklistFormData({})}
                    />
                  )}
                </>
              )}
            </>
          )}
        </VStack>
      )}

      <Modal
        title={
          currentChecklist?.checklist && "id" in currentChecklist.checklist
            ? `Editar ${project_id ? "checklist" : "template de checklist"}`
            : `Criar ${project_id ? "checklist" : "template de checklist"}`
        }
        isOpen={!!checklistFormData}
        onClose={() => setChecklistFormData(undefined)}
      >
        {isLoading ? (
          <Center minH={"300px"} w={"100%"}>
            <MutatingDots
              height="100"
              width="100"
              color="#408EC5"
              secondaryColor="#408EC5"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              visible={true}
            />
          </Center>
        ) : (
          <CreateChecklistForm
            handleSubmitChecklist={handleCreateOrUpdateChecklist}
            checklist={checklistFormData as ChecklistWithQuestions}
          />
        )}
      </Modal>
    </VStack>
  );
};

export default ChecklistTab;
