import { Task } from "src/types";
import { createImageFromInitials } from "../../../common";
import { IconNameType } from "semente-js";

const positionProps = {
  start: {
    name: (text: string) => text.substring(0, 1),
    color: "#FFFFFF",
    background: "#0073E6",
  },
  diagnostic: {
    name: (text: string, id?: number) => text.substring(0, 1) + id,
    color: "#161A1D",
    background: "#FFBB33",
  },
  default: {
    name: (text: string, id?: number) => text.substring(0, 1) + id,
    color: "#161A1D",
    background: "#FFBB33",
  },
  last: {
    name: (text: string, id?: number) => text.substring(0, 1) + id,
    color: "#161A1D",
    background: "#FFBB33",
  },
  end: {
    name: (text: string) => text.substring(0, 1) + "E",
    color: "#161A1D",
    background: "#00CC33",
  },
  churn: {
    name: (text: string) => text.substring(0, 1) + "H",
    color: "#161A1D",
    background: "#00CC33",
  },
};

export type column = {
  id: number;
  name: string;
  position: keyof typeof positionProps;
  isFixed?: boolean;
};

export const TaskStatus = {
  pending: {
    icon: "clock-eight-solid" as IconNameType,
    bg: "#F5F5F5",
    color: "#000000",
    label: "Pendente",
  },
  in_work: {
    icon: "pen" as IconNameType,
    bg: "#ECF3FE",
    color: "#196FF0",
    label: "Em preenchimento",
  },
  finished: {
    icon: "check-circle" as IconNameType,
    bg: "#EFFBF3",
    color: "#1D8640",
    label: "Concluída",
  },
  no_work: {
    icon: "calendar-slash" as IconNameType,
    bg: "#FDEDF0",
    color: "#DD1940",
    label: "Não realizada",
  },
};

export const getColumns = (midLength: string) => {
  let columns: column[] = [
    {
      name: "LISTA DE EMPRESAS",
      position: "start",
      isFixed: true,
      id: 0,
    },
    {
      name: "Consultoria 1 - Diagnóstico",
      position: "diagnostic",
      isFixed: true,
      id: 1,
    },
  ];

  columns = [
    ...columns,
    ...Array.from(
      { length: parseInt(midLength) },
      (_, i) =>
        ({
          position: "default" as keyof typeof positionProps,
          name: `Consultoria ${i + columns.length}`,
          id: i + columns.length,
        }) as column
    ),
  ];

  columns = [
    ...columns,
    {
      name: `Consultoria ${columns.length} - Final`,
      id: columns.length,
      position: "last",
      isFixed: true,
    },
    {
      name: "Consultorias encerradas",
      id: columns.length + 1,
      position: "end",
      isFixed: true,
    },
    {
      name: "Churn",
      id: columns.length + 2,
      position: "churn",
      isFixed: true,
    },
  ];

  return columns.map((column) => ({
    ...column,
    icon: createImageFromInitials({
      ...positionProps[column.position || "default"],
      name: positionProps[column.position || "default"].name(
        column.name,
        column.id
      ),
      fontSize: 10,
      size: 32,
    }),
  }));
};

export const adjustTasksByIndex = (tasks: Task[]): Task[] => {
  return tasks.sort((a, b) => a.row_index - b.row_index);
};
