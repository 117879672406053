import { Button, Center, HStack, Text, VStack } from "@chakra-ui/react";
import { toast } from "react-toastify";
import { Icon } from "semente-js";
import InviteService from "src/services/invite";
import { Props } from "./types";
import { useState } from "react";

const ReSendInvite: React.FC<Props> = ({ company_id }) => {
  const [inviteIsSending, setInviteIsSending] = useState(false);
  const [alredySended, setAlredySended] = useState(false);

  const handleReSendInvite = async () => {
    try {
      setInviteIsSending(true);
      await InviteService.resendInvite(company_id);
      setInviteIsSending(false);
      setAlredySended(true);

      toast.success("Convite reenviado!");
    } catch (err) {
      toast.error("Ocorreu um erro ao reenviar o convite");
    }
  };

  return (
    <VStack
      p={"16px"}
      border={"1px solid"}
      borderColor={"Gray.$400"}
      borderRadius={"12px"}
      alignItems={"flex-start"}
      w={"100%"}
      mt={"36px"}
    >
      <HStack spacing={"16px"} w={"100%"}>
        <Center
          background={"Yellow.pure"}
          borderRadius={"50%"}
          w={"36px"}
          h={"36px"}
        >
          <Icon name={"exclamation-triangle-solid"} size="18px" />
        </Center>

        <VStack alignItems={"flex-start"} spacing={0} flex={1} w={"100%"}>
          <Text
            fontSize={"L_md"}
            fontWeight={600}
            color={"Gray.$700"}
            mb={"4px !important"}
          >
            Empresa não cadastrada
          </Text>
          <Text fontSize={"L_md"} fontWeight={400} color={"Gray.$600"}>
            O representante dessa empresa ainda não fez o cadastro no app Rama.
          </Text>
        </VStack>

        <Button
          minH={"36px"}
          isDisabled={alredySended}
          isLoading={inviteIsSending}
          onClick={handleReSendInvite}
        >
          <Text>{alredySended ? "Convite reenviado" : "Reenviar convite"}</Text>
        </Button>
      </HStack>
    </VStack>
  );
};

export default ReSendInvite;
