import { Button, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import ProfileSelector from "../../ProfileSelector";
import { ProfileProps } from "../types";
import { motion } from "framer-motion";

const BusinessModel: React.FC<ProfileProps> = ({
  data,
  defaultValue: { id, business_models },
  handleSubmit,
}) => {
  const [optionSelected, setOptionSelected] = useState<string[]>(
    business_models?.map((model) => model.business_model_id) || []
  );

  return (
    <motion.div
      style={{
        height: "100%",
        margin: 0,
        width: "100%",
        overflow: "auto",
      }}
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
    >
      <VStack height={"100%"} alignItems={"flex-start"}>
        <Text fontFamily={"Raleway"} fontSize={"RH_sm"} fontWeight={600}>
          Qual o modelo de receita da sua empresa?
        </Text>
        <Text color={"Gray.$700"} fontSize={"L_sm"} fontWeight={400}>
          Selecione para quem sua empresa vende. Você pode selecionar mais de
          uma opção
        </Text>

        <VStack
          w={"100%"}
          flex={1}
          overflow={"auto"}
          alignItems={"flex-start"}
          mt={"32px !important"}
        >
          <ProfileSelector
            data={data}
            idSelecteds={optionSelected}
            onPress={setOptionSelected}
            isMultipleChoice
          />
        </VStack>
        <Button
          variant={"Primary"}
          w={"100%"}
          onClick={() =>
            handleSubmit({
              business_models: optionSelected.map((opt) => ({
                business_model_id: opt,
                company_id: id,
              })),
            })
          }
        >
          {optionSelected.length < 1 ? "Ainda não está definido" : "Continuar"}
        </Button>
      </VStack>
    </motion.div>
  );
};

export default BusinessModel;
