import React from "react";
import { Props } from "./types";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { formatDate } from "src/common";
import PathColors from "src/components/PathSelect/common";

Chart.register(CategoryScale);

const SinglePath: React.FC<Props> = ({ values }) => {
  const chartData = {
    labels: values.map((data) => formatDate(new Date(data.date), "dd/MM/yyyy")),
    datasets: [
      ...values.map(({ value }, idx) => ({
        label: "",
        fill: true,
        tension: 0.4,
        data: values.map((_, key) => (key === idx ? value : undefined)),
        pointStyle: "circle",
        pointRadius: 10,
        pointHoverRadius: 15,
        backgroundColor: `${PathColors[value || 0].bg}aa`,
        borderColor: PathColors[value || 0].bg,
        showLabel: false,
      })),
      {
        label: "",
        fill: true,
        tension: 0.4,
        data: values.map(({ value }) => value || 0),
        pointStyle: "circle",
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: "#cbcacaaa",
        borderColor: "#cbcaca",
        showLabel: false,
        borderWidth: 1,
      },
    ],
  };

  return (
    <Line
      data={chartData}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            ticks: {
              callback: function (value) {
                return PathColors[value as number]?.label;
              },
            },
          },
        },
      }}
    />
  );
};

export default SinglePath;
