import { Button, Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { Props } from "./types";
import ProfilePic from "../../../../components/ProfilePic";
import { uploadFileToBucket } from "../../../../utils";
import { toast } from "react-toastify";
import UserService from "../../../../services/user";
import { User } from "../../../../types";
import UpdateConsultant from "./UpdateConsultant";
import { ProfileToLabel } from "../../common";
import { searchSuperIcon } from "src/assets/images";

export const ListRightSide: React.FC<Props> = ({
  consultant,
  handleUpdateConsultant,
}) => {
  const [imageUrl, setImageUrl] = useState(consultant.image_url);
  const [consultantToUpdate, setConsultantToUpdate] = useState<User>();

  const handleChangeImage = async (file: File) => {
    try {
      const params = {
        folderName: "users",
        id: consultant.id || "",
      };
      const image_url = await uploadFileToBucket(file, params);
      await UserService.update(consultant.id, { ...consultant, image_url });
      setImageUrl(image_url);
    } catch (err) {
      toast.error("Erro ao atualizar o consultor.");
    }
  };

  const handleRemoveImage = async () => {
    try {
      await UserService.update(consultant.id, { ...consultant, image_url: "" });
      setImageUrl(undefined);
    } catch (err) {
      toast.error("Erro ao atualizar o consultor.");
    }
  };

  const userProjects = [
    ...consultant.managerFrom,
    ...consultant.consultantFrom?.map(({ project }) => project),
  ];

  return (
    <VStack w={"100%"} ml={"36px !important"}>
      <HStack w={"100%"} h={"100%"}>
        <ProfilePic
          imageUrl={imageUrl}
          onRemoveImage={handleRemoveImage}
          onSelectImage={handleChangeImage}
          rounded
        />

        <VStack
          pl={"24px"}
          w={"100%"}
          h={"100%"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
        >
          <Text
            fontSize={"RH_xxl"}
            fontFamily={"raleway"}
            fontWeight={600}
            mb={"0px !important"}
          >
            {consultant?.name}
          </Text>

          <Text fontSize={"L_md"} fontWeight={400} mb={"0px !important"}>
            {ProfileToLabel[consultant.profile?.name]}
          </Text>
        </VStack>

        <Button
          onClick={() => setConsultantToUpdate(consultant)}
          variant={"Secondary"}
          px={"24px"}
          minH={"36px"}
        >
          Editar membro
        </Button>
      </HStack>

      <VStack
        w={"100%"}
        mt={"32px !important"}
        spacing={"0px !important"}
        alignItems={"flex-start"}
      >
        {userProjects.length >= 1 ? (
          <>
            <Text
              fontSize={"RH_xs"}
              fontFamily={"raleway"}
              fontWeight={600}
              mb={"0px !important"}
            >
              Projetos
            </Text>
            <Text
              mt={"8px !important"}
              fontSize={"L_md"}
              fontWeight={400}
              color={"Gray.$700"}
              mb={"16px !important"}
            >
              Lista de projetos a qual esse membro faz parte
            </Text>

            <VStack
              w={"100%"}
              border={"1px solid"}
              borderColor={"Gray.$400"}
              borderRadius={"12px"}
              spacing={"0px !important"}
              px={"16px"}
            >
              {userProjects.map((project, index) => (
                <HStack
                  key={project.id}
                  py={"16px"}
                  w={"100%"}
                  borderBottom={"1px solid"}
                  borderColor={
                    userProjects.length - 1 === index
                      ? "transparent"
                      : "Gray.$400"
                  }
                >
                  <VStack flex={1} alignItems={"flex-start"}>
                    <Text fontSize={"L_md"} fontWeight={400}>
                      {project.name}
                    </Text>
                    <Text
                      fontSize={"L_md"}
                      fontWeight={400}
                      color={"Gray.$700"}
                    >
                      {project.boards.length} quadros
                    </Text>
                  </VStack>
                </HStack>
              ))}
            </VStack>
          </>
        ) : (
          <Center w={"100%"}>
            <VStack maxW={"344px"}>
              <Image
                alt={"Ícone de uma lupa"}
                src={searchSuperIcon}
                width={"52px"}
                h={"64px"}
              />
              <Text
                fontWeight={600}
                fontFamily={"Raleway"}
                textAlign={"center"}
                fontSize={"RH_sm"}
              >
                Nenhum projeto ligado a este membro
              </Text>
              <Text
                mt={"8px !important"}
                textAlign={"center"}
                fontWeight={400}
                color={"Gray.$700"}
                fontSize={"L_sm"}
              >
                Tente criar novos projetos e adiciona-lo
              </Text>
            </VStack>
          </Center>
        )}
      </VStack>

      <UpdateConsultant
        handleClose={() => setConsultantToUpdate(undefined)}
        handleUpdateConsultant={handleUpdateConsultant}
        consultant={consultantToUpdate}
      />
    </VStack>
  );
};
