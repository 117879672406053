import { Button, HStack, Text } from "@chakra-ui/react";
import React from "react";
import Icon from "../base/Icon";
import { Props } from "./types";

export const variants = {
  Primary: {
    button: "Primary",
    icon: "white",
    text: "white",
  },
  Secondary: {
    button: "solid",
    icon: "black",
    text: "black",
  },
};

export const AddButton: React.FC<Props> = ({
  onClick,
  label,
  variant,
  isDisabled,
}) => {
  const currentVariant = variant ? variants[variant] : variants["Primary"];

  return (
    <Button
      variant={currentVariant.button}
      isDisabled={isDisabled}
      onClick={onClick}
      minH={"36px"}
    >
      <HStack spacing={"8px"} alignItems={"center"}>
        <Icon size={"14px"} name={"plus"} color={currentVariant.icon} />
        <Text fontSize={"L_sm"} fontWeight={500} color={currentVariant.text}>
          {label}
        </Text>
      </HStack>
    </Button>
  );
};
