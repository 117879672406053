import styled from "styled-components";

export const StyledFunnel = styled.div<{
  width: string;
  backgroudColor: string;
}>`
  width: ${(props) => props.width};
  height: 0;
  border-radius: 2px;
  border-top-width: 8px;
  border-top-color: ${(props) => props.backgroudColor};
  border-left-width: 3px;
  border-left-color: transparent;
  border-right-width: 3px;
  border-right-color: transparent;
`;
