import React, { useEffect, useState } from "react";
import { Props } from "./types";
import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { formatDate } from "src/common";
import Icon from "src/components/base/Icon";
import { CompanyIndicator } from "src/types";
import PerformanceForm from "./Form";
import IndicatorTypes from "src/pages/Settings/Tabs/Indicator/Form/common";
import { AddButton } from "src/components/AddButton";

Chart.register(CategoryScale);

const Performance: React.FC<Props> = ({
  taskId,
  company_id,
  indicators,
  handleRemoveIndicator,
  handleUpdateIndicator,
}) => {
  const [currentForm, setCurrentForm] = useState<CompanyIndicator>();
  const [currentTabIn, setCurrentTabIn] = useState(0);
  const [showMore, setShowMore] = useState({
    accordion: true,
    items: false,
  });

  const maxItems = 3;

  useEffect(() => {
    setShowMore((showMore) => ({ ...showMore, items: false }));
  }, [currentTabIn]);

  const tabData = indicators[currentTabIn]?.values?.sort(
    (a, b) => (new Date(a.date) as any) - (new Date(b.date) as any)
  );

  const chartData = {
    labels: tabData?.map((data) => formatDate(new Date(data.date))),
    datasets: [
      {
        label: indicators[currentTabIn]?.name,
        fill: true,
        tension: 0.4,
        data: tabData?.map((data) => data.value),
        pointStyle: "circle",
        pointRadius: 10,
        pointHoverRadius: 15,
        backgroundColor: indicators[currentTabIn]?.color + "AA",
        borderColor: indicators[currentTabIn]?.color,
        showLabel: false,
      },
    ],
  };

  return (
    <VStack
      w={"100%"}
      borderTop={"1px solid"}
      borderColor={"Gray.$300"}
      py={"32px"}
      alignItems={"flex-start"}
    >
      <Text fontWeight={600} fontSize={"RH_sm"} fontFamily={"Raleway"}>
        Desempenho
      </Text>

      {indicators.length >= 1 ? (
        <>
          {" "}
          <Box w={"100%"} position={"relative"} mt={"24px !important"}>
            <Box
              right={0}
              h={"100%"}
              width={"20px"}
              zIndex={10}
              position={"absolute"}
              bg={
                "linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)"
              }
            />

            <HStack
              w={"100%"}
              sx={{
                "::-webkit-scrollbar": {
                  display: "none",
                },
              }}
              overflow={"auto"}
            >
              {indicators?.map((indicator, i) => (
                <Box
                  cursor={"pointer"}
                  key={indicator.id}
                  bg={i === currentTabIn ? "Brand.pure" : "#F0F3F4"}
                  p={"10px 12px"}
                  borderRadius={"500px"}
                  transition={"300ms all"}
                  onClick={() => setCurrentTabIn(i)}
                >
                  <Text
                    fontSize={"L_sm"}
                    fontWeight={500}
                    whiteSpace={"nowrap"}
                    color={i === currentTabIn ? "Gray.$100" : "Gray.$800"}
                  >
                    {indicator?.name}
                  </Text>
                </Box>
              ))}
            </HStack>
          </Box>
          <HStack
            my={"24px !important"}
            w={"100%"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <VStack alignItems={"flex-start"}>
              <Text fontSize={"RH_sm"} fontFamily={"Raleway"} fontWeight={600}>
                {indicators[currentTabIn]?.name}
              </Text>
              <Text fontSize={"L_md"} fontWeight={400}>
                Descrição do indicador 1
              </Text>
            </VStack>
            <AddButton
              label="Inserir dado"
              variant="Secondary"
              onClick={() =>
                setCurrentForm({
                  company_id,
                  indicator_id: indicators[currentTabIn]?.id,
                } as CompanyIndicator)
              }
            />{" "}
            *
          </HStack>
          <Line
            data={chartData}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
          <VStack w={"100%"}>
            {tabData
              ?.slice(0, showMore.items ? 99 : maxItems)
              .map((data, i) => (
                <HStack w={"100%"} spacing={"24px"} key={i}>
                  <Icon name={"users-alt-solid"} size="22px" />

                  <HStack
                    borderBottom={"1px solid"}
                    borderColor={
                      i === tabData?.length - 1 ? "transparent" : "Gray.$300"
                    }
                    w={"100%"}
                  >
                    <VStack
                      spacing={0}
                      py={"16px"}
                      flex={1}
                      alignItems={"flex-start"}
                    >
                      <Text fontWeight={500} fontSize={"L_md"}>
                        {
                          IndicatorTypes.find(
                            (indicator) =>
                              indicator.value === indicators[currentTabIn]?.type
                          )?.prefix
                        }{" "}
                        {data.value}
                      </Text>
                      <Text
                        fontWeight={500}
                        fontSize={"L_sm"}
                        color={"Gray.$700"}
                      >
                        {formatDate(new Date(data.date))}
                      </Text>
                    </VStack>

                    <HStack>
                      <Button
                        variant={"solid"}
                        minH={"36px"}
                        onClick={() => setCurrentForm(data as CompanyIndicator)}
                      >
                        <HStack spacing={"8px"} alignItems={"center"}>
                          <Icon size={"14px"} name={"pen"} />
                          <Text fontSize={"L_sm"} fontWeight={500}>
                            Editar
                          </Text>
                        </HStack>
                      </Button>

                      <Button
                        variant={"solid"}
                        minH={"36px"}
                        onClick={async () => {
                          indicators[currentTabIn].values = indicators[
                            currentTabIn
                          ].values.filter(
                            (taskIndicator) => taskIndicator.id !== data.id
                          );

                          handleRemoveIndicator(data);
                        }}
                      >
                        <Icon size={"14px"} name={"trash-alt-solid"} />
                      </Button>
                    </HStack>
                  </HStack>
                </HStack>
              ))}

            {!showMore.items && tabData?.length > maxItems && (
              <HStack
                w={"100%"}
                py={"16px"}
                spacing={"24px"}
                cursor={"pointer"}
                onClick={() => setShowMore({ ...showMore, items: true })}
              >
                <Icon size={"22px"} name={"angle-down"} />
                <Text fontSize={"L_sm"} fontWeight={500}>
                  Ver mais {tabData?.length - maxItems}
                </Text>
              </HStack>
            )}
          </VStack>
          {currentForm && (
            <PerformanceForm
              taskId={taskId}
              handleClose={() => setCurrentForm(undefined)}
              companyIndicator={currentForm as CompanyIndicator}
              indicator={indicators[currentTabIn]}
              handleSubmitIndicator={async (newIndicator) => {
                const indicatorIn = tabData?.findIndex(
                  ({ id }) => id === newIndicator.id
                );

                if (indicatorIn >= 0) {
                  indicators[currentTabIn].values[indicatorIn] = newIndicator;
                  await handleUpdateIndicator(newIndicator);
                } else {
                  indicators[currentTabIn].values = [
                    ...indicators[currentTabIn].values,
                    newIndicator,
                  ];
                  await handleUpdateIndicator(newIndicator);
                }

                setCurrentForm(undefined);
              }}
            />
          )}
        </>
      ) : (
        <VStack spacing={0} alignItems={"flex-start"}>
          <Text fontWeight={600}>Nenhum indicador cadastrado ao projeto</Text>
          <Text>Contate o gestor para cadastrar novos</Text>
        </VStack>
      )}
    </VStack>
  );
};

export default Performance;
