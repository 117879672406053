import {
  Box,
  Center,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useRef } from "react";
import Icon from "../../../components/base/Icon";
import { motion } from "framer-motion";
import { Props } from "./types";
import { KanbanContext } from "../../../contexts/KanbanContext";
import Confetti from "./Confetti";
import { TaskStatus } from "../common";

export const Card: React.FC<Props> = ({
  task,
  setPutLocale,
  onDragStart,
  isHidded,
  onClick,
}) => {
  const { showConffetiTo } = useContext(KanbanContext);
  const stackRef = useRef<any>(null);
  const dragEnter = () => {
    setPutLocale();
    const stack = stackRef.current;
    stack.id = "hover";
  };

  const dragLeave = () => {
    const stack = stackRef.current;
    stack.id = "";
  };

  const status =
    TaskStatus[
      task?._status?.includes(task.column_index.toString())
        ? (task._status.split("-")[1] as keyof typeof TaskStatus)
        : "pending"
    ];

  return (
    <motion.div
      style={{ width: "100%" }}
      onDragEnter={dragEnter}
      onMouseLeave={dragLeave}
      initial={{
        scale: 0,
        opacity: 0,
      }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      exit={{
        scale: 0,
        opacity: 0,
      }}
    >
      <Stack
        w={"100%"}
        ref={stackRef}
        margin={"0px !important"}
        transition={"400ms all"}
      >
        <Stack width={"100%"} h={"8px"} margin={"0px !important"} />
        <motion.div
          style={{ margin: 0 }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.95 }}
          onClick={onClick}
        >
          {showConffetiTo === task.id && <Confetti />}

          <Center
            margin={"0px !important"}
            onDragStart={(e) => onDragStart(e, task.company.id)}
            background={"white"}
            key={task.column_index}
            w={"100%"}
            h={isHidded ? "72px" : "120px"}
            boxShadow={
              "0px 1px 1px rgba(22, 26, 29, 0.2), 0px 0px 1px rgba(22, 26, 29, 0.2);"
            }
            cursor={"pointer"}
            transition={"400ms all"}
            borderRadius={"8px"}
            p={"12px"}
            draggable
            {...(task.updatedBy && {
              border: "2px solid",
              borderColor: "Brand.pure",
            })}
            position={"relative"}
          >
            {task.updatedBy && (
              <Box
                background={"Brand.pure"}
                position={"absolute"}
                borderRadius={"4px"}
                padding={"2px 6px"}
                color={"white"}
                zIndex={10}
                right={0}
                bottom={0}
              >
                <Text
                  w={"100%"}
                  fontSize={"L_xs"}
                  fontWeight={400}
                  whiteSpace={"nowrap"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                >
                  Atualizada por: {task.updatedBy?.name}
                </Text>
              </Box>
            )}
            {isHidded ? (
              <Center
                w={"32px"}
                h={"32px"}
                borderRadius={"50%"}
                background={"Gray.$300"}
              >
                {task.company.image_url ? (
                  <Image
                    src={task.company.image_url}
                    borderRadius={"50%"}
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                  />
                ) : (
                  <Icon name="image" />
                )}
              </Center>
            ) : (
              <VStack
                w={"100%"}
                h={"100%"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
              >
                <VStack
                  w={"100%"}
                  alignItems={"flex-start"}
                  pb={"8px"}
                  spacing={"8px"}
                >
                  <HStack w={"100%"} alignItems={"center"} overflow={"hidden"}>
                    <Center
                      minW={"32px"}
                      minH={"32px"}
                      borderRadius={"50%"}
                      background={"Gray.$300"}
                      margin={"0px !important"}
                    >
                      {task.company.image_url ? (
                        <Image
                          src={task.company.image_url}
                          w={"32px"}
                          h={"32px"}
                          objectFit={"cover"}
                          borderRadius={"50%"}
                        />
                      ) : (
                        <Icon name="image" />
                      )}
                    </Center>
                    <Text
                      flex={1}
                      fontSize={"L_md"}
                      fontWeight={600}
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                    >
                      {task.company.name}
                    </Text>
                  </HStack>

                  <Text
                    w={"100%"}
                    fontSize={"L_xs"}
                    fontWeight={400}
                    color={"Gray.$700"}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                  >
                    {task.company.phrase_solution || "Solução não definida"}
                  </Text>

                  {status && (
                    <HStack
                      bg={status.bg}
                      borderRadius={"4px"}
                      px={"4px"}
                      alignItems={"center"}
                      spacing={1}
                    >
                      <Icon
                        name={status.icon}
                        size="12px"
                        color={status.color}
                      />
                      <Text
                        fontSize={"L_sm"}
                        fontWeight={600}
                        color={status.color}
                        whiteSpace={"nowrap"}
                      >
                        {status.label}
                      </Text>
                    </HStack>
                  )}
                </VStack>
              </VStack>
            )}
          </Center>
        </motion.div>
      </Stack>
    </motion.div>
  );
};
