import _axios from "..";
import { Historic, Task } from "../../types";

export const HistoricService = {
  create: async (body: Partial<Historic>): Promise<Historic> =>
    (await _axios.post("/historic", body)).data,

  getByTaskId: async (id: Task["id"]): Promise<Historic[]> =>
    (await _axios.get(`/historic/task/${id}`)).data,
};

export default HistoricService;
