import { HStack, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { Props } from "./types";
import { Icon } from "semente-js";
import CompanyProfileForm from "./Form";
import { FormProps } from "./Form/types";
import LegalFormatService from "src/services/company/Profile/legal-format";
import MarketSectorService from "src/services/company/Profile/market-sector";
import RevenueModelService from "src/services/company/Profile/revenue-model";
import BusinessModelService from "src/services/company/Profile/business-model";
import InvestmentsService from "src/services/company/Profile/investments";
import { sdgs_service } from "src/services/company/Profile";

type OptionProps = {
  label: string;
  value: string;
};

const ProfileDetails: React.FC<Props> = ({ company, handleUpdateCompany }) => {
  const [currentProfileForm, setCurrentProfileForm] = useState<
    FormProps["props"] & OptionProps
  >();

  const options: Array<typeof currentProfileForm> = [
    {
      label: "Formato jurídico",
      value:
        company.legal_format?.name ||
        "Selecione como a empresa foi formalizada.",
      title: "Qual o formato jurídico da empresa?",
      selecteds: [company.legal_format_id],
      subtitle: "Selecione abaixo como a empresa foi formalizada.",
      service: LegalFormatService,
    },
    {
      label: "Setor de mercado",
      value:
        company.market_sector?.name ||
        "Selecione em qual segmento de mercado a empresa se encaixa.",
      selecteds: [company.market_sector_id],
      title: "Qual é o setor de mercado da empresa?",
      subtitle: "Selecione em qual segmento de mercado a empresa se encaixa.",
      service: MarketSectorService,
    },
    {
      isMultiple: true,
      label: "Modelo de receita",
      value:
        company.revenue_models
          .map((revenue) => revenue.revenue_model?.name)
          .toString() || "Selecione para quem a empresa vende.",
      selecteds: company.revenue_models.map(
        (revenue) => revenue.revenue_model_id
      ),
      title: "Qual o modelo de receita da sua empresa?",
      subtitle:
        "Selecione para quem a empresa vende. Você pode selecionar mais de uma opção.",
      service: RevenueModelService,
    },
    {
      isMultiple: true,
      label: "Modelo de negócio",
      value:
        company.business_models
          .map((model) => model.business_model?.name)
          .toString() || "Selecione como a empresa gera receita.",
      selecteds: company.business_models.map(
        (model) => model.business_model_id
      ),
      title: "Qual o modelo de negócio da empresa?",
      subtitle:
        "Selecione como a empresa gera receita. Você pode selecionar mais de uma opção.",
      service: BusinessModelService,
    },
    {
      label: "Fonte de investimento",
      value:
        company.investments?.name ||
        "Selecione como empresa levanta recursos atualmente.",
      selecteds: [company.investments_id],
      title: "Qual é a principal fonte de investimento na empresa?",
      subtitle: "Selecione como empresa levanta recursos atualmente.",
      service: InvestmentsService,
    },
    {
      isMultiple: true,
      label: "Objetivo de Desenvolvimento Sustentável",
      value:
        company.sdgs.map((sdg) => sdg.sdg?.name).toString() ||
        "Selecione para quais ODS a empresa contribui.",
      selecteds: company.sdgs.map((sdg) => sdg.sdg_id),
      title: "Qual é a principal fonte de investimento na empresa?",
      subtitle: "Selecione como empresa levanta recursos atualmente.",
      service: sdgs_service,
    },
  ];

  return (
    <VStack w={"100%"} spacing={0}>
      {options.map((option, index) => (
        <HStack
          w={"100%"}
          py={"16px"}
          cursor={"pointer"}
          {...(options.length - 1 !== index && {
            borderBottom: "1px solid",
            borderColor: "Gray.$400",
          })}
          onClick={() => setCurrentProfileForm(option)}
        >
          <VStack w={"100%"} alignItems={"flex-start"}>
            <Text fontWeight={500} fontSize={"LMD"}>
              {option?.label}
            </Text>
            <Text fontWeight={400} fontSize={"LSM"} color={"Gray.$700"}>
              {option?.value}
            </Text>
          </VStack>

          <Icon name="angle-right-alt-solid" size="18px" />
        </HStack>
      ))}

      <CompanyProfileForm
        company_id={company.id}
        handleUpdateCompany={handleUpdateCompany}
        props={currentProfileForm as FormProps["props"]}
        handleClose={() => setCurrentProfileForm(undefined)}
      />
    </VStack>
  );
};

export default ProfileDetails;
