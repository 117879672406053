import { HStack, Text, useTheme, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { KanbanContext } from "src/contexts/KanbanContext";
import { ProjectViewContext } from "src/pages/Project/Context";
import Icon from "../../../../../components/base/Icon";
import { Props } from "./types";

export const BoardList: React.FC<Props> = ({ handleCreate, boards }) => {
  const { refreshKanban } = useContext(KanbanContext);
  const { setCurrentTabIn } = useContext(ProjectViewContext);
  const theme = useTheme();

  return (
    <VStack w={"100%"} alignItems={"flex-start"}>
      <VStack
        w={"100%"}
        mt={"16px !important"}
        border={"1px solid"}
        borderColor={"Gray.$400"}
        borderRadius={"12px"}
        px={"16px"}
      >
        {boards.map((board, index) => (
          <HStack
            w={"100%"}
            key={board.id}
            cursor={"pointer"}
            onClick={() => {
              setCurrentTabIn(1);
              refreshKanban(board.id);
            }}
            py={"16px"}
            borderBottom={"1px solid"}
            borderColor={
              index === boards.length - 1 ? "transparent" : "Gray.$400"
            }
          >
            <VStack alignItems={"flex-start"} flex={1}>
              <Text
                fontSize={"L_md"}
                fontFamily={"lato"}
                fontWeight={600}
                color={"Gray.$800"}
              >
                {board.name}
              </Text>
            </VStack>

            <Icon
              name={"angle-right"}
              color={theme.colors.Gray.$600}
              size={"24px"}
            />
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
};
