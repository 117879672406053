import _axios from "..";
import { Task } from "../../types";

export const TaskService = {
  update: async (id: Task["id"], newTask: Partial<Task>): Promise<Task> =>
    (await _axios.patch(`/task/${id}`, newTask)).data,

  create: async (newTask: Partial<Task>): Promise<Task> =>
    (await _axios.post(`/task`, newTask)).data,

  delete: async (id: Task["id"]): Promise<void> =>
    (await _axios.delete(`/task/${id}`)).data,

  getById: async (id: Task["id"]): Promise<Task> =>
    (await _axios.get(`/task/${id}`)).data,
};

export default TaskService;
