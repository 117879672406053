import { HStack, Text, VStack } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Button, Icon, Select } from "semente-js";
import { motion } from "framer-motion";
import SupersetService from "src/services/superset";
import { Props } from "../types";
import KanbanService from "src/services/kanban";
import HistoricService from "src/services/historic";
import { embedDashboard } from "src/components/SupersetEmbed";
import { SupersetIds } from "src/common";

const SupersetCurrentEnviroment =
  SupersetIds[
    (process.env.NODE_ENV as keyof typeof SupersetIds) || "development"
  ];

const tabs = [
  {
    title: "Relatório Geral",
    description:
      "Abrange todos os dados compilados durante a execução do projeto",
    dashboard_id: SupersetCurrentEnviroment[0],
    filters: [] as any[],
  },
  {
    title: "Relatório Individual",
    description:
      "Fornece informações detalhadas sobre cada negócio participante",
    dashboard_id: SupersetCurrentEnviroment[1],
    filters: [
      {
        label: "Kanban",
        value: "kanban_id",
        values: [""],
      },
      {
        label: "Negócio",
        value: "company_id",
        values: [""],
      },
    ],
  },
  {
    title: "Relatório das Consultorias",
    description:
      "Informações essenciais sobre a metodologia e o tema apresentados",
    dashboard_id: SupersetCurrentEnviroment[2],
    filters: [
      {
        label: "Kanban",
        value: "kanban_id",
      },
      {
        label: "Negócio",
        value: "company_id",
      },
      {
        label: "Consultoria",
        value: "column_name",
      },
    ],
  },
];

const Reports: React.FC<Props> = ({ project }) => {
  const [currentTab, setCurrentTab] = useState<number>();
  const [filterValues, setFilterValues] = useState<{}>({});
  const [filters, setFilters] = useState<any>({
    project_id: project.id,
  });
  const tab = tabs[currentTab as number];
  const supersetDiv = useRef<any>(null);

  useEffect(() => {
    if (filters.project_id) {
      setFilterValues((values) => ({
        ...values,
        kanban_id: project.boards.map((board) => ({
          label: board.name,
          value: board.id,
        })),
      }));
    }

    if (filters.kanban_id) {
      KanbanService.getById(filters.kanban_id).then((kanban) => {
        setFilterValues((values) => ({
          ...values,
          company_id: kanban.tasks
            .sort((a, b) => a.company.name.localeCompare(b.company.name))
            .map((task) => ({
              label: task.company.name,
              value: task.company.id,
            })),
        }));
      });
    }

    if (filters.company_id) {
      KanbanService.getById(filters.kanban_id).then((kanban) => {
        const task = kanban.tasks.find(
          (task) => task.company_id === filters.company_id
        );

        if (task) {
          HistoricService.getByTaskId(task.id).then((historic) => {
            setFilterValues((values) => ({
              ...values,
              column_name: historic.map((historic) => ({
                label: historic.column_name,
                value: historic.column_name,
              })),
            }));
          });
        }
      });
    }
  }, [filters, project]);

  useEffect(() => {
    setFilters({
      project_id: project.id,
    });
    setFilterValues({});
  }, [currentTab, project.id]);

  useEffect(() => {
    if (tab?.dashboard_id) {
      embedDashboard({
        id: tab?.dashboard_id || "", // given by the Superset embedding UI
        supersetDomain: `https://${window.location.hostname}`,
        mountPoint: document.getElementById(
          "superset-container"
        ) as HTMLElement, // any html element that can contain an iframe
        fetchGuestToken: async () =>
          await SupersetService.getDashboard(tab?.dashboard_id, filters),
        dashboardUiConfig: {
          filters: {
            visible: false,
            expanded: false,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, filters]);

  const handleDownloadDash = () => {
    const iframe = supersetDiv?.current?.getElementsByTagName("iframe")[0];
    iframe?.contentWindow?.print();
  };

  return (
    <AnimatePresence>
      {tab ? (
        <motion.div
          initial={{
            scale: 0.8,
          }}
          animate={{ scale: 1 }}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <HStack>
            <Text
              cursor={"pointer"}
              onClick={() => setCurrentTab(undefined)}
              fontSize={"L_sm"}
              fontWeight={500}
              textDecoration={"underline"}
            >
              Relatórios
            </Text>
            <Text fontSize={"L_sm"} fontWeight={500} textDecor={"dashed"}>
              / {tab.title}
            </Text>
          </HStack>
          <HStack w={"100%"} justifyContent={"space-between"}>
            <VStack alignItems={"flex-start"} spacing={0}>
              <Text
                mt={"12px"}
                fontSize={"RH_xl"}
                fontWeight={600}
                fontFamily={"raleway"}
              >
                {tab.title}
              </Text>
              <Text fontSize={"L_sm"} fontWeight={400} color={"Gray.$700"}>
                {tab.description}
              </Text>
            </VStack>

            <Button label="Baixar relatório" onClick={handleDownloadDash} />
          </HStack>

          <HStack my={"16px"}>
            {tab.filters.map((filter) => (
              <Select
                items={(filterValues as any)[filter.value] || []}
                isDisabled={!(filterValues as any)[filter.value]}
                placeholder={filter.label}
                layout="line"
                color="primary"
                onChange={(e) =>
                  setFilters({ ...filters, [filter.value]: e[0] })
                }
                value={[(filters as any)[filter.value]]}
              />
            ))}
          </HStack>

          <div
            id={"superset-container"}
            ref={supersetDiv}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#F5F5F5",
            }}
          />
        </motion.div>
      ) : (
        <motion.div
          initial={{
            scale: 0.8,
          }}
          animate={{ scale: 1 }}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <VStack
            maxW={"566px"}
            w={"100%"}
            alignItems={"flex-start"}
            spacing={0}
          >
            <Text fontSize={"RH_xl"} fontWeight={600} fontFamily={"raleway"}>
              Relatórios
            </Text>

            <VStack
              w={"100%"}
              pl={"16px"}
              mt={"32px"}
              spacing={0}
              borderRadius={"12px"}
              border={"1px solid"}
              borderColor={"Gray.$300"}
            >
              {tabs.map((tab, key) => (
                <HStack
                  key={key}
                  w={"100%"}
                  py={"16px"}
                  pr={"16px"}
                  onClick={() => setCurrentTab(key)}
                  borderBottom={"1px solid"}
                  borderColor={
                    tabs.length - 1 === key ? "transparent" : "Gray.$300"
                  }
                  cursor={"pointer"}
                >
                  <VStack w={"100%"} alignItems={"flex-start"}>
                    <Text fontSize={"L_md"} fontWeight={500}>
                      {tab.title}
                    </Text>
                    <Text
                      fontSize={"L_sm"}
                      fontWeight={400}
                      color={"Gray.$700"}
                    >
                      {tab.description}
                    </Text>
                  </VStack>

                  <Icon name="angle-right" size="18px" />
                </HStack>
              ))}
            </VStack>
          </VStack>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Reports;
