import { Box, Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Icon from "src/components/base/Icon";
import { RevenueModel } from "src/types";
import Props from "./types";

const ProfileSelector: React.FC<Props> = ({
  data,
  onPress,
  idSelecteds,
  isMultipleChoice,
}) => {
  const handlePressItem = (item: RevenueModel) => {
    if (isMultipleChoice) {
      const index = idSelecteds.findIndex((id) => id === item.id);
      if (index >= 0) {
        const newValues = idSelecteds;
        newValues.splice(index, 1);
        onPress([...newValues]);
      } else {
        onPress([...idSelecteds, item.id]);
      }
    } else {
      onPress([item.id]);
    }
  };

  return (
    <>
      {data.map((item, index) => (
        <HStack
          py={4}
          w={"100%"}
          borderBottomColor={"Gray.$400"}
          borderBottomWidth={data.length === index + 1 ? 0 : 1}
          alignItems={"center"}
          onClick={() => handlePressItem(item)}
          justifyContent={"space-between"}
          cursor={"pointer"}
        >
          <HStack alignItems={"center"}>
            {item?.image_url && (
              <Image src={item.image_url} alt="Alternate Text" mr={3} />
            )}
            <VStack flex={1} alignItems={"flex-start"}>
              <Text
                fontFamily={"lato"}
                fontSize={"L_md"}
                color={"Gray.$800"}
                fontWeight={400}
              >
                {item.name}
              </Text>
              {item?.description && (
                <Text
                  mt={1}
                  fontFamily={"lato"}
                  fontSize={"L_sm"}
                  color={"Gray.$700"}
                  fontWeight={400}
                >
                  {item.description}
                </Text>
              )}
            </VStack>
          </HStack>
          {isMultipleChoice ? (
            <Center
              ml={2}
              transition={"300ms all"}
              minW={6}
              minH={6}
              borderRadius={4}
              borderColor={"Tertiary.pure"}
              borderWidth={1}
              backgroundColor={
                idSelecteds.find((id) => id === item.id)
                  ? "Tertiary.pure"
                  : "white"
              }
              cursor={"pointer"}
            >
              <Icon name="check" size={"16px"} color={"#FFFFFF"} />
            </Center>
          ) : (
            <Center
              ml={2}
              minW={6}
              minH={6}
              borderRadius={12}
              borderColor={"Tertiary.pure"}
              borderWidth={1}
              transition={"300ms all"}
              backgroundColor={
                idSelecteds.find((id) => id === item.id)
                  ? "Tertiary.pure"
                  : "white"
              }
              cursor={"pointer"}
            >
              <Box w={2} h={2} borderRadius={12} backgroundColor={"white"} />
            </Center>
          )}
        </HStack>
      ))}
    </>
  );
};

export default ProfileSelector;
