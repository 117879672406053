import { Button, Center, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Props } from "./types";
import Icon from "src/components/base/Icon";

const AreaEmptyState: React.FC<Props> = ({ handleCreate }) => {
  return (
    <Center
      w={"100%"}
      py={"16px"}
      border={"1px solid"}
      borderRadius={"16px"}
      borderColor={"Gray.$400"}
    >
      <VStack spacing={"16px"}>
        <Text fontWeight={600} fontSize={"L_md"} color={"Gray.$800"}>
          Não existem áreas aqui ainda
        </Text>
        <Button minH={"36px"} onClick={handleCreate}>
          <HStack spacing={"12px"}>
            <Text fontWeight={600} fontSize={"L_sm"} color={"Gray.$800"}>
              Adicionar Área
            </Text>
            <Icon name={"arrow-right"} />
          </HStack>
        </Button>
      </VStack>
    </Center>
  );
};

export default AreaEmptyState;
