import { Box, Center, Stack, VStack } from "@chakra-ui/react";
import { useUserContext } from "../../contexts/UserContext";
import { Outlet, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BugBallon from "../BugBallon";
import { Header, Loading, MenuItem, UserItem } from "semente-js";
import { PERMISSIONS } from "src/permissions";

export const ProtectedLayout = () => {
  const { user, userAlredyLoaded, logout } = useUserContext();
  const navigate = useNavigate();

  const MENU_ITEMS: MenuItem[] = [
    {
      label: "Projetos",
      route: "/",
    },
    {
      label: "Clientes",
      route: "/clients",
      requiredClientRoles: [PERMISSIONS.CLIENT_VIEW],
    },
    {
      label: "Empresas",
      route: "/companies",
      requiredClientRoles: [PERMISSIONS.COMPANY_VIEW],
    },
    {
      label: "Equipe",
      route: "/team",
      requiredClientRoles: [PERMISSIONS.MEMBER_VIEW],
    },
  ];

  const USER_ITEMS: UserItem[] = [
    {
      icon: "setting-solid",
      label: "Configurações",
      onClick: () => navigate("/settings"),
      requiredClientRoles: [PERMISSIONS.SETTINGS],
    },
    { icon: "signout-solid", label: "Terminar sessão", onClick: logout },
  ];

  return userAlredyLoaded ? (
    user ? (
      <VStack w="100%" h={"100%"} spacing={0}>
        <BugBallon />

        <Header
          className="py-3"
          urlImage={Logo}
          menu={MENU_ITEMS}
          onClickApp={() => {}}
          userItems={USER_ITEMS}
          onClickMenu={({ route }) => navigate(route)}
          user={{ email: user.email, lastName: user.surname, name: user.name }}
        />

        <Stack
          m={"0px !important"}
          w={"100%"}
          h={"1px"}
          borderBottom="1px solid"
          borderColor="gray.100"
        />

        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Box
          margin={"0px !important"}
          w={"100%"}
          h={"100%"}
          position={"relative"}
        >
          <Outlet />
        </Box>
      </VStack>
    ) : (
      <></>
    )
  ) : (
    <Center h={"100vh"}>
      <Loading />
    </Center>
  );
};
