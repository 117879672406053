import {
  Box,
  HStack,
  Stack,
  Text,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useRef, useState } from "react";
import Icon from "src/components/base/Icon";
import { Props } from "../types";
import ClientsSection from "./Tabs/ClientSection";
import { fadeInUp } from "src/animations";
import { motion } from "framer-motion";
import DetailsTabProject from "./Tabs/Details";
import { Modal } from "src/components/base/Modal";
import DeleteConfirmation from "./DeleteConfirmation";
import { ProjectContext } from "src/contexts/ProjectContext";
import TabNavigator from "src/components/TabNavigator";
import { Checklist } from "src/pages/Settings/Tabs";
import InidcatorTab from "./Tabs/Indicators";

const SettingsTabProject: React.FC<Props> = ({ project }) => {
  const [currentTabIn, setCurrentTabIn] = useState(0);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { handleDeleteProject } = useContext(ProjectContext);
  const menuRef = useRef<any>(null);

  const menuOptions = [
    {
      title: "Excluir projeto",
      color: "#CC0000",
      onClick: () => setShowConfirmation(true),
    },
  ];

  useOutsideClick({
    ref: menuRef,
    handler: () => setMenuIsOpen(false),
  });

  const tabs = [
    {
      label: "Detalhes do projeto",
      Component: <DetailsTabProject project={project} />,
    },
    {
      label: "Clientes",
      Component: <ClientsSection clients={project.clients} project={project} />,
    },
    {
      label: "Checklists",
      Component: <Checklist project_id={project.id} />,
    },
    {
      label: "Indicadores",
      Component: <InidcatorTab project_id={project.id} />,
    },
  ];

  const handleDelete = async () => {
    await handleDeleteProject(project.id);
  };

  return (
    <VStack w={"100%"} h={"100%"} alignItems={"flex-start"}>
      <VStack
        alignItems={"flex-start"}
        w={"100%"}
        h={"100%"}
        maxW={"536px"}
        spacing={0}
      >
        <HStack
          w={"100%"}
          justifyContent={"space-between"}
          position={"relative"}
        >
          <Text fontSize={"RH_xl"} fontWeight={500}>
            Configurações
          </Text>

          <Box cursor={"pointer"} onClick={() => setMenuIsOpen(true)}>
            <Icon name={"ellipsis-h"} />
          </Box>

          {menuIsOpen && (
            <VStack
              py={"8px"}
              ref={menuRef}
              right={"0px"}
              top={"40px"}
              position={"absolute"}
              zIndex={200}
              background={"Gray.$100"}
              minW={"240px"}
              borderRadius={"12px"}
              boxShadow={"0px 2px 16px rgba(0, 0, 0, 0.12)"}
            >
              {menuOptions.map((option) => (
                <Stack
                  cursor={"pointer"}
                  key={option.title}
                  padding={"12px 16px"}
                  onClick={() => option.onClick()}
                  w={"100%"}
                  alignItems={"flex-start"}
                  transition={"300ms all"}
                  _hover={{
                    background: "Gray.$200",
                  }}
                >
                  <Text fontSize={"L_sm"} fontWeight={400} color={option.color}>
                    {option.title}
                  </Text>
                </Stack>
              ))}
            </VStack>
          )}
        </HStack>

        <TabNavigator
          handleClick={setCurrentTabIn}
          currentIn={currentTabIn}
          tabs={tabs}
        />

        <motion.div {...fadeInUp} style={{ width: "100%" }}>
          <VStack
            mt={"36px !important"}
            pb={"36px"}
            borderRadius={"16px"}
            w={"100%"}
          >
            {tabs[currentTabIn].Component}
          </VStack>
        </motion.div>
      </VStack>

      <Modal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        title={"Excluir projeto"}
      >
        <DeleteConfirmation
          handleClose={() => setShowConfirmation(false)}
          project={project}
          handleDelete={handleDelete}
        />
      </Modal>
    </VStack>
  );
};

export default SettingsTabProject;
