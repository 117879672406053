import _axios from "..";
import { Company, CompanyWithProfile, Users_company_datas } from "../../types";
import { ISearchResult } from "../types";

export const CompanyService = {
  create: async (
    newCompany: Partial<Company>,
    user_id: string
  ): Promise<Users_company_datas & { company: Company }[]> =>
    (await _axios.post(`company/${user_id}`, newCompany)).data,

  update: async (
    company: Partial<Company>,
    user_id?: string
  ): Promise<Users_company_datas[]> =>
    (await _axios.patch(`company${user_id ? `/${user_id}` : ""}`, company))
      .data,

  getCompanies: async (): Promise<Company[]> =>
    (await _axios.get("/companies")).data,

  getCompanyById: async (id: Company["id"]): Promise<CompanyWithProfile> =>
    (await _axios.get(`/company/${id}`)).data,

  getCompanyEvolutionById: async (
    id: Company["id"]
  ): Promise<CompanyWithProfile> =>
    (await _axios.get(`/company/${id}/evolution`)).data,

  checkIfExistsById: async (id: Company["id"]): Promise<void> =>
    (await _axios.get(`/company/check/${id}`)).data,

  getCompaniesPaginated: async (
    params: Object
  ): Promise<ISearchResult<CompanyWithProfile>> =>
    (await _axios.get("/companies/paginated", { params })).data,
};

export default CompanyService;
