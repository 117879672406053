import { Area, AreaWithProjects } from "src/types";
import _axios from "..";

export const AreaService = {
  create: async (body: Partial<Area>): Promise<AreaWithProjects> =>
    (await _axios.post("area", body)).data,

  update: (body: Partial<Area> & { id: string }): Promise<void> =>
    _axios.patch("area", body),

  delete: (area_id: Area["id"]): Promise<void> =>
    _axios.delete(`area/${area_id}`),

  getAreas: async (): Promise<AreaWithProjects[]> =>
    (await _axios.get("areas")).data,
};

export default AreaService;
