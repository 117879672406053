import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Props } from "../types";
import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { Icon } from "semente-js";
import TextArea from "src/components/base/TextArea/Index";

const HypothesesForm: React.FC<Props> = ({
  onClickBack,
  current,
  handleSubmitForm,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ defaultValues: current });

  return (
    <VStack w={"100%"} alignItems={"flex-start"}>
      <HStack spacing={"8px"} mb={"0px !important"} py={"14px"}>
        <Icon name={"arrow-left-alt"} size="18px" />
        <Text
          onClick={() => {
            onClickBack();
          }}
          fontWeight={500}
          cursor={"pointer"}
          fontSize={"L_md"}
        >
          Voltar
        </Text>
      </HStack>

      <Text fontWeight={600} fontSize={"RH_sm"} fontFamily={"Raleway"}>
        Qual hipótese você quer validar?
      </Text>
      <Text
        mt={"4px !important"}
        mb={"24px"}
        fontWeight={400}
        fontSize={"L_sm"}
        color={"Gray.$700"}
      >
        Uma hipótese é sempre uma afirmação, é algo que você supõe ser
        verdadeiro, mas que precisa ser verificado.
      </Text>

      <Controller
        control={control}
        name={"title"}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <TextArea
            {...field}
            placeholder="Hipótese"
            isInvalid={!!fieldState.error}
          />
        )}
      />

      <Button
        onClick={handleSubmit(handleSubmitForm)}
        isLoading={isSubmitting}
        borderRadius={"4px"}
        variant={"Primary"}
        alignSelf={"end"}
      >
        Continuar
      </Button>
    </VStack>
  );
};

export default HypothesesForm;
