import React, { useState, useRef, forwardRef, useEffect } from "react";
import {
  StyledPlaceHolder,
  StyledBoxSelect,
  StyledDropdown,
  StyledItem,
} from "./styles";

import { Props } from "./types";
import {
  Box,
  HStack,
  Image,
  Text,
  useOutsideClick,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import useCombinedRefs from "../../../hooks/useCombinedRefs";
import Icon from "../Icon";
import { createImageFromInitials } from "src/common";

export const Select: React.FC<Props> = forwardRef((props, ref: any) => {
  const innerRef = useRef<any>();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const combinedRef = useCombinedRefs(ref, innerRef);

  const newValue =
    typeof props.value === "string"
      ? props.items.find((item) => item.value === props.value)
      : props.value;

  useEffect(() => {
    setIsOpen(false);
  }, [props.value]);

  useOutsideClick({
    ref: combinedRef,
    handler: () => setIsOpen(false),
  });

  return (
    <VStack position={"relative"} w={"100%"} m={"0px !important"}>
      <StyledDropdown isOpen={isOpen}>
        <VStack w={"100%"} py={"8px"} alignItems={"flex-start"}>
          {props.items.map((item) => (
            <StyledItem
              key={item.value}
              isSelected={false}
              onClick={() => {
                props.onChange(item);
              }}
            >
              {item?.icon !== undefined && (
                <Image
                  src={
                    item.icon ||
                    createImageFromInitials({
                      size: 24,
                      name: item.label.substring(0, 1),
                      color: theme.colors.Gray.$800,
                      background: theme.colors.Gray.$400,
                    })
                  }
                  w={"24px"}
                  h={"24px"}
                  borderRadius={"50%"}
                  mr={"10px"}
                />
              )}

              <Text fontSize={"16px"} variant={"Poppins"} color={"#221F1D"}>
                {item.label}
              </Text>
            </StyledItem>
          ))}
          {props.aditionalButton && (
            <VStack
              w={"100%"}
              borderTop={"1px solid"}
              borderColor={"Gray.$400"}
            >
              {props.aditionalButton}
            </VStack>
          )}
        </VStack>
      </StyledDropdown>

      <StyledBoxSelect
        {...props}
        onClick={() => setIsOpen(true)}
        onChange={() => {}}
        inInput={isOpen}
        theme={theme}
        ref={ref}
      >
        <StyledPlaceHolder labelIsUpped={!!props.value || isOpen}>
          <Text
            flex={1}
            fontFamily={"lato"}
            color={
              props.isDisabled
                ? "Gray.$500"
                : props.isInvalid
                ? "Red.pure"
                : isOpen
                ? "Tertiary.pure"
                : "Gray.$700"
            }
            fontSize={props.value || isOpen ? "12px" : "16px"}
          >
            {props?.placeholder}
          </Text>
        </StyledPlaceHolder>

        <HStack position={"relative"} w={"100%"}>
          {newValue?.icon !== undefined && (
            <Image
              src={
                newValue.icon ||
                createImageFromInitials({
                  size: 24,
                  name: newValue.label.substring(0, 1),
                  color: theme.colors.Gray.$800,
                  background: theme.colors.Gray.$400,
                })
              }
              w={"24px"}
              h={"24px"}
              borderRadius={"50%"}
            />
          )}
          <Text fontSize={"16px"} variant={"Poppins"} color={"#221F1D"}>
            {newValue?.label}
          </Text>
        </HStack>
        <Box
          position={"absolute"}
          transition={"300ms all"}
          transform={`rotate(${isOpen ? 0.5 : 0}turn)`}
          right={"18px"}
          top={"18px"}
        >
          <Icon name={"angle-down"} size={"18px"} />
        </Box>
      </StyledBoxSelect>
    </VStack>
  );
});
