import {
  Box,
  Center,
  HStack,
  Image,
  Text,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useRef } from "react";
import Icon from "src/components/base/Icon";
import UnionIcon from "../../assets/images/superIcons/Union.svg";

import { Props } from "./types";
import PathColors from "./common";

export const PathSelect: React.FC<Props> = ({ value, handleSelectPath }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const menuRef = useRef<any>(null);

  useOutsideClick({
    ref: menuRef,
    handler: () => setMenuIsOpen(false),
  });

  return (
    <HStack
      position={"relative"}
      borderRadius={"8px"}
      cursor={"pointer"}
      border={"1px solid "}
      borderColor={"Gray.$400"}
      w={"100%"}
      h={"100%"}
    >
      <HStack
        onClick={() => setMenuIsOpen(!menuIsOpen)}
        w={"100%"}
        p={"8px"}
        justify={"between"}
      >
        <HStack flex={1} spacing={"8px"}>
          <Box
            w={"8px"}
            h={"8px"}
            transition={"300ms"}
            borderRadius={"50%"}
            background={PathColors[value || 0].bg}
          />

          <VStack spacing={0} alignItems={"flex-start"}>
            <Text
              color={"Gray.$800"}
              fontFamily={"lato"}
              fontSize={"L_xs"}
              fontWeight={600}
            >
              Status
            </Text>

            <Text
              color={"Gray.$800"}
              fontFamily={"lato"}
              fontSize={"L_md"}
              fontWeight={400}
            >
              {PathColors[value || 0].label}
            </Text>
          </VStack>
        </HStack>

        <Icon name={"angle-down"} />
      </HStack>

      <VStack
        boxShadow={"0px 2px 16px rgba(0, 0, 0, 0.12)"}
        maxH={menuIsOpen ? "300px" : "0"}
        margin={"0px !important"}
        transition={"300ms all"}
        position={"absolute"}
        borderRadius={"12px"}
        background={"white"}
        overflow={"hidden"}
        ref={menuRef}
        top={"66px"}
        w={"100%"}
        zIndex={5}
        left={0}
      >
        <VStack py={"8px"} w={"100%"} h={"100%"}>
          {PathColors.map((icon, index) => (
            <HStack
              key={index}
              p={"12px 16px"}
              h={"100%"}
              w={"100%"}
              transition={"300ms all"}
              _hover={{ background: "Gray.$300" }}
              onClick={() => {
                setMenuIsOpen(false);
                handleSelectPath(index);
              }}
              background={value === index ? "rgba(0,0,0, 0.1)" : "transparent"}
            >
              <Center
                background={icon.bg}
                w={"24px"}
                h={"24px"}
                borderRadius={"4px"}
              >
                <Image src={UnionIcon} alt={"Icon"} />
              </Center>
              <Text
                color={"Gray.$800"}
                margin={"0px !important"}
                ml={"6px !important"}
                fontFamily={"lato"}
                fontSize={"L_sm"}
                fontWeight={400}
              >
                {PathColors[index].label}
              </Text>
            </HStack>
          ))}
        </VStack>
      </VStack>
    </HStack>
  );
};
