import React from "react";
import { Modal } from "src/components/base/Modal";
import { CreateProjectModal } from "src/pages/Project/CreateProjectModal";
import ProjectCompanyInvite from "src/pages/Project/CreateProjectModal/CompanyInviteDialog";
import ProjectConsultantInvite from "src/pages/Project/CreateProjectModal/ConsultantInviteDialog";
import { Project } from "src/types";

type Dialogs = "companies" | "consultants" | "create";

type DialogProps = {
  project: Project;
  onClose: () => void;
  dialog: keyof typeof ProjectDialogs;
};

type ProjectComponent<T extends string | number | symbol, K extends Object> = {
  [id in T]: (props: K) => React.ReactNode;
};

export const ProjectDialogs: ProjectComponent<Dialogs, DialogProps> = {
  companies: (props) => (
    <Modal onClose={props.onClose} isOpen={props.dialog === "companies"}>
      <ProjectCompanyInvite {...props} defaultAdd />
    </Modal>
  ),
  consultants: (props) => (
    <Modal onClose={props.onClose} isOpen={props.dialog === "consultants"}>
      <ProjectConsultantInvite {...props} defaultAdd />
    </Modal>
  ),
  create: (props) => (
    <CreateProjectModal
      onClose={props.onClose}
      isOpen={props.dialog === "create"}
    />
  ),
};
