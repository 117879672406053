import React, { useRef } from "react";
import { Props } from "../types";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { Icon } from "semente-js";
import { formatDate } from "src/common";

const TimePicker: React.FC<Props> = ({ handleChangeDate, date }) => {
  const dateRef = useRef<any>(null);

  return (
    <VStack position={"relative"}>
      <HStack
        py={"10px"}
        px={"12px"}
        zIndex={2}
        cursor={"pointer"}
        borderRadius={"8px"}
        background={"Gray.$200"}
        onClick={() => dateRef.current.showPicker()}
      >
        <Icon name={"clock-eight-solid"} size="14px" />

        <Text fontSize={"LSM"} fontWeight={500}>
          {date ? formatDate(date, "HH:mm") : "Não definido"}
        </Text>

        <Icon name={"angle-down"} size="14px" />
      </HStack>

      <input
        type="time"
        ref={dateRef}
        onChange={(e) => {
          if (e.target.value) {
            const dataObj = date || new Date();
            const [hours, minutes] = e.target.value.split(":");
            dataObj.setHours(parseInt(hours));
            dataObj.setMinutes(parseInt(minutes));

            handleChangeDate(dataObj);
            return;
          }

          dateRef.current.blur();
        }}
        style={{
          position: "absolute",
          top: "20px",
          left: "0",
          width: "100%",
        }}
      />
    </VStack>
  );
};

export default TimePicker;
