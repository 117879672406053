import _axios from "..";
import { Archive, Task } from "../../types";

export const ArchiveService = {
  create: async (body: Partial<Archive>): Promise<Archive> =>
    (await _axios.post("/archive", body)).data,

  delete: async (id: Archive["id"]): Promise<void> =>
    (await _axios.delete(`/archive/${id}`)).data,

  getByTaskId: async (id: Task["id"]): Promise<Archive[]> =>
    (await _axios.post(`/archive/task/${id}`)).data,
};

export default ArchiveService;
