import { Button, Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React, { useState, useCallback, useEffect, useContext } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { MutatingDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { doneSuperIcon } from "../../../../../assets/images";
import Icon from "../../../../../components/base/Icon";
import Input from "../../../../../components/base/Input/Index";
import { Modal } from "../../../../../components/base/Modal";
import { Select } from "../../../../../components/base/Select";
import ProjectsService from "../../../../../services/projects";
import { Project, User } from "../../../../../types";
import { motion } from "framer-motion";
import { Props } from "./types";
import { fadeInUp } from "../../../../../animations";
import { ProjectContext } from "../../../../../contexts/ProjectContext";
import UserService from "src/services/user";
import Checkbox from "src/components/Checkbox";
import { UserContext } from "src/contexts/UserContext";

const defaultValues = {
  consultants: [] as User[],
};

const defaultFilters = {
  filter: undefined as any,
  project: undefined as any,
  type: ["consulting", "manager"],
};

const ConsultantAdd: React.FC<Props> = ({
  project,
  handleClose,
  handleFinish,
}) => {
  const { user } = useContext(UserContext);
  const { projects, selectProject } = useContext(ProjectContext);
  const [defaultUsers] = useState(
    project?.consultants?.map((consultant) => consultant.user_id)
  );
  const [timer, setTimer] = useState<any>(null);
  const [filters, setFilters] = useState(defaultFilters);
  const [consultants, setConsultants] = useState<User[]>();
  const [isLoading, setIsLoading] = useState(true);
  const {
    watch,
    control,
    handleSubmit,
    formState: { isSubmitSuccessful, isSubmitting },
  } = useForm({ defaultValues });

  const populateConsultants = useCallback(async () => {
    setIsLoading(true);
    try {
      setConsultants(
        (await UserService.getUsersPaginated(filters)).result.filter(
          (pUser) => pUser.id !== user?.id
        )
      );
    } catch (err) {
      handleClose();
      toast.error("Ocorreu um erro ao buscar os consultores.");
    }
    setIsLoading(false);
  }, [handleClose, filters, user?.id]);

  useEffect(() => {
    populateConsultants();
  }, [populateConsultants, filters]);

  const handleSubmitForm: SubmitHandler<typeof defaultValues> = async (
    values
  ) => {
    if (project && "id" in project) {
      try {
        await Promise.all(
          values.consultants.map(
            async (user) =>
              await ProjectsService.createProjectConsultant({
                consultant_id: user.id,
                project_id: project.id || "",
              })
          )
        );

        await selectProject(project.id, true);
      } catch (err) {
        toast.error("Ocorreu um erro ao enviar os consultores.");
      }
    }
  };

  const handleChangeFilter = (filter: string) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(setTimeout(() => setFilters({ ...filters, filter }), 500));
  };

  return (
    <Modal
      isOpen={true}
      title={isSubmitSuccessful ? "" : "Convidar pessoas consultoras"}
      onClose={handleClose}
    >
      <VStack
        w={"100%"}
        maxW={"568px"}
        borderTop={"1px solid"}
        borderColor={"Gray.$400"}
      >
        {isSubmitting ? (
          <Center minH={"448px"}>
            <MutatingDots
              height="100"
              width="100"
              color="#408EC5"
              secondaryColor="#408EC5"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              visible={true}
            />
          </Center>
        ) : (
          <VStack w={"100%"} h={"100%"} position={"relative"}>
            {isSubmitSuccessful ? (
              <VStack p={"24px"} w={"100%"} alignItems={"flex-start"}>
                <Image src={doneSuperIcon} alt={"Ícone de sucesso"} />

                <Text
                  mt={"24px !important"}
                  fontWeight={600}
                  fontSize={"RH_lg"}
                  fontFamily={"Raleway"}
                >
                  Consultores adicionados!
                </Text>
                <Text
                  fontWeight={400}
                  fontSize={"L_md"}
                  color={"Gray.$700"}
                  mt={"4px !important"}
                >
                  Os consultores selecionados já foram adicionadas ao projeto{" "}
                  {project.name}.
                </Text>

                <VStack spacing={0} w={"100%"} mt={"24px !important"}>
                  {watch("consultants").map((user) => (
                    <HStack
                      key={user.id}
                      p={"16px"}
                      w={"100%"}
                      background={"Gray.$200"}
                      borderRadius={"12px"}
                    >
                      <Center
                        w={"40px"}
                        h={"40px"}
                        borderRadius={"50%"}
                        background={"Gray.$300"}
                      >
                        {user.image_url ? (
                          <Image
                            src={user.image_url}
                            borderRadius={"50%"}
                            objectFit={"cover"}
                            w={"100%"}
                            h={"100%"}
                          />
                        ) : (
                          <Icon name="image" />
                        )}
                      </Center>

                      <Text
                        fontWeight={600}
                        ml={"16px !important"}
                        fontSize={"L_lg"}
                        color={"Gray.$800"}
                      >
                        {user.name}
                      </Text>
                    </HStack>
                  ))}
                </VStack>
              </VStack>
            ) : (
              <VStack w={"100%"} px={"12px"}>
                <HStack mt={"24px"} w={"100%"} px={"12px"}>
                  <HStack
                    pb={"24px"}
                    w={"100%"}
                    borderBottom={"1px solid"}
                    spacing={"12px !important"}
                    borderColor={"Gray.$400"}
                  >
                    <Center
                      w={"24px"}
                      h={"24px"}
                      borderRadius={"50%"}
                      background={"Gray.$300"}
                    >
                      {project.image_url ? (
                        <Image
                          src={project.image_url}
                          borderRadius={"50%"}
                          objectFit={"cover"}
                          w={"100%"}
                          h={"100%"}
                        />
                      ) : (
                        <Icon name="image" />
                      )}
                    </Center>
                    <Text fontWeight={400} fontSize={"L_md"}>
                      {project.name}
                    </Text>
                  </HStack>
                </HStack>

                <VStack w={"100%"} px={"12px"}>
                  <HStack
                    w={"100%"}
                    spacing={"16px !important"}
                    my={"24px !important"}
                  >
                    <Input
                      placeholder={"Procurar consultor"}
                      icon={"search"}
                      onChange={({ target }) =>
                        handleChangeFilter(target.value)
                      }
                    />
                    {projects.data && (
                      <Select
                        placeholder={"Projeto"}
                        items={(projects.data as Project[]).map((project) => ({
                          label: project.name,
                          value: project.id || "",
                        }))}
                        onChange={(e) => setFilters({ ...filters, project: e })}
                        value={filters.project}
                      />
                    )}
                  </HStack>
                  <HStack w={"100%"} justifyContent={"space-between"}>
                    <Text
                      fontSize={"L_md"}
                      fontWeight={600}
                      color={"Gray.$800"}
                    >
                      Pessoas consultoras cadastradas da Rama
                    </Text>
                    <Text
                      fontSize={"L_sm"}
                      fontWeight={400}
                      color={"Gray.$700"}
                    >
                      {(defaultUsers?.length || 0) +
                        watch("consultants").length}{" "}
                      pessoas selecionadas
                    </Text>
                  </HStack>
                </VStack>

                {isLoading ? (
                  <Center minH={"438px"}>
                    <MutatingDots
                      height="100"
                      width="100"
                      color="#408EC5"
                      secondaryColor="#408EC5"
                      radius="12.5"
                      ariaLabel="mutating-dots-loading"
                      visible={true}
                    />
                  </Center>
                ) : (
                  <>
                    {consultants && consultants.length >= 1 ? (
                      <VStack w={"100%"} h={"100%"} px={"12px"}>
                        <Controller
                          name={"consultants"}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <>
                              {consultants?.map((user, index) => (
                                <motion.div
                                  key={user.id}
                                  {...fadeInUp}
                                  style={{ cursor: "pointer", width: "100%" }}
                                >
                                  <HStack
                                    m={"0px !important"}
                                    alignItems={"flex-start"}
                                    cursor={"pointer"}
                                    onClick={() => {
                                      if (!defaultUsers?.includes(user.id)) {
                                        if (
                                          value.find((c) => c.id === user.id)
                                        ) {
                                          onChange(
                                            value.filter(
                                              (c) => c.id !== user.id
                                            )
                                          );
                                        } else {
                                          onChange([...value, user]);
                                        }
                                      }
                                    }}
                                    py={"16px"}
                                    w={"100%"}
                                  >
                                    <Center
                                      w={"48px"}
                                      h={"48px"}
                                      borderRadius={"50%"}
                                      background={"Gray.$300"}
                                    >
                                      {user.image_url ? (
                                        <Image
                                          src={user.image_url}
                                          borderRadius={"50%"}
                                          objectFit={"cover"}
                                          w={"100%"}
                                          h={"100%"}
                                        />
                                      ) : (
                                        <Icon name="image" />
                                      )}
                                    </Center>
                                    <HStack
                                      ml={"12px !important"}
                                      flex={1}
                                      pb={"18px"}
                                      borderBottom={"1px solid"}
                                      borderColor={
                                        consultants.length - 1 === index
                                          ? "transparent"
                                          : "Gray.$400"
                                      }
                                    >
                                      <VStack
                                        flex={1}
                                        alignItems={"flex-start"}
                                      >
                                        <Text
                                          fontWeight={400}
                                          fontSize={"L_md"}
                                          color={"Gray.$800"}
                                        >
                                          {user.name}
                                        </Text>
                                      </VStack>

                                      <Checkbox
                                        isChecked={
                                          !!value.find(
                                            (c) => c.id === user.id
                                          ) || defaultUsers?.includes(user.id)
                                        }
                                        isDisabled={defaultUsers?.includes(
                                          user.id
                                        )}
                                      />
                                    </HStack>
                                  </HStack>
                                </motion.div>
                              ))}
                            </>
                          )}
                        />
                      </VStack>
                    ) : (
                      <Center my={"32px"}>
                        <Text fontWeight={400} fontSize={"L_md"}>
                          Nenhum consultor cadastrado na plataforma
                        </Text>
                      </Center>
                    )}
                  </>
                )}
              </VStack>
            )}
            <HStack
              justifyContent={"flex-end"}
              p={"24px"}
              w={"100%"}
              borderTop={"1px solid"}
              borderColor={"Gray.$400"}
              background={"white"}
              bottom={0}
              position={"sticky"}
            >
              <Button
                variant={"Primary"}
                disabled={
                  watch("consultants")?.length < 1 && !isSubmitSuccessful
                }
                onClick={
                  isSubmitSuccessful
                    ? () => handleFinish()
                    : () => handleSubmit(handleSubmitForm)()
                }
              >
                {isSubmitSuccessful ? "Concluído" : "Adicionar consultores"}
              </Button>
            </HStack>
          </VStack>
        )}
      </VStack>
    </Modal>
  );
};

export default ConsultantAdd;
