import { StyledBoxInput, StyledTextInput, StyledPlaceHolder } from "./styles";
import { Box, Text, useOutsideClick, useTheme } from "@chakra-ui/react";
import React, { forwardRef, useState, useRef, useEffect } from "react";
import { Props } from "./types";
import { masks } from "./masks";
import useCombinedRefs from "../../../hooks/useCombinedRefs";
import { motion, useAnimation } from "framer-motion";
import { Calendar } from "react-date-range";
import Icon from "../Icon";
import pt from "date-fns/locale/pt-BR";

const Input: React.FC<Props> = forwardRef((props, ref: any) => {
  const [showPassword, setShowPassword] = useState(props.type === "password");
  const [dateModal, setDateModal] = useState(false);
  const [InFocus, setInFocus] = useState(false);
  const innerRef = useRef<any>(null);
  const combinedRef = useCombinedRefs(ref, innerRef);
  const controls = useAnimation();
  const dateRef = useRef<any>();
  const theme = useTheme();

  useOutsideClick({
    ref: dateRef,
    handler: () => setDateModal(false),
  });

  const applyMask = () => masks[props.variant || "text"](props.value || "");
  const removeMask = (val: string) => masks.removeMask(val);

  const getRandomDelay = () => -(Math.random() * 0.7 + 0.05);
  const randomDuration = () => Math.random() * 0.07 + 0.23;

  useEffect(() => {
    if (props.isInvalid) {
      controls.start("start");
    }
  }, [props.isInvalid, controls]);

  const variants = {
    start: () => ({
      rotate: [1, -1.4, 0],
      transition: {
        delay: getRandomDelay(),
        duration: randomDuration(),
      },
    }),
  };

  return (
    <StyledBoxInput
      onClick={() => props.variant === "date" && setDateModal(true)}
      width={props.width}
    >
      <motion.div
        variants={variants}
        animate={controls}
        style={{ position: "relative" }}
      >
        {!props.icon && (
          <StyledPlaceHolder
            variant={props.variant}
            labelIsUpped={!!props.value || InFocus}
            onClick={() => combinedRef.current.focus()}
          >
            <Text
              flex={1}
              fontFamily={"lato"}
              color={
                props.isDisabled
                  ? "Gray.$500"
                  : props.isInvalid
                    ? "Red.pure"
                    : InFocus
                      ? "Tertiary.pure"
                      : "Gray.$700"
              }
              fontSize={props.value || InFocus ? "12px" : "16px"}
            >
              {props?.placeholder}
            </Text>
          </StyledPlaceHolder>
        )}

        {props.maxLength && (
          <Text
            top={2}
            right={4}
            position={"absolute"}
            fontFamily={"lato"}
            color={
              props.isDisabled
                ? "Gray.$500"
                : props.isInvalid
                  ? "Red.pure"
                  : InFocus
                    ? "Tertiary.pure"
                    : "Gray.$700"
            }
            fontSize={"12px"}
          >
            {props.value?.length || 0} / {props.maxLength}
          </Text>
        )}

        {(props.variant === "date" || props.icon) && (
          <>
            <Box position={"absolute"} left={"14px"} top={"18px"}>
              <Icon
                color={
                  InFocus
                    ? (theme.colors as any).Tertiary.pure
                    : (theme.colors as any).Gray.$700
                }
                name={props.variant === "date" ? "schedule" : "search"}
                size={"20px"}
              />
            </Box>

            {props.icon && (
              <Box
                position={"absolute"}
                right={"14px"}
                top={"18px"}
                zIndex={2}
                cursor={"pointer"}
                onClick={(e: any) => {
                  if (props.onChange && combinedRef?.current?.value) {
                    props.onChange({
                      ...e,
                      target: { ...e.target, value: "" },
                    });

                    combinedRef.current.value = "";
                  }
                }}
              >
                <Icon
                  color={(theme.colors as any).Gray.$700}
                  name={"times-circle"}
                  size={"20px"}
                />
              </Box>
            )}
          </>
        )}

        {props.type === "password" && (
          <Box
            onClick={() => setShowPassword(!showPassword)}
            position={"absolute"}
            cursor={"pointer"}
            right={"14px"}
            top={"18px"}
            zIndex={2}
          >
            <Icon
              color={(theme.colors as any).Gray.$700}
              name={showPassword ? "eye" : "eye-slash"}
              size={"20px"}
            />
          </Box>
        )}

        <StyledTextInput
          ref={combinedRef}
          {...props}
          theme={theme}
          inInput={InFocus}
          placeholder={props.icon ? props.placeholder : undefined}
          onClick={() => {
            props.variant === "date" && setDateModal(true);
          }}
          haveIcon={!!props.icon}
          isInvalid={props.isInvalid}
          onFocus={() => setInFocus(true)}
          onBlur={() => setInFocus(false)}
          readOnly={props.isDisabled || props.variant === "date"}
          type={
            showPassword
              ? "password"
              : props.type === "password"
                ? "text"
                : props.type || "text"
          }
          {...(props.value && { value: applyMask() })}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.onChange &&
            props.onChange(
              props.variant && props.variant !== "text"
                ? {
                    ...e,
                    target: { ...e.target, value: removeMask(e.target.value) },
                  }
                : e
            )
          }
        />

        <Box position={"absolute"} bottom={"360px"} right={"40px"}>
          {dateModal && (
            <motion.div
              style={{
                position: "fixed",
                borderRadius: "14px",
                background: "#fdfdfd",
                height: "330px",
                width: "360px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 10,
                boxShadow:
                  "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px",
              }}
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              ref={dateRef}
            >
              <Calendar
                minDate={new Date(props.minDate || "01/01/1960")}
                onChange={(item) => {
                  setDateModal(false);
                  props.onChange && props.onChange((item as any).toString());
                }}
                locale={pt}
                date={props.value ? new Date(props.value) : new Date()}
              />
            </motion.div>
          )}
        </Box>
      </motion.div>
    </StyledBoxInput>
  );
});
export default Input;
