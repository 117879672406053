import React, { useEffect, useState } from "react";
import { Props } from "./types";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "src/components/base/Modal";
import { Box, VStack, Button, Text, HStack, Center } from "@chakra-ui/react";
import { Consultancy } from "src/types";
import ConsultancyService from "src/services/consultancy";
import { Input, Loading, Select } from "semente-js";
import { formatDate } from "src/common";

const PerformanceForm: React.FC<Props> = ({
  handleSubmitIndicator,
  companyIndicator,
  handleClose,
  indicator,
  taskId,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({ defaultValues: companyIndicator });
  const [consultancies, setConsultancies] = useState<Consultancy[]>();

  useEffect(() => {
    ConsultancyService.getByTaskId(taskId).then(setConsultancies);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const consultancyWatcher = watch("consultancy_id");
  const consultancyVal = consultancies?.find(
    ({ id }) => id === consultancyWatcher
  );

  return (
    <Modal
      isOpen={!!companyIndicator}
      onClose={handleClose}
      title={
        companyIndicator && "id" in companyIndicator
          ? "Editar dado de desempenho"
          : "Inserir dado"
      }
    >
      {!consultancies ? (
        <Center mt={"40px"}>
          <Loading />
        </Center>
      ) : (
        <VStack w={"100%"} borderTop={"1px solid"} borderColor={"Gray.$300"}>
          <VStack
            mb={"0px !important"}
            w={"100%"}
            p={"24px"}
            spacing={0}
            alignItems={"flex-start"}
          >
            <Box
              mb={"32px !important"}
              padding={"10px 12px"}
              background={"Brand.pure"}
              borderRadius={"500px"}
            >
              <Text fontSize={"L_sm"} fontWeight={500} color={"white"}>
                {indicator.name}
              </Text>
            </Box>

            <HStack w={"100%"} alignItems={"flex-start"} mb={2}>
              <Controller
                rules={{ required: true }}
                control={control}
                name="date"
                render={({ field: { onChange, value }, fieldState }) => (
                  <Input
                    placeholder="Data"
                    type="date"
                    errorMessage={fieldState.error?.message}
                    {...(value && {
                      value: formatDate(new Date(value), "yyyy-MM-dd"),
                    })}
                    id="date-input"
                    onChange={onChange}
                  />
                )}
              />

              {consultancies.length >= 1 && (
                <Controller
                  control={control}
                  name="consultancy_id"
                  render={({ field }) => (
                    <Select
                      items={consultancies.map((consultancy) => ({
                        label: `Consultoria ${consultancy.column_index}`,
                        value: consultancy.id,
                      }))}
                      layout="line"
                      containerClassName="w-full"
                      color="primary"
                      onChange={([consultancy_id]) =>
                        field.onChange(consultancy_id)
                      }
                      {...(consultancyVal && {
                        value: [
                          {
                            label: `Consultoria ${consultancyVal?.column_index}`,
                            value: consultancyVal?.id || "",
                          },
                        ],
                      })}
                      placeholder="Selecione uma consultoria"
                    />
                  )}
                />
              )}
            </HStack>

            <Controller
              rules={{ required: true }}
              control={control}
              name="value"
              render={({ field: { onChange, value }, fieldState }) => (
                <Input
                  placeholder={`Quantidade de ${indicator.name}`}
                  errorMessage={fieldState.error?.message}
                  value={value?.toString()}
                  onChange={onChange}
                  id="quantity-input"
                  type="number"
                />
              )}
            />
          </VStack>

          <Box
            w={"100%"}
            p={"16px 24px"}
            borderTop={"1px solid"}
            borderColor={"Gray.$300"}
          >
            <Button
              w={"100%"}
              variant={"Primary"}
              borderRadius={"8px"}
              isLoading={isSubmitting}
              onClick={handleSubmit(handleSubmitIndicator)}
            >
              Salvar alterações
            </Button>
          </Box>
        </VStack>
      )}
    </Modal>
  );
};

export default PerformanceForm;
