const PathColors = [
  {
    bg: "#B2B2B2",
    color: "#000000",
    label: "Não atingiu",
  },
  {
    bg: "#DC1839",
    color: "#FFFFFF",
    label: "Precisa evoluir",
  },
  {
    bg: "#FFC71F",
    color: "#000000",
    label: "Em desenvolvimento",
  },
  {
    bg: "#098643",
    color: "#FFFFFF",
    label: "Concluído",
  },
];

export default PathColors;
