import { Box, Center, Text, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Props } from "../types";
import Performance from "./Performance";
import { Company, CompanyIndicator, CompanyPath, Indicator } from "src/types";
import CompanyService from "src/services/company";
import { MutatingDots } from "react-loader-spinner";
import { ProjectContext } from "src/contexts/ProjectContext";
import CompanyPaths from "./CompanyPath";
import UpdatePath from "./CompanyPath/UpdatePath";
import { SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import CompanyPathService from "src/services/company/path";
import PathsViewer from "./CompanyPath/PathsViewer";
import { useSearchParams } from "react-router-dom";
import CompanyIndicatorService from "src/services/company/indicator";

const EvolutionSection: React.FC<Props> = ({ task }) => {
  const [searchParams] = useSearchParams();
  const [pathToUpdate, setPathToUpdate] = useState<CompanyPath>();
  const [showPaths, setShowPaths] = useState<boolean>();
  const { currentProject } = useContext(ProjectContext);
  const [company, setCompany] = useState({
    isLoading: true,
    data: {} as Company,
  });

  const location = searchParams.get("location");
  const indicatorRef = useRef<any>();
  const pathsRef = useRef<any>();

  useEffect(() => {
    if (currentProject.data) {
      CompanyService.getCompanyEvolutionById(task.company_id).then((data) => {
        setCompany({
          isLoading: false,
          data: {
            ...data,
            indicators: currentProject.data?.indicators.reduce(
              (acc, item) => [
                ...acc,
                {
                  ...(item as any).indicator,
                  values: (data?.indicators as CompanyIndicator[]).filter(
                    ({ indicator_id }) =>
                      indicator_id === (item as any).indicator_id
                  ),
                },
              ],
              [] as Indicator[]
            ),
          },
        });

        setTimeout(() => {
          switch (location) {
            case "indicators":
              indicatorRef.current.scrollIntoView({
                behavior: "smooth",
              });
              break;

            case "paths":
              pathsRef.current.scrollIntoView({
                behavior: "smooth",
              });
              break;
          }
        }, 100);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  const handleCreateOrUpdatePath: SubmitHandler<CompanyPath> = async (path) => {
    const pathWithCompany = {
      ...path,
      company_id: company.data.id,
      consultancy: undefined,
    };

    try {
      if ("id" in path) {
        const newPath = await CompanyPathService.update(
          path.id,
          pathWithCompany
        );
        setCompany((previous) => ({
          ...previous,
          data: {
            ...previous.data,
            paths: previous.data.paths.map((oldPath) =>
              oldPath.id === path.id ? newPath : oldPath
            ),
          },
        }));
        setShowPaths(false);
        setPathToUpdate(undefined);
        return;
      }

      const newPath = await CompanyPathService.create(pathWithCompany);
      setCompany((previous) => ({
        ...previous,
        data: { ...previous.data, paths: [...previous.data.paths, newPath] },
      }));

      setShowPaths(false);
      setPathToUpdate(undefined);
    } catch (err) {
      toast.error("Ocorreu um erro ao gerenciar o caminho");
    }
  };

  const handleDeletePath = async (path_id: CompanyPath["id"]) => {
    try {
      await CompanyPathService.remove(path_id);

      const paths = company.data.paths.filter(
        (oldPath) => oldPath.id !== path_id
      );

      setCompany((previous) => ({
        ...previous,
        data: {
          ...previous.data,
          paths,
        },
      }));

      setPathToUpdate(undefined);

      if (paths.length === 0) {
        setShowPaths(false);
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao remover seu estágio");
    }
  };

  const handleDeleteIndicator = async (companyIndicator: CompanyIndicator) => {
    try {
      setCompany((previous) => ({
        ...previous,
        data: {
          ...previous.data,
          indicators: (previous.data.indicators as any).filter(
            (cIndicator: any) => cIndicator.id !== companyIndicator.id
          ) as CompanyIndicator[],
        },
      }));

      await CompanyIndicatorService.delete(companyIndicator.id);
    } catch (err) {
      toast.error("Ocorreu um erro ao remover seu indicador");
    }
  };

  const handleCreateOrUpdateIndicator: SubmitHandler<CompanyIndicator> = async (
    companyIndicator: CompanyIndicator
  ) => {
    try {
      if ("id" in companyIndicator) {
        const newCompanyIndicator = await CompanyIndicatorService.update(
          companyIndicator.id,
          companyIndicator
        );

        setCompany((previous) => ({
          ...previous,
          data: {
            ...previous.data,
            indicators: (previous.data.indicators as any).map(
              (cIndicator: CompanyIndicator) =>
                cIndicator.id === companyIndicator.id
                  ? newCompanyIndicator
                  : cIndicator
            ) as CompanyIndicator[],
          },
        }));

        return;
      }

      const newCompanyIndicator =
        await CompanyIndicatorService.create(companyIndicator);

      setCompany((previous) => ({
        ...previous,
        data: {
          ...previous.data,
          indicators: [
            ...(previous.data.indicators || []),
            newCompanyIndicator,
          ] as CompanyIndicator[],
        },
      }));
    } catch (err) {
      toast.error("Ocorreu um erro ao gerenciar o indicador");
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        style={{ width: "100%", height: "100%" }}
      >
        {pathToUpdate ? (
          <UpdatePath
            handleBack={() => setPathToUpdate(undefined)}
            handleSubmitForm={handleCreateOrUpdatePath}
            handleDelete={handleDeletePath}
            path={pathToUpdate}
            task_id={task.id}
          />
        ) : (
          <>
            {showPaths ? (
              <PathsViewer
                paths={company.data.paths.sort(
                  (a, b) =>
                    (new Date(a.date) as any) - (new Date(b.date) as any)
                )}
                handleSelectPath={setPathToUpdate}
                handleBack={() => setShowPaths(false)}
              />
            ) : (
              <VStack
                w={"100%"}
                h={"100%"}
                justifyContent={"space-between"}
                pb={"64px"}
              >
                <VStack
                  w={"100%"}
                  flex={1}
                  px={"24px"}
                  alignItems={"flex-start"}
                  overflow={"auto"}
                  spacing={0}
                >
                  <Text
                    flex={1}
                    fontWeight={600}
                    fontSize={"RH_sm"}
                    fontFamily={"Raleway"}
                  >
                    Evolução
                  </Text>

                  {company.isLoading ? (
                    <Center flex={1} w={"100%"}>
                      <MutatingDots
                        height="100"
                        width="100"
                        color="#408EC5"
                        secondaryColor="#408EC5"
                        radius="12.5"
                        ariaLabel="mutating-dots-loading"
                        visible={true}
                      />
                    </Center>
                  ) : (
                    <>
                      {company.data.paths && (
                        <Box w={"full"} ref={pathsRef}>
                          <CompanyPaths
                            handleShowPaths={() => setShowPaths(true)}
                            paths={company.data.paths.sort(
                              (a, b) =>
                                (new Date(a.date) as any) -
                                (new Date(b.date) as any)
                            )}
                            handleUpdatePath={setPathToUpdate}
                          />
                        </Box>
                      )}

                      {company.data.indicators && (
                        <Box ref={indicatorRef} w={"full"}>
                          <Performance
                            indicators={company.data.indicators as Indicator[]}
                            handleRemoveIndicator={handleDeleteIndicator}
                            handleUpdateIndicator={
                              handleCreateOrUpdateIndicator
                            }
                            company_id={company.data.id}
                            taskId={task.id}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </VStack>
              </VStack>
            )}
          </>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default EvolutionSection;
