import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Props } from "./types";
import Icon from "src/components/base/Icon";
import { useKanbanContext } from "src/contexts/KanbanContext";

const BoardCard: React.FC<Props> = ({ name, tasks, id }) => {
  const { refreshKanban } = useKanbanContext();

  return (
    <VStack
      borderRadius={"16px"}
      border={"1px solid"}
      borderColor={"Gray.$300"}
      p={"16px"}
      alignItems={"flex-start"}
      spacing={0}
    >
      <Text fontSize={"L_md"} color={"Gray.$800"} fontWeight={600}>
        {name}
      </Text>

      <Button
        mt={"24px !important"}
        minH={"36px"}
        onClick={() => refreshKanban(id)}
      >
        <HStack spacing={2}>
          <Text fontSize={"L_sm"} color={"Gray.$800"} fontWeight={500}>
            Abrir quadro
          </Text>

          <Icon name={"arrow-right"} />
        </HStack>
      </Button>
    </VStack>
  );
};

export default BoardCard;
