import axios from "axios";

const config = {
  baseURL: process.env.REACT_APP_API_URL,
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("accessToken");
    config.headers.Authorization = `Bearer ${window.accessToken}`;

    if (token) {
      config.headers.AuthorizationJWT = `Bearer ${token}`;
    }
    return config;
  },
  (error) => error
);

export default _axios;

declare global {
  interface Window {
    accessToken?: string;
  }
}
