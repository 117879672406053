export enum PERMISSIONS {
  CLIENT_CREATION = "Criação de clientes",
  CLIENT_UPDATE = "Atualização de clientes",
  CLIENT_VIEW = "Visualização de clientes",
  CLIENT_DELETE = "Remoção de clientes",

  COMPANY_CREATION = "Criação de empresas",
  COMPANY_UPDATE = "Atualização de empresas",
  COMPANY_VIEW = "Visualização de empresas",

  MEMBER_CREATION = "Criação de membro",
  MEMBER_UPDATE = "Atualização de membro",
  MEMBER_VIEW = "Visualização de membros",

  SETTINGS = "Configurações",
}
