import { HStack, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { motion } from "framer-motion";
import * as MenuComponents from "./Tabs";

export type MenuTab = {
  title: string;
  tabs: { label: string; component: keyof typeof MenuComponents }[];
};

const MenuTabs: MenuTab[] = [
  /*   {
    title: "Conta",
    tabs: [
      {
        label: "Geral",
        component: "General",
      },
      {
        label: "Senha",
        component: "Password",
      },
    ],
  }, */
  {
    title: "Sistema",
    tabs: [
      {
        label: "Áreas",
        component: "Areas",
      },
      {
        label: "Templates de checklists",
        component: "Checklist",
      },
      {
        label: "Indicadores",
        component: "Indicator",
      },
    ],
  },
];

export const Settings: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<keyof typeof MenuComponents>(
    MenuTabs[0].tabs[0].component
  );

  const Component = MenuComponents[currentTab];

  return (
    <VStack w={"100%"} px={"80px"} py={"66px"}>
      <HStack
        w={"100%"}
        pb={"24px"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        borderBottom={"1px solid"}
        borderColor={"Gray.$400"}
      >
        <Text
          fontSize={"RH_xl"}
          color={"Gray.$800"}
          fontFamily={"raleway"}
          fontWeight={600}
        >
          Configurações
        </Text>
      </HStack>

      <motion.div
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "24px",
        }}
      >
        <HStack w={"100%"} spacing={"36px"} alignItems={"flex-start"}>
          <VStack w={"260px"} alignItems={"flex-start"} spacing={0}>
            {MenuTabs.map((section, index) => (
              <VStack
                key={section.title}
                w={"100%"}
                alignItems={"flex-start"}
                spacing={0}
                py={"4px"}
                borderBottom={"1px solid"}
                borderColor={
                  MenuTabs.length - 1 !== index ? "Gray.$400" : "transparent"
                }
              >
                <Text
                  pt={"12px"}
                  pb={"4px"}
                  px={"16px"}
                  fontWeight={400}
                  fontSize={"LSM"}
                  color={"Gray.$600"}
                >
                  {section.title}
                </Text>

                {section.tabs.map((tab) => {
                  const isChecked = currentTab === tab.component;

                  return (
                    <Text
                      cursor={"pointer"}
                      onClick={() => setCurrentTab(tab.component)}
                      w={"100%"}
                      px={"16px"}
                      key={tab.label}
                      fontWeight={500}
                      fontSize={"LMD"}
                      transition={"300ms all"}
                      color={isChecked ? "Brand.pure" : "Gray.$800"}
                      background={
                        isChecked ? "Brand.background" : "transparent"
                      }
                      py={"16px"}
                      borderRadius={"12px"}
                    >
                      {tab.label}
                    </Text>
                  );
                })}
              </VStack>
            ))}
          </VStack>

          <VStack flex={1} maxW={"638px"}>
            <Component />
          </VStack>
        </HStack>
      </motion.div>
    </VStack>
  );
};
