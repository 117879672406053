import React, { useRef } from "react";
import { Props } from "./types";
import { Box, Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { PathTabs } from "../../common";
import UnionRing from "../../../../../../../../../assets/images/superIcons/Union.svg";
import { formatDate } from "src/common";
import PathColors from "src/components/PathSelect/common";
import { CompanyPath } from "src/types";
import { motion } from "framer-motion";
import { Icon } from "semente-js";

const MultiplePath: React.FC<Props> = ({ paths }) => {
  const scrollableArea = useRef<any>(null);

  return (
    <VStack w={"full"} spacing={"34px"}>
      <HStack
        w={"full"}
        alignItems={"flex-end"}
        spacing={"18px"}
        position={"relative"}
        mt={"40px"}
      >
        <VStack spacing={"24px"} alignItems={"flex-start"} w={"200px"}>
          {PathTabs.filter((path) => !path.hideInUpdate).map((tab, idx) => (
            <HStack key={tab.value}>
              <Image
                src={UnionRing}
                width={"20px"}
                height={"20px"}
                transform={`rotate(${0.1 * idx + 1}turn)`}
              />
              <Text
                whiteSpace={"nowrap"}
                fontSize={"L_lg"}
                fontWeight={600}
                color={"Gray.$800"}
              >
                {tab.label}
              </Text>
            </HStack>
          ))}
        </VStack>

        <HStack
          ref={scrollableArea}
          overflow={"auto"}
          spacing={0}
          w={"full"}
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {paths.map((path) => (
            <motion.div
              key={path.id}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
            >
              <VStack position={"relative"} minW={"150px"} mt={"64px"}>
                <VStack alignItems={"center"} position={"absolute"} top={-62}>
                  <Text fontSize={"L_xs"} fontWeight={400} color={"Gray.$700"}>
                    {formatDate(new Date(path.date), "dd/MM/yyyy")}
                  </Text>
                  <Center
                    px={"4px"}
                    borderRadius={"500"}
                    border={"1px solid"}
                    borderColor={"Gray.$400"}
                  >
                    <Text
                      whiteSpace={"nowrap"}
                      fontSize={"L_xs"}
                      fontWeight={600}
                      color={"Gray.$800"}
                    >
                      {path.consultancy_id
                        ? `Consultoria ${path.consultancy?.column_index}`
                        : "Não definido"}
                    </Text>
                  </Center>
                </VStack>

                <VStack spacing={"35.5px"} mt={"12px"} w={"full"}>
                  {PathTabs.filter((path) => !path.hideInUpdate).map((tab) => (
                    <Center w={"full"} position={"relative"}>
                      <Box
                        bg={
                          PathColors[
                            (path[tab.value as keyof CompanyPath] as number) ||
                              0
                          ]?.bg
                        }
                        borderRadius={"50%"}
                        key={tab.value}
                        w={"16px"}
                        h={"16px"}
                        zIndex={2}
                      />
                      <Box
                        w={"full"}
                        h={"1px"}
                        bg={"Gray.$200"}
                        position={"absolute"}
                        left={0}
                      />
                    </Center>
                  ))}
                </VStack>
              </VStack>
            </motion.div>
          ))}
        </HStack>
      </HStack>

      <HStack w={"full"} justifyContent={"space-between"}>
        <HStack spacing={"32px"}>
          {PathColors.map((item, index) => (
            <HStack key={index}>
              <Box
                bg={item.bg}
                borderRadius={"50%"}
                w={"16px"}
                h={"16px"}
                zIndex={2}
              />
              <Text
                whiteSpace={"nowrap"}
                fontSize={"L_xs"}
                fontWeight={400}
                color={"Gray.$800"}
              >
                {item.label}
              </Text>
            </HStack>
          ))}
        </HStack>

        <HStack>
          <button
            onClick={() =>
              scrollableArea.current.scrollTo({
                left: 0,
                behavior: "smooth",
              })
            }
          >
            <Icon name="angle-left" size="20px" />
          </button>

          <button
            onClick={() =>
              scrollableArea.current.scrollTo({
                left: 1000,
                behavior: "smooth",
              })
            }
          >
            <Icon name="angle-right" size="20px" />
          </button>
        </HStack>
      </HStack>
    </VStack>
  );
};

export default MultiplePath;
