import React, { useEffect, useState, useContext } from "react";
import { Props } from "./types";
import { Box, Button, Center, HStack, Text, VStack } from "@chakra-ui/react";
import Icon from "../../../components/base/Icon";
import Input from "../../../components/base/Input/Index";
import { Controller, useForm } from "react-hook-form";
import TextArea from "../../../components/base/TextArea/Index";
import { Select } from "../../../components/base/Select";
import { Modal } from "../../../components/base/Modal";
import {
  Area,
  Checklist,
  Client,
  Indicator,
  Project,
  User,
} from "../../../types";
import { MutatingDots } from "react-loader-spinner";
import UserService from "../../../services/user";
import ProjectInvite from "./CompanyInviteDialog";
import { ProjectContext } from "../../../contexts/ProjectContext";
import ChoiceClientsDialog from "./ChoiceClientsDialog";
import { toast } from "react-toastify";
import { ClientContext } from "src/contexts/ClientContext";
import AreaService from "src/services/area";
import ChecklistService from "src/services/checklist/checklist";
import MultiSelect from "src/components/base/MultiSelect";
import IndicatorService from "src/services/indicator";
import ChoiceIndicatorsDialog from "./ChoiceIndicatorsDialog";

export const CreateProjectModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { handleInsertInProjects } = useContext(ClientContext);
  const { handleCreateProject, selectProject } = useContext(ProjectContext);
  const [step, setStep] = useState<
    {
      callback: (project_id: Project["id"], data: string[]) => Promise<void>;
      data: string[];
    }[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectData, setSelectData] = useState<{
    managers: User[];
    areas: Area[];
    checklists: Checklist[];
    indicators: Indicator[];
    isLoaded: boolean;
  }>({ isLoaded: false } as any);

  const [newProject, setNewProject] = useState<Project>();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues: {} as Project & { checklists: string[] },
  });

  useEffect(() => {
    (async () => {
      if (isOpen) {
        const [managers, areas, checklists, indicators] = await Promise.all(
          [
            UserService.getAdminUsers({ type: ["manager"] }),
            AreaService.getAreas(),
            ChecklistService.getAll({}),
            IndicatorService.getAll(),
          ].map(async (service) => await service)
        );
        setSelectData({
          managers: managers as User[],
          indicators: indicators as Indicator[],
          areas,
          checklists,
          isLoaded: true,
        });
      }
    })();
  }, [isOpen]);

  useEffect(() => {
    reset({});
    setNewProject(undefined);
  }, [isOpen, reset]);

  const handleSubmitProject = async () => {};

  const handleSubmitClients = async (
    project_id: string,
    clients: Client["id"][]
  ) => {
    try {
      await Promise.all(
        clients.map(
          async (client_id) =>
            await handleInsertInProjects([project_id], client_id)
        )
      );
    } catch (err) {
      toast.error("Ocorreu um erro ao adicionar os clientes");
    }
  };

  const handleSubmitIndicators = async (
    project_id: string,
    indicators: Indicator["id"][]
  ) => {
    try {
      await IndicatorService.addToProject(project_id, indicators);
    } catch (err) {
      toast.error("Ocorreu um erro ao adicionar os indicadores");
    }
  };

  const handleFinishFlow = async () => {
    try {
      setIsLoading(true);
      const newP = getValues();
      const newProject = await handleCreateProject(newP, newP.checklists);
      if (!newProject) throw new Error("Erro ao criar projeto.");

      await Promise.all(
        step.map(async (step) => await step.callback(newProject?.id, step.data))
      );

      setNewProject(newProject);
      selectProject(newProject.id);
      setIsLoading(false);
    } catch (err) {
      toast.error("Ocorreu um erro ao tentar criar o projeto");
    }
  };

  const handleClose = () => {
    setNewProject(undefined);
    reset({});
    onClose();
  };

  const steps = [
    <ChoiceClientsDialog
      handleSubmit={(data) =>
        setStep([...step, { callback: handleSubmitClients, data }])
      }
      isProjectDialog
    />,
    <ChoiceIndicatorsDialog
      handleSubmit={(data) =>
        setStep([...step, { callback: handleSubmitIndicators, data }])
      }
      handleInsertIndicator={(indicator) =>
        setSelectData({
          ...selectData,
          indicators: [...selectData.indicators, indicator],
        })
      }
      {...selectData}
    />,
  ];

  useEffect(() => {
    if (steps.length === step.length) {
      handleFinishFlow();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps.length, step.length]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={step.length !== steps.length ? handleClose : undefined}
      title={isLoading || newProject ? "" : "Detalhes do projeto"}
      {...(!newProject &&
        !isLoading && {
          subTitle: `Passo ${step.length + (isSubmitSuccessful ? 2 : 1)} de ${
            steps.length + 1
          }`,
        })}
    >
      {isLoading || !selectData.isLoaded ? (
        <Center minH={"300px"}>
          <MutatingDots
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <>
          {isSubmitSuccessful ? (
            newProject ? (
              <ProjectInvite project={newProject} onClose={onClose} />
            ) : (
              steps[step.length] || (
                <Center minH={"300px"}>
                  <MutatingDots
                    height="100"
                    width="100"
                    color="#408EC5"
                    secondaryColor="#408EC5"
                    radius="12.5"
                    ariaLabel="mutating-dots-loading"
                    visible={true}
                  />
                </Center>
              )
            )
          ) : (
            <>
              <VStack
                maxW={"568px"}
                spacing={"16px"}
                w={"100%"}
                alignItems={"flex-start"}
                p={"24px"}
                borderTop={"1px solid"}
                borderColor={"Gray.$300"}
                maxH={"75vh"}
                overflow={"auto"}
              >
                <Controller
                  name={"name"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      isInvalid={!!error}
                      placeholder={"Nome"}
                    />
                  )}
                />

                <Controller
                  name={"description"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <TextArea
                      minHeight={"104px"}
                      isInvalid={!!error}
                      {...field}
                      placeholder={"Sobre"}
                    />
                  )}
                />

                <Controller
                  name={"manager"}
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Box w={"100%"}>
                      <Select
                        items={(selectData.managers || []).map((user) => ({
                          label: user?.name || "",
                          value: user?.id || "",
                        }))}
                        placeholder={"Gestor de carteira"}
                        onChange={(e) => {
                          setValue("manager_id", e.value);
                          onChange(e);
                        }}
                        {...((value?.id || (value as any)?.value) && {
                          value: {
                            label: value?.name || (value as any)?.label,
                            value: value?.id || (value as any)?.value,
                          },
                        })}
                        isInvalid={!!error}
                      />
                    </Box>
                  )}
                />

                <Controller
                  name={"area"}
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Box w={"100%"}>
                      <Select
                        items={(selectData.areas || []).map((area) => ({
                          label: area?.name || "",
                          value: area?.id || "",
                        }))}
                        placeholder={"Unidade"}
                        onChange={(e) => {
                          setValue("area_id", e.value);
                          onChange(e);
                        }}
                        {...((value?.id || (value as any)?.value) && {
                          value: {
                            label: value?.name || (value as any)?.label,
                            value: value?.id || (value as any)?.value,
                          },
                        })}
                        isInvalid={!!error}
                      />
                    </Box>
                  )}
                />

                <Controller
                  name={"checklists"}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Box w={"100%"}>
                      <MultiSelect
                        options={(selectData.checklists || []).map(
                          (checklist) => ({
                            label: checklist?.name || "",
                            value: checklist?.id || "",
                          })
                        )}
                        value={value}
                        placeholder={"Checklist"}
                        onChange={onChange}
                      />
                    </Box>
                  )}
                />

                <HStack alignItems={"center"}>
                  <Icon name={"schedule"} color={"#576975"} size={"22px"} />
                  <Text fontSize={"L_md"} color={"Gray.$700"} ml={"10px"}>
                    Período previsto do projeto
                  </Text>
                </HStack>

                <HStack spacing={"16px"} w={"100%"}>
                  <Controller
                    name={"start_date"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState: { error } }) => (
                      <Input
                        {...field}
                        variant={"date"}
                        value={field?.value?.toString()}
                        placeholder={"Inicio previsto"}
                        width={"100%"}
                        isInvalid={!!error}
                      />
                    )}
                  />

                  <Controller
                    name={"end_date"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState: { error } }) => (
                      <Input
                        {...field}
                        variant={"date"}
                        value={field?.value?.toString()}
                        placeholder={"Final previsto"}
                        width={"100%"}
                        minDate={watch("start_date")?.toString()}
                        isInvalid={!!error}
                      />
                    )}
                  />
                </HStack>
              </VStack>

              <Box
                p={"16px 24px"}
                borderTop={"1px solid"}
                borderColor={"Gray.$300"}
                w={"100%"}
              >
                <Button
                  w={"100%"}
                  borderRadius={"4px"}
                  variant={"Primary"}
                  minH={"48px !important"}
                  onClick={handleSubmit(handleSubmitProject)}
                >
                  Continuar
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </Modal>
  );
};
