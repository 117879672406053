import React, { useEffect, useState } from "react";
import { Props } from "./types";
import {
  Box,
  Button,
  Center,
  Grid,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import Icon from "src/components/base/Icon";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Checkbox from "src/components/Checkbox";
import { MultiInput } from "semente-js";

const defaultValues = {
  message: "",
  copyRepresentative: false,
  cc: [] as string[],
  images: [] as File[],
};

const MessageForm: React.FC<Props> = ({ handleCreate, company }) => {
  const { control, setValue, watch, handleSubmit } = useForm({ defaultValues });
  const [imagesRendered, setImagesRendered] = useState<any[]>([]);
  const representative_email = company.users_company_datas.find(
    (user) => user.user_id === company.representative_id
  )?.user.email;

  const sendToRepresentative = watch("copyRepresentative");
  const images = watch("images");
  const emails = watch("cc");

  const onDropRejected = () => {
    toast.error("Tipo de arquivo não suportado.");
  };

  useEffect(() => {
    (async () => {
      const files = await Promise.all(
        images.map(
          async (file) =>
            await new Promise((resolver) => {
              const reader = new FileReader();

              reader.onload = function ({ target }) {
                resolver(target?.result);
              };

              reader.readAsDataURL(file);
            })
        )
      );

      setImagesRendered(files);
    })();
  }, [images]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) => setValue("images", [...images, ...files]),
    onDropRejected,
    accept: {
      "image/jpeg": [".jpg"],
      "image/png": [".png"],
      "image/heic": [".HEIC"],
    },
  });

  return (
    <VStack
      spacing={0}
      w={"100%"}
      h={"100%"}
      px={"24px"}
      pb={"24px"}
      alignItems={"flex-start"}
    >
      <Text fontWeight={400} fontSize={"L_MD"} mb={"28px"}>
        Essa mensagem será enviada para o e-mail <b>{company.email}</b>
      </Text>

      <Controller
        name={"message"}
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Box w={"100%"}>
            <CKEditor
              editor={ClassicEditor as any}
              data={value}
              onChange={(_, editor) => {
                const data = (editor as any).getData();
                onChange(data);
              }}
              config={{
                placeholder: "Mensagem para a empresa",
                toolbar: {
                  items: [
                    "undo",
                    "redo",
                    "|",
                    "heading",
                    "|",
                    "bold",
                    "|",
                    "italic",
                    "|",
                    "numberedList",
                    "bulletedList",
                    "link",
                    "indent",
                  ],
                },
              }}
            />
          </Box>
        )}
      />

      <Controller
        name={"cc"}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <VStack w={"100%"} mt={"28px"} spacing={1} alignItems={"flex-start"}>
            <MultiInput
              onChange={(values) => {
                if (
                  representative_email &&
                  sendToRepresentative &&
                  !values.includes(representative_email)
                ) {
                  setValue("copyRepresentative", false);
                  onChange(values);
                  return;
                }

                if (
                  !sendToRepresentative &&
                  representative_email &&
                  values.includes(representative_email)
                ) {
                  setValue("copyRepresentative", true);
                }
                onChange(values);
              }}
              values={value}
              placeholder="E-mails em cópia"
              id="copy-input"
            />

            <Text fontWeight={400} fontSize={"LSM"} color={"gray.600"}>
              Aperte <b>Enter</b> para adicionar os valores
            </Text>
          </VStack>
        )}
      />

      <Controller
        name={"copyRepresentative"}
        control={control}
        render={({ field: { value, onChange } }) => (
          <HStack w={"100%"} mt={"28px"} spacing={4}>
            <Checkbox
              isChecked={value}
              onChange={(e) => {
                if (e && representative_email) {
                  setValue("cc", [representative_email, ...emails]);
                } else {
                  setValue(
                    "cc",
                    emails.filter((email) => email !== representative_email)
                  );
                }

                onChange(e);
              }}
            />

            <Text fontWeight={500} fontSize={"LMD"}>
              Enviar mensagem para o representante da empresa
            </Text>
          </HStack>
        )}
      />

      <Text mt={"28px"} fontWeight={600} fontSize={"L_LG"} mb={"16px"}>
        Anexar arquivo
      </Text>

      <Center
        {...getRootProps()}
        w={"100%"}
        h={"132px"}
        border={"2px dashed"}
        borderColor={"gray.200"}
        borderRadius={"12px"}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Text fontFamily={"lato"} fontSize={"l_md"}>
            Solte seu arquivo aqui!
          </Text>
        ) : (
          <VStack spacing={"12px"}>
            <Text fontFamily={"lato"} fontSize={"l_md"}>
              Solte aqui os arquivos
            </Text>
            <Button minH={"36px"}>Procurar arquivo</Button>
          </VStack>
        )}
      </Center>

      <Grid
        mt={"16px"}
        gridTemplateColumns={"1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"}
        gap={"8px"}
      >
        {imagesRendered.map((image, index) => (
          <Center
            w={"48px"}
            h={"48px"}
            borderRadius={"8px"}
            background={"gray.200"}
            onClick={() => {
              setValue(
                "images",
                images.filter((_, i) => index !== i)
              );
            }}
            cursor={"pointer"}
            position={"relative"}
            boxShadow={
              "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px"
            }
          >
            <Box
              position={"absolute"}
              width={"100%"}
              h={"100%"}
              bg={"transparent"}
              borderRadius={"8px"}
              transition={"all 300ms"}
              color={"white"}
              sx={{
                _hover: {
                  background: "RGBA(255,0,0,0.50)",
                  content: `'"Remover"'`,
                },
              }}
            />
            {images[index]?.type?.includes("heic") ? (
              <Icon name="image" />
            ) : (
              <Image
                objectFit={"cover"}
                src={image}
                w={"100%"}
                h={"100%"}
                borderRadius={"8px"}
              />
            )}
          </Center>
        ))}
      </Grid>

      <HStack mt={"12px"} py={"8px"} w={"100%"} justifyContent={"flex-end"}>
        <Button
          onClick={handleSubmit(handleCreate)}
          minH={"36px"}
          variant={"Primary"}
        >
          Enviar mensagem
        </Button>
      </HStack>
    </VStack>
  );
};

export default MessageForm;
