import { HStack, Text, VStack, Button } from "@chakra-ui/react";
import React, { useState } from "react";
import { Props } from "./types";
import { motion } from "framer-motion";
import { Button as SButton } from "semente-js";
import { Modal } from "src/components/base/Modal";

const IndicatorsList: React.FC<Props> = ({
  handleEditIndicator,
  handleRemoveIndicator,
  indicators,
}) => {
  const [indicatorToRemove, setIndicatorToRemove] = useState<string>();

  return (
    <VStack w={"100%"}>
      {indicators.map((indicator, idx) => (
        <motion.div
          key={indicator?.id}
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          style={{ width: "100%" }}
        >
          <HStack
            w={"100%"}
            py={"16px"}
            borderBottom={"1px solid"}
            borderColor={
              indicators.length - 1 !== idx ? "Gray.$300" : "transparent"
            }
          >
            <Text flex={1} fontSize={"LMD"} fontWeight={500}>
              {indicator.name}
            </Text>
            <HStack>
              <SButton
                label="Editar"
                color="gray"
                layout="rounded"
                onClick={() => handleEditIndicator(indicator)}
              />

              <SButton
                iconName="trash-alt-solid"
                color="gray"
                layout="circle"
                onClick={() => setIndicatorToRemove(indicator.id)}
              />
            </HStack>
          </HStack>
        </motion.div>
      ))}

      <Modal title={"Excluir indicador"} isOpen={!!indicatorToRemove}>
        <VStack maxW={"568px"}>
          <VStack borderTop={"1px solid"} borderColor={"Gray.$300"} p={"24px"}>
            <Text flex={1} fontSize={"LMD"} fontWeight={500}>
              Tem certeza que deseja excluir o indicador Clientes? Essa ação não
              poderá ser desfeita.
            </Text>
          </VStack>

          <VStack
            borderTop={"1px solid"}
            borderColor={"Gray.$300"}
            p={"16px 24px"}
            w={"100%"}
          >
            <Button
              variant={"Primary"}
              borderRadius={"8px"}
              w={"100%"}
              onClick={() => {
                handleRemoveIndicator(indicatorToRemove as string);
                setIndicatorToRemove(undefined);
              }}
            >
              Excluir indicador
            </Button>
            <Button
              onClick={() => setIndicatorToRemove(undefined)}
              variant={"Secondary"}
              borderRadius={"8px"}
              w={"100%"}
            >
              Voltar
            </Button>
          </VStack>
        </VStack>
      </Modal>
    </VStack>
  );
};

export default IndicatorsList;
