/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Center, HStack, Switch, Text, VStack } from "@chakra-ui/react";
import { Reorder } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button } from "semente-js";
import { ChecklistOption, ChecklistQuestionWithOptions } from "src/types";
import Input from "src/components/base/Input/Index";
import QuestionOption from "./QuestionOption";
import { Props } from "./types";
import ChecklistQuestionService from "src/services/checklist/checklistQuestion";
import { MutatingDots } from "react-loader-spinner";
import * as _ from "lodash";
import ChecklistOptionService from "src/services/checklist/checklistOption";
import Checkbox from "src/components/Checkbox";

const QuestionForm: React.FC<Props> = ({
  question,
  handleClose,
  checklist_id,
  questionsLength,
  handleSubmitQuestion,
}) => {
  const [continueCreating, setContinueCreating] = useState(false);
  const defaultValues = {
    ...question,
    index: question?.index || questionsLength,
    is_multiple: question?.is_multiple || false,
    options: question?.options || [
      {
        index: 0,
        id: Math.floor(Math.random() * (100 - 0) + 0),
        isNew: true,
      },
    ],
  } as ChecklistQuestionWithOptions;

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [question, reset]);

  const reorderByIndex = (options: ChecklistOption[]) => {
    return options.sort((a, b) => a.index - b.index).map(({ index }) => index);
  };

  const handleSubmitForm: SubmitHandler<typeof defaultValues> = async (
    values
  ) => {
    const isUpdate = "id" in values;
    if (values.options.find((opt) => !opt.name)) {
      toast.error("Você possuí opções não preenchidas.");
      return;
    }
    try {
      const newQuestion = {
        ...values,
        options: values.options.map((option) =>
          (option as any).isNew ? _.omit(option, ["id", "isNew"]) : option
        ),
        checklist_id,
      } as ChecklistQuestionWithOptions;

      if (isUpdate) {
        const optionsToDelete = question?.options
          .filter(
            (question) => !values.options.find(({ id }) => id === question.id)
          )
          .map(({ id }) => id);

        if (optionsToDelete && optionsToDelete?.length >= 1) {
          await Promise.all(
            optionsToDelete?.map(
              async (option) => await ChecklistOptionService.delete(option)
            )
          );
        }

        handleSubmitQuestion(
          await ChecklistQuestionService.update(
            newQuestion.id as string,
            newQuestion
          )
        );
      } else {
        handleSubmitQuestion(
          await ChecklistQuestionService.create(newQuestion)
        );

        reset({ ...defaultValues, index: defaultValues.index + 1 });
      }

      toast.success(
        `Questão ${isUpdate ? "atualizada" : "criada"} com sucesso!`
      );
      if (!continueCreating) handleClose();
    } catch (err) {
      console.log(err);
      handleClose();
      toast.error(
        `Ocorreu um erro ao ${isUpdate ? "Atualizar" : "Criar"} as questões`
      );
    }
  };

  return (
    <VStack w={"100%"} position={"relative"}>
      {isSubmitting && (
        <Center
          w={"100%"}
          h={"100%"}
          position={"absolute"}
          background={"white"}
          zIndex={2}
        >
          <MutatingDots
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      )}

      <VStack
        w={"100%"}
        borderTop={"1px solid"}
        borderColor={"Gray.$400"}
        py={"16px"}
        px={"24px"}
        spacing={"16px"}
        alignItems={"flex-start"}
      >
        <Controller
          name="title"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              isInvalid={!!error}
              value={value || ""}
              onChange={onChange}
              placeholder={"Título da questão"}
            />
          )}
        />

        <Controller
          name="description"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              isInvalid={!!error}
              value={value || ""}
              onChange={onChange}
              placeholder={"Descrição"}
            />
          )}
        />

        <Text py={"16px"} fontSize={"RH_xs"} fontWeight={500}>
          Opções
        </Text>

        <Controller
          name="options"
          control={control}
          render={({ field: { value, onChange } }) => (
            <VStack w={"100%"} alignItems={"flex-start"}>
              <Reorder.Group
                axis="y"
                values={reorderByIndex(value)}
                onReorder={(i) =>
                  onChange(
                    value.map((item, index) => ({ ...item, index: i[index] }))
                  )
                }
                style={{ width: "100%" }}
              >
                <VStack w={"100%"} spacing={"8px"}>
                  {value?.map((item, index) => (
                    <QuestionOption
                      key={item.id}
                      handleChange={(text) => {
                        value[index] = { ...item, name: text };
                        onChange(value);
                      }}
                      handleDelete={() => {
                        if (value.length >= 2) {
                          onChange(
                            value.filter((value) => value.index !== item.index)
                          );
                          return;
                        }

                        toast.error(
                          "Você precisa ter ao menos uma opção na questão"
                        );
                      }}
                      item={item}
                    />
                  ))}
                </VStack>
              </Reorder.Group>

              <Box
                onClick={() =>
                  onChange([
                    ...value,
                    {
                      index: value.length,
                      id: Math.floor(Math.random() * (100 - 0) + 0),
                      isNew: true,
                    },
                  ])
                }
              >
                <Button
                  color="gray"
                  label="Adicionar opção"
                  layout="rounded"
                  iconName="plus"
                />
              </Box>
            </VStack>
          )}
        />

        <Controller
          name="is_multiple"
          control={control}
          render={({ field: { value, onChange } }) => (
            <HStack w={"100%"} justifyContent={"space-between"} py={"16px"}>
              <Text fontSize={"L_md"} fontWeight={500}>
                Permitir múltiplas respostas
              </Text>

              <Switch isChecked={value} onChange={onChange} size={"lg"} />
            </HStack>
          )}
        />
      </VStack>

      <HStack
        w={"100%"}
        borderTop={"1px solid"}
        borderColor={"Gray.$400"}
        py={"16px"}
        px={"24px"}
        justifyContent={"space-between"}
      >
        <HStack cursor={"pointer"}>
          {!question?.id && (
            <>
              <Checkbox
                isChecked={continueCreating}
                onChange={() => setContinueCreating(!continueCreating)}
              />
              <Text
                onClick={() => setContinueCreating(!continueCreating)}
                fontSize={"L_md"}
                fontWeight={500}
              >
                Criar outra questão
              </Text>
            </>
          )}
        </HStack>

        <Box onClick={handleSubmit(handleSubmitForm)}>
          <Button
            label={
              question && "id" in question ? "Salvar questão" : "Criar questão"
            }
            color="primary"
          />
        </Box>
      </HStack>
    </VStack>
  );
};

export default QuestionForm;
