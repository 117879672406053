import { Outlet } from "react-router-dom";
import ClientContextProvider from "./ClientContext";
import KanbanContextProvider from "./KanbanContext";
import ProjectContextProvider from "./ProjectContext";
import SocketContextProvider from "./SocketContext";
import UserContextProvider from "./UserContext";

export const Providers: React.FC = () => {
  return (
    <SocketContextProvider>
      <UserContextProvider>
        <ClientContextProvider>
          <ProjectContextProvider>
            <KanbanContextProvider>
              <Outlet />
            </KanbanContextProvider>
          </ProjectContextProvider>
        </ClientContextProvider>
      </UserContextProvider>
    </SocketContextProvider>
  );
};
