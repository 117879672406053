import React, { useRef, useState } from "react";
import { Props } from "../types";
import { HStack, Text, VStack, useOutsideClick } from "@chakra-ui/react";
import { Icon } from "semente-js";
import { Calendar } from "react-date-range";
import { formatDate } from "src/common";
import { motion } from "framer-motion";
import { pt } from "date-fns/locale";

const DatePicker: React.FC<Props> = ({ handleChangeDate, date }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dateRef = useRef<any>(null);

  useOutsideClick({
    ref: dateRef,
    handler: () => setIsOpen(false),
  });

  return (
    <VStack position={"relative"}>
      <HStack
        py={"10px"}
        px={"12px"}
        cursor={"pointer"}
        borderRadius={"8px"}
        background={"Gray.$200"}
        onClick={() => setIsOpen(true)}
      >
        <Icon name={"calendar-solid"} size="14px" />

        <Text fontSize={"LSM"} fontWeight={500}>
          {date ? formatDate(date, "dd/MM/yyyy") : "Não definido"}
        </Text>

        <Icon name={"angle-down"} size="14px" />
      </HStack>

      {isOpen && (
        <motion.div
          style={{
            position: "absolute",
            borderRadius: "14px",
            background: "#fdfdfd",
            height: "330px",
            width: "360px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: "54px",
            left: 0,
            zIndex: 10,
            boxShadow:
              "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px",
          }}
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          ref={dateRef}
        >
          <Calendar
            onChange={(item) => {
              setIsOpen(false);

              if (date) {
                let dateB = item.toISOString().split("T")[0];

                // Extrair a hora e minuto de 'a'
                let hoursA = date?.getHours().toString().padStart(2, "0");
                let minutesA = date?.getMinutes().toString().padStart(2, "0");

                handleChangeDate(
                  new Date(dateB + " " + hoursA + ":" + minutesA)
                );
              } else {
                handleChangeDate(item);
              }
            }}
            locale={pt}
            date={date}
          />
        </motion.div>
      )}
    </VStack>
  );
};

export default DatePicker;
