import { Center, Text, VStack } from "@chakra-ui/react";
import { Accordion, Button, Checkbox, DrawerComponent } from "semente-js";
import { Props } from "./types";
import { useEffect, useState } from "react";

const KanbanFilter: React.FC<Props> = ({
  value,
  isOpen,
  consultants,
  handleClose,
  handleFilter,
}) => {
  const [filters, setFilters] = useState({
    ...value,
    consultants: value.consultants || [],
  });

  useEffect(() => {
    if (value) {
      setFilters({
        ...value,
        consultants: value.consultants || [],
      });
    }
  }, [value]);

  return (
    <DrawerComponent id="filters-drawer" isOpen={isOpen} onClose={handleClose}>
      <VStack w={"100%"} h={"100%"}>
        <Center
          borderBottom={"1px solid"}
          borderColor={"Gray.$300"}
          py={"26px"}
          w={"100%"}
        >
          <Text fontSize={"L_md"} fontWeight={500}>
            Filtros
          </Text>
        </Center>

        <VStack p={"24px"} w={"100%"} flex={1}>
          <Accordion
            id="consultant-filter-accordion"
            accordionTitle={"Consultor"}
            className="w-full"
            isInitiallyOpen
          >
            {[...consultants, { name: "Sem consultor", id: null }].map(
              (consultant) => (
                <Checkbox
                  label={consultant.name}
                  key={consultant.id}
                  onChange={() => {
                    if (
                      filters.consultants?.includes(consultant?.id as string)
                    ) {
                      setFilters({
                        ...filters,
                        consultants: filters.consultants?.filter(
                          (fConsultant) => fConsultant !== consultant.id
                        ),
                      });
                      return;
                    }

                    setFilters({
                      ...filters,
                      consultants: [
                        ...filters.consultants,
                        consultant.id as string,
                      ],
                    });
                  }}
                  isChecked={filters.consultants?.includes(
                    consultant.id as any
                  )}
                />
              )
            )}
          </Accordion>
        </VStack>

        <Center
          borderTop={"1px solid"}
          borderTopColor={"Gray.$300"}
          py={"16px"}
          px={"24px"}
          w={"100%"}
        >
          <Button
            className="w-full"
            label="Aplicar filtros"
            onClick={() => {
              handleFilter(filters);
              handleClose();
            }}
          />
        </Center>
      </VStack>
    </DrawerComponent>
  );
};

export default KanbanFilter;
