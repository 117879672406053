import _axios from "..";
import {
  AppProfiles,
  User,
  Users_company_datas,
  UserWithProjects,
} from "../../types";
import { ISearchResult } from "../types";

export const UserService = {
  signup: async (
    credentials: Partial<
      Omit<User, "profile"> & {
        profile: Partial<AppProfiles>;
        user_company_datas: Partial<Users_company_datas>[];
      }
    >
  ) => {
    const res = await _axios.post("auth/user/sign-up", credentials);

    return { user: res.data as User, token: res.headers.token };
  },

  getCurrentUser: async (): Promise<User> => (await _axios.get("user")).data,

  getById: async (id: User["id"]): Promise<UserWithProjects> =>
    (await _axios.get(`user/${id}`)).data,

  update: async (id: User["id"], newUser: Partial<User>): Promise<User> =>
    (await _axios.patch(`user/${id}`, newUser)).data,

  getAvailableEmailsToInvite: async (): Promise<string[]> =>
    (await _axios.get("users/keycloak/emails")).data,

  getAdminUsers: async (params: {
    type: string[];
    filter?: string;
  }): Promise<User[]> => (await _axios.get(`users/admin`, { params })).data,

  getUsersPaginated: async (params: Object): Promise<ISearchResult<User>> =>
    (await _axios.get("/users/paginated", { params })).data,
};

export default UserService;
