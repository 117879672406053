import React, { useEffect, useState } from "react";
import { Props } from "./types";
import { Box, Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { Button, Icon, Input, Loading, Select } from "semente-js";
import { PathTabs } from "../common";
import { PathSelect } from "src/components/PathSelect";
import UnionRing from "../../../../../../../../assets/images/superIcons/Union.svg";
import { Controller, useForm } from "react-hook-form";
import { Consultancy } from "src/types";
import ConsultancyService from "src/services/consultancy";
import { formatDate } from "src/common";

const UpdatePath: React.FC<Props> = ({
  path,
  task_id,
  handleBack,
  handleDelete,
  handleSubmitForm,
}) => {
  const [consultancies, setConsultancies] = useState<Consultancy[]>();
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({ defaultValues: path });

  useEffect(() => {
    setIsLoading(false);
    ConsultancyService.getByTaskId(task_id).then(setConsultancies);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const consultancyWatcher = watch("consultancy_id");
  const consultancyVal = consultancies?.find(
    ({ id }) => id === consultancyWatcher
  );

  return !consultancies ? (
    <Center mt={"40px"}>
      <Loading />
    </Center>
  ) : (
    <VStack w={"full"} alignItems={"flex-start"} flex={1} pb={"40px"}>
      <VStack
        w={"full"}
        pb={"40px"}
        mb={"40px"}
        borderBottom={"1px solid"}
        borderColor={"Gray.$400"}
        alignItems={"flex-start"}
      >
        <HStack cursor={"pointer"} onClick={handleBack}>
          <Icon name="arrow-left-alt" color="#141414" size="18px" />
          <Text fontSize={"L_md"} fontWeight={600}>
            Voltar
          </Text>
        </HStack>

        <HStack w={"full"} justify={"between"}>
          <Text
            flex={1}
            fontWeight={600}
            fontSize={"RH_xl"}
            fontFamily={"Raleway"}
          >
            {"id" in path ? "Editar estágio" : "Criar estágio"}
          </Text>

          <HStack>
            {path.id && (
              <Button
                onClick={() => {
                  setIsLoading(true);
                  handleDelete(path.id);
                }}
                isLoading={isSubmitting || isLoading}
                iconColor="#141414"
                iconName="trash-alt"
                layout="rounded"
                label="Excluir"
                color="gray"
                size="sm"
              />
            )}

            <Button
              onClick={handleSubmit(handleSubmitForm)}
              isLoading={isSubmitting || isLoading}
              iconName="save-solid"
              iconColor="#141414"
              layout="rounded"
              label="Salvar"
              color="gray"
              size="sm"
            />
          </HStack>
        </HStack>
      </VStack>

      <HStack spacing={"40px"} w={"full"} alignItems={"flex-start"}>
        <Controller
          control={control}
          name="date"
          render={({ field }) => (
            <Input
              onChange={(e) =>
                field.onChange(e?.target?.value?.replaceAll("-", "/"))
              }
              value={formatDate(new Date(field?.value), "yyyy-MM-dd")}
              placeholder="Data"
              id="date-picker"
              type="date"
            />
          )}
          rules={{ required: true }}
        />

        {consultancies.length >= 1 && (
          <VStack alignItems={"flex-start"} spacing={"8px"} w={"full"}>
            <Controller
              control={control}
              name="consultancy_id"
              render={({ field }) => (
                <Select
                  items={consultancies.map((consultancy) => ({
                    label: `Consultoria ${consultancy.column_index}`,
                    value: consultancy.id,
                  }))}
                  layout="line"
                  containerClassName="w-full"
                  color="primary"
                  onChange={([consultancy_id]) =>
                    field.onChange(consultancy_id)
                  }
                  {...(consultancyVal && {
                    value: [
                      {
                        label: `Consultoria ${consultancyVal?.column_index}`,
                        value: consultancyVal?.id || "",
                      },
                    ],
                  })}
                  placeholder="Selecione uma consultoria"
                />
              )}
            />

            <Text fontSize={"L_sm"} fontWeight={400} color={"Gray.$700"}>
              Opcional
            </Text>
          </VStack>
        )}
      </HStack>

      <VStack w={"full"} spacing={"40px"} mt={"40px"}>
        {PathTabs.filter((path) => !path.hideInUpdate).map(
          ({ label, ...rest }, idx) => (
            <Controller
              key={rest.value}
              name={rest.value}
              control={control}
              render={({ field: { value, onChange } }) => (
                <HStack
                  w={"full"}
                  key={rest.value}
                  spacing={"40px"}
                  alignItems={"flex-start"}
                >
                  <Box w={"190px"}>
                    <HStack
                      border={"1px solid"}
                      borderColor={"Gray.$400"}
                      borderRadius={"500px"}
                      w={"fit-content"}
                      px={"12px"}
                      py={"14px"}
                    >
                      <Image
                        src={UnionRing}
                        alt={"Icon"}
                        width={"20px"}
                        height={"20px"}
                        transform={`rotate(${0.1 * idx + 1}turn)`}
                      />

                      <Text
                        whiteSpace={"nowrap"}
                        fontSize={"L_lg"}
                        fontWeight={600}
                        color={"Gray.$800"}
                      >
                        {label}
                      </Text>
                    </HStack>
                  </Box>

                  <PathSelect
                    handleSelectPath={(val) => onChange(val)}
                    value={parseInt((value as string) || "0")}
                  />
                </HStack>
              )}
            />
          )
        )}
      </VStack>
    </VStack>
  );
};

export default UpdatePath;
