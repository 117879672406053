import { Center, Stack, Text } from "@chakra-ui/react";
import React from "react";
import Icon from "../../../components/base/Icon";
import { motion } from "framer-motion";
import { Props } from "./types";

export const AddCard: React.FC<Props> = ({ isHidded, onClick }) => {
  return (
    <Stack w={"100%"} margin={"0px !important"} transition={"400ms all"}>
      <Stack width={"100%"} h={"8px"} margin={"0px !important"} />
      <motion.div
        style={{ margin: 0 }}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.95 }}
        onClick={onClick}
      >
        <Center
          margin={"0px !important"}
          background={"Gray.$100"}
          w={"100%"}
          cursor={"pointer"}
          transition={"400ms all"}
          borderRadius={"8px"}
          boxShadow={
            " 0px 1px 1px rgba(22, 26, 29, 0.2), 0px 0px 1px rgba(22, 26, 29, 0.2);"
          }
          p={"12px"}
        >
          <>
            {isHidded ? (
              <Icon name="plus" />
            ) : (
              <Center
                w={"100%"}
                h={"100%"}
                flexDir={"row"}
                alignItems={"center"}
              >
                <Icon name="plus" />
                <Text
                  ml={"8px"}
                  fontSize={"L_md"}
                  fontWeight={600}
                  whiteSpace={"nowrap"}
                >
                  Adicionar empresas
                </Text>
              </Center>
            )}
          </>
        </Center>
      </motion.div>
    </Stack>
  );
};
