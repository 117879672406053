import React, { useContext } from "react";
import { Props } from "./types";
import { motion } from "framer-motion";
import { fadeInUp, fastStagger } from "src/animations";
import Card from "./Card";
import { ProjectContext } from "src/contexts/ProjectContext";
import { ProjectViewContext } from "../../Context";

const ProjectList: React.FC<Props> = ({ projects }) => {
  const { selectProject } = useContext(ProjectContext);
  const { setCurrentTabIn } = useContext(ProjectViewContext);

  return (
    <motion.div
      variants={fastStagger}
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gap: "30px",
        marginTop: "36px",
      }}
    >
      {projects.map((project) => (
        <motion.div
          style={{ width: "100%", cursor: "pointer" }}
          key={project.id}
          variants={fadeInUp}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <Card
            project={project}
            handleClick={() => {
              setCurrentTabIn(0);
              selectProject(project.id);
            }}
          />
        </motion.div>
      ))}
    </motion.div>
  );
};

export default ProjectList;
