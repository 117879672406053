import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Center, HStack, Text, VStack, Button } from "@chakra-ui/react";
import { CompanyMessage } from "src/types";
import { toast } from "react-toastify";
import { MutatingDots } from "react-loader-spinner";
import { Props } from "../types";
import MessagesEmptyState from "./EmptyState";
import MessageService from "src/services/message";
import MessageViewer from "./Viewer";
import MessageForm from "./Form";
import MessageItem from "./Item";

const MessageSection: React.FC<Props> = ({ task, column }) => {
  const [currentMessage, setCurrentMessage] = useState<CompanyMessage>();
  const [messages, setMessages] = useState({
    isLoading: true,
    data: undefined as undefined | CompanyMessage[],
  });

  const refreshChecklists = useCallback(async () => {
    try {
      setMessages({
        isLoading: true,
        data: undefined,
      });

      setMessages({
        isLoading: false,
        data: await MessageService.getAll(task.id),
      });
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar suas mensagens");
    }
  }, [task]);

  useEffect(() => {
    refreshChecklists();
  }, [refreshChecklists]);

  const handleCreateMessage = async (value: {
    message: string;
    images: File[];
    cc: string[];
  }) => {
    try {
      if (messages.data) {
        setMessages({ isLoading: true, data: undefined });
        setCurrentMessage(undefined);
        const formData = new FormData();
        formData.append("cc", JSON.stringify(value.cc));
        value.images.map(async (image) => formData.append(`files`, image));

        const message = await MessageService.create(formData, {
          cc: JSON.stringify(value.cc),
          company_id: task.company.id,
          message: value.message,
          title: column.name,
          task_id: task.id,
          "Content-Type": "multipart/form-data",
        });

        toast.success("Mensagem enviada com sucesso!");

        const newMessages = [...messages.data, message];
        setMessages({
          isLoading: false,
          data: newMessages,
        });
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao enviar a mensagem.");
      refreshChecklists();
    }
  };

  return !currentMessage ? (
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      style={{ width: "100%", height: "100%" }}
    >
      <VStack
        w={"100%"}
        h={"100%"}
        px={"24px"}
        pb={"64px"}
        alignItems={"flex-start"}
        overflow={"auto"}
        spacing={0}
      >
        <HStack w={"100%"} justifyContent={"space-between"}>
          <Text fontWeight={600} fontSize={"RH_sm"} fontFamily={"Raleway"}>
            Mensagens
          </Text>

          {messages.data && messages.data?.length >= 1 && (
            <Button
              minH={"36px"}
              fontWeight={500}
              onClick={() => setCurrentMessage({} as any)}
            >
              Nova mensagem
            </Button>
          )}
        </HStack>

        {messages.isLoading ? (
          <Center flex={1} w={"100%"}>
            <MutatingDots
              height="100"
              width="100"
              color="#408EC5"
              secondaryColor="#408EC5"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              visible={true}
            />
          </Center>
        ) : (
          <VStack
            alignItems={"flex-start"}
            w={"100%"}
            h={"100%"}
            mt={"32px !important"}
          >
            {messages.data && messages.data?.length >= 1 ? (
              messages.data?.map((message, index) => (
                <MessageItem
                  isLast={
                    messages?.data && messages?.data?.length - 1 === index
                  }
                  handleClick={() => setCurrentMessage(message)}
                  message={message}
                  key={message.id}
                />
              ))
            ) : (
              <Center flex={1} w={"100%"}>
                <MessagesEmptyState
                  handleAdd={() => setCurrentMessage({} as any)}
                  hasCompanyEmail={!!task.company.email}
                />
              </Center>
            )}
          </VStack>
        )}
      </VStack>
    </motion.div>
  ) : (
    <>
      {"id" in currentMessage ? (
        <MessageViewer message={currentMessage} />
      ) : (
        <MessageForm
          handleCreate={handleCreateMessage}
          company={task.company}
        />
      )}
    </>
  );
};

export default MessageSection;
