import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useContext, useRef } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Props } from "../types";
import { omit } from "lodash";
import { TaskStatus, column } from "src/pages/Kanban/common";
import { toast } from "react-toastify";
import { KanbanContext } from "src/contexts/KanbanContext";
import { motion } from "framer-motion";
import { consultancyFields } from "../common";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ConsultancyUpdates from "./Updates";
import SaveBar from "./SaveBar";
import ConsultancyService from "src/services/consultancy";
import { Consultancy } from "src/types";
import DatePicker from "./DateSelector/Pickers/DatePicker";
import TimePicker from "./DateSelector/Pickers/TimePicker";
import { useBlocker, useNavigate } from "react-router-dom";
import NavigationBlockerModal from "./NavigationBlockerModal";
import ConsultancyCards from "./Cards";
import ConsultancyStatus from "./ConsultancyStatus";

const ConsultancySection: React.FC<Props> = ({ column, task }) => {
  const { updateTask } = useContext(KanbanContext);
  const navigate = useNavigate();
  const ckRef = useRef<any>();

  const fieldsToOmit: {
    [key in column["position"]]?: Array<any>;
  } = {
    default: [
      "start_diagnostic",
      "start_challenge",
      "challenges_after_program",
      "end_diagnostic",
    ],
    diagnostic: ["challenges_after_program", "end_diagnostic"],
    last: ["start_diagnostic", "start_challenge"],
  };

  const availableHistoric = omit(
    consultancyFields,
    fieldsToOmit[column.position]
  );
  const defaultValues = {
    ...(Object.keys(availableHistoric) as Array<keyof Consultancy>).reduce(
      (acc, item) => {
        return {
          ...acc,
          [item]: ((task.consultancy && task.consultancy[item]) ||
            "") as string,
        };
      },
      {} as { [key: string]: string }
    ),
    start_date: task?.consultancy?.start_date,
    end_date: task?.consultancy?.end_date,
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = useForm({
    defaultValues,
  });

  const handleSubmitForm: SubmitHandler<{ [x: string]: string }> = async (
    values
  ) => {
    const callbackValues = values;
    try {
      const touchedFields = Object.keys(dirtyFields);
      const fieldsToSave = touchedFields.reduce(
        (acc, key) => ({ ...acc, [key]: values[key] }),
        {} as any
      );

      const consultancy = await ConsultancyService.upsert({
        ...fieldsToSave,
        column_index: column.id,
        ...((task?._status?.split("-")[1] === "pending" || !task._status) && {
          status: "in_work",
        }),
        task_id: task.id,
      });

      const consultancyCurated = (
        Object.keys(consultancy) as Array<keyof Consultancy>
      ).reduce((acc, item) => {
        if (!!consultancy[item]) {
          return { ...acc, [item]: consultancy[item] };
        }

        return acc;
      }, {});

      await updateTask(
        {
          ...task,
          _status:
            `${task.column_index}-${consultancy.status}` as keyof typeof TaskStatus,
          consultancy: { ...(task.consultancy || {}), ...consultancyCurated },
        },
        true
      );
      reset({ ...defaultValues, ...fieldsToSave });
    } catch (err) {
      toast.error("Ocorreu um erro ao salvar as anotações.");
      reset({ ...defaultValues, ...callbackValues });
    }
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname
  );

  const handleChangeStatus = async (status: keyof typeof TaskStatus) => {
    try {
      await updateTask(
        {
          ...task,
          _status: `${task.column_index}-${status}` as keyof typeof TaskStatus,
          consultancy: { ...(task.consultancy || {}), status },
        },
        true
      );

      await ConsultancyService.upsert({
        status,
        column_index: task.column_index,
        task_id: task.id,
      });
    } catch (err) {
      toast.error("Ocorreu um erro ao salvar as anotações.");
    }
  };

  return (
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      style={{ width: "100%", height: "100%", margin: 0 }}
    >
      <VStack
        w={"100%"}
        h={"100%"}
        justifyContent={"space-between"}
        pb={"64px"}
      >
        <VStack w={"100%"} alignItems={"flex-start"} spacing={0}>
          <HStack w={"100%"} justifyContent={"space-between"}>
            <Text fontWeight={600} fontSize={"RH_sm"} fontFamily={"Raleway"}>
              {column.name}
            </Text>

            <ConsultancyStatus
              consultancy={task.consultancy}
              handleChange={handleChangeStatus}
            />
          </HStack>

          <HStack
            w={"full"}
            mt={"16px"}
            pb={"16px"}
            alignItems={"flex-start"}
            spacing={0}
          >
            <Controller
              name="start_date"
              control={control}
              render={({ field: { value, onChange } }) => (
                <HStack spacing={"16px"}>
                  <DatePicker
                    date={value ? new Date(value) : undefined}
                    handleChangeDate={(date) => onChange(date.toString())}
                  />

                  <TimePicker
                    date={value ? new Date(value) : undefined}
                    handleChangeDate={(date) => onChange(date.toString())}
                  />
                </HStack>
              )}
            />

            <Controller
              name="end_date"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <HStack alignItems={"center"} ml={"8px"} spacing={0}>
                    <Text
                      fontSize={"LSM"}
                      fontWeight={700}
                      color={"Gray.$800"}
                      mr={"8px"}
                    >
                      -
                    </Text>

                    <TimePicker
                      date={value ? new Date(value) : undefined}
                      handleChangeDate={(date) => onChange(date.toString())}
                    />
                  </HStack>
                </>
              )}
            />
          </HStack>

          <ConsultancyUpdates
            task={task}
            lastUpdate={task?.consultancy?.updatedAt}
          />

          <VStack
            w={"100%"}
            borderTop={"1px solid"}
            borderColor={"Gray.$300"}
            alignItems={"flex-start"}
            pt={"32px"}
            mt={"32px"}
            spacing={0}
          >
            <ConsultancyCards
              handleSelectCard={(tab, query) =>
                navigate(`/kanban/task/${tab}/${task.id}?location=${query}`)
              }
            />

            <Text
              mb={"24px !important"}
              fontWeight={600}
              fontSize={"L_md"}
              mt={"32px"}
            >
              Notas
            </Text>
            <VStack w={"100%"} spacing={"16px"}>
              {(
                Object.keys(availableHistoric) as Array<
                  keyof typeof availableHistoric
                >
              ).map((fieldKey: any) => (
                <Controller
                  key={fieldKey}
                  control={control}
                  name={fieldKey}
                  render={({ field }) => (
                    <Box w={"100%"}>
                      <Text mb={"8px"} fontSize={"L_md"} fontWeight={400}>
                        {(availableHistoric as any)[fieldKey].label}
                      </Text>

                      <CKEditor
                        ref={ckRef}
                        editor={ClassicEditor as any}
                        data={field.value}
                        onChange={(_, editor) => {
                          const data = (editor as any).getData();
                          field.onChange(data);

                          if (data.length >= 1000) {
                            field.onChange(data.substr(0, 1000));
                          }
                        }}
                        config={{
                          placeholder: (availableHistoric as any)[fieldKey]
                            .label,
                          toolbar: {
                            items: [
                              "undo",
                              "redo",
                              "|",
                              "heading",
                              "|",
                              "bold",
                              "|",
                              "italic",
                              "|",
                              "numberedList",
                              "bulletedList",
                              "link",
                              "indent",
                            ],
                          },
                        }}
                      />
                      {field.value.length >= 1000 && (
                        <Text
                          mt={"8px"}
                          fontSize={"L_md"}
                          color={"red.600"}
                          fontWeight={400}
                        >
                          Seu texto está ultrapassando 1000 caracteres,
                          corrija-o.
                        </Text>
                      )}
                    </Box>
                  )}
                />
              ))}
            </VStack>
          </VStack>
        </VStack>
      </VStack>

      {isDirty && (
        <SaveBar
          handleSave={handleSubmit(handleSubmitForm)}
          handleCancel={() => reset(defaultValues)}
          isLoading={isSubmitting}
        />
      )}

      {blocker.state === "blocked" && (
        <NavigationBlockerModal
          handleCancel={() => blocker.reset?.()}
          handleContinue={() => blocker.proceed?.()}
        />
      )}
    </motion.div>
  );
};

export default ConsultancySection;
