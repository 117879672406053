import _axios from "..";

export type UserSignUpParams = {
  name: string;
  surname: string;
  email: string;
  password: string;
  image_url?: string;
  phone?: string;
};

export const AuthService = {
  login: () => _axios.get("auth/keycloak/login"),

  signUp: (body: UserSignUpParams) => _axios.post("auth/user/sign-up", body),

  changePassword: (body: { password: string }, code?: string) =>
    _axios.post(`auth/user/change-password${code ? `/${code}` : ""}`, body),
};

export default AuthService;
