import _axios from "src/services";
import {
  Checklist,
  ChecklistWithAnswers,
  ChecklistWithQuestions,
  Project,
  Task,
} from "src/types";

type getAll = {
  project_id?: Project["id"];
  task_id?: Task["id"];
};

export const ChecklistService = {
  getAll: async ({
    project_id,
    task_id,
  }: getAll): Promise<ChecklistWithAnswers[]> =>
    (
      await _axios.get(
        `/checklist${project_id ? `/${project_id}` : ""}${
          task_id ? `/${task_id}` : ""
        }`
      )
    ).data,

  create: async (
    newChecklist: Omit<Checklist, "id">
  ): Promise<ChecklistWithQuestions> =>
    (await _axios.post(`/checklist`, newChecklist)).data,

  update: async (
    id: Checklist["id"],
    newChecklist: Partial<Checklist>
  ): Promise<Checklist> =>
    (await _axios.patch(`/checklist/${id}`, newChecklist)).data,

  delete: async (id: Checklist["id"]): Promise<void> =>
    (await _axios.delete(`/checklist/${id}`)).data,

  duplicate: async (
    id: Checklist["id"],
    newChecklistParams: Partial<Checklist>
  ): Promise<void> =>
    (await _axios.post(`/checklist/duplicate/${id}`, newChecklistParams)).data,
};

export default ChecklistService;
