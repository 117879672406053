import _axios from "..";
import { Stage } from "src/types";

const StageService = {
  create: async (stage: Partial<Stage>): Promise<void> =>
    (await _axios.post("stage", stage)).data,

  update: async (
    stage_id: Stage["id"],
    newStage: Partial<Stage>
  ): Promise<Stage> => (await _axios.put(`stage/${stage_id}`, newStage)).data,

  delete: async (stage_id: Stage["id"]): Promise<Stage> =>
    (await _axios.delete(`stage/${stage_id}`)).data,
};

export default StageService;
