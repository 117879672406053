import React from "react";
import { Props } from "./types";
import { Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { Icon } from "semente-js";

const ProjectList: React.FC<Props> = ({ projects }) => {
  return (
    <VStack
      w={"100%"}
      mt={"32px !important"}
      spacing={"0px !important"}
      alignItems={"flex-start"}
    >
      <Text
        fontSize={"RH_xs"}
        fontFamily={"raleway"}
        fontWeight={600}
        mb={"0px !important"}
      >
        Projetos
      </Text>
      <Text
        mt={"8px !important"}
        fontSize={"L_md"}
        fontWeight={400}
        color={"Gray.$700"}
        mb={"16px !important"}
      >
        Lista de projetos a qual essa empresa faz parte
      </Text>

      {projects.length >= 1 ? (
        <VStack
          w={"100%"}
          border={"1px solid"}
          borderColor={"Gray.$400"}
          borderRadius={"12px"}
          spacing={"0px !important"}
          px={"16px"}
        >
          {projects.map((project, index) => (
            <HStack
              key={project.id}
              py={"16px"}
              w={"100%"}
              borderBottom={"1px solid"}
              borderColor={
                projects.length - 1 === index ? "transparent" : "Gray.$400"
              }
            >
              <Center
                width="36px"
                height="36px"
                overflow="hidden"
                borderRadius="60px"
                alignItems="center"
                background={project.image_url ? "transparent" : "#E3E5E8"}
                justifyContent="center"
              >
                {project.image_url ? (
                  <Image
                    src={project.image_url}
                    width="100%"
                    height="100%"
                    borderRadius="60px"
                    objectFit={"cover"}
                  />
                ) : (
                  <Icon name={"image"} size={"18px"} />
                )}
              </Center>

              <VStack flex={1} alignItems={"flex-start"} spacing={"4px"}>
                <Text fontSize={"L_md"} fontWeight={400}>
                  {project.name}
                </Text>
              </VStack>
            </HStack>
          ))}
        </VStack>
      ) : (
        <Center w="100%" my={"24px"}>
          <VStack>
            <Text fontSize={"LLG"} fontWeight={600}>
              Essa empresa não faz parte de nenhum projeto
            </Text>
            <Text fontSize={"LSM"} fontWeight={400}>
              Adicione essa empresa a um projeto
            </Text>
          </VStack>
        </Center>
      )}
    </VStack>
  );
};

export default ProjectList;
