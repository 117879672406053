import React, { useContext, useState } from "react";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Icon } from "semente-js";
import { formatDate } from "src/common";
import { consultancyFields } from "../../common";
import { Props } from "./types";
import { KanbanContext } from "src/contexts/KanbanContext";

const ConsultancyCard: React.FC<Props> = ({ consultancy }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { kanbanData } = useContext(KanbanContext);

  return (
    <VStack
      w={"100%"}
      pl={"26px"}
      borderRadius={"12px"}
      border={"1px solid"}
      borderColor={"Gray.$300"}
      transition={"300ms all"}
      overflow={"hidden"}
      maxH={!isOpen ? "85px" : "100%"}
    >
      <HStack w={"100%"} onClick={() => setIsOpen(!isOpen)} cursor={"pointer"}>
        <Icon name={"comments-solid"} size="24px" />
        <HStack
          w={"100%"}
          {...(isOpen && {
            borderBottom: "1px solid",
            borderColor: "Gray.$300",
          })}
          pr={"26px"}
          py={"16px"}
        >
          <VStack alignItems={"flex-start"} spacing={0} flex={1}>
            <Text fontWeight={500} fontSize={"LMD"}>
              {kanbanData.columns[consultancy.column_index].name}
            </Text>
            <Text fontWeight={400} fontSize={"LSM"} color={"Gray.$600"}>
              {formatDate(new Date(consultancy.createdAt))}
            </Text>
          </VStack>

          <Box
            transition={"300ms all"}
            transform={`rotate(${isOpen ? "1" : "0.75"}turn)`}
          >
            <Icon name="angle-down" size="20px" />
          </Box>
        </HStack>
      </HStack>

      <VStack py={"22px"} w={"100%"} alignItems={"flex-start"} spacing={"22px"}>
        {Object.keys(consultancyFields).map((key, index) => (
          <HStack w={"100%"} spacing={"14px"} alignItems={"flex-start"}>
            <Box mt={"2px"}>
              <Icon name={(consultancyFields as any)[key].icon} size="18px" />
            </Box>

            <VStack
              alignItems={"flex-start"}
              pb={"16px"}
              w={"100%"}
              spacing={0}
              borderBottom="1px solid"
              borderColor={
                Object.keys(consultancyFields).length - 1 !== index
                  ? "Gray.$300"
                  : "transparent"
              }
            >
              <Text fontWeight={400} fontSize={"LSM"}>
                {(consultancyFields as any)[key].label}
              </Text>
              <Text
                fontWeight={500}
                fontSize={"LMD"}
                color={"Gray.$600"}
                py={"12px"}
                dangerouslySetInnerHTML={{
                  __html: (consultancy as any)[key] || "Não definido",
                }}
              />
            </VStack>
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
};

export default ConsultancyCard;
