import React from "react";
import { Props } from "./types";
import { Box, HStack, Text } from "@chakra-ui/react";
import { Button, Icon } from "semente-js";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

const animations = {
  initial: {
    scale: 0,
    opacity: 0,
  },
  animate: {
    scale: 1,
    opacity: 1,
  },
  exit: {
    scale: 0,
    opacity: 0,
  },
};

const FilterViewer: React.FC<Props> = ({
  handleFilter,
  consultants,
  tasksLen,
  filter,
}) => {
  const hasFiltered = (Object.keys(filter) as Array<keyof typeof filter>).find(
    (key) =>
      key !== "company" && filter[key] && (filter[key] as any)?.length >= 1
  );

  return (
    <AnimatePresence>
      {hasFiltered && (
        <motion.div
          style={{
            marginBottom: "16px",
            display: "flex",
            gap: "16px",
            alignItems: "center",
          }}
          {...animations}
        >
          <Text fontSize={"L_lg"} fontWeight={500}>
            Filtros ativos:
          </Text>

          <AnimatePresence>
            {filter.consultants?.map((consultant) => (
              <motion.div key={consultant} {...animations}>
                <HStack
                  px={"4px"}
                  py={"6px"}
                  borderRadius={"4px"}
                  bg={"Brand.pure"}
                  spacing={"8px"}
                >
                  <Text fontSize={"L_sm"} fontWeight={500} color={"Gray.$100"}>
                    {consultants.find(({ id }) => id === consultant)?.name ||
                      "Sem consultor"}
                  </Text>

                  <Box
                    cursor={"pointer"}
                    onClick={() => {
                      handleFilter({
                        ...filter,
                        consultants: filter.consultants?.filter(
                          (fConsultant) => fConsultant !== consultant
                        ),
                      });
                    }}
                  >
                    <Icon name="times" color="#FFFFFF" size="18" />
                  </Box>
                </HStack>
              </motion.div>
            ))}
          </AnimatePresence>

          <Button
            onClick={() => handleFilter({ ...filter, consultants: [] })}
            label="Limpar filtros"
            layout="rounded"
            color="gray"
          />

          <Text fontSize={"L_md"} fontWeight={400} color={"Gray.$500"}>
            {tasksLen} empresas encontradas
          </Text>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FilterViewer;
