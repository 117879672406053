import { VStack } from "@chakra-ui/react";
import { Reorder } from "framer-motion";
import React, { useState } from "react";
import ChecklistQuestionService from "src/services/checklist/checklistQuestion";
import { ChecklistQuestion } from "src/types";
import QuestionItem from "./QuestionItem";
import { Props } from "./types";

const QuestionList: React.FC<Props> = ({
  handleDuplicate,
  handleReorder,
  handleDelete,
  handleUpdate,
  questions,
}) => {
  const [timer, setTimer] = useState<any>(null);

  const reorderByIndex = (options: ChecklistQuestion[]) => {
    return options.sort((a, b) => a.index - b.index).map(({ index }) => index);
  };

  const menuOptions = [
    {
      title: "Editar questão",
      color: "black",
      onClick: handleUpdate,
    },
    {
      title: "Excluir questão",
      color: "#CC0000",
      onClick: (question: ChecklistQuestion) => handleDelete(question.id),
    },
  ];

  const handleReorderItems = (items: any[]) => {
    const newQuestions = questions.map((item, index) => ({
      ...item,
      index: items[index],
    }));

    handleReorder(newQuestions);

    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }

    setTimer(
      setTimeout(() => {
        newQuestions.map((question) =>
          ChecklistQuestionService.update(question.id as string, question)
        );
      }, 700)
    );
  };

  return (
    <VStack w={"100%"}>
      <Reorder.Group
        axis="y"
        values={reorderByIndex(questions)}
        onReorder={handleReorderItems}
        style={{ width: "100%" }}
      >
        <VStack w={"100%"}>
          {questions.map((question) => (
            <QuestionItem
              key={question.id}
              handleDuplicate={() => handleDuplicate(question)}
              question={question}
              menuOptions={menuOptions}
            />
          ))}
        </VStack>
      </Reorder.Group>
    </VStack>
  );
};

export default QuestionList;
