import { Project } from "semente-js";
import _axios from "../..";
import { CompanyIndicator, Task } from "src/types";

export const CompanyIndicatorService = {
  update: async (
    id: CompanyIndicator["id"],
    newCompanyIndicator: Partial<CompanyIndicator>
  ): Promise<CompanyIndicator> =>
    (await _axios.patch(`/company_indicator/${id}`, newCompanyIndicator)).data,

  create: async (
    newCompanyIndicator: Partial<CompanyIndicator>
  ): Promise<CompanyIndicator> =>
    (await _axios.post(`/company_indicator`, newCompanyIndicator)).data,

  delete: async (id: CompanyIndicator["id"]): Promise<void> =>
    (await _axios.delete(`/company_indicator/${id}`)).data,

  getIndicatorsFromTask: async (
    id: Task["id"],
    project_id: Project["id"]
  ): Promise<CompanyIndicator[]> =>
    (await _axios.get(`/company_indicator/${id}/${project_id}`)).data,
};

export default CompanyIndicatorService;
