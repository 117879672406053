import { layout, margin } from "styled-system";
import styled from "styled-components";
import { Props } from "./types";

interface TextInputProps extends Props {
  isInvalid?: boolean;
  haveIcon?: boolean;
  inInput?: boolean;
  theme?: any;
}

export const StyledPlaceHolder = styled.div<{
  labelIsUpped: boolean;
  variant: any;
}>`
  padding-top: ${(props) => (props.labelIsUpped ? "8px" : "16px")};
  transition: all 300ms;
  position: absolute;
  flex-direction: row;
  padding-left: ${(props) => (props.variant === "date" ? "39px" : "16px")};
  padding-right: 16px;
  border-radius: 8px;
  left: 0;
  top: 0;
`;

export const StyledBoxInput = styled.div<any>`
  position: relative;
  width: 100%;
  ${margin}
`;

export const StyledTextInput = styled.textarea<TextInputProps>`
  border-width: 1px;
  font-size: 16px;
  min-height: 56px;
  width: 100%;
  outline: none;
  resize: none;
  border-color: ${(props) =>
    props.isInvalid
      ? props.theme.colors.Red.pure
      : props.inInput
      ? props.theme.colors.Tertiary.pure
      : props.theme.colors.Gray.$500};

  padding: ${(props) =>
    props.haveIcon
      ? "12px 40px"
      : props.placeholder && (!!props.value || props.inInput)
      ? "22px 20px 8px 15px"
      : "22px 16px 4px 16px"};

  border-radius: 8px;
  color: ${(props) =>
    !props.readOnly
      ? props.theme.colors.Gray.$800
      : props.theme.colors.Gray.$600};

  ${(props) =>
    props.readOnly &&
    !props.value &&
    "background-color: 'rgba(55, 55, 55, 0.1)'"}

  ${layout}
`;
