import React, { useCallback, useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  Center,
  HStack,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ChecklistAnswer, ChecklistWithAnswers } from "src/types";
import { toast } from "react-toastify";
import { ProjectContext } from "src/contexts/ProjectContext";
import ChecklistService from "src/services/checklist/checklist";
import { MutatingDots } from "react-loader-spinner";
import ChecklistAnswerService from "src/services/checklist/checklistAnswer";
import { useDebouncedEffect } from "src/hooks/useDebouncedEffect";
import { Props } from "../types";
import Checkbox from "src/components/Checkbox";

const ChecklistSection: React.FC<Props> = ({ task }) => {
  const { currentProject } = useContext(ProjectContext);
  const [checklists, setChecklists] = useState({
    isLoading: true,
    data: undefined as undefined | ChecklistWithAnswers[],
  });
  const [answersToModifie, setAnswersToModifie] = useState<
    Array<
      Omit<ChecklistAnswer, "id"> & {
        id?: string;
        action: "add" | "remove";
      }
    >
  >([]);

  const refreshChecklists = useCallback(async () => {
    try {
      if (currentProject && currentProject.data) {
        setChecklists({
          isLoading: true,
          data: undefined,
        });

        setChecklists({
          isLoading: false,
          data: await ChecklistService.getAll({
            project_id: currentProject.data.id,
            task_id: task.id,
          }),
        });
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar os checklists");
    }
  }, [currentProject, task.id]);

  useEffect(() => {
    refreshChecklists();
  }, [refreshChecklists]);

  useEffect(() => {
    return () => {
      handleModifieAnswer(answersToModifie);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModifieAnswer = async (value: typeof answersToModifie) => {
    try {
      const answersCreated = await Promise.all(
        value
          .filter((answer) => answer.action === "add")
          .map(async (cAnswer) => await ChecklistAnswerService.create(cAnswer))
      );

      const answersToRemove = value
        .filter((answer) => answer.action === "remove")
        .map(({ id }) => id);

      await Promise.all(
        answersToRemove.map(
          async (cAnswer: any) => await ChecklistAnswerService.delete(cAnswer)
        )
      );

      if (checklists && checklists.data) {
        const newChecklist = checklists.data.reduce(
          (acc, item) => {
            const newQuestions = item.questions.map((question) => {
              return {
                ...question,
                answers: [
                  ...question.answers.filter(
                    ({ id }) => !answersToRemove.includes(id)
                  ),
                  ...answersCreated.filter(
                    (answer) => answer.checklist_question_id === question.id
                  ),
                ],
              };
            });

            return [...acc, { ...item, questions: newQuestions }];
          },
          [] as typeof checklists.data
        );

        setChecklists({ data: newChecklist, isLoading: false });
        setAnswersToModifie([]);
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao gerenciar suas últimas respostas.");
      await refreshChecklists();
    }
  };

  useDebouncedEffect(
    () => {
      if (answersToModifie.length >= 1) {
        handleModifieAnswer(answersToModifie);
      }
    },
    [answersToModifie],
    1000
  );

  return (
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      style={{ width: "100%", height: "100%" }}
    >
      <VStack
        w={"100%"}
        h={"100%"}
        px={"24px"}
        pb={"64px"}
        alignItems={"flex-start"}
        overflow={"auto"}
      >
        <Text fontWeight={600} fontSize={"RH_sm"} fontFamily={"Raleway"}>
          Checklist de entrega
        </Text>
        <Text
          mt={"4px !important"}
          fontWeight={400}
          fontSize={"L_sm"}
          color={"Gray.$700"}
        >
          Registre as atividades concluídas pela empresa nos últimos dias.
        </Text>

        {checklists.isLoading ? (
          <Center flex={1} w={"100%"}>
            <MutatingDots
              height="100"
              width="100"
              color="#408EC5"
              secondaryColor="#408EC5"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              visible={true}
            />
          </Center>
        ) : (
          <VStack
            alignItems={"flex-start"}
            w={"100%"}
            h={"100%"}
            mt={"40px !important"}
          >
            {checklists.data && checklists.data?.length >= 1 ? (
              checklists.data?.map((checklist, index) => (
                <VStack
                  borderBottom={"1px solid"}
                  borderColor={
                    checklists.data && checklists?.data?.length - 1 !== index
                      ? "Gray.$300"
                      : "transparent"
                  }
                  key={checklist.id}
                  spacing={0}
                  w={"100%"}
                  alignItems={"flex-start"}
                >
                  <Text
                    mt={"4px !important"}
                    fontWeight={600}
                    fontSize={"RH_xs"}
                  >
                    {checklist.name}
                  </Text>
                  {checklist.questions.filter(
                    (question) => question.options.length >= 1
                  ).length >= 1 ? (
                    <>
                      {checklist.questions
                        .filter((question) => question.options.length >= 1)
                        .sort((a, b) => a.index - b.index)
                        .map((question) => (
                          <VStack
                            spacing={0}
                            key={question.id}
                            w={"100%"}
                            alignItems={"flex-start"}
                          >
                            <Text
                              mt={"4px !important"}
                              fontWeight={500}
                              fontSize={"L_md"}
                            >
                              {question.title} -{" "}
                              {question.is_multiple
                                ? "Escolha mais de uma opção"
                                : "Escolha uma opção"}
                            </Text>

                            <VStack
                              w={"100%"}
                              alignItems={"flex-start"}
                              spacing={0}
                              mt={"16px"}
                            >
                              {question.is_multiple ? (
                                <>
                                  {question?.options
                                    ?.sort((a, b) => a.index - b.index)
                                    .map((option) => {
                                      const answer = question.answers.find(
                                        ({ checklist_question_option_id }) =>
                                          option.id ===
                                          checklist_question_option_id
                                      );

                                      const defaultAnswer = {
                                        task_id: task.id,
                                        checklist_question_option_id: option.id,
                                        checklist_question_id:
                                          question.id || "",
                                        action: !!answer
                                          ? "remove"
                                          : ("add" as any),
                                        ...(answer && { id: answer.id }),
                                      };

                                      return (
                                        <HStack
                                          key={option.id}
                                          alignItems={"center"}
                                          py={"16px"}
                                        >
                                          <Checkbox
                                            onChange={() => {
                                              if (
                                                answersToModifie.find(
                                                  (answer) =>
                                                    answer.checklist_question_option_id ===
                                                    option.id
                                                )
                                              ) {
                                                const newAnswers =
                                                  answersToModifie.filter(
                                                    (answer) =>
                                                      answer.checklist_question_option_id !==
                                                      option.id
                                                  );

                                                setAnswersToModifie(newAnswers);
                                                return;
                                              }

                                              setAnswersToModifie([
                                                ...answersToModifie,
                                                defaultAnswer,
                                              ]);
                                            }}
                                            defaultChecked={!!answer}
                                          />

                                          <Text
                                            fontWeight={400}
                                            fontSize={"L_sm"}
                                          >
                                            {option.name}
                                          </Text>
                                        </HStack>
                                      );
                                    })}
                                </>
                              ) : (
                                <RadioGroup
                                  defaultValue={
                                    question.answers[0]
                                      ?.checklist_question_option_id
                                  }
                                >
                                  {question?.options
                                    ?.sort((a, b) => a.index - b.index)
                                    .map((option) => {
                                      const defaultAnswer = {
                                        task_id: task.id,
                                        checklist_question_option_id: option.id,
                                        checklist_question_id:
                                          question.id || "",
                                        action: "add",
                                      };

                                      return (
                                        <HStack
                                          key={option?.id}
                                          alignItems={"center"}
                                          py={"16px"}
                                        >
                                          <Radio
                                            size={"lg"}
                                            value={option.id}
                                            onChange={() => {
                                              const newAnswers =
                                                answersToModifie.filter(
                                                  (answer) =>
                                                    answer.checklist_question_id !==
                                                    question.id
                                                );

                                              const answersToRemove =
                                                question.answers
                                                  .filter(
                                                    ({
                                                      checklist_question_option_id,
                                                    }) =>
                                                      checklist_question_option_id !==
                                                      option.id
                                                  )
                                                  .map((answer) => ({
                                                    ...answer,
                                                    action: "remove",
                                                  })) as any;

                                              setAnswersToModifie([
                                                ...newAnswers,
                                                ...answersToRemove,
                                                defaultAnswer,
                                              ]);
                                            }}
                                          />

                                          <Text
                                            fontWeight={400}
                                            fontSize={"L_sm"}
                                          >
                                            {option.name}
                                          </Text>
                                        </HStack>
                                      );
                                    })}
                                </RadioGroup>
                              )}
                            </VStack>
                          </VStack>
                        ))}
                    </>
                  ) : (
                    <Text
                      mt={"4px !important"}
                      fontWeight={400}
                      fontSize={"L_sm"}
                    >
                      Ainda não possuí questões cadastradas.
                    </Text>
                  )}
                </VStack>
              ))
            ) : (
              <Center flex={1} w={"100%"}>
                <VStack>
                  <Text
                    mt={"4px !important"}
                    fontWeight={600}
                    fontSize={"L_lg"}
                  >
                    Não existem checklists aqui ainda
                  </Text>
                  <Text
                    mt={"4px !important"}
                    fontWeight={500}
                    fontSize={"L_sm"}
                  >
                    Retorne a tela de projetos e crie novos
                  </Text>
                </VStack>
              </Center>
            )}
          </VStack>
        )}
      </VStack>
    </motion.div>
  );
};

export default ChecklistSection;
