import _axios from "..";
import { Consultancy, Task } from "../../types";

export const ConsultancyService = {
  upsert: async (consultancy: Partial<Consultancy>): Promise<Consultancy> =>
    (await _axios.patch(`/consultancy`, consultancy)).data,

  getByTaskId: async (task_id: Task["id"]): Promise<Consultancy[]> =>
    (await _axios.get(`/consultancy/task/${task_id}`)).data,

  getById: async (
    task_id: Task["id"],
    column_index: Consultancy["column_index"]
  ): Promise<Consultancy> =>
    (await _axios.get(`task/${task_id}/column/${column_index}/consultancy`))
      .data,
};

export default ConsultancyService;
