import {
  Button,
  Center,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import React, { useContext, useState, useEffect } from "react";
import Icon from "src/components/base/Icon";
import { masks } from "src/components/base/Input/masks";
import { motion } from "framer-motion";
import { Props } from "../types";
import { Select } from "src/components/base/Select";
import { ProjectContext } from "src/contexts/ProjectContext";
import { defaultUserImage } from "src/assets/images";
import { ProjectDialogs } from "src/contexts/ProjectContext/const";
import { Modal } from "src/components/base/Modal";
import { Company, CompanyWithProfile, User } from "src/types";
import { toast } from "react-toastify";
import { KanbanContext } from "src/contexts/KanbanContext";
import { createImageFromInitials } from "src/common";
import ProjectsService from "src/services/projects";
import { MutatingDots } from "react-loader-spinner";
import DetailTab from "src/pages/Companies/CompanyForm/Tabs/Detail";
import CompanyService from "src/services/company";
import ReSendInvite from "src/pages/Companies/List/RightSide/ReSendInvite";
import ProfileDetails from "src/pages/Companies/CompanyForm/Tabs/Profile";
import { ProtectedComponent } from "semente-js";
import { PERMISSIONS } from "src/permissions";

const defaultConsultantValue = {
  name: "Sem consultor",
  image_url: defaultUserImage,
  id: "null",
};

const AboutSection: React.FC<Props> = ({ task }) => {
  const { updateTask, kanbanData } = useContext(KanbanContext);
  const [dialog, setDialog] = useState<React.ReactNode>();
  const { currentProject } = useContext(ProjectContext);
  const [updateIsOpen, setUpdateIsOpen] = useState(false);
  const [consultants, setConsultants] = useState({
    isLoading: true,
    data: [] as User[],
  });
  const theme = useTheme();

  const [company, setCompany] = useState({
    data: {} as CompanyWithProfile,
    isLoading: true,
  });

  const [consultantValue, setConsultantValue] = useState<Partial<User>>(
    task.consultant || defaultConsultantValue
  );

  useEffect(() => {
    if (!("id" in company)) {
      CompanyService.getCompanyById(task.company_id).then((data) =>
        setCompany({ data, isLoading: false })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task.company_id]);

  useEffect(() => {
    if (currentProject.data) {
      ProjectsService.getConsultantsByProject(currentProject.data.id).then(
        (data) => setConsultants({ data, isLoading: false })
      );
    }
  }, [currentProject.data]);

  useEffect(() => {
    const kanbanTask = kanbanData.data?.tasks.find(
      (kTask) => kTask.id === task.id
    );

    const consultant = consultants?.data.find(
      (consultant) => kanbanTask?.consultant_id === consultant.id
    );

    setConsultantValue(consultant || defaultConsultantValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, kanbanData, consultants.data]);

  const handleChoiceConsultant = async (obj: typeof consultantValue) => {
    setConsultantValue(obj);

    try {
      await updateTask({
        ...task,
        consultant_id: obj.id === "null" ? null : obj.id,
      });
    } catch (err) {
      toast.error("Ocorreu um erro  ao atualizar o consultor.");
    }
  };

  const handleUpdateCompany = async (newCompany: Partial<Company>) => {
    try {
      setCompany({ ...company, data: { ...company.data, ...newCompany } });
      await CompanyService.update(newCompany);

      updateTask(
        { ...task, company: { ...task.company, ...newCompany } },
        true
      );

      console.log(newCompany);

      setUpdateIsOpen(false);
    } catch (err) {
      toast.error("Ocorreu um erro ao atualizar a empresa");
    }
  };

  return (
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      style={{ width: "100%", height: "100%" }}
    >
      <VStack
        w={"100%"}
        h={"100%"}
        justifyContent={"space-between"}
        pb={"64px"}
      >
        <VStack w={"100%"} flex={1} alignItems={"flex-start"} spacing={0}>
          <HStack spacing={"16px"} w={"100%"}>
            <Image
              w={"64px"}
              h={"64px"}
              objectFit={"cover"}
              borderRadius={"50%"}
              background={"Gray.$300"}
              src={
                task.company.image_url ||
                createImageFromInitials({
                  background: (theme.colors as any).Tertiary.pure,
                  color: (theme.colors as any).white,
                  name: task.company?.name.substring(0, 2) || "",
                  size: 64,
                  fontSize: 32,
                })
              }
            />

            <VStack spacing={"0px !important"} alignItems={"flex-start"}>
              <Text fontWeight={600} fontSize={"RH_xl"} fontFamily={"Raleway"}>
                {task.company?.name}
              </Text>
              <Text fontWeight={400} fontSize={"L_md"} color={"Gray.$600"}>
                {task.company.phrase_solution ||
                  "Solução em uma frase não definida"}
              </Text>
            </VStack>

            <ProtectedComponent
              requiredClientRoles={[PERMISSIONS.COMPANY_UPDATE]}
              children={
                <Button
                  minH={"36px"}
                  ml={"auto"}
                  onClick={() => setUpdateIsOpen(true)}
                >
                  Editar empresa
                </Button>
              }
            />
          </HStack>

          {task.company.status !== "finished" && (
            <ReSendInvite company_id={task.company.id} />
          )}

          {company.isLoading || consultants?.isLoading ? (
            <Center w={"100%"} py={"62px"}>
              <MutatingDots
                height="100"
                width="100"
                color="#408EC5"
                secondaryColor="#408EC5"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                visible={true}
              />
            </Center>
          ) : (
            <>
              <Text
                fontSize={"RH_sm"}
                fontWeight={600}
                fontFamily={"Raleway"}
                mb={"8px"}
                mt={"40px"}
              >
                Perfil da empresa
              </Text>

              <ProfileDetails
                company={company.data}
                handleUpdateCompany={handleUpdateCompany}
              />

              <VStack
                w={"100%"}
                mt={"40px"}
                alignItems={"flex-start"}
                spacing={0}
              >
                <Text
                  fontSize={"RH_sm"}
                  fontWeight={600}
                  fontFamily={"Raleway"}
                >
                  Consultor responsável
                </Text>
                <Text
                  fontSize={"LSM"}
                  fontWeight={400}
                  color={"Gray.$600"}
                  mb={"16px"}
                >
                  Selecione o consultor responsável pelo acompanhemento dessa
                  empresa
                </Text>

                <Select
                  items={[...consultants?.data, defaultConsultantValue].map(
                    (consultant) => ({
                      label: consultant?.name,
                      value: consultant?.id,
                      icon: consultant?.image_url,
                    })
                  )}
                  value={{
                    label: consultantValue?.name || "",
                    value: consultantValue.id || "",
                    icon: consultantValue.image_url,
                  }}
                  aditionalButton={
                    <HStack
                      p={"16px"}
                      w={"100%"}
                      cursor={"pointer"}
                      onClick={() => {
                        if (currentProject.data) {
                          setDialog(
                            ProjectDialogs["consultants"]({
                              project: currentProject.data,
                              onClose: () => setDialog(undefined),
                              dialog: "consultants",
                            })
                          );
                        }
                      }}
                    >
                      <Center
                        w={"24px"}
                        h={"24px"}
                        border={"1px solid"}
                        borderColor={"Gray.$800"}
                        borderRadius={"50%"}
                      >
                        <Icon name={"plus"} />
                      </Center>

                      <Text
                        fontSize={"16px"}
                        variant={"Poppins"}
                        color={"#221F1D"}
                      >
                        Convidar novo consultor
                      </Text>
                    </HStack>
                  }
                  onChange={({ label, value, icon }) =>
                    handleChoiceConsultant({
                      id: value,
                      image_url: icon,
                      name: label,
                    })
                  }
                />
              </VStack>

              <Text
                mt={"40px !important"}
                mb={"8px !important"}
                fontWeight={600}
                fontFamily={"Raleway"}
                fontSize={"RH_sm"}
              >
                Equipe
              </Text>

              <VStack mt={"8px !important"} w={"100%"}>
                {task.company?.users_company_datas?.length >= 1 ? (
                  <>
                    {task.company.users_company_datas?.map(
                      ({ user }, index) => (
                        <VStack
                          key={user.id}
                          w={"100%"}
                          alignItems={"flex-end"}
                          spacing={"0px !important"}
                        >
                          <HStack
                            w={"100%"}
                            spacing={"12px !important"}
                            py={"16px"}
                          >
                            <Center
                              w={"40px"}
                              h={"40px"}
                              borderRadius={"50%"}
                              background={"Gray.$300"}
                            >
                              {user.image_url ? (
                                <Image
                                  src={user.image_url}
                                  borderRadius={"50%"}
                                  objectFit={"cover"}
                                  w={"100%"}
                                  h={"100%"}
                                />
                              ) : (
                                <Icon name="user" />
                              )}
                            </Center>

                            <HStack flex={1} w={"100%"}>
                              <VStack
                                w={"100%"}
                                spacing={"0px !important"}
                                alignItems={"flex-start"}
                              >
                                <Text fontSize={"L_md"}>{user?.name}</Text>
                                <Text fontSize={"L_sm"} color={"Gray.$700"}>
                                  {user.email}{" "}
                                  {user.phone &&
                                    "• " + masks["text"](user.phone)}
                                </Text>
                              </VStack>

                              {user.id === task.company.representative_id && (
                                <Text
                                  background={"Gray.$200"}
                                  borderRadius={"4px"}
                                  fontSize={"L_sm"}
                                  p={"2px 4px"}
                                >
                                  Representante
                                </Text>
                              )}
                            </HStack>
                          </HStack>
                          {task.company.users_company_datas.length - 1 !==
                            index && (
                            <Stack
                              w={"91%"}
                              h={"1px"}
                              background={"Gray.$300"}
                            />
                          )}
                        </VStack>
                      )
                    )}
                  </>
                ) : (
                  <HStack w={"100%"} alignItems={"flex-start"}>
                    <Text>Essa empresa ainda não possuí membros</Text>
                  </HStack>
                )}
              </VStack>
            </>
          )}
        </VStack>
      </VStack>
      <Modal isOpen={!!dialog} onClose={() => setDialog(undefined)}>
        {dialog}
      </Modal>

      <Modal
        isOpen={updateIsOpen}
        onClose={() => setUpdateIsOpen(false)}
        title="Editar detalhes da empresa"
      >
        <DetailTab
          company={task.company as CompanyWithProfile}
          handleUpdateCompany={handleUpdateCompany}
          modalMode
        />
      </Modal>
    </motion.div>
  );
};

export default AboutSection;
