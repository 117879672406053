import styled from "styled-components";
import { layout } from "styled-system";
import { Props } from "./types";

interface SelectProps extends Props {
  isInvalid?: boolean;
  inInput: boolean;
  theme: any;
}

export const StyledBoxSelect = styled.div<SelectProps>`
  margin: 0 !important;
  border-width: 1px;
  font-size: 16px;
  min-height: 56px;
  width: 100%;
  outline: none;
  border-color: ${(props) =>
    props.isInvalid
      ? props.theme.colors.Red.pure
      : props.inInput
        ? props.theme.colors.Tertiary.pure
        : props.theme.colors.Gray.$500};

  padding: ${(props) =>
    props.placeholder && (!!props.value || props.inInput)
      ? "22px 20px 8px 15px"
      : "16px 16px 4px 15px"};

  border-radius: 8px;
  color: ${(props) => props.theme.colors.Gray.$600};
  position: relative;
  cursor: pointer;

  ${layout}
`;

export const StyledPlaceHolder = styled.div<{
  labelIsUpped: boolean;
}>`
  padding-top: ${(props) => (props.labelIsUpped ? "8px" : "16px")};
  transition: all 300ms;
  position: absolute;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  left: 0;
  top: 0;
`;

export const StyledDropdown = styled.div<{ isOpen: boolean }>`
  top: 70px;
  left: 0;
  width: 100%;
  display: flex;
  z-index: 9999;
  overflow-y: auto;
  position: absolute;
  border-radius: 12px;
  transition: all 300ms;
  flex-direction: column;
  background: white;
  max-height: ${(props) => (props.isOpen ? 180 : 0)}px;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.1),
    0 3px 1px -2px rgba(0, 0, 0, 0.1),
    0 1px 5px 0 rgba(0, 0, 0, 0.1);
  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }
`;

export const StyledItem = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  flex-direction: row;
  width: 100%;
  display: flex;
  padding: 16px;
  transition: all 300ms;

  &:hover {
    background-color: #eee;
  }
`;
