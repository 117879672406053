import { Archive } from "src/types";

export type documentType = "image" | "video" | "archive" | "all";

export const documentTypes: {
  [x in documentType]: { formats: string[]; label: string };
} = {
  all: { formats: [], label: "Tudo" },
  image: { formats: ["png", "gif", "heic", "jpg", "jpeg"], label: "Imagens" },
  video: { formats: ["mp4", "avi"], label: "Videos" },
  archive: { formats: ["pdf", "docx", "xlsx"], label: "Arquivos" },
};

export const getDocumentsByType = (
  archives: Archive[],
  type: documentType
): Archive[] =>
  archives.reduce((acc, item) => {
    const prefix = item.name
      .substring(item.name.indexOf(".") + 1, 99)
      .toLowerCase();

    if (type !== "all") {
      return [
        ...acc,
        ...(documentTypes[type].formats.includes(prefix) ? [item] : []),
      ];
    }

    return [...acc, item];
  }, [] as Archive[]);
