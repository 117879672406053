import {
  Box,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
  useOutsideClick,
  useTheme,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { createImageFromInitials } from "src/common";
import Icon from "src/components/base/Icon";
import { Props } from "./types";
import { Client } from "src/types";
import { useNavigate } from "react-router-dom";

const ClientLine: React.FC<Props> = ({
  client,
  isLast,
  handleRemoveClient,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme<any>();
  const menuRef = useRef<any>(null);

  useOutsideClick({
    ref: menuRef,
    handler: () => setMenuIsOpen(false),
  });

  const menuOptions = [
    {
      title: "Abrir cliente",
      color: "black",
      onClick: (client_id: Client["id"]) => navigate(`/clients/${client_id}`),
    },
    {
      title: "Remover do projeto",
      color: "#CC0000",
      onClick: handleRemoveClient,
    },
  ];

  return (
    <HStack spacing={"16px"} w={"100%"} position={"relative"}>
      <Image
        w={"36px"}
        h={"36px"}
        borderRadius={"50%"}
        src={createImageFromInitials({
          background: theme.colors.Tertiary.pure,
          color: (theme.colors as any).white,
          name: client.name.substring(0, 1) || "",
          size: 36,
          fontSize: 18,
        })}
      />
      <HStack
        w={"100%"}
        justifyContent={"space-between"}
        py={"18px"}
        borderBottom={"1px solid"}
        borderBottomColor={!isLast ? "Gray.$400" : "transparent"}
      >
        <Text fontSize={"LMD"} fontWeight={600}>
          {client.name}
        </Text>

        <Box cursor={"pointer"} onClick={() => setMenuIsOpen(true)}>
          <Icon name={"ellipsis-h"} size={"20px"} />
        </Box>
      </HStack>

      {menuIsOpen && (
        <VStack
          ref={menuRef}
          py={"8px"}
          right={"-200px"}
          top={"50px"}
          position={"absolute"}
          zIndex={200}
          background={"Gray.$100"}
          minW={"240px"}
          borderRadius={"12px"}
          boxShadow={"0px 2px 16px rgba(0, 0, 0, 0.12)"}
        >
          {menuOptions.map((option) => (
            <Stack
              cursor={"pointer"}
              key={option.title}
              padding={"12px 16px"}
              onClick={() => {
                setMenuIsOpen(false);
                option.onClick(client.id);
              }}
              w={"100%"}
              alignItems={"flex-start"}
              transition={"300ms all"}
              _hover={{
                background: "Gray.$200",
              }}
            >
              <Text fontSize={"L_sm"} fontWeight={400} color={option.color}>
                {option.title}
              </Text>
            </Stack>
          ))}
        </VStack>
      )}
    </HStack>
  );
};

export default ClientLine;
