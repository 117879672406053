import { Reorder, useDragControls } from "framer-motion";
import { Props } from "./types";
import React from "react";
import { Box, HStack } from "@chakra-ui/react";
import Input from "src/components/base/Input/Index";
import Icon from "src/components/base/Icon";

const QuestionOption: React.FC<Props> = ({
  handleChange,
  handleDelete,
  item,
}) => {
  const controls = useDragControls();

  return (
    <Reorder.Item
      style={{ width: "100%" }}
      value={item.index}
      dragListener={false}
      dragControls={controls}
    >
      <HStack w="100%" spacing={"8px"}>
        <Box onPointerDown={(e) => controls.start(e)} cursor={"pointer"}>
          <Icon name={"grip-horizontal-line"} />
        </Box>

        <Input
          placeholder={`Opção ${item.index + 1}`}
          onChange={({ target }) => handleChange(target.value)}
          value={item.name}
        />

        <Box cursor={"pointer"} onClick={handleDelete}>
          <Icon name={"multiply"} />
        </Box>
      </HStack>
    </Reorder.Item>
  );
};

export default QuestionOption;
