import {
  HStack,
  VStack,
  Center,
  Text,
  useTheme,
  Stack,
  useOutsideClick,
  Image,
} from "@chakra-ui/react";
import React, { useState, useRef, useContext } from "react";
import { MutatingDots } from "react-loader-spinner";
import { createImageFromInitials } from "src/common";
import { ClientContext } from "src/contexts/ClientContext";
import ClientService from "src/services/clients";
import Input from "../../../../components/base/Input/Index";
import { MenuButton } from "../../../../components/MenuButton";
import { Client } from "../../../../types";
import { masks } from "src/components/base/Input/masks";
import { PERMISSIONS } from "src/permissions";
import { ProtectedComponent } from "semente-js";

export const ListLeftSide: React.FC = () => {
  const theme = useTheme();
  const {
    clients,
    currentClient,
    handleDeleteClient,
    selectClient,
    showClientAddon,
  } = useContext(ClientContext);

  const [timer, setTimer] = useState<any>(null);

  const [searchClients, setSearchClients] = useState<{
    isLoading: boolean;
    data?: Client[];
  }>();

  const [menuIsOpen, setMenuIsOpen] = useState<string>();
  const menuRef = useRef<any>(null);

  useOutsideClick({
    ref: menuRef,
    handler: () => setMenuIsOpen(undefined),
  });

  const menuOptions = [
    {
      title: "Editar cliente",
      color: "black",
      requiredClientRoles: [PERMISSIONS.CLIENT_UPDATE],
      onClick: (client: Client) => showClientAddon("update", client.id),
    },
    {
      title: "Excluir cliente",
      color: "#CC0000",
      requiredClientRoles: [PERMISSIONS.CLIENT_DELETE],
      onClick: (client: Client) => {
        handleDeleteClient(client.id);
        setMenuIsOpen(undefined);
      },
    },
  ];

  const handleSearchProject = async (filter: string) => {
    if (filter) {
      setSearchClients({ isLoading: true, data: undefined });
      setSearchClients({
        isLoading: false,
        data: await ClientService.getClients({ filter }),
      });
    } else {
      setSearchClients(undefined);
    }
  };

  const handleChangeInput = (e: string) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(setTimeout(() => handleSearchProject(e), 500));
  };

  return (
    <VStack maxW={"360px"} w={"100%"}>
      <Input
        placeholder={"Procurar cliente"}
        width={"100%"}
        onChange={(e) => handleChangeInput(e.target.value)}
        icon={"search"}
      />
      {searchClients && searchClients.isLoading ? (
        <Center flex={1} mt={"32px !important"}>
          <MutatingDots
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <VStack w={"100%"} mt={"32px !important"}>
          {searchClients?.data && searchClients?.data?.length < 1 ? (
            <Text>Nenhum cliente encontrado</Text>
          ) : (
            <>
              {((searchClients?.data || clients.data) as Client[]).map(
                (client, key) => (
                  <HStack
                    key={key}
                    w={"100%"}
                    background={
                      currentClient.client_id === client.id
                        ? "#ECF6FE"
                        : "transparent"
                    }
                    borderRadius={"12px"}
                    alignItems={"center"}
                    cursor={"pointer"}
                    position={"relative"}
                    transition={"300ms all"}
                  >
                    <HStack
                      padding={"16px"}
                      flex={1}
                      onClick={() => selectClient(client.id)}
                    >
                      <Center
                        w="36px"
                        h="36px"
                        overflow="hidden"
                        borderRadius="60px"
                        alignItems="center"
                        background={"#E3E5E8"}
                        justifyContent="center"
                      >
                        <Image
                          src={createImageFromInitials({
                            background: (theme.colors as any).Tertiary.pure,
                            color: (theme.colors as any).white,
                            name: client?.name.substring(0, 1) || "",
                            size: 36,
                            fontSize: 16,
                          })}
                          width="100%"
                          height="100%"
                          borderRadius="60px"
                          objectFit={"cover"}
                        />
                      </Center>
                      <VStack
                        maxW={"70%"}
                        overflow={"hidden"}
                        alignItems={"flex-start"}
                        spacing={0}
                        ml={"8px"}
                      >
                        <Text
                          whiteSpace={"nowrap"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          w={"100%"}
                          fontSize={"L_md"}
                          fontWeight={600}
                          color={"Gray.$800"}
                        >
                          {client.name}
                        </Text>
                        <Text
                          fontSize={"L_sm"}
                          fontWeight={500}
                          color={"Gray.$800"}
                        >
                          {!client.cnpj
                            ? "CNPJ não definido."
                            : masks["cnpj"](client?.cnpj)}
                        </Text>
                      </VStack>
                    </HStack>

                    <Center
                      position={"absolute"}
                      zIndex={10}
                      right={"20px"}
                      height={"100%"}
                      onClick={() => setMenuIsOpen(client.id || "")}
                    >
                      <MenuButton />
                    </Center>

                    {client.id === menuIsOpen && (
                      <VStack
                        py={"8px"}
                        ref={menuRef}
                        right={"-55%"}
                        top={"50px"}
                        position={"absolute"}
                        zIndex={200}
                        background={"Gray.$100"}
                        minW={"240px"}
                        borderRadius={"12px"}
                        boxShadow={"0px 2px 16px rgba(0, 0, 0, 0.12)"}
                      >
                        {menuOptions.map((option) => (
                          <ProtectedComponent
                            key={option.title}
                            requiredClientRoles={option.requiredClientRoles}
                            children={
                              <Stack
                                padding={"12px 16px"}
                                onClick={() => option.onClick(client)}
                                w={"100%"}
                                alignItems={"flex-start"}
                                transition={"300ms all"}
                                _hover={{
                                  background: "Gray.$200",
                                }}
                              >
                                <Text
                                  fontSize={"L_sm"}
                                  fontWeight={400}
                                  color={option.color}
                                >
                                  {option.title}
                                </Text>
                              </Stack>
                            }
                          />
                        ))}
                      </VStack>
                    )}
                  </HStack>
                )
              )}
            </>
          )}
        </VStack>
      )}
    </VStack>
  );
};
