import { Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Props } from "./types";
import { AddButton } from "src/components/AddButton";
import Icon from "src/components/base/Icon";

const MessagesEmptyState: React.FC<Props> = ({
  handleAdd,
  hasCompanyEmail,
}) => {
  return (
    <VStack spacing={0}>
      <Icon name="file-blank" size="40px" />
      <Text mt={"12px"} textAlign={"center"} fontWeight={600} fontSize={"L_md"}>
        Não existem mensagens aqui ainda
      </Text>
      <Text
        mb={"16px"}
        textAlign={"center"}
        fontWeight={400}
        color={"Gray.$700"}
        fontSize={"L_sm"}
      >
        {!hasCompanyEmail
          ? "Essa empresa ainda não possui um email cadastrado"
          : "Envie mensagems por e-mail para a empresa"}
      </Text>

      <AddButton
        isDisabled={!hasCompanyEmail}
        label="Nova mensagem"
        onClick={handleAdd}
        variant="Secondary"
      />
    </VStack>
  );
};

export default MessagesEmptyState;
