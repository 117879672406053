import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal } from "../../../../../components/base/Modal";
import * as XLSX from "xlsx";
import { Props } from "./types";
import Icon from "../../../../../components/base/Icon";
import Input from "../../../../../components/base/Input/Index";
import { MutatingDots } from "react-loader-spinner";
import { doneSuperIcon } from "../../../../../assets/images";
import { saveAs } from "file-saver";

import { CompanyModel } from "../../../../../models";
import InviteService from "src/services/invite";
import { ProjectContext } from "src/contexts/ProjectContext";
import Checkbox from "src/components/Checkbox";

const CompanyInvite: React.FC<Props> = ({
  isOpen,
  project,
  handleClose,
  handleFinish,
}) => {
  const [inviteByPlan, setInviteByPlan] = useState(false);
  const { selectProject } = useContext(ProjectContext);
  const [sendAllInEmail, setSendAllInEmail] = useState(true);

  const defaultValues = {
    data: [
      {
        name: "",
        sendEmail: true,
        representative_email: "",
      },
    ],
  };

  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    watch,
    setValue,
    getValues,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues,
  });

  const handleCloseModal = () => {
    reset(defaultValues);
    handleClose();
  };

  useEffect(() => {
    if (sendAllInEmail) {
      setValue(
        "data",
        getValues().data.map((data) => ({ ...data, sendEmail: true }))
      );
    }
  }, [sendAllInEmail, setValue, getValues]);

  const handleSubmitForm: SubmitHandler<typeof defaultValues> = async (
    values
  ) => {
    try {
      await InviteService.sendInvites({
        companies: values.data.map(
          ({ name, sendEmail, representative_email }) => ({
            email: representative_email,
            sendEmail,
            company: { name },
          })
        ),
        ...(project && { project_id: project.id }),
      });

      if (project) {
        await selectProject(project.id, true);
      }
    } catch (err) {
      toast.success("Ocorreu uma falha ao enviar os convites.");
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      try {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = (evt: any) => {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const jsonObject: {
            ["Email do representante"]: string;
            ["Nome da empresa"]: string;
            ["Enviar email?"]: string;
          }[] = XLSX.utils.sheet_to_json(ws, {});

          reset({
            data: [
              ...watch("data").filter(
                ({ name, representative_email }) =>
                  name !== "" && representative_email !== ""
              ),
              ...jsonObject.map((data) => ({
                name: data["Nome da empresa"],
                representative_email: data["Email do representante"],
                sendEmail: data["Enviar email?"] === "SIM",
              })),
            ],
          });
          setInviteByPlan(false);
        };
        reader.readAsBinaryString(file);
      } catch (err) {
        toast.error("Ocorreu um erro ao importar sua planilha.");
        setInviteByPlan(false);
      }
    },
    [reset, watch]
  );

  const handleDownloadExample = () => {
    saveAs(CompanyModel, "Template para criação de empresas");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <Modal
      onClose={handleCloseModal}
      isOpen={isOpen !== undefined ? isOpen : true}
      title={
        isSubmitSuccessful || isSubmitting ? "" : "Convidar novas empresas"
      }
    >
      <VStack
        w={"100%"}
        maxW={"800px"}
        borderTop={"1px solid"}
        borderColor={"Gray.$400"}
        minH={project ? "300px" : "200px"}
        overflow={"auto"}
      >
        {isSubmitting ? (
          <Center minH={"390px"}>
            <MutatingDots
              height="100"
              width="100"
              color="#408EC5"
              secondaryColor="#408EC5"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              visible={true}
            />
          </Center>
        ) : (
          <>
            {isSubmitSuccessful ? (
              <VStack p={"24px"} alignItems={"flex-start"}>
                <Image src={doneSuperIcon} alt={"Ícone de sucesso"} />

                <Text
                  mt={"24px !important"}
                  fontWeight={600}
                  fontSize={"RH_lg"}
                  fontFamily={"Raleway"}
                >
                  Empresas adicionadas!
                </Text>
                <Text
                  fontWeight={400}
                  fontSize={"L_md"}
                  color={"Gray.$700"}
                  mt={"4px !important"}
                >
                  As empresas selecionadas já foram adicionadas ao projeto{" "}
                  <b style={{ color: "black" }}>{project?.name}</b>.
                </Text>

                <VStack spacing={"16px"} w={"100%"} mt={"24px !important"}>
                  {watch("data").map((company, index) => (
                    <HStack
                      key={index}
                      p={"16px"}
                      w={"100%"}
                      background={"Gray.$200"}
                      borderRadius={"12px"}
                    >
                      <Icon name="store" size="22px" />

                      <VStack alignItems={"flex-start"}>
                        <Text
                          fontWeight={600}
                          ml={"16px !important"}
                          fontSize={"L_lg"}
                          color={"Gray.$800"}
                        >
                          {company.name}
                        </Text>
                        <Text
                          fontWeight={400}
                          ml={"16px !important"}
                          fontSize={"L_sm"}
                          color={"Gray.$700"}
                        >
                          {company.representative_email}
                        </Text>
                      </VStack>
                    </HStack>
                  ))}
                </VStack>
              </VStack>
            ) : (
              <VStack py={"24px"} px={"12px"} w={"100%"}>
                {project && (
                  <HStack
                    mt={"24px"}
                    w={"100%"}
                    px={"12px"}
                    mb={"24px !important"}
                  >
                    <HStack
                      pb={"24px"}
                      w={"100%"}
                      borderBottom={"1px solid"}
                      spacing={"12px !important"}
                      borderColor={"Gray.$400"}
                    >
                      <Center
                        w={"24px"}
                        h={"24px"}
                        borderRadius={"50%"}
                        background={"Gray.$300"}
                      >
                        {project?.image_url ? (
                          <Image
                            src={project.image_url}
                            borderRadius={"50%"}
                            objectFit={"cover"}
                            w={"100%"}
                            h={"100%"}
                          />
                        ) : (
                          <Icon name="image" />
                        )}
                      </Center>
                      <Text fontWeight={400} fontSize={"L_md"}>
                        {project?.name}
                      </Text>
                    </HStack>
                  </HStack>
                )}

                {inviteByPlan ? (
                  <VStack w={"100%"} alignItems={"flex-start"} px={"12px"}>
                    <Text fontSize={"L_lg"} fontWeight={600}>
                      Convidar através de planilha
                    </Text>
                    <Text
                      fontSize={"L_sm"}
                      fontWeight={400}
                      color={"Gray.$700"}
                    >
                      Faça o download do modelo da planilha e preencha com os
                      nomes das empresas e os e-mails dos representantes.
                    </Text>
                    <HStack
                      my={"16px !important"}
                      cursor={"pointer"}
                      onClick={handleDownloadExample}
                    >
                      <Icon name={"arrow-circle-down"} color={"#2F77A9"} />
                      <Text color={"Tertiary.dark"} fontWeight={600}>
                        Baixar modelo de planilha
                      </Text>
                    </HStack>
                    <Center
                      w={"100%"}
                      background={"Gray.$200"}
                      border={"1px solid"}
                      borderColor={"Gray.$400"}
                      borderStyle={"dashed"}
                      borderRadius={"12px"}
                      height={"158px"}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <Text fontFamily={"lato"} fontSize={"l_md"}>
                          Solte seu arquivo aqui!
                        </Text>
                      ) : (
                        <VStack>
                          <Text fontFamily={"lato"} fontSize={"l_md"}>
                            Solte aqui o arquivo da planilha
                          </Text>
                          <Button mt={"10px !important"}>
                            Procurar arquivo
                          </Button>
                        </VStack>
                      )}
                    </Center>
                  </VStack>
                ) : (
                  <VStack w={"100%"} alignItems={"flex-end"}>
                    <Controller
                      name={"data"}
                      control={control}
                      render={({ field: { value, onChange }, fieldState }) => (
                        <VStack
                          w={"100%"}
                          alignItems={"flex-start"}
                          spacing={"16px !important"}
                        >
                          {value.map((data, index) => (
                            <VStack
                              key={index}
                              w={"100%"}
                              alignItems={"flex-start"}
                            >
                              <HStack key={index} w={"100%"}>
                                <HStack w={"100%"} spacing={"16px"}>
                                  <Input
                                    onChange={(e) => {
                                      clearErrors("data");
                                      value[index] = {
                                        ...value[index],
                                        name: e.target.value,
                                      };
                                      onChange([...value]);
                                    }}
                                    isInvalid={
                                      index === value.length - 1 &&
                                      !!fieldState.error
                                    }
                                    value={data.name}
                                    placeholder={"Nome da empresa"}
                                  />

                                  <Input
                                    onChange={(e) => {
                                      clearErrors("data");
                                      value[index] = {
                                        ...value[index],
                                        representative_email: e.target.value,
                                      };
                                      onChange([...value]);
                                    }}
                                    isInvalid={
                                      index === value.length - 1 &&
                                      !!fieldState.error
                                    }
                                    value={data.representative_email}
                                    placeholder={"E-mail do representante"}
                                  />
                                </HStack>

                                {value.length >= 2 && (
                                  <Box
                                    cursor={"pointer"}
                                    ml={"18px !important"}
                                    onClick={() => {
                                      value.splice(index, 1);
                                      onChange([...value]);
                                    }}
                                  >
                                    <Icon name={"multiply"} size={"22px"} />
                                  </Box>
                                )}
                              </HStack>

                              <HStack>
                                <Checkbox
                                  isChecked={data.sendEmail}
                                  onChange={(e) => {
                                    setSendAllInEmail(false);
                                    console.log(e);

                                    value[index] = {
                                      ...value[index],
                                      sendEmail: e,
                                    };
                                    onChange([...value]);
                                  }}
                                />

                                <Text fontFamily={"lato"} fontSize={"l_md"}>
                                  Enviar convite por e-mail
                                </Text>
                              </HStack>
                            </VStack>
                          ))}

                          <Button
                            onClick={() => {
                              if (
                                value[value.length - 1].name === "" ||
                                value[value.length - 1].representative_email ===
                                  ""
                              ) {
                                setError(
                                  "data",
                                  new Error("Invalid last field")
                                );
                                return;
                              }
                              onChange([...value, defaultValues.data[0]]);
                            }}
                            minH={"36px"}
                          >
                            <HStack spacing={"8px !important"}>
                              <Icon name={"plus"} />
                              <Text>Adicionar mais</Text>
                            </HStack>
                          </Button>
                        </VStack>
                      )}
                    />
                  </VStack>
                )}
              </VStack>
            )}
          </>
        )}
      </VStack>

      {!isSubmitting && (
        <HStack
          w={"100%"}
          py={"16px"}
          px={"24px"}
          borderTop={"1px solid"}
          borderColor={"Gray.$400"}
          justifyContent={"space-between"}
        >
          {!isSubmitSuccessful ? (
            <>
              <VStack w={"100%"} alignItems={"flex-start"}>
                <HStack>
                  <Checkbox
                    onChange={() => setSendAllInEmail(!sendAllInEmail)}
                    isChecked={sendAllInEmail}
                  />

                  <Text fontFamily={"lato"} fontSize={"l_md"}>
                    Enviar todos os convites por e-mail
                  </Text>
                </HStack>

                <Text
                  fontWeight={600}
                  color={"Tertiary.dark"}
                  cursor={"pointer"}
                  onClick={() => setInviteByPlan(!inviteByPlan)}
                >
                  {inviteByPlan
                    ? "Preencher e-mails manualmente"
                    : "Enviar planilha com e-mails"}
                </Text>
              </VStack>
              <Button
                minH={"36px"}
                variant={"Primary"}
                isDisabled={
                  !!watch("data").find(
                    ({ name, representative_email }) =>
                      name === "" || representative_email === ""
                  )
                }
                borderRadius={"8px"}
                px={"24px"}
                onClick={handleSubmit(handleSubmitForm)}
              >
                Enviar convites
              </Button>
            </>
          ) : (
            <>
              <Text />
              <Button variant={"Primary"} onClick={handleFinish}>
                Concluído
              </Button>
            </>
          )}
        </HStack>
      )}
    </Modal>
  );
};

export default CompanyInvite;
