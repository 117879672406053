const IndicatorTypes = [
  {
    label: "Número",
    value: "number",
    prefix: "",
  },
  {
    label: "Monetário",
    value: "monetary",
    prefix: "R$",
  },
];

export default IndicatorTypes;
