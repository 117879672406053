import React, { useContext, useRef, useState } from "react";
import { Props } from "./types";
import {
  Box,
  Button,
  Center,
  HStack,
  Text,
  VStack,
  useOutsideClick,
} from "@chakra-ui/react";
import { fadeInUp } from "src/animations";
import { motion } from "framer-motion";
import GeneralForm from "./Tabs/General";
import CompaniesForm from "./Tabs/Companies";
import Icon from "src/components/base/Icon";
import { KanbanContext } from "src/contexts/KanbanContext";
import { Modal } from "src/components/base/Modal";
import { MutatingDots } from "react-loader-spinner";
import { ProjectViewContext } from "src/pages/Project/Context";

const KanbanUpdate: React.FC<Props> = ({ kanbanData, handleBack }) => {
  const { handleDeleteKanban } = useContext(KanbanContext);
  const { setCurrentTabIn } = useContext(ProjectViewContext);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<{
    isLoading: boolean;
    id: string;
  }>();
  const menuRef = useRef<any>(null);

  const tabs = [
    {
      name: "Geral",
      Component: <GeneralForm kanbanData={kanbanData} />,
    },
    {
      name: "Empresas",
      Component: <CompaniesForm />,
    },
  ];

  useOutsideClick({
    ref: menuRef,
    handler: () => setMenuIsOpen(false),
  });

  const menuOptions = [
    {
      title: "Remover quadro",
      color: "#CC0000",
      icon: "trash-alt-solid",
      onClick: () => {
        setShowConfirmationModal({
          isLoading: false,
          id: kanbanData.id,
        });
        setMenuIsOpen(false);
      },
    },
  ];

  const handleDeleteBoard = async () => {
    if (showConfirmationModal) {
      setShowConfirmationModal({ ...showConfirmationModal, isLoading: true });
      await handleDeleteKanban(showConfirmationModal.id);
      setCurrentTabIn(1);
      setShowConfirmationModal(undefined);
    }
  };

  return (
    <VStack
      w={"100%"}
      alignItems={"flex-start"}
      maxW={"536px"}
      pb={"32px"}
      spacing={0}
    >
      <HStack spacing={"8px"} mb={"28px !important"}>
        <Text
          fontSize={"L_md"}
          cursor={"pointer"}
          fontWeight={500}
          onClick={handleBack}
          textDecor={"underline"}
        >
          {kanbanData.name}
        </Text>
        <Text fontSize={"L_md"} fontWeight={500} onClick={handleBack}>
          / Editar quadro
        </Text>
      </HStack>

      <HStack w={"100%"} justifyContent={"space-between"}>
        <Text fontSize={"RH_xl"} fontWeight={500}>
          Editar quadro
        </Text>

        <Box cursor={"pointer"} position={"relative"}>
          <Center
            onClick={() => setMenuIsOpen(true)}
            w={"36px"}
            h={"36px"}
            borderRadius={"50%"}
            transition={"all 300ms"}
            background={menuIsOpen ? "Gray.$300" : ""}
            _hover={{
              background: "Gray.$300",
            }}
          >
            <Icon name={"ellipsis-h"} />
          </Center>

          {menuIsOpen && (
            <VStack
              py={"8px"}
              ref={menuRef}
              left={"0px"}
              top={"40px"}
              position={"absolute"}
              zIndex={200}
              background={"Gray.$100"}
              minW={"240px"}
              borderRadius={"12px"}
              boxShadow={"0px 2px 16px rgba(0, 0, 0, 0.12)"}
            >
              {menuOptions.map((option) => (
                <HStack
                  cursor={"pointer"}
                  key={option.title}
                  padding={"12px 16px"}
                  onClick={option.onClick}
                  w={"100%"}
                  transition={"300ms all"}
                  _hover={{
                    background: "Gray.$200",
                  }}
                >
                  <Icon name={option.icon} color={option.color} />
                  <Text fontSize={"L_sm"} fontWeight={400} color={option.color}>
                    {option.title}
                  </Text>
                </HStack>
              ))}
            </VStack>
          )}
        </Box>
      </HStack>

      <HStack mt={"36px !important"} spacing={"12px"}>
        {tabs.map((tab, index) => {
          const isChecked = currentTabIndex === index;

          return (
            <Center
              p={"10px 12px"}
              background={isChecked ? "Brand.pure" : "Gray.$200"}
              borderRadius={"500px"}
              onClick={() => setCurrentTabIndex(index)}
              cursor={"pointer"}
              transition={"300ms all"}
            >
              <Text
                color={isChecked ? "white" : "black"}
                fontWeight={500}
                fontSize={"L_sm"}
              >
                {tab.name}
              </Text>
            </Center>
          );
        })}
      </HStack>

      <motion.div {...fadeInUp} style={{ width: "100%" }}>
        <VStack mt={"36px !important"} borderRadius={"16px"} w={"100%"}>
          {tabs[currentTabIndex].Component}
        </VStack>
      </motion.div>

      <Modal
        onClose={() => setShowConfirmationModal(undefined)}
        isOpen={!!showConfirmationModal}
        title={"Remover quadro"}
      >
        {showConfirmationModal?.isLoading ? (
          <Center
            maxW={"568px"}
            minH={"300px"}
            borderTop={"1px solid"}
            borderColor={"Gray.$400"}
          >
            <VStack maxW={"400px"}>
              <MutatingDots
                height="100"
                width="100"
                color="#408EC5"
                secondaryColor="#408EC5"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                visible={true}
              />

              <Text
                mt={"24px !important"}
                fontSize={"L_md"}
                fontFamily={"lato"}
                color={"Gray.$800"}
                fontWeight={400}
                textAlign={"center"}
              >
                Aguarde, estamos removendo este quadro.
              </Text>
            </VStack>
          </Center>
        ) : (
          <VStack
            maxW={"568px"}
            borderTop={"1px solid"}
            borderColor={"Gray.$400"}
          >
            <Text
              p={"24px"}
              fontSize={"L_md"}
              fontFamily={"lato"}
              color={"Gray.$800"}
              fontWeight={400}
            >
              Tem certeza que deseja remover este quadro? todo o histórico será
              perdido.
            </Text>

            <VStack
              px={"24px"}
              py={"24px"}
              spacing={"8px !important"}
              w={"100%"}
              borderTop={"1px solid"}
              borderColor={"Gray.$400"}
            >
              <Button
                w={"100%"}
                variant={"Primary"}
                onClick={handleDeleteBoard}
              >
                Remover quadro
              </Button>
              <Button
                w={"100%"}
                variant={"Secondary"}
                onClick={() => setShowConfirmationModal(undefined)}
              >
                Voltar
              </Button>
            </VStack>
          </VStack>
        )}
      </Modal>
    </VStack>
  );
};

export default KanbanUpdate;
