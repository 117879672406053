import React, { useState } from "react";
import { ProfileProps } from "../types";
import { motion } from "framer-motion";
import { Button, Text, VStack } from "@chakra-ui/react";
import ProfileSelector from "../../ProfileSelector";

const Investments: React.FC<ProfileProps> = ({
  data,
  defaultValue: { investments_id },
  handleSubmit,
}) => {
  const [optionSelected, setOptionSelected] = useState<string[]>(
    investments_id ? [investments_id] : []
  );

  return (
    <motion.div
      style={{
        height: "100%",
        margin: 0,
        width: "100%",
        overflow: "auto",
      }}
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
    >
      <VStack height={"100%"} alignItems={"flex-start"}>
        <Text fontFamily={"Raleway"} fontSize={"RH_sm"} fontWeight={600}>
          Qual é a principal fonte de investimento na sua empresa?
        </Text>
        <Text color={"Gray.$700"} fontSize={"L_sm"} fontWeight={400}>
          Como sua empresa levanta recursos atualmente?
        </Text>

        <VStack
          w={"100%"}
          flex={1}
          overflow={"auto"}
          alignItems={"flex-start"}
          mt={"32px !important"}
        >
          <ProfileSelector
            data={data}
            idSelecteds={optionSelected}
            onPress={setOptionSelected}
          />
        </VStack>
        <Button
          variant={"Primary"}
          w={"100%"}
          onClick={() => handleSubmit({ investments_id: optionSelected[0] })}
          isDisabled={optionSelected.length < 1}
        >
          Continuar
        </Button>
      </VStack>
    </motion.div>
  );
};

export default Investments;
