import { Center, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { searchSuperIcon } from "../../assets/images";
import { AddButton } from "../AddButton";
import { Props } from "./types";

export const HomeEmptyState: React.FC<Props> = ({
  handleCreate,
  buttonLabel,
  description,
  title,
}) => {
  return (
    <Center w={'100%'}>
      <VStack padding={"64px 108px"}>
        <Image src={searchSuperIcon} alt={"Ícone de uma lupa"} />
        <Text
          fontSize={"RH_sm"}
          mt={"16px !important"}
          fontWeight={600}
          fontFamily={"raleway"}
        >
          {title}
        </Text>
        <Text
          mt={"8px !important"}
          mb={"16px !important"}
          color={"Gray.$700"}
          fontSize={"L_md"}
          fontWeight={400}
          textAlign={"center"}
        >
          {description}
        </Text>
        {handleCreate && buttonLabel && (
          <AddButton label={buttonLabel} onClick={handleCreate} />
        )}
      </VStack>
    </Center>
  );
};
