import { Box, Input } from "@chakra-ui/react";
import React from "react";
import { Controller } from "react-hook-form";
import { DefaultProps } from "../types";

const CnpjForm: React.FC<DefaultProps> = ({ control }) => {
  return (
    <Box w={"100%"} mt={"24px !important"}>
      <Controller
        control={control}
        name={"cnpj"}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            variant={"cnpj"}
            isInvalid={!!error}
            placeholder={"CNPJ"}
          />
        )}
      />
    </Box>
  );
};

export default CnpjForm;
