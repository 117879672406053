import React, { useContext, useEffect, useState } from "react";
import { socket } from "../../connections/socket";
import { Task } from "src/types";

export type SocketProps = {
  events: {
    taskUpdate: Task[];
  };
};

export const SocketContext = React.createContext<SocketProps>(
  {} as SocketProps
);

interface Props {
  children: React.ReactNode;
}

const SocketContextProvider = ({ children }: Props) => {
  const [socketEvents, setSocketEvents] = useState<SocketProps["events"]>({
    taskUpdate: [],
  });

  useEffect(() => {
    socket.connect();
  }, []);

  useEffect(() => {
    function onTaskUpdate(value: Task) {
      setSocketEvents((previous) => ({
        ...previous,
        taskUpdate: [...previous.taskUpdate, value],
      }));
    }

    socket.on("kanban_update", onTaskUpdate);

    return () => {
      socket.off("kanban_update", onTaskUpdate);
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        events: socketEvents,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  const context = useContext(SocketContext);

  if (context) return context;

  throw new Error(
    "useSocketContext must be used within a SocketContextProvider."
  );
};

export default SocketContextProvider;
