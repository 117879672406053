import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Center,
  HStack,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import Input from "src/components/base/Input/Index";
import { uploadFileToBucket } from "src/utils";
import ProfilePic from "src/components/ProfilePic";
import { createImageFromInitials } from "src/common";
import Icon from "src/components/base/Icon";
import { useDebouncedEffect } from "src/hooks/useDebouncedEffect";
import cep from "cep-promise";
import { Select } from "src/components/base/Select";
import { States } from "src/pages/CompanySignUp/Forms/Company/consts";
import { Props } from "./types";
import { omit } from "lodash";

const DetailTab: React.FC<Props> = ({
  handleUpdateCompany,
  modalMode,
  company,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    getValues,
    reset,
    watch,
  } = useForm({
    defaultValues: company,
  });
  const [newImage, setNewImage] = useState<{
    file: File;
    base64?: string;
  }>();
  const theme = useTheme();
  const zipWatcher = watch("address.zip");

  const handleUploadImage = async (file: File) => {
    if (file) {
      try {
        const params = {
          folderName: "empresas",
          id: company.id || "",
        };
        const image_url = await uploadFileToBucket(file, params);

        return image_url;
      } catch (err) {
        toast.error("Erro ao atualizar o projeto.");
      }
    }
  };

  const handleConvertImage = async (file: File) => {
    const base64: string = await new Promise((resolver) => {
      const reader = new FileReader();

      reader.onload = function ({ target }) {
        resolver(target?.result as string);
      };

      reader.readAsDataURL(file);
    });

    setNewImage({ file, base64: base64 });
  };

  useDebouncedEffect(
    async () => {
      if (zipWatcher?.length >= 1) {
        const addressInfo = await cep(zipWatcher.replace("-", ""));

        reset({
          ...getValues(),
          address: {
            zip: zipWatcher as any,
            city: addressInfo.city,
            state_province: addressInfo.state,
            address: addressInfo.street,
            country: "BR",
          },
          status: "finished",
        });
      }
    },
    [zipWatcher],
    500
  );

  return (
    <VStack w={"100%"} maxW={"566px"} spacing={0}>
      <VStack
        alignItems={modalMode ? "center" : "flex-start"}
        px={modalMode ? "24px" : "0px"}
        pb={modalMode ? "24px" : "0px"}
        overflow={"auto"}
        maxH={modalMode ? "65vh" : ""}
        w={"100%"}
      >
        <Box py={"36px"}>
          <ProfilePic
            imageUrl={newImage?.base64 || company?.image_url}
            defaultImage={createImageFromInitials({
              background: (theme.colors as any).Tertiary.pure,
              color: (theme.colors as any).white,
              name: company.name.substring(0, 2) || "",
              size: 116,
              fontSize: 64,
            })}
            onSelectImage={handleConvertImage}
            onRemoveImage={console.log}
            rounded
          />
        </Box>

        <VStack alignItems={"flex-start"} spacing={"16px"} w={"100%"}>
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <Input
                isInvalid={!!error}
                {...field}
                placeholder="Nome da empresa"
              />
            )}
          />

          <Controller
            control={control}
            name="phrase_solution"
            render={({ field, fieldState: { error } }) => (
              <Input
                isInvalid={!!error}
                {...field}
                placeholder="Solução em uma frase"
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            render={({ field, fieldState: { error } }) => (
              <Input
                isInvalid={!!error}
                {...field}
                placeholder="E-mail da empresa"
              />
            )}
          />

          <Controller
            control={control}
            name="website"
            render={({ field, fieldState: { error } }) => (
              <Input
                isInvalid={!!error}
                {...field}
                placeholder="Site da empresa"
              />
            )}
          />

          <Controller
            control={control}
            name="cnpj"
            render={({ field, fieldState: { error } }) => (
              <Input
                isInvalid={!!error}
                {...field}
                placeholder="CNPJ"
                variant="cnpj"
              />
            )}
          />

          <Controller
            control={control}
            name={"representative_id"}
            render={({ field, fieldState: { error } }) => (
              <Select
                placeholder="Representante"
                items={company.users_company_datas.map((user) => ({
                  label: user.user.name,
                  value: user.user_id,
                  icon: user.user.image_url,
                }))}
                isInvalid={!!error}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />

          <HStack mt={"16px !important"}>
            <Icon name="map-marker" />
            <Text>Endereço</Text>
          </HStack>

          <Controller
            control={control}
            name={"address.zip"}
            render={({ field, fieldState: { error } }) => (
              <Input
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                variant={"cep"}
                isInvalid={!!error}
                placeholder={"CEP"}
              />
            )}
          />

          <Text
            mt={"8px !important"}
            fontSize={"L_sm"}
            color={"Gray.$700"}
            fontWeight={400}
          >
            Caso a empresa não tenha um endereço, coloque o seu endereço
            residencial
          </Text>

          <HStack spacing={"16px"} w={"100%"}>
            <Controller
              control={control}
              name={"address.address"}
              rules={{ required: !!zipWatcher }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  isInvalid={!!error}
                  placeholder={"Endereço"}
                />
              )}
            />

            <Controller
              control={control}
              name={"address.number"}
              rules={{ required: !!zipWatcher }}
              render={({ field, fieldState: { error } }) => (
                <Input {...field} isInvalid={!!error} placeholder={"Número"} />
              )}
            />
          </HStack>

          <Controller
            control={control}
            name={"address.complement"}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                isInvalid={!!error}
                placeholder={"Complemento ( Opcional )"}
              />
            )}
          />

          <HStack spacing={"16px"} w={"100%"}>
            <Controller
              control={control}
              name={"address.city"}
              rules={{ required: !!zipWatcher }}
              render={({ field, fieldState: { error } }) => (
                <Input {...field} isInvalid={!!error} placeholder={"Cidade"} />
              )}
            />

            <Controller
              control={control}
              name={"address.state_province"}
              rules={{ required: !!zipWatcher }}
              render={({ field }) => (
                <Select
                  placeholder="Estado"
                  items={States}
                  value={{
                    value: field.value,
                    label:
                      States.find((e) => e.value === field.value)?.label || "",
                  }}
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
            />
          </HStack>
        </VStack>
      </VStack>

      <Center
        justifyContent={modalMode ? "center" : "flex-start"}
        marginTop={"32px"}
        w={"100%"}
        {...(modalMode && {
          borderTop: "1px solid",
          borderColor: "Gray.$300",
          padding: "16px 24px",
          marginTop: "0px",
        })}
      >
        <Button
          onClick={handleSubmit(async (e) =>
            handleUpdateCompany(
              omit(
                {
                  ...e,
                  ...(newImage && {
                    image_url: await handleUploadImage(newImage.file),
                  }),
                },
                [!!zipWatcher ? "" : "address"]
              )
            )
          )}
          borderRadius={"8px"}
          variant={"Primary"}
          isLoading={isSubmitting}
          width={modalMode ? "100%" : "fit-content"}
        >
          Salvar alterações
        </Button>
      </Center>
    </VStack>
  );
};

export default DetailTab;
