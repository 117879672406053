import {
  Center,
  HStack,
  Image,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { Props } from "../types";
import { BoardList } from "./BoardList";
import { BoardEmptyState } from "./BoardEmptyState";
import { createImageFromInitials, formatDate } from "src/common";
import Icon from "src/components/base/Icon";
import { motion } from "framer-motion";
import { BoardCreation } from "../../BoardCreation";
import { ProjectViewContext } from "../../Context";

const HomeTabProject: React.FC<Props> = ({ project }) => {
  const { setCurrentTabIn } = useContext(ProjectViewContext);
  const [showBoardCreation, setShowBoardCreation] = useState(false);
  const theme = useTheme();

  const info = [
    {
      icon: "store",
      label: "Empresas",
      defaultText: "Nenhuma empresa nesse projeto",
      data: project.companies,
      onClick: () => setCurrentTabIn(3),
    },
    {
      icon: "users-alt",
      label: "Consultores",
      defaultText: "Nenhum pessoa consultora nesse projeto",
      data: project.consultants,
      onClick: () => setCurrentTabIn(4),
    },
  ];

  return (
    <motion.div
      style={{
        width: "100%",
        maxWidth: "1318px",
        height: "100%",
      }}
      animate={{ scale: 1.0 }}
      initial={{ scale: 0.9 }}
    >
      <HStack w={"100%"} alignItems={"flex-start"} spacing={"36px"} pb={"24px"}>
        <VStack flex={1} alignItems={"flex-start"}>
          <HStack spacing={"24px"}>
            <Image
              src={
                project.image_url ||
                createImageFromInitials({
                  background: (theme.colors as any).Tertiary.pure,
                  color: (theme.colors as any).white,
                  name: project.name.substring(0, 2) || "",
                  size: 116,
                  fontSize: 64,
                })
              }
              alt={`Imagem do projeto: ${project.name}`}
              w={"116px"}
              h={"116px"}
              borderRadius={"50%"}
              objectFit={"cover"}
            />

            <Text
              fontSize={"RH_xl"}
              fontFamily={"Raleway"}
              color={"Gray.$800"}
              fontWeight={600}
            >
              {project.name}
            </Text>
          </HStack>

          <Text
            mt={"16px !important"}
            fontSize={"L_md"}
            color={"Gray.$700"}
            fontWeight={400}
          >
            {project.description}
          </Text>

          <VStack
            mt={"36px !important"}
            alignItems={"flex-start"}
            spacing={0}
            w={"100%"}
          >
            <Text fontSize={"RH_lg"} color={"Gray.$800"} fontWeight={500}>
              Empresas e consultores
            </Text>
            <Text
              mt={"4px !important"}
              fontSize={"L_md"}
              color={"Gray.$700"}
              fontWeight={400}
            >
              Gerencie as empresas e as consultores que fazem parte desse
              projeto
            </Text>

            <VStack
              mt={"16px !important"}
              w={"100%"}
              border={"1px solid"}
              borderRadius={"16px"}
              borderColor={"Gray.$300"}
              spacing={0}
            >
              {info.map((item, index) => (
                <HStack
                  key={index}
                  w={"100%"}
                  pl={"24px"}
                  spacing={"16px"}
                  cursor={"pointer"}
                  onClick={item.onClick}
                >
                  <Icon name={item.icon} />

                  <HStack
                    py={"18px"}
                    w={"100%"}
                    justifyContent={"space-between"}
                    borderBottom={"1px solid"}
                    borderColor={
                      info.length - 1 !== index ? "Gray.$300" : "transparent"
                    }
                  >
                    <Text
                      fontSize={"L_md"}
                      color={"Gray.$800"}
                      fontWeight={500}
                    >
                      {item.label}
                    </Text>

                    <HStack spacing={"16px"} mr={"16px !important"}>
                      <Text
                        fontSize={"L_md"}
                        color={"Gray.$700"}
                        fontWeight={400}
                      >
                        {item.data.length} {item.label.toLowerCase()}
                      </Text>

                      <Icon name={"angle-right"} size="20px" />
                    </HStack>
                  </HStack>
                </HStack>
              ))}
            </VStack>
          </VStack>

          <VStack
            mt={"36px !important"}
            alignItems={"flex-start"}
            spacing={0}
            w={"100%"}
          >
            <Text fontSize={"RH_lg"} color={"Gray.$800"} fontWeight={500}>
              Quadros de consultoria
            </Text>

            {project.boards.length >= 1 ? (
              <BoardList
                boards={project.boards}
                handleCreate={() => setShowBoardCreation(true)}
              />
            ) : (
              <BoardEmptyState
                handleCreate={() => setShowBoardCreation(true)}
              />
            )}
          </VStack>
        </VStack>

        <VStack
          border={"1px solid"}
          borderColor={"Gray.$300"}
          borderRadius={"12px"}
          pl={"20px"}
          minW={"345px"}
          alignItems={"flex-start"}
          spacing={0}
        >
          {project.clients.map(({ client }) => (
            <HStack spacing={"16px"} key={client.id} w={"100%"}>
              <Image
                w={"36px"}
                h={"36px"}
                borderRadius={"50%"}
                src={createImageFromInitials({
                  background: theme.colors.Tertiary.pure,
                  color: (theme.colors as any).white,
                  name: client.name.substring(0, 1) || "",
                  size: 36,
                  fontSize: 18,
                })}
              />

              <VStack
                flex={1}
                py={"16px"}
                borderBottom={"1px solid"}
                borderColor={"Gray.$300"}
                alignItems={"flex-start"}
                spacing={0}
              >
                <Text fontSize={"LMD"} fontWeight={500}>
                  {client.name}
                </Text>
                <Text fontSize={"LSM"} fontWeight={400} color={"Gray.$700"}>
                  Cliente
                </Text>
              </VStack>
            </HStack>
          ))}

          <HStack spacing={"16px"} w={"100%"}>
            <Center
              w={"36px"}
              h={"36px"}
              borderRadius={"50%"}
              background={"Gray.$300"}
            >
              {project.manager.image_url ? (
                <Image
                  src={project.manager.image_url}
                  borderRadius={"50%"}
                  objectFit={"cover"}
                  w={"100%"}
                  h={"100%"}
                />
              ) : (
                <Icon name="user" />
              )}
            </Center>

            <VStack
              py={"16px"}
              flex={1}
              borderBottom={"1px solid"}
              borderColor={"Gray.$300"}
              alignItems={"flex-start"}
              spacing={0}
            >
              <Text fontSize={"LMD"} fontWeight={500}>
                {project.manager.name}
              </Text>
              <Text fontSize={"LSM"} fontWeight={400} color={"Gray.$700"}>
                Gestor de carteira
              </Text>
            </VStack>
          </HStack>

          <HStack spacing={"16px"} w={"100%"}>
            <Center w={"36px"} h={"36px"} borderRadius={"50%"}>
              <Icon name="schedule" size="24px" />
            </Center>

            <VStack py={"16px"} flex={1} alignItems={"flex-start"} spacing={0}>
              <Text fontSize={"LMD"} fontWeight={500}>
                {formatDate(new Date(project.start_date || ""), "dd/MM/yyyy")}
                {project.end_date
                  ? ` - ${formatDate(
                      new Date(project.end_date || ""),
                      "dd/MM/yyyy"
                    )}`
                  : ""}
              </Text>
              <Text fontSize={"LSM"} fontWeight={400} color={"Gray.$700"}>
                Período previsto do projeto
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </HStack>

      <BoardCreation
        isOpen={showBoardCreation}
        onClose={() => setShowBoardCreation(false)}
      />
    </motion.div>
  );
};

export default HomeTabProject;
