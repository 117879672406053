import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    Brand: {
      background: "#EBF7FF",
      light: "#C2E6FF",
      pure: "#0077CC",
      dark: "#005FA3",
    },
    Gray: {
      $100: "#FFFFFF",
      $200: "#F5F5F5",
      $300: "#E0E0E0",
      $400: "#CCCCCC",
      $500: "#A3A3A3",
      $600: "#666666",
      $700: "#3D3D3D",
      $800: "#141414",
    },
    Red: {
      background: "#FDEDEF",
      light: "#F9C8D0",
      pure: "#DC1839",
      dark: "#B81430",
    },
    Yellow: {
      background: "#FFFAEB",
      light: "#FFF0C2",
      pure: "#FFC71F",
      dark: "#8F6B00",
    },
    Green: {
      background: "#ECFEF4",
      light: "#C5FBDF",
      pure: "#0CC060",
      dark: "#098643",
    },

    //obsolet
    Tertiary: {
      background: "#EFF6FA",
      light: "#C4DCED",
      pure: "#408EC5",
      dark: "#2F77A9",
    },
  },
  semanticTokens: {
    colors: {
      Background: {
        primary: "Gray.$100",
        secondary: "Gray.$200",
        tertiary: "Gray.$300",
        brand: "Brand.pure",
        brandDark: "Brand.dark",
        inversePrimary: "Gray.$800",
        inverseSecondary: "Gray.$700",
      },
      Content: {
        primary: "Gray.$800",
        secondary: "Gray.$700",
        tertiary: "Gray.$600",
        brand: "Brand.pure",
        inversePrimary: "Gray.$100",
        inverseSecondary: "Gray.$300",
        inverseTertiary: "Gray.$500",
      },
      Border: {
        primary: "Gray.$300",
        secondary: "Gray.$400",
        tertiary: "Gray.$500",
        selected: "Brand.pure",
        inversePrimary: "Gray.$700",
        inverseSelected: "Gray.$100",
      },

      //default
      error: "red.500",
      success: "green.500",
      primary: {
        default: "red.500",
        _dark: "red.400",
      },
    },
  },
  fonts: {
    body: `'Lato', sans-serif`,
    heading: `'Inter', sans-serif`,
    raleway: `'Raleway', sans-serif`,
    mono: "Lato, sans-serif",
  },
  fontSizes: {
    RD_lg: "96px",
    RD_md: "64px",
    RD_sm: "55px",
    RD_xs: "44px",
    RH_xxl: "40px",
    RH_xl: "36px",
    RH_lg: "32px",
    RH_md: "28px",
    RH_sm: "24px",
    RH_xs: "20px",
    L_lg: "18px",
    L_md: "16px",
    L_sm: "14px",
    L_xs: "12px",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: 32,
        minHeight: "56px",
        fontSize: "L_lg",
      },
      variants: {
        Primary: {
          color: "Gray.$100",
          backgroundColor: "Tertiary.pure",
          _hover: {
            backgroundColor: "Tertiary.dark",
          },

          _disabled: {
            backgroundColor: "Gray.$200",
            color: "Gray.$500",
          },
        },
        Secondary: {
          color: "Gray.$800",
          backgroundColor: "Gray.$300",
          _hover: {
            backgroundColor: "Gray.$400",
          },

          _disabled: {
            backgroundColor: "Gray.$200",
            color: "Gray.$500",
          },
        },
        Tertiary: {
          color: "Gray.$800",
          backgroundColor: "transparent",
          _hover: {
            backgroundColor: "Gray.$200",
          },

          _disabled: {
            backgroundColor: "Gray.transparent",
            color: "Gray.$500",
          },
        },
        _Primary: {
          color: "Tertiary.dark",
          backgroundColor: "transparent",
          borderWidth: "1px",
          borderColor: "Tertiary.dark",
          _hover: {
            backgroundColor: "Tertiary.background",
          },

          _disabled: {
            backgroundColor: "Gray.$200",
            color: "Gray.$500",
          },
        },
        _Secondary: {
          color: "Gray.$100",
          borderWidth: "1px",
          borderColor: "Gray.$100",
          backgroundColor: "rgba(255, 255, 255, 0.1)",

          _hover: {
            backgroundColor: "Tertiary.dark",
          },

          _disabled: {
            backgroundColor: "Gray.$200",
            color: "Gray.$500",
          },
        },
        _Tertiary: {
          color: "Gray.$100",
          backgroundColor: "transparent",
          _hover: {
            backgroundColor: "Tertiary.pure",
          },

          _disabled: {
            backgroundColor: "transparent",
            color: "Gray.$500",
          },
        },
      },
    },
  },
});

export const breakpoints = {
  desktop: 1440,
  notebook: 1280,
  tablet: 768,
  mobile: 320,
};
