import { Client } from "src/types";

const maskFieldByKey: {
  [value in keyof Partial<Client>]: (value?: any) => string;
} = {
  name: (value: Client["name"]) => value || "Não definido",
  cnpj: (value: Client["cnpj"]) => value || "Não definido",
  type: (value: Client["type"]) => value || "Não definido",
};

export default maskFieldByKey;
