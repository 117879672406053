import styled from "styled-components";

const StyledOutlineInput = styled.input`
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  border-bottom-color: #f0f3f4;
  border-bottom-width: 2px;
  outline: none;
`;

export default StyledOutlineInput;
