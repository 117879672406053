import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal } from "../../../../../components/base/Modal";
import * as XLSX from "xlsx";
import { Props } from "./types";
import Icon from "../../../../../components/base/Icon";
import { MutatingDots } from "react-loader-spinner";
import { doneSuperIcon } from "../../../../../assets/images";
import { saveAs } from "file-saver";

import { CosultingModel } from "../../../../../models";
import InviteService from "src/services/invite";
import { ProjectContext } from "src/contexts/ProjectContext";
import ProjectsService from "src/services/projects";
import UserService from "src/services/user";
import { Select } from "src/components/base/Select";

const Consultant: React.FC<Props> = ({
  project,
  handleClose,
  handleFinish,
}) => {
  const theme = useTheme();

  const [inviteByPlan, setInviteByPlan] = useState(false);
  const { selectProject } = useContext(ProjectContext);
  const [emailsAvailable, setEmailsAvailable] = useState({
    isLoading: true,
    isInvalid: false,
    data: [] as string[],
  });

  const defaultValues = {
    emails: [""],
  };

  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    watch,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues,
  });

  const handleCloseModal = () => {
    reset(defaultValues);
    handleClose();
  };

  const handleSubmitForm: SubmitHandler<typeof defaultValues> = async (
    values
  ) => {
    try {
      if (project.id) {
        const { consultants } = await InviteService.sendInvites({
          consultants: values.emails.map((email) => ({
            email,
            profile_name: "consulting",
          })),
          project_id: project.id,
        });

        await Promise.all(
          consultants.existents.map(
            async (consultant) =>
              await ProjectsService.createProjectConsultant({
                consultant_id: consultant.id,
                project_id: project.id || "",
              })
          )
        );

        if (consultants.existents.length >= 1)
          await selectProject(project.id, true);
      }
    } catch (err) {
      toast.success("Ocorreu uma falha ao enviar os convites.");
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      try {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = (evt: any) => {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const jsonObject: {
            ["Email do consultor"]: string;
          }[] = XLSX.utils.sheet_to_json(ws, {});
          reset({
            emails: [
              ...watch("emails").filter((value) => value !== ""),
              ...jsonObject.map((data) => data["Email do consultor"]),
            ],
          });
          setInviteByPlan(false);
        };
        reader.readAsBinaryString(file);
      } catch (err) {
        toast.error("Ocorreu um erro ao importar sua planilha.");
        setInviteByPlan(false);
      }
    },
    [reset, watch]
  );

  const handleDownloadExample = () => {
    saveAs(CosultingModel, "Template para criação de consultores");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  useEffect(() => {
    UserService.getAvailableEmailsToInvite()
      .then((data) => {
        setEmailsAvailable({ isLoading: false, isInvalid: false, data });
      })
      .catch(() => {
        setEmailsAvailable({ isLoading: false, isInvalid: true, data: [] });
      });
  }, []);

  return (
    <Modal
      onClose={handleCloseModal}
      isOpen={true}
      title={
        isSubmitSuccessful || isSubmitting ? "" : "Convidar pessoas consultoras"
      }
    >
      <VStack
        w={"100%"}
        maxW={"568px"}
        maxH={"460px"}
        overflow={"auto"}
        borderTop={"1px solid"}
        borderColor={"Gray.$400"}
        justifyContent={"center"}
        height={"100%"}
        minH={"300px"}
      >
        {emailsAvailable.isInvalid ? (
          <VStack my={"32px"} spacing={0}>
            <Text fontWeight={600} fontSize={"L_lg"} color={"Gray.$800"}>
              Oops, você não possui acesso a este recurso.
            </Text>
            <Text fontWeight={600} fontSize={"L_sm"} color={"Gray.$700"}>
              Contate um administrador para ajustar suas permissões
            </Text>

            <Button
              variant={"Primary"}
              onClick={handleClose}
              mt={"24px"}
              minW={"100px"}
              minH={"48px"}
            >
              Voltar
            </Button>
          </VStack>
        ) : (
          <>
            {isSubmitting || emailsAvailable.isLoading ? (
              <Center flex={1} minH={"390px"}>
                <MutatingDots
                  height="100"
                  width="100"
                  color="#408EC5"
                  secondaryColor="#408EC5"
                  radius="12.5"
                  ariaLabel="mutating-dots-loading"
                  visible={true}
                />
              </Center>
            ) : (
              <>
                {emailsAvailable.data.length >= 1 ? (
                  <>
                    {isSubmitSuccessful ? (
                      <VStack p={"24px"} w={"100%"} alignItems={"flex-start"}>
                        <Image src={doneSuperIcon} alt={"Ícone de sucesso"} />

                        <Text
                          mt={"24px !important"}
                          fontWeight={600}
                          fontSize={"RH_lg"}
                          fontFamily={"Raleway"}
                        >
                          Convites enviados!
                        </Text>
                        <Text
                          fontWeight={400}
                          fontSize={"L_md"}
                          color={"Gray.$700"}
                          mt={"4px !important"}
                        >
                          Os convites foram enviados para as pessoas consultoras
                          e as mesmas já foram adicinadas ao projeto{" "}
                          <b style={{ color: "black" }}>{project.name}</b>.
                        </Text>

                        <VStack
                          spacing={"16px"}
                          w={"100%"}
                          mt={"24px !important"}
                        >
                          {watch("emails").map((consultant, index) => (
                            <HStack
                              key={index}
                              p={"16px"}
                              w={"100%"}
                              background={"Gray.$200"}
                              borderRadius={"12px"}
                            >
                              <Icon name="user" size="22px" />

                              <VStack alignItems={"flex-start"}>
                                <Text
                                  fontWeight={400}
                                  ml={"16px !important"}
                                  fontSize={"L_sm"}
                                  color={"Gray.$700"}
                                >
                                  {consultant}
                                </Text>
                              </VStack>
                            </HStack>
                          ))}
                        </VStack>
                      </VStack>
                    ) : (
                      <VStack py={"24px"} px={"12px"} w={"100%"}>
                        <HStack
                          mt={"24px"}
                          w={"100%"}
                          px={"12px"}
                          mb={"24px !important"}
                        >
                          <HStack
                            pb={"24px"}
                            w={"100%"}
                            borderBottom={"1px solid"}
                            spacing={"12px !important"}
                            borderColor={"Gray.$400"}
                          >
                            <Center
                              w={"24px"}
                              h={"24px"}
                              borderRadius={"50%"}
                              background={"Gray.$300"}
                            >
                              {project.image_url ? (
                                <Image
                                  src={project.image_url}
                                  borderRadius={"50%"}
                                  objectFit={"cover"}
                                  w={"100%"}
                                  h={"100%"}
                                />
                              ) : (
                                <Icon name="image" />
                              )}
                            </Center>
                            <Text fontWeight={400} fontSize={"L_md"}>
                              {project.name}
                            </Text>
                          </HStack>
                        </HStack>
                        {inviteByPlan ? (
                          <VStack
                            w={"100%"}
                            alignItems={"flex-start"}
                            px={"12px"}
                          >
                            <Text fontSize={"L_lg"} fontWeight={600}>
                              Convidar através de planilha
                            </Text>
                            <Text
                              fontSize={"L_sm"}
                              fontWeight={400}
                              color={"Gray.$700"}
                            >
                              Faça o download do modelo da planilha e preencha
                              com os nomes das empresas e os e-mails dos
                              representantes.
                            </Text>
                            <HStack
                              my={"16px !important"}
                              cursor={"pointer"}
                              onClick={handleDownloadExample}
                            >
                              <Icon
                                name={"arrow-circle-down"}
                                color={"#2F77A9"}
                              />
                              <Text color={"Tertiary.dark"} fontWeight={600}>
                                Baixar modelo de planilha
                              </Text>
                            </HStack>
                            <Center
                              w={"100%"}
                              background={"Gray.$200"}
                              border={"1px solid"}
                              borderColor={"Gray.$400"}
                              borderStyle={"dashed"}
                              borderRadius={"12px"}
                              height={"158px"}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              {isDragActive ? (
                                <Text fontFamily={"lato"} fontSize={"l_md"}>
                                  Solte seu arquivo aqui!
                                </Text>
                              ) : (
                                <VStack>
                                  <Text fontFamily={"lato"} fontSize={"l_md"}>
                                    Solte aqui o arquivo da planilha
                                  </Text>
                                  <Button mt={"10px !important"}>
                                    Procurar arquivo
                                  </Button>
                                </VStack>
                              )}
                            </Center>
                          </VStack>
                        ) : (
                          <Controller
                            name={"emails"}
                            control={control}
                            render={({
                              field: { value, onChange },
                              fieldState,
                              formState,
                            }) => (
                              <VStack
                                w={"100%"}
                                alignItems={"flex-start"}
                                spacing={"16px !important"}
                              >
                                {value.map((data, index) => (
                                  <HStack key={index} w={"100%"}>
                                    <HStack w={"100%"} spacing={"16px"}>
                                      <Select
                                        items={emailsAvailable.data.map(
                                          (email) => ({
                                            value: email,
                                            label: email,
                                          })
                                        )}
                                        placeholder={"E-mail"}
                                        {...(data && {
                                          value: {
                                            label: data,
                                            value: data,
                                          },
                                        })}
                                        onChange={(e: { value: string }) => {
                                          clearErrors("emails");
                                          value[index] = e.value;
                                          onChange([...value]);
                                        }}
                                        isInvalid={
                                          index === value.length - 1 &&
                                          !!fieldState.error
                                        }
                                      />
                                    </HStack>

                                    {value.length >= 2 && (
                                      <Box
                                        cursor={"pointer"}
                                        ml={"18px !important"}
                                        onClick={() => {
                                          value.splice(index, 1);
                                          onChange([...value]);
                                        }}
                                      >
                                        <Icon name={"multiply"} size={"22px"} />
                                      </Box>
                                    )}
                                  </HStack>
                                ))}

                                <Button
                                  onClick={() => {
                                    if (value[value.length - 1] === "") {
                                      setError(
                                        "emails",
                                        new Error("Invalid last field")
                                      );
                                      return;
                                    }
                                    onChange([
                                      ...value,
                                      defaultValues.emails[0],
                                    ]);
                                  }}
                                >
                                  <HStack spacing={"8px !important"}>
                                    <Icon name={"plus"} />
                                    <Text>Adicionar mais</Text>
                                  </HStack>
                                </Button>
                              </VStack>
                            )}
                          />
                        )}
                      </VStack>
                    )}
                  </>
                ) : (
                  <VStack my={"32px"} spacing={0}>
                    <Text
                      fontWeight={600}
                      fontSize={"L_lg"}
                      color={"Gray.$800"}
                    >
                      Oops, não há nenhum email disponível para convite.
                    </Text>
                    <Text
                      fontWeight={600}
                      fontSize={"L_sm"}
                      color={"Gray.$700"}
                    >
                      Garanta que o novo usuário já está criado dentro do{" "}
                      <a
                        onClick={() => {
                          window.location.href =
                            process.env.REACT_APP_HUB_URL || "";
                        }}
                        href={process.env.HUB_URL}
                        style={{
                          textDecoration: "underline",
                          color: theme.colors.Brand.pure,
                          cursor: "pointer",
                        }}
                      >
                        Hub
                      </a>
                    </Text>

                    <Button
                      variant={"Primary"}
                      onClick={handleClose}
                      mt={"24px"}
                      minW={"100px"}
                      minH={"48px"}
                    >
                      Voltar
                    </Button>
                  </VStack>
                )}
              </>
            )}
          </>
        )}
      </VStack>

      {!isSubmitting && (
        <HStack
          w={"100%"}
          py={"16px"}
          px={"24px"}
          borderTop={"1px solid"}
          borderColor={"Gray.$400"}
          justifyContent={"space-between"}
        >
          {!isSubmitSuccessful ? (
            <>
              <Text
                fontWeight={600}
                color={"Tertiary.dark"}
                cursor={"pointer"}
                onClick={() => setInviteByPlan(!inviteByPlan)}
              >
                {inviteByPlan
                  ? "Preencher e-mails manualmente"
                  : "Enviar planilha com e-mails"}
              </Text>
              <Button
                variant={"Primary"}
                isDisabled={!!watch("emails").includes("")}
                onClick={handleSubmit(handleSubmitForm)}
              >
                Enviar convites
              </Button>
            </>
          ) : (
            <>
              <Text />
              <Button variant={"Primary"} onClick={handleFinish}>
                Concluído
              </Button>
            </>
          )}
        </HStack>
      )}
    </Modal>
  );
};

export default Consultant;
