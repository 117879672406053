import { Box } from "@chakra-ui/react";
import React from "react";
import { Controller, UseFormSetValue } from "react-hook-form";
import { DefaultProfileProps } from "../types";
import { Select } from "src/components/base/Select";
import { Client } from "src/types";
import { ClientTypes } from "src/common";

interface Props extends DefaultProfileProps {
  setValue: UseFormSetValue<Partial<Client>>;
}

const Type: React.FC<Props> = ({ control }) => {
  return (
    <Box w={"100%"} mt={"24px !important"}>
      <Controller
        control={control}
        name={"type"}
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Select
            items={ClientTypes}
            placeholder={"Tipo"}
            value={{
              label: value || "Valor não encontrado",
              value: value || "",
            }}
            isInvalid={!!error}
            onChange={(i) => onChange(i.value)}
          />
        )}
      />
    </Box>
  );
};

export default Type;
