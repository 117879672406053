import React, { useContext, useState, useCallback } from "react";
import {
  Checklist,
  Client,
  ClientData,
  Company,
  Project,
  ProjectFull,
  ProjectWithClients,
  User,
} from "../../types";
import ProjectsService from "../../services/projects";
import { Modal } from "../../components/base/Modal";
import { UserContext } from "../UserContext";
import { toast } from "react-toastify";
import { ProjectDialogs } from "./const";
import ClientService from "src/services/clients";
import ChecklistService from "src/services/checklist/checklist";

export type DefaultProjectType<T> = {
  project_id?: string;
  isLoading: boolean;
  addon?: string;
  data?: T;
};

export type ProjectProps = {
  projects: DefaultProjectType<ProjectWithClients[]>;
  refreshProjects: (resetSelected?: boolean) => Promise<void>;
  selectProject: (
    project_id: Project["id"],
    noReload?: boolean
  ) => Promise<void>;
  currentProject: DefaultProjectType<ProjectFull>;
  handleOutProject: () => void;
  handleUpdateProject: (
    newProject: Partial<ProjectFull> & { id: string },
    onlyState?: boolean
  ) => Promise<void>;
  showProjectDialog: (dialog: keyof typeof ProjectDialogs) => void;
  handleDeleteProject: (project_id: Project["id"]) => Promise<void>;
  handleCreateProject: (
    newProject: Project,
    checklists?: Checklist["id"][]
  ) => Promise<Project | undefined>;
  handleRemoveCompany: (company_id: Company["id"]) => Promise<void>;
  handleInsertClients: (
    clients: Client["id"][],
    project: ProjectWithClients
  ) => Promise<void>;
  handleRemoveClient: (
    clientDataId: ClientData["id"],
    project: ProjectWithClients
  ) => Promise<void>;
  handleRemoveConsultant: (consultant_id: User["id"]) => Promise<void>;
};

export const ProjectContext = React.createContext<ProjectProps>(
  {} as ProjectProps
);

interface Props {
  children: React.ReactNode;
}

const ProjectContextProvider = ({ children }: Props) => {
  const { user } = useContext(UserContext);
  const [dialog, setDialog] = useState<React.ReactNode>();
  const [currentProject, setCurrentProject] = useState<
    ProjectProps["currentProject"]
  >({ isLoading: true });
  const [projects, setProjects] = useState<ProjectProps["projects"]>({
    isLoading: true,
  });

  const selectProject = useCallback(
    async (project_id: Project["id"], noReload?: boolean) => {
      if (!noReload) {
        setCurrentProject({
          ...currentProject,
          project_id: project_id,
          isLoading: true,
        });
      }

      try {
        setCurrentProject({
          isLoading: false,
          project_id: project_id,
          data: await ProjectsService.getById(project_id),
        });
      } catch (err) {
        toast.error(`Ocorreu um erro ao buscar o projeto ${project_id}`);
      }
    },
    [currentProject]
  );

  const handleOutProject = () => {
    setCurrentProject({ isLoading: true });
  };

  const refreshProjects = useCallback(
    async (resetSelected?: boolean) => {
      if (user) {
        try {
          if (resetSelected) {
            setCurrentProject({ data: undefined, isLoading: true });
          }

          setProjects({
            isLoading: true,
            data: undefined,
          });

          setProjects({
            isLoading: false,
            data: (await ProjectsService.getProjects()).result,
          });
        } catch (err) {
          toast.error("Ocorreu um erro ao buscar projetos");
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const showProjectDialog = (dialog: keyof typeof ProjectDialogs) => {
    setDialog(
      ProjectDialogs[dialog]({
        project: currentProject.data as any,
        onClose: () => setDialog(undefined),
        dialog: dialog,
      })
    );
  };

  const handleRemoveCompany = async (id: Company["id"]): Promise<void> => {
    if (currentProject.data) {
      try {
        const projectCompany = currentProject.data.companies.find(
          ({ company_id }) => company_id === id
        );
        if (projectCompany) {
          await ProjectsService.deleteProjectCompany(projectCompany?.id);

          setCurrentProject({
            ...currentProject,
            data: {
              ...currentProject.data,
              companies: currentProject.data.companies.filter(
                (pCompany) => pCompany.id !== projectCompany.id
              ),
            },
          });

          toast.success("Empresa removida com sucesso!");
        }
      } catch (err) {
        toast.error("Ocorreu um erro ao remover a empresa.");
      }
    }
  };

  const handleRemoveConsultant = async (id: User["id"]): Promise<void> => {
    if (currentProject.data) {
      try {
        const projectConsultant = currentProject.data.consultants.find(
          ({ user_id }) => user_id === id
        );

        if (projectConsultant) {
          await ProjectsService.deleteProjectConsultant(projectConsultant.id);

          setCurrentProject({
            ...currentProject,
            data: {
              ...currentProject.data,
              consultants: currentProject.data.consultants.filter(
                (pConsultant) => pConsultant.id !== projectConsultant.id
              ),
            },
          });
          toast.success("Consultor removido com sucesso!");
        }
      } catch (err) {
        toast.error("Ocorreu um erro ao remover a empresa.");
      }
    }
  };

  const handleCreateProject = async (
    newProject: Project,
    checklists?: Checklist["id"][]
  ): Promise<Project | undefined> => {
    try {
      const project = await ProjectsService.createProject(newProject);

      if (checklists) {
        await Promise.all(
          checklists.map(
            async (checklist) =>
              await ChecklistService.duplicate(checklist, {
                project_id: project?.id,
              })
          )
        );
      }

      return project;
    } catch (err) {
      toast.error("Ocorreu um erro ao criar o projeto.");
    }
  };

  const handleUpdateProject = async (
    newProject: Partial<ProjectFull> & { id: string },
    onlyState?: boolean
  ): Promise<void> => {
    console.log(projects);
    try {
      if (!onlyState) {
        await ProjectsService.update(newProject.id, newProject);
      }

      if (projects && projects.data) {
        const index = projects.data.findIndex(
          (project) => project.id === newProject.id
        );
        projects.data[index] = { ...projects.data[index], ...newProject };
        setProjects(JSON.parse(JSON.stringify(projects)));
      }

      if (currentProject.data && currentProject.data.id === newProject.id) {
        setCurrentProject({
          ...currentProject,
          data: { ...currentProject.data, ...newProject } as ProjectFull,
        });
      }
      toast.success("Projeto atualizado com sucesso!");
    } catch (err) {
      toast.error("Ocorreu um erro ao criar o projeto.");
    }
  };

  const handleDeleteProject = async (id: Project["id"]) => {
    setProjects({ ...projects, isLoading: true });
    try {
      await ProjectsService.delete(id);
      setCurrentProject({ isLoading: true });
      toast.success("Projeto removido com sucesso!");
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o projeto.");
    }
  };

  const handleInsertClients = async (
    client_ids: Client["id"][],
    project: ProjectWithClients
  ) => {
    try {
      const clients = await Promise.all(
        client_ids.map(
          async (client_id) =>
            await ClientService.insertInProject({
              client_id,
              project_id: project.id,
            })
        )
      );

      const projectUpdated = {
        ...project,
        clients: [...project.clients, ...clients],
      } as ProjectFull;

      handleUpdateStates(projectUpdated);
      toast.success("Clientes adicionados com sucesso!");
    } catch (err) {
      console.log(err);
      toast.error("Ocorreu um erro ao inserir os clientes.");
    }
  };

  const handleRemoveClient = async (
    clientDataId: ClientData["id"],
    project: ProjectWithClients
  ) => {
    try {
      const projectUpdated = {
        ...project,
        clients: project.clients.filter((client) => client.id !== clientDataId),
      } as ProjectFull;

      handleUpdateStates(projectUpdated);

      await ClientService.removeFromProject(clientDataId);

      toast.success("Cliente removido com sucesso!");
    } catch (err) {
      console.log(err);
      toast.error("Ocorreu um erro ao remover o cliente.");
    }
  };

  const handleUpdateStates = (project: ProjectFull) => {
    if (currentProject.project_id === project.id) {
      setCurrentProject({
        ...currentProject,
        data: { ...currentProject.data, ...project },
      });
    }

    if (projects.data) {
      const index = projects.data.findIndex(({ id }) => id === project.id);
      const data = projects.data;
      data[index] = project;

      setProjects({ ...projects, data: data });
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,
        refreshProjects,
        handleOutProject,
        handleInsertClients,
        handleRemoveClient,
        selectProject,
        currentProject,
        showProjectDialog,
        handleDeleteProject,
        handleCreateProject,
        handleUpdateProject,
        handleRemoveCompany,
        handleRemoveConsultant,
      }}
    >
      <>
        {children}
        <Modal isOpen={!!dialog} onClose={() => setDialog(undefined)}>
          {dialog}
        </Modal>
      </>
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  const context = useContext(ProjectContext);

  if (context) return context;

  throw new Error(
    "useProjectContext must be used within a ProjectContextProvider."
  );
};

export default ProjectContextProvider;
