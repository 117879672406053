import { Box, Center, HStack, Text, VStack } from "@chakra-ui/react";
import { Props } from "./types";
import React from "react";
import { motion } from "framer-motion";
import Icon from "../Icon";

export const Modal: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  title,
  subTitle,
}) => {
  return isOpen ? (
    <Center
      background={"rgba(0, 0, 0, 0.3)"}
      top={0}
      bottom={0}
      left={0}
      right={0}
      zIndex={999}
      position={"fixed"}
      margin={"0px !important"}
    >
      <motion.div
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: "568px",
          background: "white",
          borderRadius: "12px",
          maxHeight: "92vh",
          overflow: "auto",
          boxShadow:
            "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px",
        }}
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
      >
        {(title || onClose) && (
          <HStack
            p={subTitle ? "10px" : !title ? "36px" : "24px"}
            w={"100%"}
            position={"relative"}
          >
            {title && subTitle ? (
              <Center flex={1}>
                <VStack spacing={1}>
                  <Text fontSize={"LMD"} fontWeight={600}>
                    {title}
                  </Text>

                  <Text fontSize={"LSM"} fontWeight={400} color={"Gray.$700"}>
                    {subTitle}
                  </Text>
                </VStack>
              </Center>
            ) : (
              <Text fontSize={"RH_xs"} fontFamily={"raleway"} fontWeight={600}>
                {title}
              </Text>
            )}

            {onClose && (
              <Box
                cursor={"pointer"}
                onClick={onClose}
                position={"absolute"}
                right={"24px"}
              >
                <Icon name={"multiply"} size={"24px"} />
              </Box>
            )}
          </HStack>
        )}

        {children}
      </motion.div>
    </Center>
  ) : (
    <></>
  );
};
