import { Box, HStack, Image, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Icon from "../../../components/base/Icon";
import { Props } from "./types";
import { AnimatePresence } from "framer-motion";

export const Column: React.FC<Props> = ({
  onPutOutside,
  onDragOver,
  isHidded,
  onHide,
  column,
  onDrop,
  tasks,
}) => {
  return (
    <VStack
      width={"272px"}
      minW={isHidded ? "88px" : "272px"}
      maxW={"88px"}
      overflow={"hidden"}
      transition={"300ms all"}
      position={"relative"}
      h={"100%"}
      onDragOver={onDragOver}
      onDrop={onDrop}
      spacing={0}
    >
      <HStack
        p={"12px"}
        w={"100%"}
        position={"sticky"}
        top={0}
        zIndex={10}
        background={"Gray.$200"}
        borderRadius={"8px 8px 0px 0px"}
        alignItems={"center"}
        borderBottom={"1px solid"}
        borderColor={"Gray.$300"}
      >
        <HStack h={"100%"} flex={1}>
          <Image
            src={column.icon}
            width={"32px"}
            h={"32px"}
            borderRadius={"50%"}
            alt={"Consultant icon"}
          />
          {!isHidded && (
            <Text
              fontFamily={"lato"}
              fontSize={"L_sm"}
              flexWrap={"nowrap"}
              whiteSpace={"nowrap"}
            >
              {column.name}
            </Text>
          )}
        </HStack>

        <Box
          cursor={"pointer"}
          onClick={() => onHide()}
          transition={"300ms all"}
          transform={`rotate(${isHidded ? "0.5" : "1"}turn)`}
        >
          <Icon name={"angle-double-left"} />
        </Box>
      </HStack>

      {tasks && (
        <VStack
          w={"100%"}
          h={"100%"}
          background={"Gray.$200"}
          borderRadius={"0px 0px 8px 8px"}
          px={"8px"}
          pb={"8px"}
          spacing={0}
          overflow={"auto"}
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <AnimatePresence>{tasks.map((Element) => Element)}</AnimatePresence>

          <Stack
            flex={1}
            margin={"0px !important"}
            height={"100%"}
            width={"100%"}
            onDragEnter={onPutOutside}
          />
        </VStack>
      )}
    </VStack>
  );
};
