import React from "react";
import { Props } from "./types";
import { HStack, Text, VStack } from "@chakra-ui/react";
import Icon from "src/components/base/Icon";
import { motion } from "framer-motion";

const AreaList: React.FC<Props> = ({ areas, handleClickArea }) => {
  return (
    <VStack w={"100%"}>
      {areas.map((area) => (
        <motion.div
          key={area.id}
          onClick={() => handleClickArea(area)}
          whileHover={{
            scale: 1.02,
          }}
          whileTap={{
            scale: 0.95,
          }}
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          style={{ width: "100%" }}
        >
          <HStack
            p={"16px"}
            w={"100%"}
            border={"1px solid"}
            borderRadius={"12px"}
            borderColor={"Gray.$400"}
          >
            <VStack flex={1} alignItems={"flex-start"} cursor={"pointer"}>
              <Text fontWeight={600} fontSize={"L_md"} color={"Gray.$800"}>
                {area.name}
              </Text>
              <Text fontWeight={400} fontSize={"L_sm"} color={"Gray.$700"}>
                {area.projects.length} projetos
              </Text>
            </VStack>

            <Icon name={"angle-right"} size={"20px"} />
          </HStack>
        </motion.div>
      ))}
    </VStack>
  );
};

export default AreaList;
