import { motion } from "framer-motion";
import { Props } from "./types";
import React, { useRef, useState } from "react";
import {
  Box,
  HStack,
  Stack,
  Text,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import Icon from "src/components/base/Icon";
import { AddButton } from "src/components/AddButton";
import AreaDetailsEmptyState from "./EmptyState";
import ProjectList from "./List";
import { useNavigate } from "react-router-dom";
import { AreaWithProjects, Project } from "src/types";
import { Modal } from "src/components/base/Modal";
import AddInProject from "./AddInProject";
import { SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import ProjectsService from "src/services/projects";

const AreaDetails: React.FC<Props> = ({
  area,
  handleBack,
  handleDeleteArea,
  handleUpdateArea,
  handleAddProjects,
}) => {
  const [addProjectIsOpen, setAddProjectIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const menuRef = useRef<any>(null);
  const navigate = useNavigate();

  const handleAddProject: SubmitHandler<{
    projects: Project["id"][];
  }> = async ({ projects }) => {
    try {
      const newProjects = await Promise.all(
        projects.map(async (project) =>
          ProjectsService.update(project, { area_id: area.id })
        )
      );

      handleAddProjects({
        ...area,
        projects: [...area.projects, ...newProjects],
      });
      setAddProjectIsOpen(false);
    } catch (err) {
      toast.error("Ocorreu um erro ao adicionar aos projetos.");
    }
  };

  useOutsideClick({
    ref: menuRef,
    handler: () => setMenuIsOpen(false),
  });

  const menuOptions = [
    {
      title: "Editar área",
      color: "black",
      onClick: handleUpdateArea,
    },
    {
      title: "Excluir área",
      color: "#CC0000",
      onClick: (area: AreaWithProjects) => handleDeleteArea(area.id),
    },
  ];

  return (
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <HStack pb={"16px"} w={"100%"} justifyContent={"space-between"}>
        <HStack spacing={"18px"} onClick={handleBack} cursor={"pointer"}>
          <Icon name={"arrow-left"} size={"22px"} />
          <Text
            fontWeight={600}
            fontFamily={"Raleway"}
            fontSize={"RH_sm"}
            color={"Gray.$800"}
          >
            {area.name}
          </Text>
        </HStack>

        <Box position={"relative"}>
          <Box cursor={"pointer"} onClick={() => setMenuIsOpen(true)}>
            <Icon name={"ellipsis-h"} size={"20px"} />
          </Box>

          {menuIsOpen && (
            <VStack
              py={"8px"}
              ref={menuRef}
              right={0}
              top={"30px"}
              position={"absolute"}
              zIndex={200}
              background={"Gray.$100"}
              minW={"240px"}
              borderRadius={"12px"}
              boxShadow={"0px 2px 16px rgba(0, 0, 0, 0.12)"}
            >
              {menuOptions.map((option) => (
                <Stack
                  cursor={"pointer"}
                  key={option.title}
                  padding={"12px 16px"}
                  onClick={() => {
                    option.onClick(area);
                    setMenuIsOpen(false);
                  }}
                  w={"100%"}
                  alignItems={"flex-start"}
                  transition={"300ms all"}
                  _hover={{
                    background: "Gray.$200",
                  }}
                >
                  <Text fontSize={"L_sm"} fontWeight={400} color={option.color}>
                    {option.title}
                  </Text>
                </Stack>
              ))}
            </VStack>
          )}
        </Box>
      </HStack>

      <HStack w={"100%"} justifyContent={"space-between"} pb={"16px"}>
        <Text fontWeight={600} fontSize={"L_lg"} color={"Gray.$800"}>
          Projetos dentro dessa área
        </Text>

        <AddButton
          label={"Adicionar projeto"}
          onClick={() => setAddProjectIsOpen(true)}
        />
      </HStack>

      <VStack w={"100%"}>
        {area.projects.length >= 1 ? (
          <ProjectList
            handleClickProject={({ id }) => navigate(`/${id}`)}
            projects={area.projects}
          />
        ) : (
          <AreaDetailsEmptyState handleAdd={() => setAddProjectIsOpen(true)} />
        )}
      </VStack>

      <Modal
        isOpen={addProjectIsOpen}
        title={"Selecione os projetos"}
        onClose={() => setAddProjectIsOpen(false)}
      >
        <AddInProject
          defaultProjects={area.projects.reduce(
            (acc, item) => [...acc, item.id],
            [] as string[]
          )}
          handleSubmitProjects={handleAddProject}
        />
      </Modal>
    </motion.div>
  );
};

export default AreaDetails;
