import { Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Props } from "./types";
import { AddButton } from "src/components/AddButton";
import Icon from "src/components/base/Icon";

const DocumentsEmptyState: React.FC<Props> = ({ handleAdd }) => {
  return (
    <VStack spacing={0}>
      <Icon name="file-blank" size="40px" />
      <Text mt={"12px"} textAlign={"center"} fontWeight={600} fontSize={"L_md"}>
        Não existem documentos aqui ainda
      </Text>
      <Text
        mb={"16px"}
        textAlign={"center"}
        fontWeight={400}
        color={"Gray.$700"}
        fontSize={"L_sm"}
      >
        Carregue documentos relacionados à consultoria, como prints e gravações
      </Text>

      <AddButton
        label="Novo documento"
        onClick={handleAdd}
        variant="Secondary"
      />
    </VStack>
  );
};

export default DocumentsEmptyState;
