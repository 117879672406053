import styled from "styled-components";

export const StyledDropdown = styled.div<{ isOpen: boolean }>`
  top: 70px;
  left: 0;
  width: 100%;
  display: flex;
  z-index: 998;
  overflow-y: auto;
  position: absolute;
  border-radius: 12px;
  transition: all 300ms;
  flex-direction: column;
  background: white;
  max-height: ${(props) => (props.isOpen ? 180 : 0)}px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px -2px rgba(0, 0, 0, 0.1),
    0 1px 5px 0 rgba(0, 0, 0, 0.1);
  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }
`;

export const StyledItem = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  flex-direction: row;
  width: 100%;
  display: flex;
  padding: 16px;
  transition: all 300ms;

  &:hover {
    background-color: #eee;
  }
`;
