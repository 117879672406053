import { HStack, Text, VStack } from "@chakra-ui/react";
import React, { useState, useCallback, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { Props } from "./types";
import FieldWrapper from "./FieldWrapper";
import * as Forms from "./Forms";
import { defaultFieldsData, getFieldsData } from "./consts";
import { Client, ClientWithProject } from "src/types";
import { toast } from "react-toastify";
import { ClientContext } from "src/contexts/ClientContext";
import { fadeInUp } from "src/animations";
import Icon from "src/components/base/Icon";

const ClientUpdate: React.FC<Props> = ({ handleBack, client }) => {
  const { handleUpdateClient } = useContext(ClientContext);
  const [clientUpdated, setClientUpdated] = useState(client);
  const [fieldsData, setFieldsData] = useState(defaultFieldsData);

  const handlePopulateFields = useCallback(async () => {
    setFieldsData(await getFieldsData());
  }, []);

  useEffect(() => {
    handlePopulateFields();
  }, [handlePopulateFields]);

  const handleUpdateField = async (fieldsUpdated: {
    [key in keyof Partial<Client>]: any;
  }) => {
    try {
      const newClient = {
        ...clientUpdated,
        ...fieldsUpdated,
      };

      setClientUpdated(newClient);

      await handleUpdateClient(newClient as ClientWithProject);
    } catch (err) {
      console.log(err);
      toast.error("Ocorreu um erro ao atualizar este campo.");
    }
  };

  const clientFields: Array<
    Omit<
      React.ComponentProps<typeof FieldWrapper>,
      "handleUpdate" | "isLoading" | "fieldData"
    >
  > = [
    {
      label: "Nome",
      value: { name: clientUpdated.name },
      Form: (props) => <Forms.Name {...props} />,
    },
    {
      label: "CNPJ",
      value: { cnpj: clientUpdated.cnpj },
      Form: (props) => <Forms.Cnpj {...props} />,
    },
    {
      label: "Tipo",
      value: { type: clientUpdated.type },
      Form: (props) => <Forms.Type {...props} />,
    },
  ];

  return (
    <VStack flex={1} alignItems={"flex-start"} p={'32px 80px'}>
      <HStack spacing={"18px"} cursor={"pointer"} onClick={handleBack}>
        <Icon name={"arrow-left"} size={"32px"} />
        <Text fontSize={"RH_sm"} fontWeight={600} fontFamily={"Raleway"}>
          Detalhes do cliente
        </Text>
      </HStack>
      <motion.div {...fadeInUp} style={{ width: "100%" }}>
        <VStack
          border={"1px solid"}
          borderColor={"Gray.$400"}
          mt={"32px !important"}
          borderRadius={"16px"}
          maxW={"558px"}
          px={"16px"}
          w={"100%"}
        >
          {clientFields.map((field, index) => (
            <motion.div
              key={index}
              initial={{ height: 0 }}
              animate={{ height: "inherit" }}
              style={{
                width: "100%",
                borderBottom:
                  clientFields.length - 1 === index
                    ? "transparent"
                    : "1px solid #E4E6E8",
              }}
            >
              <FieldWrapper
                {...field}
                fieldData={
                  fieldsData[
                    Object.keys(field.value)[0] as keyof typeof fieldsData
                  ]
                }
                handleUpdate={handleUpdateField}
              />
            </motion.div>
          ))}
        </VStack>
      </motion.div>
    </VStack>
  );
};

export default ClientUpdate;
