import { PutObjectCommand } from "@aws-sdk/client-s3";
import { s3Client } from "./connection";
import { toast } from "react-toastify";

export const uploadFileToBucket = async (
  file: File,
  { folderName, id }: { folderName: string; id: string }
): Promise<string> => {
  const bucketParams = {
    Bucket: process.env.REACT_APP_SPACES_NAME,
    Key: `rama/${folderName}/${id}/${makeHash(4)}.${file.type.split("/")[1]}`,
    Body: file,
    ACL: "public-read",
  };

  try {
    await s3Client.send(new PutObjectCommand(bucketParams as any));
    return `https://${bucketParams.Bucket}.sfo3.digitaloceanspaces.com/${bucketParams.Key}`;
  } catch (err) {
    toast.error("Ocorreu um erro ao fazer upload.");
    console.log("Error", err);
  }

  return "";
};

function makeHash(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
