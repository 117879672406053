import {
  Box,
  Center,
  HStack,
  Image,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Accordion, Button, DrawerComponent, Icon, Loading } from "semente-js";
import { createImageFromInitials, formatDate } from "src/common";
import { consultancyFields } from "../../common";
import { Props } from "./types";
import { Consultancy } from "src/types";
import ConsultancyService from "src/services/consultancy";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { TaskStatus } from "src/pages/Kanban/common";

const ConsultancyUpdates: React.FC<Props> = ({
  task: { column_index, id },
  lastUpdate,
}) => {
  const [consultancyExpanded, setConsultancyExpanded] = useState<string[]>([]);
  const [notesExpanded, setNotesExpanded] = useState<string[]>([]);
  const [consultancy, setConsultancy] = useState({
    data: {} as Consultancy | undefined,
    isLoading: true,
  });
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const MessageKeys = Object.keys(consultancyFields);

  useEffect(() => {
    if (isOpen) {
      ConsultancyService.getById(id, column_index).then((data) =>
        setConsultancy({
          isLoading: false,
          data,
        })
      );
      return;
    }

    setConsultancy({
      data: undefined,
      isLoading: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {lastUpdate && (
        <HStack w={"full"} justifyContent={"space-between"} mt={"4px"}>
          <Text fontWeight={400} fontSize={"L_lg"} color={"Gray.$700"}>
            {lastUpdate
              ? formatDate(
                  new Date(lastUpdate),
                  `'Última edição em' dd/MM/yyyy 'às' HH:mm'h'`
                )
              : "Primeira atualização"}
          </Text>

          <Button
            label="Ver histórico de edições"
            onClick={() => setIsOpen(true)}
            iconName="history"
            layout="rounded"
            color="gray"
          />
        </HStack>
      )}

      <DrawerComponent
        onClose={() => setIsOpen(false)}
        id="updates-drawer"
        isOpen={isOpen}
      >
        <VStack w={"700px"} h={"100%"}>
          {consultancy.isLoading ? (
            <Center w={"100%"} h={"100%"}>
              <Loading />
            </Center>
          ) : (
            <>
              {consultancy.data ? (
                <>
                  <Center
                    borderBottom={"1px solid"}
                    borderColor={"Gray.$300"}
                    py={"26px"}
                    w={"100%"}
                  >
                    <Text fontSize={"L_md"} fontWeight={500}>
                      Histórico de Edições
                    </Text>
                  </Center>
                  <VStack p={"24px"} w={"100%"} overflow={"auto"} flex={1}>
                    {consultancy?.data.updates.map(
                      ({ body, createdAt, id, updatedBy }, index) => {
                        const consultancy = (
                          typeof body === "string" ? JSON.parse(body) : body
                        ) as Partial<Consultancy>;

                        return (
                          <VStack w={"100%"} pt={"16px"}>
                            <Accordion
                              id={`consultancy-${index}-update`}
                              isInitiallyOpen={false}
                              className="w-full"
                              accordionTitle={
                                <HStack
                                  key={index}
                                  w={"100%"}
                                  alignItems={"flex-start"}
                                  spacing={"16px"}
                                >
                                  <Image
                                    src={createImageFromInitials({
                                      background: (theme.colors as any).Tertiary
                                        .pure,
                                      color: (theme.colors as any).white,
                                      name:
                                        updatedBy?.name.substring(0, 2) || "",
                                      fontSize: 18,
                                      size: 36,
                                    })}
                                    w={"36px"}
                                    h={"36px"}
                                    objectFit={"cover"}
                                    borderRadius={"50%"}
                                    background={"Gray.$300"}
                                    marginTop={index === 0 ? "12px" : "6px"}
                                  />

                                  <HStack
                                    w={"100%"}
                                    justifyContent={"space-between"}
                                  >
                                    <VStack
                                      spacing={"0"}
                                      alignItems={"flex-start"}
                                    >
                                      <Text fontSize={"L_md"} fontWeight={600}>
                                        {formatDate(
                                          new Date(createdAt),
                                          "dd 'de' MMMM 'de' yyyy 'às' HH:mm'h'"
                                        )}
                                      </Text>
                                      {index === 0 && (
                                        <HStack spacing={"8px"}>
                                          <Box
                                            width={"8px"}
                                            height={"8px"}
                                            borderRadius={"50%"}
                                            background={"Brand.pure"}
                                          />
                                          <Text
                                            fontSize={"L_sm"}
                                            fontWeight={400}
                                          >
                                            Versão atual
                                          </Text>
                                        </HStack>
                                      )}

                                      <Text fontSize={"L_sm"} fontWeight={400}>
                                        Editado por {updatedBy?.name}{" "}
                                        {updatedBy?.surname}
                                      </Text>
                                    </VStack>
                                  </HStack>
                                </HStack>
                              }
                            >
                              <VStack
                                w={"100%"}
                                mt={"16px"}
                                spacing={"16px"}
                                alignItems={"flex-start"}
                                pl={"54px"}
                              >
                                {(consultancy.start_date ||
                                  consultancy.end_date ||
                                  consultancy.status) && (
                                  <VStack
                                    w={"100%"}
                                    spacing={0}
                                    alignItems={"flex-start"}
                                  >
                                    <HStack
                                      w={"100%"}
                                      alignItems={"flex-start"}
                                      spacing={"16px"}
                                    >
                                      <Box marginTop={"12px"}>
                                        <Icon
                                          name="calendar-solid"
                                          size="24px"
                                        />
                                      </Box>

                                      <HStack
                                        w={"100%"}
                                        justify={"space-between"}
                                      >
                                        <VStack
                                          alignItems={"flex-start"}
                                          spacing={0}
                                        >
                                          <Text
                                            fontSize={"L_md"}
                                            fontWeight={600}
                                          >
                                            Informações da Consultoria
                                          </Text>
                                          <Text
                                            fontSize={"L_sm"}
                                            fontWeight={400}
                                            color={"Gray.$600"}
                                          >
                                            Data do agendamento e status da
                                            consultoria
                                          </Text>
                                        </VStack>

                                        <Button
                                          layout="rounded"
                                          color="gray"
                                          label={
                                            consultancyExpanded.includes(id)
                                              ? "Ocultar"
                                              : "Expandir"
                                          }
                                          onClick={() =>
                                            consultancyExpanded.includes(id)
                                              ? setConsultancyExpanded(
                                                  (previous) =>
                                                    previous.filter(
                                                      (note) => note !== id
                                                    )
                                                )
                                              : setConsultancyExpanded(
                                                  (previous) => [
                                                    ...previous,
                                                    id,
                                                  ]
                                                )
                                          }
                                        />
                                      </HStack>
                                    </HStack>

                                    <AnimatePresence>
                                      {consultancyExpanded.includes(id) && (
                                        <motion.div
                                          initial={{ opacity: 0, scale: 0.8 }}
                                          animate={{ opacity: 1, scale: 1 }}
                                          exit={{ opacity: 0, scale: 0.8 }}
                                        >
                                          {(consultancy.start_date ||
                                            consultancy.end_date) && (
                                            <VStack
                                              pt={"16px"}
                                              alignItems={"flex-start"}
                                              spacing={0}
                                            >
                                              <Text
                                                fontSize={"L_md"}
                                                fontWeight={600}
                                              >
                                                Agendamento
                                              </Text>

                                              <Text
                                                fontSize={"L_sm"}
                                                fontWeight={400}
                                                color={"Gray.$600"}
                                              >
                                                {consultancy?.start_date &&
                                                  formatDate(
                                                    new Date(
                                                      consultancy?.start_date
                                                    ),
                                                    "cccc, dd 'de' MMMM 'de' yyyy 'das' HH:mm"
                                                  )}{" "}
                                                {consultancy?.end_date &&
                                                  formatDate(
                                                    new Date(
                                                      consultancy?.end_date
                                                    ),
                                                    "'às' HH:mm"
                                                  )}
                                              </Text>
                                            </VStack>
                                          )}

                                          {consultancy.status && (
                                            <VStack
                                              pt={"16px"}
                                              alignItems={"flex-start"}
                                              spacing={0}
                                            >
                                              <Text
                                                fontSize={"L_md"}
                                                fontWeight={600}
                                              >
                                                Status
                                              </Text>

                                              <Text
                                                fontSize={"L_sm"}
                                                fontWeight={400}
                                                color={"Gray.$600"}
                                              >
                                                {
                                                  TaskStatus[consultancy.status]
                                                    .label
                                                }
                                              </Text>
                                            </VStack>
                                          )}
                                        </motion.div>
                                      )}
                                    </AnimatePresence>
                                  </VStack>
                                )}

                                {Object.keys(consultancyFields).find(
                                  (key) =>
                                    (consultancy as any)[key] !== undefined
                                ) && (
                                  <VStack
                                    w={"100%"}
                                    spacing={0}
                                    alignItems={"flex-start"}
                                  >
                                    <HStack
                                      w={"100%"}
                                      alignItems={"flex-start"}
                                      spacing={"16px"}
                                    >
                                      <Box marginTop={"12px"}>
                                        <Icon name="pen-solid" size="24px" />
                                      </Box>

                                      <HStack
                                        w={"100%"}
                                        justify={"space-between"}
                                      >
                                        <VStack
                                          alignItems={"flex-start"}
                                          spacing={0}
                                        >
                                          <Text
                                            fontSize={"L_md"}
                                            fontWeight={600}
                                          >
                                            Notas
                                          </Text>
                                          <Text
                                            fontSize={"L_sm"}
                                            fontWeight={400}
                                            color={"Gray.$600"}
                                          >
                                            Diagnósticos, Desafios e Anotações
                                            internas
                                          </Text>
                                        </VStack>

                                        <Button
                                          layout="rounded"
                                          color="gray"
                                          label={
                                            notesExpanded.includes(id)
                                              ? "Ocultar"
                                              : "Expandir"
                                          }
                                          onClick={() =>
                                            notesExpanded.includes(id)
                                              ? setNotesExpanded((previous) =>
                                                  previous.filter(
                                                    (note) => note !== id
                                                  )
                                                )
                                              : setNotesExpanded((previous) => [
                                                  ...previous,
                                                  id,
                                                ])
                                          }
                                        />
                                      </HStack>
                                    </HStack>

                                    <AnimatePresence>
                                      {notesExpanded.includes(id) && (
                                        <motion.div
                                          initial={{ opacity: 0, scale: 0.8 }}
                                          animate={{ opacity: 1, scale: 1 }}
                                          exit={{ opacity: 0, scale: 0.8 }}
                                        >
                                          {MessageKeys.map((key) =>
                                            consultancy[
                                              key as keyof typeof consultancy
                                            ] !== undefined ? (
                                              <VStack
                                                key={key}
                                                pt={"16px"}
                                                alignItems={"flex-start"}
                                                spacing={0}
                                              >
                                                <Text
                                                  fontSize={"L_md"}
                                                  fontWeight={600}
                                                >
                                                  {
                                                    consultancyFields[
                                                      key as keyof typeof consultancyFields
                                                    ].label
                                                  }
                                                </Text>

                                                <Text
                                                  fontSize={"L_sm"}
                                                  fontWeight={400}
                                                  color={"Gray.$600"}
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      (consultancy[
                                                        key as keyof typeof consultancy
                                                      ] as string) ||
                                                      "Informação excluída",
                                                  }}
                                                />
                                              </VStack>
                                            ) : (
                                              <></>
                                            )
                                          )}
                                        </motion.div>
                                      )}
                                    </AnimatePresence>
                                  </VStack>
                                )}
                              </VStack>
                            </Accordion>
                          </VStack>
                        );
                      }
                    )}
                  </VStack>
                  <Center
                    borderTop={"1px solid"}
                    borderTopColor={"Gray.$300"}
                    py={"16px"}
                    px={"24px"}
                    w={"100%"}
                  >
                    <Button
                      className="w-full"
                      label="Fechar"
                      onClick={() => setIsOpen(false)}
                    />
                  </Center>
                </>
              ) : (
                <Center w={"full"} h={"full"}>
                  <VStack spacing={0}>
                    <Text
                      fontSize={"24px"}
                      fontWeight={600}
                      fontFamily={"Raleway"}
                    >
                      Nada por aqui ainda
                    </Text>
                    <Text
                      fontSize={"LMD"}
                      fontWeight={400}
                      marginBottom={"24px"}
                    >
                      Tente definir notas ou horários da consultoria
                    </Text>

                    <Button
                      className="w-full "
                      label="Voltar a consultoria"
                      onClick={() => setIsOpen(false)}
                    />
                  </VStack>
                </Center>
              )}
            </>
          )}
        </VStack>
      </DrawerComponent>
    </>
  );
};

export default ConsultancyUpdates;
