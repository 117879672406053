import _axios from "../../..";
import { Company, MarketSector } from "../../../../types";

const MarketSectorService = {
  getAll: async (): Promise<MarketSector[]> =>
    (await _axios.get("market-sector")).data,

  create: async (body: string[], id: Company["id"]): Promise<void> =>
    (
      await _axios.patch(`company`, {
        id,
        market_sector_id: body[0],
      } as Partial<Company>)
    ).data,
};

export default MarketSectorService;
