import _axios from "src/services";
import {
  Checklist,
  ChecklistQuestion,
  ChecklistQuestionWithOptions,
} from "src/types";

export const ChecklistQuestionService = {
  create: async (
    newChecklistQuestion: Omit<ChecklistQuestion, "id">
  ): Promise<ChecklistQuestionWithOptions> =>
    (await _axios.post(`/checklist/question`, newChecklistQuestion)).data,

  update: async (
    id: Checklist["id"],
    newChecklistQuestion: Partial<ChecklistQuestion>
  ): Promise<ChecklistQuestionWithOptions> =>
    (await _axios.patch(`/checklist/question/${id}`, newChecklistQuestion))
      .data,

  delete: async (id: ChecklistQuestion["id"]): Promise<void> =>
    (await _axios.delete(`/checklist/question/${id}`)).data,
};

export default ChecklistQuestionService;
