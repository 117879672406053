import React from "react";
import { Props } from "./types";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { Icon } from "semente-js";
import { formatDate } from "src/common";

const PathsViewer: React.FC<Props> = ({
  handleBack,
  paths,
  handleSelectPath,
}) => {
  return (
    <VStack w={"full"} alignItems={"flex-start"} flex={1} pb={"40px"}>
      <VStack
        w={"full"}
        pb={"40px"}
        mb={"40px"}
        borderBottom={"1px solid"}
        borderColor={"Gray.$400"}
        alignItems={"flex-start"}
      >
        <HStack cursor={"pointer"} onClick={handleBack}>
          <Icon name="arrow-left-alt" color="#141414" size="18px" />
          <Text fontSize={"L_md"} fontWeight={600}>
            Voltar
          </Text>
        </HStack>

        <HStack w={"full"} justify={"between"}>
          <Text
            flex={1}
            fontWeight={600}
            fontSize={"RH_xl"}
            fontFamily={"Raleway"}
          >
            Editar dados
          </Text>
        </HStack>
      </VStack>

      {paths.map((path) => (
        <button
          key={path.id}
          onClick={() => handleSelectPath(path)}
          style={{ width: "100%" }}
        >
          <HStack
            border={"1px solid"}
            borderColor={"Gray.$400"}
            p={"16px 20px"}
            borderRadius={"12px"}
            w={"full"}
            justifyContent={"space-between"}
          >
            <HStack>
              <Icon name="calendar-solid" size="18px" />
              <VStack spacing={0} alignItems={"flex-start"}>
                <Text flex={1} fontWeight={600} fontSize={"L_md"}>
                  {formatDate(new Date(path.date), "dd/MM/yyyy")}
                </Text>
                {path.consultancy && (
                  <Text
                    flex={1}
                    fontWeight={400}
                    fontSize={"L_sm"}
                    color={"Gray.$600"}
                  >
                    Consultoria {path.consultancy?.column_index}
                  </Text>
                )}
              </VStack>
            </HStack>

            <Icon name="angle-right" size="18px" />
          </HStack>
        </button>
      ))}
    </VStack>
  );
};

export default PathsViewer;
