import React from "react";
import ClientUpdate from "src/pages/Clients/Addons/Update";
import AddInProject from "src/pages/Clients/Dialogs/AddInProject";
import { Client } from "src/types";

type AddOnProps = {
  client: Client;
  handleBack: () => void;
};

export type ClientDialogProps = {
  client: Client;
  onClose: () => void;
};

type Addons = "update";
type Dialogs = "addInProject";

type ClientComponent<T extends string | number | symbol, K extends Object> = {
  [id in T]: (props: K) => React.ReactNode;
};

export const ClientAddons: ClientComponent<Addons, AddOnProps> = {
  update: (props) => <ClientUpdate {...props} />,
};

export const ClientDialogs: ClientComponent<Dialogs, ClientDialogProps> = {
  addInProject: (props) => <AddInProject {...(props as any)} />,
};
