import React, { useState } from "react";
import { ProfileProps } from "../types";
import { motion } from "framer-motion";
import { Button, Text, VStack } from "@chakra-ui/react";
import ProfileSelector from "../../ProfileSelector";

const DevelopmentGoal: React.FC<ProfileProps> = ({
  data,
  defaultValue: { id, sdgs },
  handleSubmit,
}) => {
  const [optionSelected, setOptionSelected] = useState<string[]>(
    sdgs?.map((model) => model.sdg_id) || []
  );

  return (
    <motion.div
      style={{
        height: "100%",
        margin: 0,
        width: "100%",
        overflow: "auto",
      }}
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
    >
      <VStack height={"100%"} alignItems={"flex-start"}>
        <Text fontFamily={"Raleway"} fontSize={"RH_sm"} fontWeight={600}>
          Para quais ODS sua empresa contribui?
        </Text>
        <Text color={"Gray.$700"} fontSize={"L_sm"} fontWeight={400}>
          Os Objetivos de Desenvolvimento Sustentável (ODS) foram estabelecidos
          pela ONU em 2015 e compõem uma agenda mundial para a construção e
          implementação de políticas públicas que visam guiar a humanidade até
          2030
        </Text>

        <VStack
          w={"100%"}
          flex={1}
          overflow={"auto"}
          alignItems={"flex-start"}
          mt={"32px !important"}
        >
          <ProfileSelector
            data={data}
            idSelecteds={optionSelected}
            onPress={setOptionSelected}
            isMultipleChoice
          />
        </VStack>
        <Button
          variant={"Primary"}
          w={"100%"}
          onClick={() =>
            handleSubmit({
              sdgs: optionSelected.map((opt) => ({
                sdg_id: opt,
                company_id: id,
              })),
            })
          }
          isDisabled={optionSelected.length < 1}
        >
          Continuar
        </Button>
      </VStack>
    </motion.div>
  );
};

export default DevelopmentGoal;
