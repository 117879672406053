import { CompanyPath } from "src/types";

type Path = {
  label: string;
  value: keyof CompanyPath;
  description?: string;
  hideInUpdate?: boolean;
};

export const PathTabs: Path[] = [
  {
    label: "Visão Geral",
    value: "" as any,
    description: "Visualize o status geral dos Estágios",
    hideInUpdate: true,
  },
  {
    label: "Explorar",
    value: "explore",
  },
  {
    label: "Engajar",
    value: "engage",
  },
  {
    label: "Entregar",
    value: "deliver",
  },
  {
    label: "Vender",
    value: "sell",
  },
  {
    label: "Crescer",
    value: "grow",
  },
  {
    label: "Estruturar",
    value: "structure",
  },
];
