import _axios from "../../..";
import { Company, Investment } from "../../../../types";

const InvestmentsService = {
  getAll: async (): Promise<Investment[]> =>
    (await _axios.get("investments")).data,

  create: async (body: string[], id: Company["id"]): Promise<void> =>
    (
      await _axios.patch(`company`, {
        id,
        investments_id: body[0],
      } as Partial<Company>)
    ).data,
};

export default InvestmentsService;
