import React from "react";
import { Props } from "./types";
import { HStack, Text } from "@chakra-ui/react";
import { Button } from "semente-js";

const IndicatorLine: React.FC<Props> = ({
  handleRemoveIndicator,
  indicator,
  isLast,
}) => {
  return (
    <HStack
      w={"100%"}
      py={"22px"}
      borderBottom={"1px solid"}
      borderColor={isLast ? "transparent" : "Gray.$400"}
    >
      <Text flex={1} fontSize={"LMD"} fontWeight={500}>
        {indicator.name}
      </Text>

      <Button
        color="gray"
        iconName="trash-alt-solid"
        layout="circle"
        onClick={() => handleRemoveIndicator(indicator.id)}
      />
    </HStack>
  );
};

export default IndicatorLine;
