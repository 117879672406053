import { CompanyMessage, Task } from "src/types";
import _axios from "..";

export const MessageService = {
  create: async (images: any, headers: any): Promise<CompanyMessage> =>
    (await _axios.post("message", images, { headers })).data,

  getAll: async (task_id: Task["id"]): Promise<CompanyMessage[]> =>
    (await _axios.get(`messages/${task_id}`)).data,
};

export default MessageService;
