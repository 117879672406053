import React from "react";
import { Props } from "./types";
import { Center, HStack, Text, VStack } from "@chakra-ui/react";
import Icon from "src/components/base/Icon";
import { formatDate } from "src/common";

const MessageItem: React.FC<Props> = ({ handleClick, message, isLast }) => {
  return (
    <HStack w={"100%"} onClick={handleClick}>
      <Icon name={"envelope-solid"} size="24px" />

      <HStack
        w={"100%"}
        ml={"16px"}
        py={"16px"}
        cursor={"pointer"}
        borderBottom={"1px solid"}
        borderColor={isLast ? "transparent" : "Gray.$300"}
        justifyContent={"space-between"}
      >
        <VStack spacing={"4px"} alignItems={"flex-start"} maxW={"250px"}>
          <Text fontWeight={600} fontSize={"L_md"}>
            {message.title}
          </Text>
          <Text
            whiteSpace={"nowrap"}
            width={"100%"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            fontWeight={500}
            fontSize={"L_sm"}
            color={"Gray.$700"}
            dangerouslySetInnerHTML={{ __html: message.message }}
          />
        </VStack>

        <HStack spacing={"16px"}>
          <Text fontWeight={500} fontSize={"L_sm"} color={"Gray.$700"}>
            {formatDate(new Date(message.createdAt), "dd/MM/yyyy")}
          </Text>

          <Center borderRadius={"4px"} p={"2px 4px"} background={"gray.200"}>
            <Text fontWeight={500} fontSize={"L_sm"}>
              {message.images.length}
            </Text>
          </Center>

          <Icon name={"angle-right"} size="24px" />
        </HStack>
      </HStack>
    </HStack>
  );
};

export default MessageItem;
