import React, { useRef, useState } from "react";
import { Props } from "./types";
import {
  Box,
  Center,
  HStack,
  Text,
  VStack,
  useOutsideClick,
} from "@chakra-ui/react";
import Icon from "../Icon";
import { Item } from "../Select/types";
import { StyledDropdown, StyledItem } from "./styles";
import { motion } from "framer-motion";

type ItemSelectedProps = {
  item: Item;
  handleRemove: () => void;
  handleClick: () => void;
};

const ItemSelectedBox: React.FC<ItemSelectedProps> = ({
  item,
  handleRemove,
  handleClick,
}) => {
  return (
    <HStack
      background={"Gray.$200"}
      px={"8px"}
      borderRadius={"4px"}
      position={"relative"}
    >
      <HStack onClick={handleClick}>
        <Text
          overflow={"hidden"}
          fontSize={"12px"}
          textOverflow={"ellipsis"}
          whiteSpace={"pre"}
        >
          {item.label}
        </Text>
      </HStack>
      <Center
        onClick={handleRemove}
        background={"Red.light"}
        borderRadius={"2px"}
        minW={"12px"}
        minH={"12px"}
        transition={"all 300ms"}
        _hover={{
          background: "Red.pure",
        }}
      >
        <Icon name={"times"} size={"8px"} />
      </Center>
    </HStack>
  );
};

const MultiSelect: React.FC<Props> = ({
  onChange,
  options,
  value = [],
  placeholder,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const menuRef = useRef<any>(null);
  const hasItems = value.length >= 1;

  useOutsideClick({
    ref: menuRef,
    handler: () => setMenuIsOpen(false),
  });

  const menuOptions: Item[] = options.filter(
    (item) => !value.includes(item.value)
  );

  return (
    <VStack
      flex={1}
      h={"56px"}
      p={"16px"}
      {...(hasItems && { paddingBottom: "4px" })}
      border={"1px solid"}
      borderRadius={"8px"}
      borderColor={menuIsOpen ? "Brand.pure" : "Gray.$500"}
      cursor={"pointer"}
      spacing={0}
      justifyContent={"center"}
      position={"relative"}
      ref={menuRef}
    >
      <VStack alignItems={"flex-start"} w={"100%"}>
        {placeholder && (
          <Center
            onClick={() => setMenuIsOpen(true)}
            width={"100%"}
            top={0}
            left={0}
            height={"100%"}
            position={"absolute"}
            justifyContent={"flex-start"}
            zIndex={2}
            {...(menuIsOpen && { color: "Brand.pure" })}
            {...((hasItems || menuIsOpen) && {
              top: "6px",
              height: "inherit",
              fontSize: "10px",
            })}
          >
            <Text ml={"16px"}>{placeholder}</Text>
          </Center>
        )}

        <HStack w="100%">
          <HStack
            maxWidth={"95%"}
            justifyContent={"flex-start"}
            overflow={"auto"}
            css={{
              "&::-webkit-scrollbar": {
                height: "2px",
              },
            }}
            pb={"2px"}
          >
            {value.map((item_id) => (
              <motion.div
                key={item_id}
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
              >
                <ItemSelectedBox
                  item={options.find((item) => item.value === item_id) as Item}
                  handleClick={() => setMenuIsOpen(true)}
                  handleRemove={() =>
                    onChange(value.filter((id) => id !== item_id))
                  }
                />
              </motion.div>
            ))}
          </HStack>

          <HStack flex={1} h={"100%"} onClick={() => setMenuIsOpen(true)} />
        </HStack>
      </VStack>

      <Box
        position={"absolute"}
        right={"16px"}
        top={"20px"}
        transition={"all 300ms"}
        transform={`rotate(${menuIsOpen ? 0.5 : 0}turn)`}
      >
        <Icon name={"angle-down"} size={"18px"} />
      </Box>

      <StyledDropdown isOpen={menuIsOpen}>
        <VStack w={"100%"} py={"8px"} alignItems={"flex-start"}>
          {menuOptions.length >= 1 ? (
            menuOptions.map((item) => (
              <StyledItem
                key={item.value}
                onClick={() => {
                  onChange([...value, item.value]);
                  setMenuIsOpen(false);
                }}
                isSelected={false}
              >
                <Text fontSize={"16px"} variant={"Poppins"} color={"#221F1D"}>
                  {item.label}
                </Text>
              </StyledItem>
            ))
          ) : (
            <StyledItem
              onClick={() => {
                setMenuIsOpen(false);
              }}
              isSelected={false}
            >
              Nenhuma opção disponível
            </StyledItem>
          )}
        </VStack>
      </StyledDropdown>
    </VStack>
  );
};

export default MultiSelect;
