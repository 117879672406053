import { Center, HStack, Text, VStack } from "@chakra-ui/react";
import { MutatingDots } from "react-loader-spinner";
import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import CompanyService from "../../services/company";
import { Company, CompanyWithProfile } from "../../types";
import { HomeEmptyState } from "src/components/HomeEmptyState";
import { AddButton } from "src/components/AddButton";
import CompanyInvite from "../Project/CreateProjectModal/CompanyInviteDialog/CompanyInvite";
import { ListLeftSide } from "./List/LeftSide";
import { ListRightSide } from "./List/RightSide";
import { ProtectedComponent } from "semente-js";
import { PERMISSIONS } from "src/permissions";

export const Companies: React.FC = () => {
  const [inviteIsOpen, setInviteIsOpen] = useState<boolean>(false);
  const [requestData, setRequestData] = useState({
    data: [] as CompanyWithProfile[],
    isLoading: true,
  });
  const [currentCompany, setCurrentCompany] = useState({
    isLoading: true,
    data: {} as CompanyWithProfile,
  });
  const [page, setPage] = useState({
    current: 0,
    isLast: false,
    isLoading: false,
  });

  const handleSelectCompany = useCallback(async (company: Company) => {
    setCurrentCompany({ data: {} as CompanyWithProfile, isLoading: true });
    try {
      setCurrentCompany({
        data: await CompanyService.getCompanyById(company.id),
        isLoading: false,
      });
    } catch (err) {
      toast.error(`Ocorreu um erro ao buscar a empresa ${company.name}`);
      setCurrentCompany({ data: {} as CompanyWithProfile, isLoading: false });
    }
  }, []);

  useEffect(() => {
    CompanyService.getCompaniesPaginated({
      offset: page.current * 10,
      limit: 10,
    }).then((data) => {
      if (!("id" in currentCompany.data)) {
        handleSelectCompany(data.result[0]);
      }

      const newCompanies = [...requestData.data, ...data.result];

      setRequestData({
        data: newCompanies,
        isLoading: false,
      });

      setPage({
        ...page,
        isLoading: false,
        isLast: newCompanies.length === data.total,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.current]);

  const createLabel = "Nova empresa";

  return (
    <VStack w={"100%"} px="80px" py="24px">
      <HStack
        w={"100%"}
        pb={"24px"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        borderBottom={"1px solid"}
        borderColor={"Gray.$400"}
      >
        <Text
          fontSize={"RD_md"}
          color={"Gray.$800"}
          fontFamily={"raleway"}
          fontWeight={600}
        >
          Empresas
        </Text>
        <ProtectedComponent
          requiredClientRoles={[PERMISSIONS.COMPANY_CREATION]}
          children={
            <AddButton
              label={createLabel}
              onClick={() => setInviteIsOpen(true)}
            />
          }
        />
      </HStack>
      {requestData.isLoading ? (
        <Center mt={"64px !important"}>
          <MutatingDots
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {requestData && requestData.data.length >= 1 ? (
            <HStack
              w={"100%"}
              pt={"24px"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
            >
              <ListLeftSide
                handleSelectCompany={handleSelectCompany}
                handleGetCompanies={() => {
                  setPage({
                    ...page,
                    isLoading: true,
                    current: page.current + 1,
                  });
                }}
                page={page}
                companies={requestData.data}
              />

              {currentCompany.isLoading ? (
                <Center w={"100%"} mt={"64px !important"}>
                  <MutatingDots
                    height="100"
                    width="100"
                    color="#408EC5"
                    secondaryColor="#408EC5"
                    radius="12.5"
                    ariaLabel="mutating-dots-loading"
                    visible={true}
                  />
                </Center>
              ) : (
                <ListRightSide company={currentCompany.data} />
              )}
            </HStack>
          ) : (
            <>
              <ProtectedComponent
                requiredClientRoles={[PERMISSIONS.COMPANY_CREATION]}
                children={
                  <HomeEmptyState
                    buttonLabel={createLabel}
                    description={
                      "As empresas podem ser cadastradas pelos empreendedores no app da Rama Semente."
                    }
                    title={"Nenhuma empresa aqui ainda"}
                    handleCreate={() => setInviteIsOpen(true)}
                  />
                }
              />
            </>
          )}
        </motion.div>
      )}

      <CompanyInvite
        isOpen={inviteIsOpen}
        handleClose={() => setInviteIsOpen(false)}
        handleFinish={() => {
          setInviteIsOpen(false);
          setPage(JSON.parse(JSON.stringify(page)));
        }}
      />
    </VStack>
  );
};
