import { Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React, { useState, useCallback, useEffect, useContext } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { MutatingDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { doneSuperIcon } from "../../../../../assets/images";
import Icon from "../../../../../components/base/Icon";
import Input from "../../../../../components/base/Input/Index";
import { Modal } from "../../../../../components/base/Modal";
import { Select } from "../../../../../components/base/Select";
import CompanyService from "../../../../../services/company";
import ProjectsService from "../../../../../services/projects";
import { Company, Project } from "../../../../../types";
import { motion } from "framer-motion";
import { Props } from "./types";
import { fadeInUp } from "../../../../../animations";
import { ProjectContext } from "../../../../../contexts/ProjectContext";
import Checkbox from "src/components/Checkbox";
import { Button } from "semente-js";

const defaultValues = {
  companies: [] as Company[],
};

const defaultFilters = {
  filter: undefined as any,
  project: undefined as any,
};

const CompanyAdd: React.FC<Props> = ({
  project,
  handleClose,
  handleFinish,
}) => {
  const { projects, selectProject, currentProject } =
    useContext(ProjectContext);
  const defaultCompanies = project?.companies?.map(
    (company) => company.company_id
  );
  const [timer, setTimer] = useState<any>(null);
  const [filters, setFilters] = useState(defaultFilters);
  const [companies, setCompanies] = useState<Company[]>();
  const [isLoading, setIsLoading] = useState(true);
  const {
    watch,
    control,
    handleSubmit,
    formState: { isSubmitSuccessful, isSubmitting },
  } = useForm({ defaultValues });

  const populateCompanies = useCallback(async () => {
    setIsLoading(true);
    try {
      setCompanies(
        (await CompanyService.getCompaniesPaginated(filters)).result
      );
    } catch (err) {
      handleClose();
      toast.error("Ocorreu um erro ao buscar as empresas.");
    }
    setIsLoading(false);
  }, [handleClose, filters]);

  useEffect(() => {
    populateCompanies();
  }, [populateCompanies, filters]);

  const handleSubmitForm: SubmitHandler<typeof defaultValues> = async (
    values
  ) => {
    if (project && "id" in project) {
      try {
        await Promise.all(
          values.companies.map(
            async (company) =>
              await ProjectsService.createProjectCompany({
                company_id: company.id,
                project_id: project.id || "",
              })
          )
        );

        await selectProject(project.id, true);
      } catch (err) {
        toast.error("Ocorreu um erro ao enviar as empresas.");
      }
    }
  };

  const handleChangeFilter = (filter: string) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(setTimeout(() => setFilters({ ...filters, filter }), 500));
  };

  return (
    <Modal
      isOpen={true}
      title={isSubmitSuccessful ? "" : "Adicionar empresas"}
      onClose={handleClose}
    >
      <VStack
        w={"100%"}
        maxW={"568px"}
        borderTop={"1px solid"}
        borderColor={"Gray.$400"}
      >
        {isSubmitting ? (
          <Center minH={"448px"}>
            <MutatingDots
              height="100"
              width="100"
              color="#408EC5"
              secondaryColor="#408EC5"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              visible={true}
            />
          </Center>
        ) : (
          <VStack w={"100%"} h={"100%"} position={"relative"}>
            {isSubmitSuccessful ? (
              <VStack p={"24px"} w={"100%"} alignItems={"flex-start"}>
                <Image src={doneSuperIcon} alt={"Ícone de sucesso"} />

                <Text
                  mt={"24px !important"}
                  fontWeight={600}
                  fontSize={"RH_lg"}
                  fontFamily={"Raleway"}
                >
                  Empresas adicionadas!
                </Text>
                <Text
                  fontWeight={400}
                  fontSize={"L_md"}
                  color={"Gray.$700"}
                  mt={"4px !important"}
                >
                  As empresas selecionadas já foram adicionadas ao projeto{" "}
                  {currentProject.data?.name}.
                </Text>

                <VStack spacing={"16px"} w={"100%"} mt={"24px !important"}>
                  {watch("companies")?.map((company) => (
                    <HStack
                      key={company.id}
                      p={"16px"}
                      w={"100%"}
                      background={"Gray.$200"}
                      borderRadius={"12px"}
                    >
                      <Center
                        w={"40px"}
                        h={"40px"}
                        borderRadius={"50%"}
                        background={"Gray.$300"}
                      >
                        {company.image_url ? (
                          <Image
                            src={company.image_url}
                            borderRadius={"50%"}
                            objectFit={"cover"}
                            w={"100%"}
                            h={"100%"}
                          />
                        ) : (
                          <Icon name="image" />
                        )}
                      </Center>

                      <Text
                        fontWeight={600}
                        ml={"16px !important"}
                        fontSize={"L_lg"}
                        color={"Gray.$800"}
                      >
                        {company.name}
                      </Text>
                    </HStack>
                  ))}
                </VStack>
              </VStack>
            ) : (
              <VStack w={"100%"} px={"12px"}>
                <HStack mt={"24px"} w={"100%"} px={"12px"}>
                  <HStack
                    pb={"24px"}
                    w={"100%"}
                    borderBottom={"1px solid"}
                    spacing={"12px !important"}
                    borderColor={"Gray.$400"}
                  >
                    <Center
                      w={"24px"}
                      h={"24px"}
                      borderRadius={"50%"}
                      background={"Gray.$300"}
                    >
                      {project.image_url ? (
                        <Image
                          src={project.image_url}
                          borderRadius={"50%"}
                          objectFit={"cover"}
                          w={"100%"}
                          h={"100%"}
                        />
                      ) : (
                        <Icon name="image" />
                      )}
                    </Center>
                    <Text fontWeight={400} fontSize={"L_md"}>
                      {project.name}
                    </Text>
                  </HStack>
                </HStack>

                <VStack w={"100%"} px={"12px"}>
                  <HStack
                    w={"100%"}
                    spacing={"16px !important"}
                    my={"24px !important"}
                  >
                    <Input
                      placeholder={"Procurar empresa"}
                      icon={"search"}
                      onChange={({ target }) =>
                        handleChangeFilter(target.value)
                      }
                    />
                    {projects.data && (
                      <Select
                        placeholder={"Projeto"}
                        items={(projects?.data as Project[])?.map(
                          (project) => ({
                            label: project.name,
                            value: project.id || "",
                          })
                        )}
                        onChange={(e) => setFilters({ ...filters, project: e })}
                        value={filters.project}
                      />
                    )}
                  </HStack>
                  <HStack w={"100%"} justifyContent={"space-between"}>
                    <Text
                      fontSize={"L_md"}
                      fontWeight={600}
                      color={"Gray.$800"}
                    >
                      Empresas dentro de toda a Rama Semente
                    </Text>
                    <Text
                      fontSize={"L_sm"}
                      fontWeight={400}
                      color={"Gray.$700"}
                    >
                      {(defaultCompanies?.length || 0) +
                        watch("companies").length}{" "}
                      empresas selecionadas
                    </Text>
                  </HStack>
                </VStack>

                {isLoading ? (
                  <Center minH={"438px"}>
                    <MutatingDots
                      height="100"
                      width="100"
                      color="#408EC5"
                      secondaryColor="#408EC5"
                      radius="12.5"
                      ariaLabel="mutating-dots-loading"
                      visible={true}
                    />
                  </Center>
                ) : (
                  <>
                    {companies && companies.length >= 1 && (
                      <VStack w={"100%"} px={"12px"}>
                        <Controller
                          name={"companies"}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <>
                              {companies?.map((company, index) => (
                                <motion.div
                                  key={company.id}
                                  {...fadeInUp}
                                  style={{ cursor: "pointer", width: "100%" }}
                                >
                                  <HStack
                                    m={"0px !important"}
                                    alignItems={"flex-start"}
                                    cursor={"pointer"}
                                    onClick={() => {
                                      if (
                                        !defaultCompanies?.includes(company.id)
                                      ) {
                                        if (
                                          value.find((c) => c.id === company.id)
                                        ) {
                                          onChange(
                                            value.filter(
                                              (c) => c.id !== company.id
                                            )
                                          );
                                        } else {
                                          onChange([...value, company]);
                                        }
                                      }
                                    }}
                                    py={"16px"}
                                    w={"100%"}
                                  >
                                    <Center
                                      w={"48px"}
                                      h={"48px"}
                                      borderRadius={"50%"}
                                      background={"Gray.$300"}
                                    >
                                      {company.image_url ? (
                                        <Image
                                          src={company.image_url}
                                          borderRadius={"50%"}
                                          objectFit={"cover"}
                                          w={"100%"}
                                          h={"100%"}
                                        />
                                      ) : (
                                        <Icon name="image" />
                                      )}
                                    </Center>
                                    <HStack
                                      ml={"12px !important"}
                                      flex={1}
                                      pb={"18px"}
                                      borderBottom={"1px solid"}
                                      borderColor={
                                        companies.length - 1 === index
                                          ? "transparent"
                                          : "Gray.$400"
                                      }
                                    >
                                      <Text
                                        flex={1}
                                        fontWeight={400}
                                        fontSize={"L_md"}
                                        color={"Gray.$800"}
                                        mt={"8px"}
                                      >
                                        {company.name}
                                      </Text>

                                      <Checkbox
                                        isChecked={
                                          !!value.find(
                                            (c) => c.id === company.id
                                          ) ||
                                          defaultCompanies?.includes(company.id)
                                        }
                                        isDisabled={defaultCompanies?.includes(
                                          company.id
                                        )}
                                      />
                                    </HStack>
                                  </HStack>
                                </motion.div>
                              ))}
                            </>
                          )}
                        />
                      </VStack>
                    )}
                  </>
                )}
              </VStack>
            )}
            <HStack
              justifyContent={"flex-end"}
              p={"24px"}
              w={"100%"}
              borderTop={"1px solid"}
              borderColor={"Gray.$400"}
              background={"white"}
              bottom={0}
              position={"sticky"}
            >
              <Button
                layout="rounded"
                buttonProps={{
                  disabled:
                    watch("companies")?.length < 1 && !isSubmitSuccessful,
                }}
                label={isSubmitSuccessful ? "Concluído" : "Adicionar empresas"}
                onClick={
                  isSubmitSuccessful
                    ? handleFinish
                    : () => handleSubmit(handleSubmitForm)()
                }
              />
            </HStack>
          </VStack>
        )}
      </VStack>
    </Modal>
  );
};

export default CompanyAdd;
