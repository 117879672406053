import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import { ProtectedLayout } from "../components/ProtectedLayout";
import { UserContext } from "../contexts/UserContext";
import { Companies } from "../pages/Companies";
import { useContext } from "react";
import SignUp from "src/pages/SignUp";
import { Team } from "src/pages/Team";
import CompanySignUp from "src/pages/CompanySignUp";
import EmployeeSignUp from "src/pages/EmployeeSignUp";
import { Clients } from "src/pages/Clients";
import { Settings } from "src/pages/Settings";
import ProjectView from "src/pages/Project";
import ProjectViewContextProvider from "src/pages/Project/Context";
import TaskView from "src/pages/Kanban/Dialogs/TaskView";
import CompanyForm from "src/pages/Companies/CompanyForm";
import { Providers } from "src/contexts";
import { ToastContainer } from "react-toastify";
import { ProtectedApp } from "semente-js";

export const Router = () => {
  const { user } = useContext(UserContext);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Providers />}>
        <>
          {!user && (
            <Route
              element={
                <>
                  <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                  <Outlet />
                </>
              }
            >
              <Route path="/signup/:hash" element={<SignUp />} />
              <Route path="/companysignup/:hash" element={<CompanySignUp />} />
              <Route
                path="/signup/employee/:companyId"
                element={<EmployeeSignUp />}
              />
            </Route>
          )}

          <Route
            element={
              <ProtectedApp>
                <Outlet />
              </ProtectedApp>
            }
          >
            <Route path="/" element={<ProtectedLayout />}>
              <Route
                index
                path={"/:projectId?"}
                element={
                  <ProjectViewContextProvider>
                    <ProjectView />
                  </ProjectViewContextProvider>
                }
              />

              <Route path="/kanban/task/:tab/:id" element={<TaskView />} />

              <Route path="companies" element={<Companies />} />
              <Route path="companies/:id" element={<CompanyForm />} />
              <Route path="settings" element={<Settings />} />
              <Route path="team" element={<Team />} />
              <Route path="clients" element={<Clients />} />
              <Route path="clients/:clientId" element={<Clients />} />
              <Route path="*" element={<Navigate to={"/"} />} />
            </Route>
          </Route>
        </>
      </Route>
    ),
    { basename: "/app" }
  );

  return <RouterProvider router={router} />;
};
