import { Button, Center, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MutatingDots } from "react-loader-spinner";
import maskFieldByKey from "./consts";
import { Props } from "./types";
import { motion } from "framer-motion";
import { Client } from "src/types";

const FieldWrapper: React.FC<Props> = ({
  handleUpdate,
  fieldData,
  Form,
  label,
  value,
}) => {
  const [fieldInUpdateMode, setFieldInUpdateMode] = useState(false);
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: value as Partial<Client>,
  });

  const needExtraData = !!fieldData;
  const fieldKey = Object.keys(value)[0] as keyof Client;
  const fieldValue = value[fieldKey];

  useEffect(() => {
    reset(value);
  }, [value, reset, fieldInUpdateMode]);

  return (
    <VStack
      w={"100%"}
      alignItems={"flex-start"}
      py={"24px"}
      margin={"0px !important"}
    >
      {!fieldInUpdateMode ? (
        <VStack w={"100%"}>
          <HStack w={"100%"} justifyContent={"space-between"}>
            <VStack
              alignItems={"flex-start"}
              maxW={"400px"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              <Text fontWeight={400} fontSize={"L_md"}>
                {label}
              </Text>
              <Text fontWeight={400} fontSize={"L_md"} color={"Gray.$700"}>
                {fieldKey in maskFieldByKey
                  ? (maskFieldByKey[fieldKey] as any)(fieldValue)
                  : fieldValue}
              </Text>
            </VStack>

            <Text
              cursor={"pointer"}
              onClick={() => setFieldInUpdateMode(true)}
              color={"Tertiary.dark"}
              fontWeight={600}
              fontSize={"L_md"}
            >
              Editar
            </Text>
          </HStack>
        </VStack>
      ) : (
        <motion.div
          initial={{
            height: 0,
          }}
          animate={{
            height: "auto",
          }}
          exit={{
            height: 0,
          }}
          style={{ width: "100%" }}
        >
          <VStack w={"100%"} alignItems={"flex-start"}>
            {fieldData?.loaded || !needExtraData ? (
              <>
                {fieldData && fieldData.error ? (
                  <Center>
                    <Text>
                      Ocorreu um erro ao buscar os dados de edição deste campo.
                    </Text>
                  </Center>
                ) : (
                  <>
                    <Text fontWeight={400} fontSize={"L_md"}>
                      {label}
                    </Text>

                    {((fieldData && fieldData.data) || !needExtraData) && (
                      <Form
                        setValue={setValue}
                        control={control}
                        data={fieldData?.data as any}
                        watch={watch}
                        reset={reset}
                      />
                    )}

                    <HStack mt={"24px !important"} spacing={"16px"}>
                      <Button
                        variant={"Primary"}
                        maxH={"48px"}
                        onClick={() => {
                          setFieldInUpdateMode(false);
                          handleSubmit(handleUpdate)();
                        }}
                      >
                        Salvar alteração
                      </Button>
                      <Button
                        maxH={"48px"}
                        onClick={() => setFieldInUpdateMode(false)}
                      >
                        Cancelar
                      </Button>
                    </HStack>
                  </>
                )}
              </>
            ) : (
              <Center w={"100%"}>
                <MutatingDots
                  height="100"
                  width="100"
                  color="#408EC5"
                  secondaryColor="#408EC5"
                  radius="12.5"
                  ariaLabel="mutating-dots-loading"
                  visible={true}
                />
              </Center>
            )}
          </VStack>
        </motion.div>
      )}
    </VStack>
  );
};

export default FieldWrapper;
