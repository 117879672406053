import {
  Button,
  Center,
  HStack,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { ActionsStage } from "src/common";
import Input from "src/components/base/Input/Index";
import { Select } from "src/components/base/Select";
import StyledOutlineInput from "./styles";
import { Props } from "./types";

const StageForm: React.FC<Props> = ({
  defaultValues,
  handleSubmitForm,
  handleDelete,
  handleCancel,
  test,
}) => {
  const { control, handleSubmit, watch } = useForm({ defaultValues });
  const priority = (defaultValues.priority || 0) - 1;

  const labelStyles = {
    mt: "2",
    ml: "-2.5",
    fontSize: "sm",
  };

  return (
    <VStack
      h={"100%"}
      w={"100%"}
      alignItems={"flex-start"}
      overflowY={"auto"}
      px={"16px !important"}
      sx={{
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <VStack
        flex={1}
        w={"100%"}
        alignItems={"flex-start"}
        spacing={"0px !important"}
      >
        <Text
          fontWeight={600}
          fontFamily={"Raleway"}
          fontSize={"RH_sm"}
          my={"18px !important"}
        >
          {`Passo ${defaultValues.priority} - ${
            Object.keys(ActionsStage)[priority]
          }`}
        </Text>

        <VStack
          w={"100%"}
          p={"16px"}
          mb={"24px !important"}
          background={"Gray.$300"}
          spacing={"8px !important"}
          alignItems={"flex-start"}
          borderRadius={"8px"}
        >
          <Text fontSize={"L_sm"} color={"Gray.$700"}>
            Nesse passo, meu objetivo é:
          </Text>
          <Text fontSize={"L_sm"}>
            {watch("name")} {watch("indicator")} {watch("description")}
          </Text>
        </VStack>

        <Controller
          control={control}
          name={"name"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Select
              items={ActionsStage[
                Object.keys(ActionsStage)[priority] as keyof typeof ActionsStage
              ]?.map((action) => ({
                label: action.label,
                value: action.label,
              }))}
              placeholder="Ação"
              {...(value && {
                value: {
                  label: value,
                  value: value,
                },
              })}
              onChange={(item) => {
                onChange(item.value);
              }}
              isInvalid={!!error}
            />
          )}
        />

        <Controller
          control={control}
          name={"indicator"}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Center flex={1} w={"100%"} py={"52px"}>
              <VStack w={"100%"} spacing={"20px !important"}>
                <VStack>
                  <StyledOutlineInput
                    value={value + " " + test.sample_spaces[0].name}
                    onChange={(e) => onChange((e?.target as any).value)}
                    disabled
                  />
                </VStack>

                <Slider
                  aria-label="slider-ex-6"
                  onChange={(percent) =>
                    onChange(
                      parseInt(
                        `${test.sample_spaces[0].size * (percent / 100)}`
                      )
                    )
                  }
                  value={Math.round((value / test.sample_spaces[0].size) * 100)}
                >
                  <SliderMark value={2} {...labelStyles}>
                    0
                  </SliderMark>

                  <SliderMark value={97} {...labelStyles}>
                    {test.sample_spaces[0].size}
                  </SliderMark>

                  <SliderMark
                    value={Math.round(
                      (value / test.sample_spaces[0].size) * 100
                    )}
                    textAlign="center"
                    bg="blue.500"
                    color="white"
                    mt="-10"
                    ml="-5"
                    w="12"
                  />
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </VStack>
            </Center>
          )}
        />

        <Controller
          control={control}
          name={"description"}
          render={({ field, fieldState: { error } }) => (
            <Input {...field} placeholder={"Complemento"} isInvalid={!!error} />
          )}
        />
      </VStack>
      <HStack p={"16px 0px"} w={"100%"}>
        <Button onClick={handleCancel} minH={"48px"}>
          Voltar
        </Button>
        <HStack flex={1} justifyContent={"flex-end"}>
          {defaultValues.id && (
            <Button
              variant={"Tertiary"}
              border={"1px solid"}
              borderColor={"Red.pure"}
              color={"Red.pure"}
              minH={"48px"}
              onClick={() => handleDelete(defaultValues.id)}
            >
              Excluir estágio
            </Button>
          )}
          <Button
            variant={"Primary"}
            minH={"48px"}
            onClick={handleSubmit(handleSubmitForm)}
          >
            {defaultValues?.id ? "Salvar estágio" : "Criar novo estágio"}
          </Button>
        </HStack>
      </HStack>
    </VStack>
  );
};

export default StageForm;
