import { Image } from "@chakra-ui/react";
import React from "react";
import { motion } from "framer-motion";
import { feedback } from "src/assets/images";

const BugBallon: React.FC = () => {
  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      style={{
        position: "fixed",
        right: "32px",
        bottom: "32px",
        borderRadius: "50%",
        zIndex: 1000,
        cursor: "pointer",
      }}
      onClick={() => window.open("https://forms.gle/f6iSuzJsABZJFpFc7")}
    >
      <Image src={feedback} alt={"Ícone de feedback"} w={"52px"} h={"52px"} />
    </motion.div>
  );
};

export default BugBallon;
