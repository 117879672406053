import { Indicator } from "src/types";
import _axios from "..";
import { Project } from "semente-js";

export const IndicatorService = {
  getAll: async (project_id?: Project["id"]): Promise<Indicator[]> =>
    (await _axios.get(`/indicators${project_id ? `/${project_id}` : ""}`)).data,

  create: async (indicator: Partial<Indicator>): Promise<Indicator> =>
    (await _axios.post("/indicator", indicator)).data,

  update: async (indicator: Partial<Indicator>): Promise<Indicator> =>
    (await _axios.patch(`/indicator/${indicator.id}`, indicator)).data,

  remove: async (indicator_id: Indicator["id"]): Promise<void> =>
    (await _axios.delete(`/indicator/${indicator_id}`)).data,

  addToProject: async (
    project_id: Project["id"],
    indicators: Indicator["id"][]
  ): Promise<Indicator[]> =>
    (
      await _axios.post(`/indicator/project`, {
        indicators,
        project_id,
      })
    ).data,

  removeFromProject: async (data: {
    project_id: Project["id"];
    indicator_id: Indicator["id"];
  }): Promise<void> =>
    (await _axios.delete("/indicator/project", { data })).data,
};

export default IndicatorService;
