import { consultancyIcon } from "src/assets/images";

const ConsultancyCardsData = [
  {
    tab: "evolution",
    image: consultancyIcon,
    query: "paths",
    title: "Estágios do Caminho Empreendedor",
    description: "Acesse para atualizar os status",
  },
  {
    tab: "evolution",
    icon: "chart-bar",
    query: "indicators",
    title: "Desempenho",
    description: "Acesse para atualizar os indicadores",
  },
];

export default ConsultancyCardsData;
