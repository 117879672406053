import { Button, HStack, Text } from "@chakra-ui/react";
import { Props } from "./types";
import { Button as SButton } from "semente-js";
import React from "react";

export const Header: React.FC<Props> = ({
  hasFilter,
  kanbanData,
  handleUpdateKanban,
  handleOpenFilters,
}) => {
  return (
    <HStack
      w={"100%"}
      pb={"24px"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <HStack position={"relative"}>
        <Text fontSize={"RH_md"} fontWeight={500} mr={"14px !important"}>
          {kanbanData?.name}
        </Text>
      </HStack>

      <HStack spacing={4}>
        <Button onClick={handleUpdateKanban} minH={"36px"}>
          Editar quadro
        </Button>
        <SButton
          onClick={handleOpenFilters}
          iconName="filter-solid"
          layout="circle"
          color={hasFilter ? "primary" : "gray"}
        />
      </HStack>
    </HStack>
  );
};
