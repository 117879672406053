import {
  Button,
  Center,
  HStack,
  Image,
  Stack,
  Text,
  useOutsideClick,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import React, { useRef, useState, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { Props } from "../types";
import { MutatingDots } from "react-loader-spinner";
import { fadeInUp } from "src/animations";
import { User } from "src/types";
import { MenuButton } from "src/components/MenuButton";
import { Modal } from "src/components/base/Modal";
import { ProjectContext } from "src/contexts/ProjectContext";
import Icon from "src/components/base/Icon";
import { createImageFromInitials } from "src/common";
import ProjectsService from "src/services/projects";

const ConsultantsTabProject: React.FC<Props> = ({ project }) => {
  const [menuIsOpen, setMenuIsOpen] = useState<string>();
  const { showProjectDialog, handleRemoveConsultant } =
    useContext(ProjectContext);
  const [consultants, setConsultants] = useState({
    data: [] as User[],
    isLoading: true,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState<{
    isLoading: boolean;
    id: string;
  }>();
  const menuRef = useRef<any>(null);
  const theme = useTheme();

  useOutsideClick({
    ref: menuRef,
    handler: () => setMenuIsOpen(undefined),
  });

  const menuOptions = [
    {
      title: "Remover consultor",
      color: "#CC0000",
      onClick: (user_id: User["id"]) => {
        setShowConfirmationModal({
          isLoading: false,
          id: user_id,
        });
        setMenuIsOpen(undefined);
      },
    },
  ];

  useEffect(() => {
    setConsultants({ isLoading: true, data: [] });
    ProjectsService.getConsultantsByProject(project.id).then((data) =>
      setConsultants({ data, isLoading: false })
    );
  }, [project]);

  const handleDeleteConsultant = async () => {
    if (showConfirmationModal) {
      setShowConfirmationModal({ ...showConfirmationModal, isLoading: true });
      await handleRemoveConsultant(showConfirmationModal.id);
      setConsultants({
        ...consultants,
        data: consultants.data.filter(
          (consultant) => consultant.id !== showConfirmationModal.id
        ),
      });
      setShowConfirmationModal(undefined);
    }
  };
  return (
    <VStack flex={1} alignItems={"flex-start"}>
      <VStack w={"100%"} maxW={"558px"} spacing={0}>
        <HStack w={"100%"} justifyContent={"space-between"}>
          <Text
            fontSize={"RH_lg"}
            fontFamily={"raleway"}
            color={"Gray.$800"}
            fontWeight={500}
          >
            Consultores
          </Text>

          {!consultants.isLoading && consultants.data.length >= 1 && (
            <Button
              minH={"36px"}
              onClick={() => showProjectDialog("consultants")}
            >
              <HStack spacing={2}>
                <Icon name={"plus"} />

                <Text fontSize={"L_sm"} color={"Gray.$800"} fontWeight={500}>
                  Adicionar consultor
                </Text>
              </HStack>
            </Button>
          )}
        </HStack>

        {consultants.isLoading ? (
          <Center height={"300px"} w={"100%"}>
            <MutatingDots
              height="100"
              width="100"
              color="#408EC5"
              secondaryColor="#408EC5"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              visible={true}
            />
          </Center>
        ) : (
          <motion.div {...fadeInUp} style={{ width: "100%" }}>
            {consultants.data.length >= 1 ? (
              <VStack
                border={"1px solid"}
                borderColor={"Gray.$300"}
                mt={"32px !important"}
                borderRadius={"16px"}
                pl={"16px"}
                w={"100%"}
              >
                {consultants.data.map((consultant, index) => {
                  return (
                    <HStack
                      cursor={"inherit"}
                      key={consultant?.id}
                      alignItems={"flex-start"}
                      m={"0px !important"}
                      pt={"16px"}
                      w={"100%"}
                    >
                      <Center
                        w={"48px"}
                        h={"48px"}
                        borderRadius={"50%"}
                        background={"Tertiary.pure"}
                      >
                        <Image
                          src={
                            consultant?.image_url ||
                            createImageFromInitials({
                              background: (theme.colors as any).Tertiary.pure,
                              color: (theme.colors as any).white,
                              name: consultant?.name.substring(0, 2) || "",
                              size: 48,
                              fontSize: 16,
                            })
                          }
                          borderRadius={"50%"}
                          objectFit={"cover"}
                          w={"100%"}
                          h={"100%"}
                        />
                      </Center>
                      <HStack
                        ml={"12px !important"}
                        flex={1}
                        position={"relative"}
                        pb={"26px"}
                        pr={"16px"}
                        borderBottom={"1px solid"}
                        borderColor={
                          consultants.data.length - 1 === index
                            ? "transparent"
                            : "Gray.$300"
                        }
                      >
                        <HStack justifyContent={"space-between"} w={"100%"}>
                          <VStack alignItems={"flex-start"} spacing={0}>
                            <Text
                              fontWeight={consultant ? 400 : 600}
                              fontSize={"L_md"}
                              color={"Gray.$800"}
                            >
                              {consultant?.name} {consultant?.surname}
                            </Text>
                            <Text
                              fontWeight={consultant ? 400 : 600}
                              fontSize={"L_md"}
                              color={"Gray.$600"}
                            >
                              {consultant?.email}
                            </Text>
                          </VStack>

                          <Center
                            cursor={"pointer"}
                            onClick={() => setMenuIsOpen(consultant.id)}
                          >
                            <MenuButton />
                          </Center>

                          {consultant.id === menuIsOpen && (
                            <VStack
                              py={"8px"}
                              ref={menuRef}
                              right={"0px"}
                              top={"40px"}
                              position={"absolute"}
                              zIndex={200}
                              background={"Gray.$100"}
                              minW={"240px"}
                              borderRadius={"12px"}
                              boxShadow={"0px 2px 16px rgba(0, 0, 0, 0.12)"}
                            >
                              {menuOptions.map((option) => (
                                <Stack
                                  cursor={"pointer"}
                                  key={option.title}
                                  padding={"12px 16px"}
                                  onClick={() => option.onClick(consultant.id)}
                                  w={"100%"}
                                  alignItems={"flex-start"}
                                  transition={"300ms all"}
                                  _hover={{
                                    background: "Gray.$200",
                                  }}
                                >
                                  <Text
                                    fontSize={"L_sm"}
                                    fontWeight={400}
                                    color={option.color}
                                  >
                                    {option.title}
                                  </Text>
                                </Stack>
                              ))}
                            </VStack>
                          )}
                        </HStack>
                      </HStack>
                    </HStack>
                  );
                })}
              </VStack>
            ) : (
              <VStack w={"558px"} mt={"36px"}>
                <Icon
                  name={"question-circle"}
                  color={"#0077CC"}
                  size={"64px"}
                />
                <Text
                  fontSize={"RH_xs"}
                  color={"Gray.$800"}
                  fontFamily={"raleway"}
                  fontWeight={500}
                >
                  Nenhum consultor aqui ainda
                </Text>
                <Text fontSize={"L_md"} color={"Gray.$700"} fontWeight={400}>
                  Convide consultores para esse projeto.
                </Text>

                <Button
                  minH={"36px"}
                  mt={"16px !important"}
                  onClick={() => showProjectDialog("consultants")}
                >
                  <HStack spacing={2}>
                    <Icon name={"plus"} />

                    <Text
                      fontSize={"L_sm"}
                      color={"Gray.$800"}
                      fontWeight={500}
                    >
                      Adicionar consultor
                    </Text>
                  </HStack>
                </Button>
              </VStack>
            )}
          </motion.div>
        )}
      </VStack>

      <Modal
        onClose={() => setShowConfirmationModal(undefined)}
        isOpen={!!showConfirmationModal}
        title={"Remover consultor"}
      >
        {showConfirmationModal?.isLoading ? (
          <Center
            maxW={"568px"}
            minH={"300px"}
            borderTop={"1px solid"}
            borderColor={"Gray.$400"}
          >
            <VStack maxW={"400px"}>
              <MutatingDots
                height="100"
                width="100"
                color="#408EC5"
                secondaryColor="#408EC5"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                visible={true}
              />

              <Text
                mt={"24px !important"}
                fontSize={"L_md"}
                fontFamily={"lato"}
                color={"Gray.$800"}
                fontWeight={400}
                textAlign={"center"}
              >
                Aguarde, estamos removendo esse consultor do projeto.
              </Text>
            </VStack>
          </Center>
        ) : (
          <VStack
            maxW={"568px"}
            borderTop={"1px solid"}
            borderColor={"Gray.$400"}
          >
            <Text
              p={"24px"}
              fontSize={"L_md"}
              fontFamily={"lato"}
              color={"Gray.$800"}
              fontWeight={400}
            >
              Tem certeza que deseja remover este consultor? o consultor será
              removido do quadro de consultorias e todo o histórico será
              perdido.
            </Text>

            <VStack
              px={"24px"}
              py={"24px"}
              spacing={"8px !important"}
              w={"100%"}
              borderTop={"1px solid"}
              borderColor={"Gray.$400"}
            >
              <Button
                w={"100%"}
                variant={"Primary"}
                onClick={handleDeleteConsultant}
              >
                Remover consultor
              </Button>
              <Button
                w={"100%"}
                variant={"Secondary"}
                onClick={() => setShowConfirmationModal(undefined)}
              >
                Voltar
              </Button>
            </VStack>
          </VStack>
        )}
      </Modal>
    </VStack>
  );
};

export default ConsultantsTabProject;
