import { Button, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Props } from "./types";
import { ColorInput, Input } from "semente-js";
import { Modal } from "src/components/base/Modal";
import { Select } from "src/components/base/Select";
import IndicatorTypes from "./common";
import { Indicator } from "src/types";

const IndicatorForm: React.FC<Props> = ({
  handleSubmitIndicator,
  handleClose,
  indicator,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: indicator as Indicator,
  });

  useEffect(() => {
    reset(indicator || {});
  }, [indicator, reset]);

  return (
    <Modal
      title={
        indicator && "id" in indicator ? `Editar Indicador` : `Criar Indicador`
      }
      isOpen={!!indicator}
      onClose={handleClose}
    >
      <VStack
        spacing={"16px"}
        w={"100%"}
        alignItems={"flex-start"}
        px={"24px"}
        borderTop={"1px solid"}
        borderBottom={"1px solid"}
        borderColor={"Gray.$300"}
        py={"24px"}
      >
        <Controller
          name={"name"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <Input
              id="name-field"
              {...field}
              {...(error && { errorMessage: "Este campo é obrigatório" })}
              placeholder={"Nome do indicador"}
            />
          )}
        />

        <Controller
          name={"color"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <ColorInput
              id="color-field"
              label="Cor"
              width={"100%"}
              className="w-full"
              {...field}
              {...(error && { errorMessage: "Este campo é obrigatório" })}
            />
          )}
        />

        <Controller
          name={"type"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <Select
              isInvalid={!!error}
              onChange={(i) => field.onChange(i.value)}
              value={field.value}
              items={IndicatorTypes}
              placeholder={"Tipo de entrada"}
            />
          )}
        />
      </VStack>

      <Button
        m={"24px !important"}
        borderRadius={"8px"}
        variant={"Primary"}
        minH={"48px !important"}
        isLoading={isSubmitting}
        onClick={handleSubmit(handleSubmitIndicator)}
      >
        {indicator && "id" in indicator ? "Salvar" : "Adicionar"}
      </Button>
    </Modal>
  );
};

export default IndicatorForm;
