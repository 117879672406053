export const consultancyFields = {
  challenges_after_program: {
    label: "Desafios pós consultoria",
    icon: "stethoscope",
  },
  end_diagnostic: {
    label: "Diagnóstico final",
    icon: "stethoscope",
  },
  start_diagnostic: {
    label: "Diagnóstico Inicial",
    icon: "stethoscope",
  },
  internal_annotation: {
    label: "Anotações internas",
    icon: "edit",
  },
  start_challenge: {
    label: "Desafios Iniciais",
    icon: "stethoscope",
  },
  company_message: {
    label: "Mensagem para a startup",
    icon: "message",
  },
};
