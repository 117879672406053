import React, { useState } from "react";
import { Props } from "./types";
import { Box, Center, HStack, Text, VStack } from "@chakra-ui/react";
import { PathTabs } from "./common";
import { Button, Icon } from "semente-js";
import { CompanyPath } from "src/types";
import MultiplePath from "./Charts/MultiplePath";
import SinglePath from "./Charts/SinglePath";

const CompanyPaths: React.FC<Props> = ({
  paths,
  handleUpdatePath,
  handleShowPaths,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const tabData = PathTabs[currentTab];

  return (
    <VStack
      w={"full"}
      alignItems={"flex-start"}
      mt={"40px"}
      pt={"40px"}
      borderTop={"1px solid"}
      borderColor={"Gray.$400"}
      spacing={0}
      pb={"40px"}
    >
      <VStack alignItems={"flex-start"} spacing={0} mb={"40px"}>
        <Text
          fontSize={"RH_sm"}
          fontFamily={"Raleway"}
          color={"Gray.$800"}
          fontWeight={700}
        >
          Estágios do Caminho Empreendedor
        </Text>
        <Text fontSize={"L_lg"} color={"Gray.$700"} fontWeight={400}>
          O nome de cada estágio expressa o objetivo da fase que o negócio está
          passando
        </Text>
      </VStack>

      <VStack w={"full"}>
        <HStack
          w={"full"}
          borderBottom={"4px solid"}
          borderColor={"Gray.$400"}
          position={"relative"}
          spacing={0}
        >
          {PathTabs.map((item, index) => (
            <VStack
              w={"full"}
              key={item.value}
              cursor={"pointer"}
              position={"relative"}
              onClick={() => setCurrentTab(index)}
            >
              <Center w={"full"} py={"14px"}>
                <Text fontSize={"L_sm"} color={"Gray.$800"} fontWeight={600}>
                  {item.label}
                </Text>
              </Center>

              {currentTab === index && (
                <Box
                  w={"full"}
                  h={"4px"}
                  position={"absolute"}
                  bottom={"-4px"}
                  bg={"Brand.pure"}
                />
              )}
            </VStack>
          ))}
        </HStack>

        {tabData.hideInUpdate && (
          <HStack w={"full"} mt={"24px"} justify={"space-between"}>
            <VStack alignItems={"flex-start"} spacing={0}>
              <Text
                fontFamily={"Raleway"}
                color={"Gray.$800"}
                fontSize={"RH_xs"}
                fontWeight={700}
              >
                {tabData.label}
              </Text>
              <Text color={"Gray.$700"} fontSize={"L_md"} fontWeight={400}>
                {tabData.description}
              </Text>
            </VStack>

            <HStack>
              {paths.length >= 1 && (
                <Button
                  onClick={handleShowPaths}
                  iconColor="#141414"
                  layout="rounded"
                  iconName="pen-solid"
                  label="Editar"
                  color="gray"
                  size="sm"
                />
              )}

              <Button
                onClick={() => handleUpdatePath({} as CompanyPath)}
                label="Atualizar estágios"
                iconColor="#141414"
                layout="rounded"
                iconName="plus"
                color="gray"
                size="sm"
              />
            </HStack>
          </HStack>
        )}
      </VStack>

      {paths.length >= 1 ? (
        tabData.hideInUpdate ? (
          <MultiplePath paths={paths} />
        ) : (
          <Box w={"full"} mt={"16px"}>
            <SinglePath
              values={paths.map((path) => ({
                date: path.date,
                value: path[tabData.value] as number,
              }))}
            />
          </Box>
        )
      ) : (
        <Center mt={"24px"} w={"full"} my={"44px"}>
          <VStack spacing={0}>
            <Icon name="question-circle" color="#0077CC" size="64px" />

            <Text
              color={"Gray.$800"}
              fontFamily={"Raleway"}
              fontSize={"RH_xs"}
              fontWeight={700}
            >
              Nenhum dado inserido
            </Text>
            <Text
              color={"Gray.$700"}
              fontFamily={"Raleway"}
              fontSize={"L_xs"}
              fontWeight={400}
              mb={"16px"}
            >
              Atualize para visualizar e acompanhar a evolução dos Estágios do
              Caminho Empreendedor
            </Text>

            <Button
              onClick={() => handleUpdatePath({} as CompanyPath)}
              label="Atualizar estágios"
              iconColor="#141414"
              layout="rounded"
              iconName="plus"
              color="gray"
              size="sm"
            />
          </VStack>
        </Center>
      )}
    </VStack>
  );
};

export default CompanyPaths;
