import React, { useContext } from "react";
import { KanbanContext } from "src/contexts/KanbanContext";
import { Kanban } from "src/types";
import { motion } from "framer-motion";
import { Button, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { getColumns } from "src/pages/Kanban/common";
import { useForm, Controller } from "react-hook-form";
import { Props } from "../types";
import Input from "src/components/base/Input/Index";
import QuantitySelector from "src/components/QuantitySelector";
import { ProjectContext } from "src/contexts/ProjectContext";

const GeneralForm: React.FC<Props> = ({ kanbanData }) => {
  const defaultValues = {
    name: kanbanData.name,
    qtd_columns: kanbanData.qtd_columns,
  };

  const { selectProject, currentProject } = useContext(ProjectContext);
  const { updateKanban } = useContext(KanbanContext);
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({ defaultValues });

  const handleUpdateKanban = async (kanban: Partial<Kanban>) => {
    if (currentProject && currentProject.data) {
      await updateKanban(kanban);
      selectProject(currentProject?.data?.id, true);
    }
  };

  const columns = getColumns(watch("qtd_columns").toString());

  return (
    <VStack flex={1} w={"100%"} alignItems={"flex-start"}>
      <Controller
        control={control}
        name={"name"}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            isInvalid={!!error}
            placeholder={"Nome do quadro"}
          />
        )}
      />

      <HStack
        w={"100%"}
        justifyContent={"space-between"}
        my={"32px !important"}
      >
        <VStack w={"100%"} alignItems={"flex-start"}>
          <Text fontWeight={500} fontSize={"L_md"}>
            Quantidade de consultorias
          </Text>
          <Text fontWeight={400} fontSize={"L_sm"} color={"Gray.$700"}>
            Escolha a quantidade de consultorias desse quadro
          </Text>
        </VStack>

        <Controller
          control={control}
          name={"qtd_columns"}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <QuantitySelector onChange={onChange} value={value} />
          )}
        />
      </HStack>

      <VStack
        alignItems={"flex-start"}
        borderRadius={"12px"}
        w={"100%"}
        border={"1px solid"}
        borderColor={"Gray.$400"}
        pl={"16px"}
        spacing={0}
      >
        {columns.map((column, index) => (
          <motion.div
            key={column.id}
            animate={{ scale: 0.98 }}
            initial={{ scale: 1.1 }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Image
              src={column.icon}
              w={"24px"}
              h={"24px"}
              borderRadius={"50%"}
              mr={"12px !important"}
            />
            <HStack
              py={"16px"}
              w={"100%"}
              borderBottom={"1px solid"}
              borderColor={
                columns.length - 1 === index ? "transparent" : "Gray.$400"
              }
              spacing={0}
            >
              <Text
                w={"100%"}
                fontSize={"L_md"}
                fontWeight={500}
                color={"Gray.$800"}
              >
                {column.name}
              </Text>
            </HStack>
          </motion.div>
        ))}
      </VStack>

      <Button
        onClick={handleSubmit(handleUpdateKanban)}
        mt={"32px !important"}
        borderRadius={"8px"}
        variant={"Primary"}
        isLoading={isSubmitting}
      >
        Salvar alterações
      </Button>
    </VStack>
  );
};

export default GeneralForm;
