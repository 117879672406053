import React, { useEffect, useState } from "react";
import { Props } from "./types";
import { Center, useTheme } from "@chakra-ui/react";
import { Icon } from "semente-js";
import { motion } from "framer-motion";

const Checkbox: React.FC<Props> = ({
  isChecked,
  onChange,
  isDisabled,
  defaultChecked,
}) => {
  const [checked, setChecked] = useState(!!(isChecked || defaultChecked));
  const theme = useTheme();

  useEffect(() => {
    setChecked(!!isChecked);
  }, [isChecked]);

  const value = isChecked !== undefined ? isChecked : checked;

  return (
    <Center
      cursor={"pointer"}
      border={"2px solid"}
      aria-label="Checkbox"
      transition={"100ms all"}
      borderColor={
        value
          ? isDisabled
            ? "Gray.$200"
            : "Brand.pure"
          : isDisabled
          ? "Gray.$300"
          : "Gray.$300"
      }
      borderRadius={"4px"}
      bg={
        value
          ? isDisabled
            ? "Gray.$200"
            : "Brand.pure"
          : isDisabled
          ? "Gray.$200"
          : "Gray.$200"
      }
      onClick={() => !isDisabled && onChange && onChange(!value)}
      w={"20px"}
      h={"20px"}
    >
      {value && (
        <motion.div
          initial={{ scale: 0.7 }}
          animate={{ scale: 1 }}
          transition={{ velocity: 2.5 }}
        >
          <Icon
            name="check"
            color={isDisabled ? theme.colors.Gray.$400 : theme.colors.Gray.$100}
            size="16px"
          />
        </motion.div>
      )}
    </Center>
  );
};

export default Checkbox;
