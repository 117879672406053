import React from "react";
import { Props } from "./types";
import { HStack } from "@chakra-ui/react";
import { Button } from "semente-js";

const SaveBar: React.FC<Props> = ({ handleCancel, handleSave, isLoading }) => {
  return (
    <HStack
      px={"24px"}
      py={"16px"}
      position={"fixed"}
      width={"100%"}
      bg={"white"}
      bottom={0}
      left={0}
      justifyContent={"flex-end"}
      borderTop={"1px solid"}
      borderTopColor={"Gray.$300"}
    >
      <HStack spacing={"16px"}>
        <Button label="Cancelar edições" color="gray" onClick={handleCancel} />
        <Button
          iconName="save"
          iconColor="white"
          label="Salvar edições"
          color="primary"
          onClick={handleSave}
          isLoading={isLoading}
        />
      </HStack>
    </HStack>
  );
};

export default SaveBar;
