import { SampleSpace, Stage, Test } from "../../types";
import axios from "../index";

const TestService = {
  create: async (test: Test): Promise<void> =>
    (await axios.post("test", test)).data,

  update: async (test_id: Test["id"], newTest: Partial<Test>): Promise<Test> =>
    (await axios.put(`test/${test_id}`, newTest)).data,

  delete: async (test_id: Test["id"]): Promise<Test> =>
    (await axios.delete(`test/${test_id}`)).data,

  findById: async (
    test_id: Test["id"]
  ): Promise<Test & { sample_spaces: SampleSpace[]; stages: Stage[] }> =>
    (await axios.get(`test/${test_id}`)).data,
};

export default TestService;
