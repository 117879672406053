import React from "react";
import { Props } from "./types";
import { Center, Grid, Image, Text, VStack } from "@chakra-ui/react";
import Icon from "src/components/base/Icon";

const MessageViewer: React.FC<Props> = ({ message }) => {
  return (
    <VStack
      alignItems={"flex-start"}
      w={"100%"}
      h={"100%"}
      px={"24px"}
      overflow={"auto"}
      spacing={0}
    >
      <Text fontWeight={600} fontSize={"RH_sm"} fontFamily={"Raleway"}>
        {message.title}
      </Text>
      <Text
        mt={"16px"}
        color={"Gray.$700"}
        fontWeight={400}
        fontSize={"L_md"}
        dangerouslySetInnerHTML={{ __html: message.message }}
      />

      <VStack
        mt={"16px"}
        borderTop={"1px solid"}
        borderColor={"Gray.$300"}
        w={"100%"}
        alignItems={"flex-start"}
      >
        <Text
          mt={"16px"}
          fontWeight={600}
          fontSize={"RH_sm"}
          fontFamily={"Raleway"}
        >
          {message.images.length} Anexos
        </Text>
        <Grid
          mt={"16px"}
          w={"100%"}
          gridTemplateColumns={"1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"}
          gap={"8px"}
        >
          {message.images.map((image) => (
            <Center
              w={"48px"}
              h={"48px"}
              borderRadius={"8px"}
              background={"gray.200"}
              cursor={"pointer"}
              position={"relative"}
              boxShadow={
                "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px"
              }
            >
              {image.Key.includes("heic") ? (
                <Icon name="image" />
              ) : (
                <Image
                  src={`https://${
                    process.env.REACT_APP_SPACES_NAME
                  }.sfo3.digitaloceanspaces.com/${(image as any).Key}`}
                  w={"100%"}
                  h={"100%"}
                  borderRadius={"8px"}
                  objectFit={"cover"}
                />
              )}
            </Center>
          ))}
        </Grid>
      </VStack>
    </VStack>
  );
};

export default MessageViewer;
