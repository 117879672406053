import {
  Button,
  HStack,
  Stack,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { ActionsStage } from "../../common";
import Icon from "../base/Icon";

import { StyledFunnel } from "./styles";
import { Props } from "./types";

const StagesViewer: React.FC<Props> = ({
  stages,
  onCreateStage,
  onUpdateStage,
}) => {
  const theme: any = useTheme();

  return (
    <VStack w={"100%"} alignItems={"flex-start"} margin={"0px !important"}>
      {stages.map((item, index) => (
        <VStack key={index} w={"100%"}>
          <HStack
            w={"100%"}
            alignItems={"center"}
            py={"18px !important"}
            position={"relative"}
            onClick={() => onUpdateStage(item)}
            cursor={"pointer"}
          >
            <VStack
              w={"32px"}
              h={"32px"}
              spacing={"4px"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <StyledFunnel
                width={"100%"}
                backgroudColor={
                  index !== stages?.length - 1 && index === 0
                    ? theme.colors.Red.pure
                    : theme.colors.Gray.$400
                }
              />
              <StyledFunnel
                width={"66%"}
                backgroudColor={
                  index < stages?.length - 1 && index >= 1
                    ? theme.colors.Yellow.pure
                    : theme.colors.Gray.$400
                }
              />
              <StyledFunnel
                width={"33%"}
                backgroudColor={
                  index === stages?.length - 1
                    ? theme.colors.Green.pure
                    : theme.colors.Gray.$400
                }
              />
            </VStack>

            <HStack w={"100%"} ml={"20px !important"}>
              <VStack
                w={"100%"}
                alignItems={"flex-start"}
                spacing={"4px !important"}
              >
                <Text fontWeight={600} color={"Gray.$800"} fontSize={"L_md"}>
                  {item.name
                    ? item.priority === 0
                      ? "Meta a alcançar"
                      : `Passo ${index + 1} - ${
                          Object.keys(ActionsStage)[index]
                        }`
                    : item.indicator
                    ? "Meta a alcançar"
                    : "Crie o primeiro passo para alcançar sua meta de vendas"}
                </Text>

                {item.indicator && (
                  <Text fontWeight={400} color={"Gray.$700"} fontSize={"L_sm"}>
                    {item.name} {item.indicator} {item.description}
                  </Text>
                )}
              </VStack>

              {item.indicator && (
                <Icon name={"pen"} color={theme.colors.Gray.$600} />
              )}
            </HStack>
            <Stack
              width={"91%"}
              h={"1px"}
              background={
                index === stages.length - 1 ? "transparent" : "Gray.$400"
              }
              position={"absolute"}
              bottom={0}
              right={0}
            />
          </HStack>

          {item.priority !== undefined &&
          item.priority !== 0 &&
          index === stages.length - 2 &&
          stages.length < 6 ? (
            <VStack
              w={"100%"}
              alignItems={"flex-end"}
              margin={"0px !important"}
            >
              <VStack
                w={"91%"}
                pb={"16px"}
                pt={"16px"}
                borderBottom={"1px solid"}
                borderColor={"Gray.$400"}
                alignItems={"flex-start"}
              >
                <Button variant={"Secondary"} onClick={() => onCreateStage()}>
                  <HStack spacing={"8px !important"}>
                    <Icon name={"plus"} />
                    <Text>Adicionar novo passo</Text>
                  </HStack>
                </Button>
              </VStack>
            </VStack>
          ) : (
            <></>
          )}
        </VStack>
      ))}
    </VStack>
  );
};

export default StagesViewer;
