import { Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Button } from "semente-js";
import { Modal } from "src/components/base/Modal";
import { Props } from "./types";

const NavigationBlockerModal: React.FC<Props> = ({
  handleCancel,
  handleContinue,
}) => {
  return (
    <Modal isOpen={true} onClose={handleCancel} title={"Cancelar edições"}>
      <VStack
        spacing={"16px"}
        w={"100%"}
        alignItems={"flex-start"}
        px={"24px"}
        borderTop={"1px solid"}
        borderBottom={"1px solid"}
        borderColor={"Gray.$300"}
        py={"24px"}
      >
        <Text fontSize={"LMD"} fontWeight={"400"}>
          Tem certeza que deseja sair da página? Você possui alterações não
          salvas
        </Text>
      </VStack>

      <VStack px={"16px"} py={"24px"}>
        <Button
          label="Voltar para as edições"
          onClick={handleCancel}
          color="primary"
          className="w-full"
        />
        <Button
          label="Sair da página"
          onClick={handleContinue}
          color="gray"
          className="w-full"
        />
      </VStack>
    </Modal>
  );
};

export default NavigationBlockerModal;
