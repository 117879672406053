import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { MutatingDots } from "react-loader-spinner";
import { defaultUserImage, logo, signupArt } from "src/assets/images";
import { fadeInUp } from "src/animations";
import InviteService from "src/services/invite";
import { Invite, User } from "src/types";
import Input from "src/components/base/Input/Index";
import ProfilePic from "src/components/ProfilePic";
import { toast } from "react-toastify";
import { UserContext } from "src/contexts/UserContext";
import { uploadFileToBucket } from "src/utils";

const defaultValues = {
  surname: "",
  password: "",
  image_url: "",
} as User & { hash: Invite["hash"] };

const errors = {
  ProjectNotFound: (
    <VStack>
      <Text
        fontFamily={"raleway"}
        fontSize={"RH_md"}
        fontWeight={600}
        color={"black"}
      >
        Oops, o projeto no qual você foi convidado não está mais disponível.
      </Text>
      <Text
        fontSize={"L_md"}
        fontWeight={400}
        color={"Gray.$700"}
        mt={"24px !important"}
      >
        Tente entrar em contato com o gestor de carteira responsável.
      </Text>
    </VStack>
  ),
  HashNotFound: (
    <VStack>
      <Text
        fontFamily={"raleway"}
        fontSize={"RH_md"}
        fontWeight={600}
        color={"black"}
      >
        Oops, aparentemente seu convite não é válido ou já foi utilizado.
      </Text>
      <Text
        fontSize={"L_md"}
        fontWeight={400}
        color={"Gray.$700"}
        mt={"24px !important"}
      >
        Tente entrar em contato com o gestor de carteira responsável.
      </Text>
    </VStack>
  ),
  HashToCompany: (
    <VStack>
      <Text
        fontFamily={"raleway"}
        fontSize={"RH_md"}
        fontWeight={600}
        color={"black"}
      >
        Oops, este convite foi criado para uma empresa.
      </Text>
      <Text
        fontSize={"L_md"}
        fontWeight={400}
        color={"Gray.$700"}
        mt={"24px !important"}
      >
        Esta página existe unicamente para criação de usuários da plataforma de
        administração da Rama.
      </Text>
    </VStack>
  ),
};

const SignUp = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [ErrorComponent, setErrorComponent] = useState<JSX.Element>();
  const { reset, control, handleSubmit, setValue } = useForm({ defaultValues });
  const { handleSignUp } = useContext(UserContext);
  const navigate = useNavigate();
  const { hash } = useParams();

  useEffect(() => {
    (async () => {
      if (hash) {
        try {
          const data = await InviteService.findByHash(hash);

          if (data.company_id) {
            setErrorComponent(errors["HashToCompany"]);
            return;
          }

          reset({
            email: data.email,
            hash: hash,
          });
        } catch (err: any) {
          if (err.toString().includes("403")) {
            setErrorComponent(errors["HashNotFound"]);
          } else {
            setErrorComponent(errors["ProjectNotFound"]);
          }
        }
        setIsLoading(false);
      } else {
        navigate("/");
      }
    })();
  }, [hash, navigate, reset]);

  const handleChangeImage = async (file: File) => {
    if (file && hash) {
      try {
        const params = {
          folderName: "user_invites",
          id: hash,
        };
        const image_url = await uploadFileToBucket(file, params);
        setValue("image_url", image_url);
      } catch (err) {
        toast.error("Erro ao atualizar o projeto.");
      }
    }
  };

  const handleSubmitForm: SubmitHandler<typeof defaultValues> = async (
    values
  ) => {
    try {
      setIsLoading(true);
      await handleSignUp({ ...values });
      setIsLoading(false);
    } catch (err) {
      toast.error("Ocorreu um erro ao registrar seu usuário");
    }
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit={{ opacity: 0 }}
      style={{ width: "100%", height: "100%", margin: "0px" }}
    >
      <HStack height={"100vh"} width={"100vw"} position={"relative"}>
        <Center width={"50%"} height={"100%"}>
          {!isLoading ? (
            <motion.div
              variants={fadeInUp}
              style={{ width: "100%", maxWidth: "400px" }}
            >
              {ErrorComponent || (
                <VStack w={"100%"} alignItems={"flex-start"}>
                  <Text
                    fontFamily={"raleway"}
                    fontSize={"RH_md"}
                    fontWeight={600}
                    color={"black"}
                  >
                    Crie sua conta
                  </Text>

                  <VStack
                    mt={"24px !important"}
                    spacing={"16px"}
                    w={"100%"}
                    alignItems={"left"}
                  >
                    <Controller
                      control={control}
                      name={"image_url"}
                      render={({ field: { value, onChange } }) => (
                        <ProfilePic
                          imageUrl={value}
                          defaultImage={defaultUserImage}
                          onRemoveImage={() => onChange(undefined)}
                          onSelectImage={handleChangeImage}
                        />
                      )}
                    />

                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name={"name"}
                      render={({ field, fieldState: { error } }) => (
                        <Box mt={"32px !important"}>
                          <Input
                            {...field}
                            placeholder={"Primeiro nome"}
                            isInvalid={!!error}
                            onKeyDown={({ key }) =>
                              key === "Enter" &&
                              handleSubmit(handleSubmitForm)()
                            }
                          />
                        </Box>
                      )}
                    />

                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name={"surname"}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          placeholder={"Sobrenome"}
                          isInvalid={!!error}
                          onKeyDown={({ key }) =>
                            key === "Enter" && handleSubmit(handleSubmitForm)()
                          }
                        />
                      )}
                    />

                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name={"email"}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          placeholder={"E-mail"}
                          isInvalid={!!error}
                          isDisabled
                          onKeyDown={({ key }) =>
                            key === "Enter" && handleSubmit(handleSubmitForm)()
                          }
                        />
                      )}
                    />

                    <Controller
                      rules={{ required: true, min: 8 }}
                      control={control}
                      name={"password"}
                      render={({ field, fieldState: { error } }) => (
                        <VStack alignItems={"flex-start"}>
                          <Input
                            {...field}
                            placeholder={"Senha"}
                            type={"password"}
                            isInvalid={!!error}
                            onKeyDown={({ key }) =>
                              key === "Enter" &&
                              handleSubmit(handleSubmitForm)()
                            }
                          />
                          <Text fontSize={"L_sm"} color={"Gray.$700"}>
                            Utilize pelo menos 8 caracteres.
                          </Text>
                        </VStack>
                      )}
                    />
                  </VStack>
                  <Button
                    onClick={handleSubmit(handleSubmitForm)}
                    fontFamily={"heading"}
                    mt={"40px !important"}
                    variant={"Primary"}
                    fontWeight={300}
                    fontSize={"L_lg"}
                    w={"100%"}
                  >
                    Criar conta
                  </Button>
                </VStack>
              )}
            </motion.div>
          ) : (
            <MutatingDots
              height="100"
              width="100"
              color="#408EC5"
              secondaryColor="#408EC5"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              visible={true}
            />
          )}
        </Center>
        <Box width={"50%"} height={"100%"} background={"Tertiary.dark"}>
          <Image
            src={signupArt}
            alt={"Imagem com pessoas ao redor de uma mesa"}
            width={"100%"}
            height={"100%"}
            objectFit={"cover"}
          />
        </Box>

        <Image
          position={"absolute"}
          src={logo}
          alt={"Logo Semente Negócios."}
          top={"24px"}
          left={"24px"}
          zIndex={2}
        />
      </HStack>
    </motion.div>
  );
};

export default SignUp;
