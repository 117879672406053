import { Center, HStack, Input } from "@chakra-ui/react";
import React from "react";
import Icon from "../base/Icon";
import { Props } from "./types";

const QuantitySelector: React.FC<Props> = ({ onChange, value }) => {
  return (
    <HStack h={"32px"} spacing={"8px !important"}>
      <Center
        w={"32px"}
        h={"100%"}
        cursor={"pointer"}
        borderRadius={"50%"}
        background={"Gray.$300"}
        {...(value && value >= 1 && { onClick: () => onChange(value - 1) })}
      >
        <Icon name={"minus"} />
      </Center>

      <Input
        h={"100%"}
        border={"1px solid"}
        w={"52px"}
        borderColor={"Gray.$300"}
        borderRadius={"500px"}
        fontWeight={"600"}
        fontSize={"L_md"}
        transition={"300ms all"}
        value={value || 0}
        textAlign={"center"}
        onChange={(e) => {
          if (parseInt(e.target.value) >= 1) {
            onChange(parseInt(e.target.value));
          }
        }}
      />

      <Center
        w={"32px"}
        h={"100%"}
        cursor={"pointer"}
        borderRadius={"50%"}
        background={"Gray.$300"}
        onClick={() => onChange((value || 0) + 1)}
      >
        <Icon name={"plus"} />
      </Center>
    </HStack>
  );
};

export default QuantitySelector;
