import { Client } from "src/types";

export type FieldsDataProps = {
  [key in keyof Partial<Client>]: {
    error: boolean;
    loaded: boolean;
    data?: Array<{ name: string; id: string; image_url?: string }>;
  };
};

export const defaultFieldsData: FieldsDataProps = {
  name: {
    error: false,
    loaded: false,
    data: undefined,
  },
  cnpj: {
    error: false,
    loaded: false,
    data: undefined,
  },
  type: {
    error: false,
    loaded: false,
    data: undefined,
  },
};

export const getFieldsData = async (): Promise<typeof defaultFieldsData> => {
  const currentData: any = defaultFieldsData;

  await Promise.all(
    (
      Object.keys(defaultFieldsData) as Array<keyof typeof defaultFieldsData>
    ).map(async (key) => {
      currentData[key].data = {};
      currentData[key].loaded = true;
      currentData[key].error = false;
    })
  );
  return currentData;
};
