import { Button, Image, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { doneSuperIcon } from "../../../../assets/images";
import { motion } from "framer-motion";
import { Props } from "./types";
import CompanyAdd from "./CompanyAdd";
import CompanyInvite from "./CompanyInvite";

const ProjectCompanyInvite: React.FC<Props> = ({
  project,
  onClose,
  defaultAdd,
}) => {
  const [inviteCompany, setInviteCompany] = useState(defaultAdd ? true : false);
  const [dialog, setDialog] = useState<React.ReactNode>();

  const inviteOptions = [
    {
      title: "Adicionar empresas já existentes",
      subtitle: "Qualquer empresa que já se cadastrou no app Rama Semente",
      onClick: () =>
        setDialog(
          <CompanyAdd
            project={project}
            handleFinish={onClose}
            handleClose={() => setDialog(undefined)}
          />
        ),
    },
    {
      title: "Convidar novas empresas",
      subtitle:
        "Enviar convites para novas empresas se cadastrarem no app Rama Semente",
      onClick: () =>
        setDialog(
          <CompanyInvite
            project={project}
            handleFinish={onClose}
            handleClose={() => setDialog(undefined)}
          />
        ),
    },
  ];

  return (
    <>
      {dialog}
      <VStack
        w={"100%"}
        maxW={"568px"}
        borderTop={"1px solid"}
        borderColor={"Gray.$400"}
      >
        {inviteCompany ? (
          <VStack w={"100%"} p={"24px"} alignItems={"flex-start"}>
            <Text
              fontWeight={600}
              fontSize={"RH_xs"}
              fontFamily={"Raleway"}
              color={"Gray.$800"}
            >
              Como você quer adicionar as empresas?
            </Text>
            <Text
              fontWeight={400}
              fontSize={"L_sm"}
              color={"Gray.$700"}
              mb={"24px !important"}
            >
              Selecione como você quer adicionar empresas ao projeto{" "}
              {project.name}
            </Text>
            <VStack spacing={"18px !important"} w={"100%"}>
              {inviteOptions.map((option, index) => (
                <motion.div
                  key={index}
                  style={{ width: "100%" }}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.99 }}
                >
                  <VStack
                    w={"100%"}
                    p={"24px"}
                    key={index}
                    cursor={"pointer"}
                    border={"1px solid"}
                    alignItems={"flex-start"}
                    borderRadius={"12px"}
                    borderColor={"Gray.$400"}
                    onClick={option.onClick}
                  >
                    <Text fontWeight={400} color={"Gray.$800"}>
                      {option.title}
                    </Text>
                    <Text
                      mt={"4px !impotant"}
                      fontWeight={400}
                      color={"Gray.$700"}
                    >
                      {option.subtitle}
                    </Text>
                  </VStack>
                </motion.div>
              ))}
            </VStack>
          </VStack>
        ) : (
          <>
            <VStack p={"24px"} w={"100%"} alignItems={"flex-start"}>
              <Image src={doneSuperIcon} alt={"Ícone de sucesso"} />

              <Text
                mt={"24px !important"}
                fontWeight={600}
                fontSize={"RH_lg"}
                fontFamily={"Raleway"}
              >
                Projeto criado com sucesso!
              </Text>
              <Text
                fontWeight={400}
                fontSize={"L_md"}
                color={"Gray.$700"}
                mt={"4px !important"}
              >
                Agora que seu projeto foi criado, que tal convidar as empresas
                que fazem parte do projeto? Envie convites para que os
                representantes das empresas baixem o aplicativo da Rama Semente
                e façam o cadastro.
              </Text>
            </VStack>

            <VStack
              w={"100%"}
              spacing={"8px"}
              p={"16px 24px"}
              borderTop={"1px solid"}
              borderColor={"Gray.$400"}
            >
              <Button
                variant={"Primary"}
                w={"100%"}
                onClick={() => setInviteCompany(true)}
              >
                Convidar empresas
              </Button>
              <Button onClick={onClose} w={"100%"}>
                Continuar para o projeto
              </Button>
            </VStack>
          </>
        )}
      </VStack>
    </>
  );
};

export default ProjectCompanyInvite;
