import _axios from "../../..";
import { BusinessModel, Company } from "../../../../types";
import { RemoveCompanyProfileProps } from "../tyoes";

const BusinessModelService = {
  getAll: async (): Promise<BusinessModel[]> =>
    (await _axios.get("business-model")).data,

  create: async (body: string[], company_id: Company["id"]): Promise<void> =>
    (await _axios.post(`business-model/${company_id}`, { business_ids: body }))
      .data,

  remove: async ({
    id,
    company_id,
    company_profile_id,
  }: RemoveCompanyProfileProps): Promise<void> =>
    await _axios.delete(`business-model/relation${id ? `/${id}` : ""}`, {
      data: { company_id, company_profile_id },
    }),
};

export default BusinessModelService;
