import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { MutatingDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Icon from "src/components/base/Icon";
import { ClientContext } from "src/contexts/ClientContext";
import { Project } from "src/types";
import ProjectsService from "src/services/projects";
import { toast } from "react-toastify";
import { ClientDialogProps } from "src/contexts/ClientContext/const";
import Checkbox from "src/components/Checkbox";

const defaultValues = {
  projects: [] as string[],
};

const AddInProject: React.FC<ClientDialogProps> = ({ client, onClose }) => {
  const { handleInsertInProjects } = useContext(ClientContext);
  const [projects, setProjects] = useState({
    isLoading: true,
    data: undefined as undefined | Project[],
  });

  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ defaultValues });

  const refreshProjects = useCallback(async () => {
    try {
      setProjects({
        isLoading: false,
        data: (await ProjectsService.getProjects()).result,
      });
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar os projetos.");
    }
  }, []);

  const submitHandler: SubmitHandler<typeof defaultValues> = async ({
    projects,
  }) => {
    await handleInsertInProjects(projects, client.id);
    onClose();
  };

  useEffect(() => {
    refreshProjects();
  }, [refreshProjects]);

  return (
    <VStack borderTop={"1px solid"} borderColor={"Gray.$400"} w={"100%"}>
      {projects.isLoading || isSubmitting ? (
        <Center py={"64px !important"}>
          <MutatingDots
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <VStack maxH={"500px"} overflow={"auto"} w={"100%"}>
          {projects.data && projects.data?.length >= 1 ? (
            <VStack w={"100%"}>
              <Controller
                control={control}
                name={"projects"}
                render={({ field: { value, onChange } }) => (
                  <VStack p={"24px"} w={"100%"} spacing={0}>
                    {projects.data?.map((project, index) => (
                      <HStack
                        cursor={"pointer"}
                        key={project.id}
                        w={"100%"}
                        py={"16px"}
                        onClick={() => {
                          if (value.includes(project.id)) {
                            onChange(
                              value.filter((value) => value !== project.id)
                            );
                          } else {
                            onChange([...value, project.id]);
                          }
                        }}
                      >
                        <Stack
                          width="36px"
                          height="36px"
                          overflow="hidden"
                          borderRadius="60px"
                          alignItems="center"
                          background={
                            project.image_url ? "transparent" : "#E3E5E8"
                          }
                          justifyContent="center"
                        >
                          {project.image_url ? (
                            <Image
                              src={project.image_url}
                              width="100%"
                              height="100%"
                              borderRadius="60px"
                              objectFit={"cover"}
                            />
                          ) : (
                            <Icon name={"image"} size={"18px"} />
                          )}
                        </Stack>

                        <VStack flex={1} position={"relative"}>
                          <HStack w={"100%"} justifyContent={"space-between"}>
                            <Text>{project.name}</Text>

                            <Checkbox isChecked={value.includes(project.id)} />
                          </HStack>

                          {projects.data &&
                            projects.data?.length - 1 !== index && (
                              <Box
                                position={"absolute"}
                                w={"100%"}
                                bottom={"-18px"}
                                background={"Gray.$400"}
                                h={"1px"}
                              />
                            )}
                        </VStack>
                      </HStack>
                    ))}
                  </VStack>
                )}
              />
              <Box
                w={"100%"}
                padding={"16px 24px"}
                borderTop={"1px solid"}
                borderColor={"Gray.$400"}
              >
                <Button
                  onClick={handleSubmit(submitHandler)}
                  w={"100%"}
                  variant={"Primary"}
                >
                  Adicionar ao projeto
                </Button>
              </Box>
            </VStack>
          ) : (
            <Center py={"64px"}>
              <VStack>
                <Text
                  fontWeight={600}
                  fontFamily={"Raleway"}
                  textAlign={"center"}
                  fontSize={"L_MD"}
                >
                  Ainda não existem projetos cadastrados
                </Text>
                <Text
                  mt={"8px !important"}
                  textAlign={"center"}
                  fontWeight={400}
                  color={"Gray.$700"}
                  fontSize={"L_sm"}
                  mb={"16px !important"}
                >
                  Tente cadastrar novos projetos
                </Text>

                <Button minH={"36px"} onClick={() => navigate("/projects")}>
                  Criar novos projetos
                </Button>
              </VStack>
            </Center>
          )}
        </VStack>
      )}
    </VStack>
  );
};

export default AddInProject;
