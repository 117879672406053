import { Center, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MutatingDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import useDeviceDetector from "src/hooks/useDeviceDetector";
import CompanyService from "src/services/company";
import { Company, User } from "src/types";
import FinishedPage from "../CompanySignUp/Forms/FinishedPage";
import UserForm from "../CompanySignUp/Forms/User";

const defaultValues = {} as User & { company: Company };

const errors = {
  InvalidCompany: (
    <VStack>
      <Text
        fontFamily={"raleway"}
        fontSize={"RH_md"}
        fontWeight={600}
        color={"black"}
      >
        Oops, a empresa relacionada a este convite não está disponível.
      </Text>
      <Text
        fontSize={"L_md"}
        fontWeight={400}
        color={"Gray.$700"}
        mt={"24px !important"}
      >
        Tente entrar em contato com a pessoa responsável por convidar.
      </Text>
    </VStack>
  ),
};

const EmployeeSignUp: React.FC = () => {
  const [ErrorComponent, setErrorComponent] = useState<JSX.Element>();
  const [FormComponent, setFormComponent] = useState<JSX.Element>(<></>);
  const { reset, getValues } = useForm({
    defaultValues,
  });
  const [isLoading, setIsLoading] = useState(true);
  const { isMobile } = useDeviceDetector();
  const navigate = useNavigate();
  const { companyId } = useParams();

  useEffect(() => {
    (async () => {
      if (companyId) {
        try {
          await CompanyService.checkIfExistsById(companyId);

          setFormComponent(
            <UserForm
              isEmployee
              defaultValues={
                { ...getValues(), company: { id: companyId } } as User & {
                  company: Company;
                }
              }
              handleSubmitForm={() =>
                setFormComponent(<FinishedPage isEmployee />)
              }
            />
          );
        } catch (err: any) {
          setErrorComponent(errors["InvalidCompany"]);
        }
        setIsLoading(false);
      } else {
        navigate("/");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, navigate, reset, getValues]);

  const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return isMobile ? (
      <VStack flex={1} p={"16px"} h={"100vh"}>
        {children}
      </VStack>
    ) : (
      <Center background={"#FAFAFA"} h={"100%"} height={"100vh"}>
        <VStack
          overflow={"auto"}
          background={"white"}
          boxShadow={"0px 0px 10px rgba(29, 34, 31, 0.1)"}
          w={"448px"}
          borderRadius={"16px"}
          h={"90vh"}
          p={"16px"}
        >
          {children}
        </VStack>
      </Center>
    );
  };

  return isLoading ? (
    <Center flex={1} h={"100vh"}>
      <MutatingDots
        height="100"
        width="100"
        color="#408EC5"
        secondaryColor="#408EC5"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        visible={true}
      />
    </Center>
  ) : (
    <>
      {ErrorComponent ? (
        <Center flex={1} h={"100vh"}>
          {ErrorComponent}
        </Center>
      ) : (
        <Wrapper>{FormComponent}</Wrapper>
      )}
    </>
  );
};

export default EmployeeSignUp;
