import * as ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import "semente-js/styles.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Router } from "./router";
import { SementeAuthProvider } from "semente-js";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <SementeAuthProvider
    realm={"semente"}
    clientId={"rama-web"}
    hubUrl={process.env.REACT_APP_HUB_URL || ""}
    keycloakUrl={process.env.REACT_APP_KEYCLOAK_URL || ""}
  >
    <ChakraProvider theme={theme}>
      <Router />
    </ChakraProvider>
  </SementeAuthProvider>
);

serviceWorker.unregister();
reportWebVitals();

console.log("RAMA VERSION: 1.3.0");
