import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "src/contexts/ProjectContext";
import SelectProject from "../SelectProject";
import { Center } from "@chakra-ui/react";
import { MutatingDots } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { KanbanContext } from "src/contexts/KanbanContext";

export type ProjectViewProps = {
  currentTabIn: number;
  setCurrentTabIn: (index: number) => void;
};

export const ProjectViewContext = React.createContext<ProjectViewProps>(
  {} as ProjectViewProps
);

interface Props {
  children: React.ReactNode;
}

const ProjectViewContextProvider = ({ children }: Props) => {
  const [currentTabIn, setCurrentTabIn] = useState(0);
  const { kanbanData } = useContext(KanbanContext);
  const { currentProject, selectProject } = useContext(ProjectContext);
  const { projectId } = useParams();

  useEffect(() => {
    if (projectId) {
      selectProject(projectId);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (!!kanbanData.data) {
      setCurrentTabIn(1);

      return;
    }
  }, [kanbanData.data]);

  return (
    <ProjectViewContext.Provider value={{ currentTabIn, setCurrentTabIn }}>
      {currentProject.isLoading && !!currentProject.project_id ? (
        <Center w={"100%"} h={"100%"}>
          <MutatingDots
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <>{!currentProject.data ? <SelectProject /> : children}</>
      )}
    </ProjectViewContext.Provider>
  );
};

export const useProjectViewContext = () => {
  const context = useContext(ProjectViewContext);

  if (context) return context;

  throw new Error(
    "useProjectContext must be used within a ProjectViewContextProvider."
  );
};

export default ProjectViewContextProvider;
