import _axios from "src/services";
import { ChecklistAnswer } from "src/types";

export const ChecklistAnswerService = {
  create: async (
    newChecklistAnswer: Omit<ChecklistAnswer, "id">
  ): Promise<ChecklistAnswer> =>
    (await _axios.post(`/checklist/answer`, newChecklistAnswer)).data,

  delete: async (id: ChecklistAnswer["id"]): Promise<void> =>
    (await _axios.delete(`/checklist/answer/${id}`)).data,
};

export default ChecklistAnswerService;
