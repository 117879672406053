import { Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { Props } from "./types";
import ConsultantAdd from "./ConsultantAdd";
import ConsultantInvite from "./ConsultantInvite";

const ProjectConsultantInvite: React.FC<Props> = ({ project, onClose }) => {
  const [dialog, setDialog] = useState<React.ReactNode>();

  const inviteOptions = [
    {
      title: "Adicionar pessoas consultoras já existentes",
      subtitle: "Qualquer empresa que já está cadastrada na plataforma",
      onClick: () =>
        setDialog(
          <ConsultantAdd
            project={project}
            handleFinish={onClose}
            handleClose={() => setDialog(undefined)}
          />
        ),
    },
    {
      title: "Convidar novas pessoas consultoras",
      subtitle:
        "Enviar convites para novas pessoas consultoras se cadastrarem na plataforma",
      onClick: () =>
        setDialog(
          <ConsultantInvite
            project={project}
            handleFinish={onClose}
            handleClose={() => setDialog(undefined)}
          />
        ),
    },
  ];

  return (
    <>
      {dialog}
      <VStack
        w={"100%"}
        maxW={"568px"}
        borderTop={"1px solid"}
        borderColor={"Gray.$400"}
      >
        <VStack w={"100%"} p={"24px"} alignItems={"flex-start"}>
          <Text
            fontWeight={600}
            fontSize={"RH_xs"}
            fontFamily={"Raleway"}
            color={"Gray.$800"}
          >
            Como você quer adicionar as pessoas consultoras?
          </Text>
          <Text
            fontWeight={400}
            fontSize={"L_sm"}
            color={"Gray.$700"}
            mb={"24px !important"}
          >
            Selecione como você quer adicionar pessoas consultoras ao projeto{" "}
            {project.name}
          </Text>
          <VStack spacing={"18px !important"} w={"100%"}>
            {inviteOptions.map((option, index) => (
              <motion.div
                key={index}
                style={{ width: "100%" }}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.99 }}
              >
                <VStack
                  w={"100%"}
                  p={"24px"}
                  key={index}
                  cursor={"pointer"}
                  border={"1px solid"}
                  alignItems={"flex-start"}
                  borderRadius={"12px"}
                  borderColor={"Gray.$400"}
                  onClick={option.onClick}
                >
                  <Text fontWeight={400} color={"Gray.$800"}>
                    {option.title}
                  </Text>
                  <Text
                    mt={"4px !impotant"}
                    fontWeight={400}
                    color={"Gray.$700"}
                  >
                    {option.subtitle}
                  </Text>
                </VStack>
              </motion.div>
            ))}
          </VStack>
        </VStack>
      </VStack>
    </>
  );
};

export default ProjectConsultantInvite;
