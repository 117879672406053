import { Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { doneSuperIcon } from "src/assets/images";
import { Props } from "./types";

const FinishedPage: React.FC<Props> = ({ isEmployee }) => {
  return (
    <VStack w={"100%"} alignItems={"flex-start"} mt={"72px !important"}>
      <Image src={doneSuperIcon} alt={"Imagem de um foguete"} />
      <Text
        fontWeight={600}
        fontSize={"RH_lg"}
        mt={"24px !important"}
        fontFamily={"Raleway"}
      >
        Tudo pronto!
      </Text>
      <Text
        mt={"4px !important"}
        fontWeight={400}
        fontSize={"L_md"}
        color={"Gray.$700"}
      >
        {isEmployee
          ? "Seu perfil foi criado com sucesso. Esperamos que você aproveite a sua jornada empreendedora conosco e desenvolva ainda mais o seu time e o seu negócio!"
          : "O perfil da sua empresa foi criado com sucesso. Esperamos que você aproveite a sua jornada empreendedora conosco e desenvolva ainda mais o seu time e o seu negócio!"}
      </Text>

      {/*     <Button mt={"40px !important"} variant={"Primary"} w={"100%"}>
        Baixar Rama para iOS
      </Button> */}
    </VStack>
  );
};

export default FinishedPage;
