import { HStack, Text, useTheme, VStack } from "@chakra-ui/react";
import React from "react";
import Icon from "src/components/base/Icon";
import { Props } from "./types";

const Navbar: React.FC<Props> = ({ availableTabs, activeOption, onClick }) => {
  const theme = useTheme();

  return (
    <VStack spacing={"0"} minW={"290px"}>
      {availableTabs.map((opt) => {
        const isActive = activeOption === opt.value;

        return (
          <HStack
            spacing={"16px !important"}
            w={"100%"}
            cursor={"pointer"}
            p={"14px 16px"}
            borderRadius={"8px"}
            background={isActive ? "rgba(64, 142, 197,0.1)" : "transparent"}
            onClick={() => onClick(opt.value)}
            transition={"all 300ms"}
            position={"relative"}
          >
            <Icon
              name={opt.icon}
              size={"18px"}
              color={
                isActive ? theme.colors.Tertiary.dark : theme.colors.Gray.$800
              }
            />
            <Text
              color={
                isActive ? theme.colors.Tertiary.dark : theme.colors.Gray.$800
              }
              fontWeight={500}
              fontSize={"L_xs"}
            >
              {opt.label}
            </Text>
          </HStack>
        );
      })}
    </VStack>
  );
};

export default Navbar;
