import _axios from "..";
import { Company, Invite, Project, User, AdminProfiles } from "../../types";

type InviteProps = {
  consultants?: { email: User["email"]; profile_name: AdminProfiles["name"] }[];
  companies?: {
    email: User["email"];
    company: Partial<Company>;
    sendEmail: boolean;
  }[];
  project_id?: Project["id"];
};

type CallbackData = {
  errors: string[];
  existents: { id: string; email: string }[];
};

export const InviteService = {
  sendInvites: async (
    body: InviteProps
  ): Promise<{ consultants: CallbackData; companies: CallbackData }> =>
    (await _axios.post("/invite", body)).data,

  findByHash: async (hash: string): Promise<Invite> =>
    (await _axios.get(`/invite/${hash}`)).data,

  resendInvite: async (company_id: Company["id"]): Promise<void> =>
    (await _axios.post(`/invite/${company_id}`)).data,
};

export default InviteService;
