import { useEffect, useState } from "react";
import { breakpoints } from "../theme";

const initialState = {
  isMobile: false,
  isTablet: false,
  isLaptop: false,
  isDesktop: false,
};

const useDeviceDetector = () => {
  const [width, setWidth] = useState(window.outerWidth);
  const [device, setDevice] = useState(initialState);

  const { desktop, notebook, tablet } = breakpoints;

  useEffect(() => {
    function handleResize() {
      if (width >= desktop) {
        setDevice({ ...initialState, isDesktop: true });
      } else if (width >= notebook) {
        setDevice({ ...initialState, isLaptop: true });
      } else if (width >= tablet) {
        setDevice({ ...initialState, isTablet: true });
      } else {
        setDevice({ ...initialState, isMobile: true });
      }
      setWidth(window.outerWidth);
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return {
    ...device,
    width,
  };
};

export default useDeviceDetector;
