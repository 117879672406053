import React, { useEffect, useState } from "react";
import { Props } from "./types";
import { Modal } from "src/components/base/Modal";
import { Button, Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { formatDate } from "src/common";
import { documentTypes } from "../common";

const RemoveConfirmation: React.FC<Props> = ({
  handleConfirm,
  handleClose,
  document,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [document]);

  return (
    <Modal isOpen={!!document} title={"Excluir anexo"} onClose={handleClose}>
      <VStack borderTop={"1px"} borderTopColor={"Gray.$300"} spacing={0}>
        <VStack p={"24px"}>
          <Text fontSize={"LMD"}>
            Tem certeza que deseja excluir esse anexo? Esse ação não poderá ser
            desfeita.
          </Text>

          <HStack
            p={"16px"}
            w={"100%"}
            mt={"16px"}
            border={"1px"}
            borderRadius={"12px"}
            borderColor={"Gray.$300"}
          >
            <Center
              w={"36px"}
              h={"36px"}
              borderRadius={"8px"}
              background={"Gray.$300"}
            >
              {documentTypes.image.formats.find((type) =>
                document?.name.includes(type)
              ) && (
                <Image
                  w={"100%"}
                  h={"100%"}
                  objectFit={"cover"}
                  src={document?.url}
                  borderRadius={"8px"}
                />
              )}
            </Center>

            <VStack alignItems={"start"} spacing={0}>
              <Text fontSize={"LMD"}>{document?.name}</Text>
              <Text fontSize={"LSM"} color={"Gray.$700"}>
                Enviado em{" "}
                {formatDate(new Date(document?.createdAt || ""), "dd/MM/yyyy")}
              </Text>
            </VStack>
          </HStack>
        </VStack>

        <VStack
          w={"100%"}
          py={"16px"}
          px={"24px"}
          spacing={"8px"}
          borderTop={"1px"}
          borderTopColor={"Gray.$300"}
        >
          <Button
            w={"100%"}
            bg={"#DD1940"}
            _hover={{
              background: "#bd1134",
            }}
            color={"Gray.$100"}
            borderRadius={"8px"}
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              handleConfirm();
            }}
          >
            Excluir
          </Button>

          <Button
            onClick={handleClose}
            isLoading={isLoading}
            borderRadius={"8px"}
            w={"100%"}
          >
            Cancelar
          </Button>
        </VStack>
      </VStack>
    </Modal>
  );
};

export default RemoveConfirmation;
