import { Center, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Props } from "../types";
import Icon from "src/components/base/Icon";
import { motion } from "framer-motion";
import { Hypothesis } from "src/types";
import * as HypothesesModes from "./Modes";
import HypothesesEmptyState from "./EmptyState";
import { toast } from "react-toastify";
import HypothesisService from "src/services/hypothesis";
import { SubmitHandler } from "react-hook-form";
import { Button } from "semente-js";
import { KanbanContext } from "src/contexts/KanbanContext";
import { MutatingDots } from "react-loader-spinner";

const HypothesesSection: React.FC<Props> = ({ task }) => {
  const { updateTask } = useContext(KanbanContext);
  const [hypotheses, setHypotheses] = useState({
    data: [] as Hypothesis[],
    isLoading: true,
  });
  const [currentHypothesis, setCurrentHypothesis] = useState<{
    data: Hypothesis;
    mode: keyof typeof HypothesesModes;
  }>();

  const refreshHypotheses = useCallback(async () => {
    HypothesisService.getByCompanyId(task.company_id).then((data) =>
      setHypotheses({ isLoading: false, data })
    );
  }, [task.company_id]);

  useEffect(() => {
    refreshHypotheses();
  }, [refreshHypotheses]);

  const CurrentHypothesesMode =
    currentHypothesis && HypothesesModes[currentHypothesis?.mode];

  const handleCreateHypotheses: SubmitHandler<Hypothesis> = async (values) => {
    try {
      const newHypothesis = await HypothesisService.create(
        task.company_id,
        values
      );

      updateTask(
        {
          ...task,
          company: {
            ...task.company,
            hypothesis: [...hypotheses.data, newHypothesis],
          },
        },
        true
      );

      refreshHypotheses();
      setCurrentHypothesis({ data: newHypothesis, mode: "Viewer" });
    } catch (err) {
      toast.error("Ocorreu um erro ao criar a hipótese");
    }
  };

  return (
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      style={{ width: "100%", height: "100%" }}
    >
      {CurrentHypothesesMode ? (
        <CurrentHypothesesMode
          onClickBack={() => setCurrentHypothesis(undefined)}
          handleSubmitForm={handleCreateHypotheses}
          current={currentHypothesis.data}
        />
      ) : (
        <VStack w={"100%"} h={"100%"} justifyContent={"space-between"}>
          <VStack
            w={"100%"}
            flex={1}
            px={"24px"}
            alignItems={"flex-start"}
            position={"relative"}
          >
            <HStack w={"100%"} justifyContent={"space-between"}>
              <Text fontWeight={600} fontSize={"RH_sm"} fontFamily={"Raleway"}>
                Hipóteses
              </Text>

              {hypotheses.data.length >= 1 && (
                <Button
                  label={"Criar nova hipótese"}
                  onClick={() =>
                    setCurrentHypothesis({
                      data: {} as Hypothesis,
                      mode: "Form",
                    })
                  }
                  layout="rounded"
                  color="gray"
                  size="sm"
                />
              )}
            </HStack>

            {hypotheses.isLoading ? (
              <Center flex={1} w={"100%"}>
                <MutatingDots
                  height="100"
                  width="100"
                  color="#408EC5"
                  secondaryColor="#408EC5"
                  radius="12.5"
                  ariaLabel="mutating-dots-loading"
                  visible={true}
                />
              </Center>
            ) : (
              <>
                <Text
                  mt={"4px !important"}
                  fontWeight={400}
                  fontSize={"L_sm"}
                  color={"Gray.$700"}
                >
                  Essas são as hipóteses que estamos testando no momento
                </Text>

                {hypotheses.data.length >= 1 ? (
                  <VStack
                    w={"100%"}
                    h={"100%"}
                    spacing={0}
                    mt={"16px !important"}
                  >
                    <Text
                      alignSelf={"end"}
                      color={"Gray.$700"}
                      fontSize={"L_md"}
                    >
                      {hypotheses.data.length} hipóteses
                    </Text>

                    {hypotheses.data.map((hypothesis, index) => (
                      <VStack
                        key={hypothesis.id}
                        spacing={0}
                        alignItems={"flex-end"}
                        w={"100%"}
                      >
                        <HStack
                          w={"100%"}
                          cursor={"pointer"}
                          spacing={"16px"}
                          onClick={() =>
                            setCurrentHypothesis({
                              data: hypothesis,
                              mode: "Viewer",
                            })
                          }
                        >
                          <Icon name={"puzzle-piece-solid"} size="24px" />

                          <VStack
                            w={"100%"}
                            alignItems={"flex-start"}
                            position={"relative"}
                            py={"22px"}
                          >
                            <HStack flex={1} w={"100%"} spacing={"16px"}>
                              <Text fontSize={"L_md"} flex={1}>
                                {hypothesis.title}
                              </Text>

                              <Icon
                                mr={"8px"}
                                name={"angle-right"}
                                size="20px"
                              />
                            </HStack>

                            {hypotheses.data.length - 1 !== index && (
                              <Stack
                                w={"100%"}
                                h={"1px"}
                                background={"Gray.$400"}
                                position={"absolute"}
                                bottom={0}
                              />
                            )}
                          </VStack>
                        </HStack>
                      </VStack>
                    ))}
                  </VStack>
                ) : (
                  <HypothesesEmptyState
                    handleCreate={() =>
                      setCurrentHypothesis({
                        data: {} as Hypothesis,
                        mode: "Form",
                      })
                    }
                  />
                )}
              </>
            )}
          </VStack>
        </VStack>
      )}
    </motion.div>
  );
};

export default HypothesesSection;
