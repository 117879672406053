import { Box, Center, HStack, Text, useTheme } from "@chakra-ui/react";
import { Props } from "./types";
import React from "react";
import Icon from "src/components/base/Icon";

const ProjectNavItem: React.FC<Props> = ({
  item,
  handleSelectItem,
  isChecked,
}) => {
  const theme = useTheme();

  return (
    <HStack
      onClick={handleSelectItem}
      position={"relative"}
      cursor={"pointer"}
      spacing={0}
      w={"100%"}
      background={isChecked ? "Tertiary.background" : "transparent"}
      pr={"18px"}
    >
      <Box
        left={0}
        h={"100%"}
        w={"4px"}
        zIndex={2}
        position={"absolute"}
        borderRadius={"500px"}
        transition={"300ms all"}
        background={isChecked ? "Tertiary.dark" : "transparent"}
      />

      <HStack
        pl={"18px"}
        w={"100%"}
        spacing={"16px"}
        py={"14px"}
        position={"relative"}
        transition={"300ms all"}
      >
        {item.icon && (
          <Icon
            name={item.icon}
            color={
              isChecked ? theme.colors.Tertiary.dark : theme.colors.Gray.$800
            }
          />
        )}
        <Text
          fontSize={"L_md"}
          color={isChecked ? "Tertiary.dark" : "Gray.$800"}
          fontWeight={500}
        >
          {item.label}
        </Text>
      </HStack>

      {item.isNew && (
        <Center background={"Brand.pure"} px={"4px"} borderRadius={"500px"}>
          <Text fontSize={"L_xs"} color={"Gray.$100"} fontWeight={500}>
            Novo
          </Text>
        </Center>
      )}

      {item.subItems && (
        <Icon
          size={"20px"}
          name={"angle-right"}
          color={
            isChecked ? theme.colors.Tertiary.dark : theme.colors.Gray.$800
          }
        />
      )}
    </HStack>
  );
};

export default ProjectNavItem;
