import {
  Center,
  HStack,
  Image,
  Stack,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect, useCallback } from "react";
import { MutatingDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { ActionsStage, formatDate, formatStagesByPriority } from "src/common";
import Icon from "src/components/base/Icon";
import StagesViewer from "src/components/StagesViewer";
import StageForm from "src/components/StagesViewer/StageForm";
import StageService from "src/services/stage";
import TestService from "src/services/test";
import { Stage, Test } from "src/types";
import { Props } from "./types";

const TestViewer: React.FC<Props> = ({ currentTest }) => {
  const [currentStage, setCurrentStage] = useState<Stage | Partial<Stage>>();
  const [test, setTest] = useState<Test>();
  const theme = useTheme();

  const refreshTest = useCallback(async () => {
    if (currentTest) {
      setTest(undefined);
      setTest(await TestService.findById(currentTest.id));
    } else {
      setCurrentStage(undefined);
      setTest(undefined);
    }
  }, [currentTest]);

  const handleDeleteStage = async (id: Stage["id"]) => {
    try {
      setCurrentStage(undefined);
      setTest(undefined);
      await StageService.delete(id);
      await refreshTest();
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o estágio");
    }
  };

  const handleSubmitStage = async (stage: Partial<Stage>) => {
    try {
      setCurrentStage(undefined);
      setTest(undefined);
      if (stage.id) {
        await StageService.update(stage.id, stage);
      } else {
        await StageService.create(stage);
      }

      await refreshTest();
    } catch (err) {
      toast.error("Ocorreu um erro ao gerenciar o estágio");
    }
  };

  useEffect(() => {
    refreshTest();
  }, [refreshTest]);

  return (
    <VStack
      position={"absolute"}
      w={"100%"}
      h={"100%"}
      background={"white"}
      zIndex={2}
      right={0}
      top={0}
      transition={"500ms all"}
      overflow={"hidden"}
      maxW={currentTest ? "100%" : "0"}
    >
      {test ? (
        <VStack
          overflow={"auto"}
          w={"100%"}
          h={"100%"}
          alignItems={"flex-start"}
        >
          <Text
            mt={"16px !important"}
            fontWeight={600}
            fontSize={"RH_sm"}
            fontFamily={"Raleway"}
            whiteSpace={"nowrap"}
          >
            {test?.title}
          </Text>

          <VStack
            spacing={"8px !important"}
            mt={"16px !important"}
            alignItems={"flex-start"}
            w={"100%"}
            h={"100%"}
          >
            <HStack>
              <Icon
                size={"20px"}
                name={"schedule"}
                color={theme.colors.Gray.$700}
              />
              <Text
                ml={"10px !important"}
                color={"Gray.$700"}
                fontSize={"L_sm"}
              >
                {formatDate(new Date(test?.start_date || ""), "dd/MM/yyyy")}
                {test?.end_date &&
                  `- ${formatDate(
                    new Date(test?.end_date || ""),
                    "dd/MM/yyyy"
                  )}`}
              </Text>
            </HStack>

            <HStack>
              <Icon
                name={"users-alt"}
                color={theme.colors.Gray.$700}
                size={"20px"}
              />
              <Text
                ml={"10px !important"}
                color={"Gray.$700"}
                fontSize={"L_sm"}
              >
                {test?.sample_spaces[0]?.size} {test?.sample_spaces[0]?.name}
              </Text>
            </HStack>

            <HStack spacing={"8px !important"} mb={"34px !important"}>
              <Center
                w={"20px"}
                h={"20px"}
                borderRadius={"50%"}
                background={"Gray.$300"}
                mr={"3px !important"}
              >
                {test.assigned.image_url ? (
                  <Image
                    src={test.assigned.image_url}
                    borderRadius={"50%"}
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                  />
                ) : (
                  <Icon name="user" />
                )}
              </Center>

              <Text color={"Gray.$700"} fontSize={"L_sm"}>
                {test.assigned.name}
              </Text>
            </HStack>

            <VStack
              position={"relative"}
              h={"100%"}
              w={"100%"}
              alignItems={"flex-end"}
            >
              <Stack
                position={"absolute"}
                right={0}
                w={"100%"}
                h={"100%"}
                maxW={currentStage ? "100%" : "0%"}
                transition={"500ms all"}
                overflow={"hidden"}
                background={"white"}
                borderTop={"1px solid"}
                borderColor={"Gray.$400"}
                zIndex={10}
              >
                {currentStage && (
                  <StageForm
                    defaultValues={currentStage as Stage}
                    handleCancel={() => setCurrentStage(undefined)}
                    handleSubmitForm={handleSubmitStage}
                    handleDelete={handleDeleteStage}
                    test={test}
                  />
                )}
              </Stack>

              {!currentStage && (
                <>
                  <Text
                    fontWeight={600}
                    fontSize={"L_md"}
                    alignSelf={"flex-start"}
                  >
                    Planejamento
                  </Text>
                  <StagesViewer
                    stages={formatStagesByPriority(test.stages)}
                    onCreateStage={() =>
                      setCurrentStage({
                        priority: test.stages.length,
                        indicator: parseInt(
                          `${test.sample_spaces[0]?.size / 2}`
                        ),
                        test_id: test.id,
                        name: Object.keys(ActionsStage)[test.stages.length - 1],
                        status: "Não atingido",
                      })
                    }
                    onUpdateStage={setCurrentStage}
                  />
                </>
              )}
            </VStack>
          </VStack>
        </VStack>
      ) : (
        <>
          {currentTest && (
            <Center w={"100%"} h={"100%"}>
              <MutatingDots
                height="100"
                width="100"
                color="#408EC5"
                secondaryColor="#408EC5"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                visible={true}
              />
            </Center>
          )}
        </>
      )}
    </VStack>
  );
};

export default TestViewer;
