import _axios from "..";
import {
  Company,
  Project,
  ProjectCompanyData,
  ProjectFull,
  ProjectWithBoards,
  ProjectWithClients,
  User,
} from "../../types";
import { ISearchResult } from "../types";

export const ProjectsService = {
  getProjects: async (
    params?: Object
  ): Promise<ISearchResult<ProjectWithClients>> =>
    (await _axios.get("/projects", { params })).data,

  getById: async (id: Project["id"]): Promise<ProjectFull> =>
    (await _axios.get(`/project/${id}`)).data,

  getCompaniesByProject: async (id: Project["id"]): Promise<Company[]> =>
    (await _axios.get(`/project/${id}/company`)).data,

  getConsultantsByProject: async (id: Project["id"]): Promise<User[]> =>
    (await _axios.get(`/project/${id}/consultant`)).data,

  createProjectCompany: async (props: {
    company_id: Company["id"];
    project_id: Company["id"];
  }): Promise<Project[]> => (await _axios.post("/project/company", props)).data,

  deleteProjectCompany: async (id: ProjectCompanyData["id"]): Promise<void> =>
    (await _axios.delete(`/project/company/${id}`)).data,

  deleteProjectConsultant: async (
    id: ProjectCompanyData["id"]
  ): Promise<void> => (await _axios.delete(`/project/consultant/${id}`)).data,

  createProjectConsultant: async (props: {
    consultant_id: User["id"];
    project_id: Company["id"];
  }): Promise<ProjectWithBoards> =>
    (await _axios.post(`/project/consultant`, props)).data,

  createProject: async (newProject: Partial<Project>): Promise<Project> =>
    (await _axios.post(`/project`, newProject)).data,

  update: async (
    id: Project["id"],
    newProject: Partial<Project>
  ): Promise<ProjectWithBoards> =>
    (await _axios.patch(`/project/${id}`, newProject)).data,

  delete: async (id: Project["id"]): Promise<void> =>
    (await _axios.delete(`/project/${id}`)).data,
};

export default ProjectsService;
