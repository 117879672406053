/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import { SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal } from "src/components/base/Modal";
import { column, getColumns } from "src/pages/Kanban/common";
import { AddCompany } from "src/pages/Kanban/Dialogs/AddCompany";
import TaskView from "src/pages/Kanban/Dialogs/TaskView";
import ArchiveService from "src/services/archive";
import KanbanService from "src/services/kanban";
import TaskService from "src/services/task";
import { uploadFileToBucket } from "src/utils";
import HistoricService from "../../services/historic";
import { Historic, Kanban, Task } from "../../types";
import { ProjectContext } from "../ProjectContext";
import { DialogKeys } from "./types";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";
import { socket } from "../../connections/socket";
import { SocketContext } from "../SocketContext";
import { UserContext } from "../UserContext";
import { getAvailableTabs } from "src/pages/Kanban/Dialogs/TaskView/common";

type DialogProps = {
  key: DialogKeys;
  isLoading?: boolean;
  props?: {
    task?: Task;
    column?: column;
    [x: string]: any;
  };
};

type ExternalData<T> = {
  isLoading: boolean;
  data: T;
};

type KanbanData = ExternalData<Kanban | undefined> & {
  columns: ReturnType<typeof getColumns>;
  kanban_id?: Kanban["id"];
};

export type KanbanProps = {
  showConffetiTo?: string;
  setShowConffetiTo: (value?: string) => void;
  refreshHistoric: (taskId: Task["id"]) => void;
  uploadArchive: (files: File[]) => Promise<void>;
  currentDialog?: DialogProps;
  selectDialog: (dialog?: DialogProps) => Promise<void>;
  refreshKanban: (kanbanId: Kanban["id"]) => Promise<KanbanData | undefined>;
  createTask: (task: Partial<Task>) => Promise<void>;
  updateTask: (
    task: Partial<Task>,
    onlyState?: boolean | { hideToast: boolean }
  ) => Promise<void>;
  removeTask: (task_id: Task["id"]) => Promise<void>;
  updateKanban: SubmitHandler<Partial<Kanban>>;
  historic: ExternalData<Historic[] | undefined>;
  kanbanData: KanbanData;
  handleResetKanban: () => void;
  handleOut: () => void;
  handleDeleteKanban: (id: Kanban["id"]) => Promise<void>;
};

export const KanbanContext = React.createContext<KanbanProps>(
  {} as KanbanProps
);

interface Props {
  children: React.ReactNode;
}

const KanbanContextProvider = ({ children }: Props) => {
  const [kanbanData, setKanbanData] = useState<KanbanProps["kanbanData"]>({
    isLoading: true,
    kanban_id: undefined,
    data: undefined as undefined | Kanban,
    columns: getColumns("0"),
  });
  const {
    currentProject,
    handleUpdateProject,
    handleOutProject,
    selectProject,
  } = useContext(ProjectContext);
  const { events } = useContext(SocketContext);
  const { user } = useContext(UserContext);

  const [showConffetiTo, setShowConffetiTo] = useState<string>();
  const [historic, setHistoric] = useState<KanbanProps["historic"]>({
    isLoading: true,
    data: undefined,
  });
  const [currentDialog, setCurrentDialog] = useState<DialogProps>();
  const navigate = useNavigate();

  useEffect(() => {
    const taskUpdated = events.taskUpdate[events.taskUpdate.length - 1];
    if (taskUpdated && taskUpdated.kanban_id === kanbanData?.data?.id) {
      updateTask(
        {
          ...taskUpdated,
          updatedBy:
            taskUpdated?.updatedBy?.id !== user?.id
              ? taskUpdated.updatedBy
              : undefined,
        },
        { hideToast: true, hideFromViewer: true }
      );

      const isInEnd =
        kanbanData.columns.find(
          (column) => taskUpdated.column_index === column.id
        )?.position === "end";

      if (isInEnd) {
        setShowConffetiTo(taskUpdated.id);
      }
    }
  }, [events]);

  const handleResetKanban = () =>
    setKanbanData({
      isLoading: true,
      kanban_id: undefined,
      data: undefined as undefined | Kanban,
      columns: getColumns("0"),
    });

  const handleOut = () => {
    handleOutProject();
    handleResetKanban();
  };

  const handleDeleteKanban = async (id: Kanban["id"]): Promise<void> => {
    try {
      await KanbanService.delete(id);
      handleResetKanban();
      if (currentProject && currentProject.data) {
        handleUpdateProject({
          ...currentProject.data,
          boards: currentProject.data.boards.filter((board) => board.id !== id),
        });
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao remover o quadro");
    }
  };

  const refreshKanban = useCallback(
    async (kanban_id: Kanban["id"]) => {
      socket.emit("subscription", kanban_id);

      setKanbanData({
        ...kanbanData,
        isLoading: true,
        kanban_id,
      });
      try {
        const data = await KanbanService.getById(kanban_id);
        const kanban = {
          isLoading: false,
          data,
          kanban_id: undefined,
          columns: getColumns((data?.qtd_columns).toString()),
        };

        setKanbanData(kanban);
        if (!currentProject.data) {
          selectProject(data.project_id);
        }

        return kanban;
      } catch (err) {
        toast.error("Ocorreu um erro ao buscar os dados.");
      }
    },
    [currentProject]
  );

  const selectDialog = async (dialog?: DialogProps) => {
    try {
      if (dialog && dialog.props?.task) {
        if (dialog.props.column) {
          navigate(
            `/kanban/task/${getAvailableTabs(dialog?.props?.column?.position)[0].value}/${dialog.props.task.id}`
          );
        }

        setCurrentDialog({ ...dialog, isLoading: true });
        setHistoric({ isLoading: true, data: undefined });
        const task = await TaskService.getById(dialog.props.task.id);
        let kanban = kanbanData;

        if (!kanbanData.data) {
          kanban = (await refreshKanban(task.kanban_id)) as KanbanData;
        }

        setHistoric({ isLoading: false, data: task.historic });

        setCurrentDialog({
          ...dialog,
          isLoading: false,
          props: {
            column: kanban.columns[task.column_index],
            task,
          },
        });
      } else {
        setCurrentDialog(dialog);
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar essa tarefa.");
    }
  };

  const createTask = async (task: Partial<Task>) => {
    if (kanbanData.data) {
      try {
        setKanbanData({
          ...kanbanData,
          data: {
            ...kanbanData.data,
            tasks: [
              ...kanbanData.data.tasks,
              await TaskService.create({
                kanban_id: kanbanData.data?.id,
                column_index: 0,
                company_id: task.company_id,
                row_index: kanbanData.data.tasks.filter(
                  (task) => task.row_index === 0
                ).length,
              }),
            ],
          },
        });
      } catch (err) {
        toast.error("Ocorreu um erro ao adicionar essa empresa.");
      }
    }
  };

  const updateTask = async (
    task: Partial<Task>,
    onlyState?: boolean | { hideToast?: boolean; hideFromViewer?: boolean }
  ) => {
    if (kanbanData.data && task.id) {
      const taskI = kanbanData.data?.tasks.findIndex(
        ({ id }) => id === task.id
      );

      let taskUpdated = task as Task;

      if (!onlyState) {
        taskUpdated = await TaskService.update(
          task.id,
          _.omit(task, ["historic"])
        );
      }

      kanbanData.data.tasks[taskI] = taskUpdated;

      try {
        setKanbanData({
          ...kanbanData,
          data: {
            ...kanbanData.data,
            tasks: [...kanbanData.data.tasks],
          },
        });

        if (
          !(
            onlyState &&
            typeof onlyState === "object" &&
            onlyState.hideFromViewer
          )
        ) {
          if (currentDialog?.props?.task?.id === taskUpdated.id) {
            setCurrentDialog({
              ...currentDialog,
              props: { ...currentDialog.props, task: taskUpdated },
            });
          }
        }

        if (
          !(onlyState && typeof onlyState === "object" && onlyState.hideToast)
        ) {
          toast.success("Empresa atualizada com sucesso!");
        }
      } catch (err) {
        toast.error("Ocorreu um erro ao adicionar essa empresa.");
      }
    }
  };

  const removeTask = async (task_id: Task["id"]) => {
    if (kanbanData.data) {
      try {
        await TaskService.delete(task_id);

        setKanbanData({
          ...kanbanData,
          data: {
            ...kanbanData.data,
            tasks: [
              ...kanbanData.data.tasks.filter((task) => task.id !== task_id),
            ],
          },
        });

        const taskHistory = JSON.parse(
          localStorage.getItem("rama-task-history") || "{}"
        );
        localStorage.setItem(
          "rama-task-history",
          JSON.stringify(_.omit(taskHistory, [task_id]))
        );
      } catch (err) {
        console.log(err);
        toast.error("Ocorreu um erro ao remover essa empresa.");
      }
    }
  };

  const Dialogs: {
    [value in DialogKeys]: {
      title?: string;
      Component: React.ReactNode;
    };
  } = {
    addCompany: {
      title: "Adicionar empresas",
      Component: <AddCompany />,
    },
    taskView: {
      title: "",
      Component: <TaskView />,
    },
  };

  useEffect(() => {
    if (showConffetiTo) {
      setTimeout(() => setShowConffetiTo(undefined), 2000);
    }
  }, [showConffetiTo]);

  const refreshHistoric = useCallback(async (taskId: Task["id"]) => {
    setHistoric({ isLoading: true, data: undefined });
    try {
      setHistoric({
        isLoading: false,
        data: await HistoricService.getByTaskId(taskId),
      });
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o histórico.");
    }
  }, []);

  const updateKanban: SubmitHandler<Partial<Kanban>> = async (values) => {
    try {
      if (kanbanData.data) {
        if (kanbanData.data.qtd_columns !== values.qtd_columns) {
          const tasksToStart = kanbanData.data.tasks.filter(
            (task) =>
              task.column_index - 2 >= (values.qtd_columns as number) &&
              !kanbanData.columns[task.column_index].isFixed
          );

          const tasksToNewPosition = kanbanData.data.tasks.filter(
            (task) =>
              kanbanData.columns[task.column_index].id >=
              kanbanData.columns.length - 2
          );

          await Promise.all(
            tasksToStart.map(
              async (task) =>
                await TaskService.update(task.id, { ...task, column_index: 0 })
            )
          );

          await Promise.all(
            tasksToNewPosition.map(
              async (task) =>
                await TaskService.update(task.id, {
                  ...task,
                  column_index:
                    task.column_index -
                    (kanbanData?.data?.qtd_columns as number) +
                    (values.qtd_columns as number),
                })
            )
          );
        }

        const kanban = await KanbanService.update(
          kanbanData.data?.id || "",
          values
        );

        setKanbanData({
          ...kanbanData,
          data: kanban,
          columns: getColumns(kanban.qtd_columns.toString()),
        });
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao atualizar o quadro.");
    }
    toast.success("Alterações salvas.");
  };

  const uploadArchive = async (files: File[]) => {
    try {
      if (currentDialog?.props?.task) {
        const task = currentDialog?.props?.task;

        await Promise.all(
          Array.from(files).map(async (file) => {
            if (file.size <= 10000000) {
              const url = await uploadFileToBucket(file, {
                folderName: "TaskArchives",
                id: task.id,
              });

              const newArchive = await ArchiveService.create({
                task_id: task.id,
                name: file.name,
                url,
              });

              task.archives.push(newArchive);

              return;
            }
            toast.error(
              "Um de seus itens ultrapassa o limite de 10 Megabytes."
            );
          })
        );

        setCurrentDialog({
          ...currentDialog,
          props: { ...currentDialog.props, task: task },
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("Ocorreu um erro ao efetuar o upload.");
    }
  };

  return (
    <KanbanContext.Provider
      value={{
        handleDeleteKanban,
        handleResetKanban,
        refreshKanban,
        uploadArchive,
        updateTask,
        updateKanban,
        selectDialog,
        currentDialog,
        removeTask,
        kanbanData,
        showConffetiTo,
        setShowConffetiTo,
        historic,
        createTask,
        refreshHistoric,
        handleOut,
      }}
    >
      <>
        {children}
        <Modal
          isOpen={!!currentDialog && currentDialog.key !== "taskView"}
          onClose={() => setCurrentDialog(undefined)}
          title={currentDialog && Dialogs[currentDialog.key]?.title}
        >
          {currentDialog && Dialogs[currentDialog.key]?.Component}
        </Modal>
      </>
    </KanbanContext.Provider>
  );
};

export const useKanbanContext = () => {
  const context = useContext(KanbanContext);

  if (context) return context;

  throw new Error(
    "useKanbanContext must be used within a KanbanContextProvider."
  );
};

export default KanbanContextProvider;
