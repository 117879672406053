import { motion } from "framer-motion";
import { Props } from "./types";
import React, { useRef, useState } from "react";
import {
  Box,
  Center,
  HStack,
  Stack,
  Text,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import Icon from "src/components/base/Icon";
import { AddButton } from "src/components/AddButton";
import {
  Checklist,
  ChecklistQuestion,
  ChecklistQuestionWithOptions,
} from "src/types";
import { Modal } from "src/components/base/Modal";
import ChecklistDetailsEmptyState from "./EmptyState";
import QuestionForm from "./QuestionForm";
import QuestionList from "./List";
import { toast } from "react-toastify";
import ChecklistQuestionService from "src/services/checklist/checklistQuestion";

const ChecklistDetails: React.FC<Props> = ({
  checklist,
  handleBack,
  handleDeleteChecklist,
  handleUpdateChecklist,
  handleModifyQuestions,
}) => {
  const [questionFormData, setQuestionFormData] = useState<
    {} | undefined | any
  >();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const menuRef = useRef<any>(null);

  useOutsideClick({
    ref: menuRef,
    handler: () => setMenuIsOpen(false),
  });

  const menuOptions = [
    {
      title: "Renomear checklist",
      color: "black",
      onClick: handleUpdateChecklist,
    },
    {
      title: "Excluir checklist",
      color: "#CC0000",
      onClick: (checklist: Checklist) => handleDeleteChecklist(checklist.id),
    },
  ];

  const handleDuplicateQuestion = async (
    question: ChecklistQuestionWithOptions
  ) => {
    try {
      const randomId = Math.floor(Math.random() * (100 - 0) + 0).toString();
      const newQuestions = [
        ...(checklist.questions as ChecklistQuestionWithOptions[]),
        { ...question, isCreating: true, id: randomId },
      ];

      handleModifyQuestions(newQuestions);

      const newQuestion = await ChecklistQuestionService.create({
        ...question,
        index: checklist.questions.length,
        checklist_id: checklist.id,
      });

      handleModifyQuestions(
        newQuestions.map((question) => {
          if (question.id === randomId && question.isCreating) {
            return newQuestion;
          }

          return question;
        }) as ChecklistQuestionWithOptions[]
      );
    } catch (err) {
      toast.error("Ocorreu um erro ao duplicar a questão");
    }
  };

  const handleDeleteQuestion = async (question_id: ChecklistQuestion["id"]) => {
    try {
      const newQuestions =
        checklist.questions as ChecklistQuestionWithOptions[];
      const index = newQuestions.findIndex(
        (question) => question.id === question_id
      );

      if (index >= 0) {
        newQuestions[index] = { ...newQuestions[index], isRemoving: true };
        handleModifyQuestions(newQuestions);

        await ChecklistQuestionService.delete(question_id);

        handleModifyQuestions(
          checklist.questions.filter(
            (question) => question.id !== question_id
          ) as ChecklistQuestionWithOptions[]
        );
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar a questão");
    }
  };

  return (
    <Center w={"100%"}>
      <motion.div
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          maxWidth: "536px",
        }}
      >
        <HStack pb={"28px"}>
          <Text
            textDecor={"underline"}
            fontWeight={500}
            cursor={"pointer"}
            onClick={handleBack}
            fontSize={"L_md"}
            color={"Gray.$800"}
          >
            Checklists
          </Text>
          <Text fontWeight={500} fontSize={"L_md"} color={"Gray.$800"}>
            / {checklist.name}
          </Text>
        </HStack>

        <HStack w={"100%"} justifyContent={"space-between"}>
          <HStack spacing={"18px"}>
            <Text
              fontWeight={500}
              fontSize={"RH_xl"}
              fontFamily={"Raleway"}
              color={"Gray.$800"}
            >
              {checklist.name}
            </Text>
          </HStack>

          <Box position={"relative"}>
            <Box cursor={"pointer"} onClick={() => setMenuIsOpen(true)}>
              <Icon name={"ellipsis-h"} size={"20px"} />
            </Box>

            {menuIsOpen && (
              <VStack
                py={"8px"}
                ref={menuRef}
                right={0}
                top={"30px"}
                position={"absolute"}
                zIndex={200}
                background={"Gray.$100"}
                minW={"240px"}
                borderRadius={"12px"}
                boxShadow={"0px 2px 16px rgba(0, 0, 0, 0.12)"}
              >
                {menuOptions.map((option) => (
                  <Stack
                    cursor={"pointer"}
                    key={option.title}
                    padding={"12px 16px"}
                    onClick={() => {
                      option.onClick(checklist);
                      setMenuIsOpen(false);
                    }}
                    w={"100%"}
                    alignItems={"flex-start"}
                    transition={"300ms all"}
                    _hover={{
                      background: "Gray.$200",
                    }}
                  >
                    <Text
                      fontSize={"L_sm"}
                      fontWeight={400}
                      color={option.color}
                    >
                      {option.title}
                    </Text>
                  </Stack>
                ))}
              </VStack>
            )}
          </Box>
        </HStack>

        <HStack
          mt={"36px !important"}
          w={"100%"}
          justifyContent={"space-between"}
          pb={"32px"}
        >
          <Text fontWeight={600} fontSize={"L_lg"} color={"Gray.$800"}>
            Questões
          </Text>

          {checklist?.questions?.length >= 1 && (
            <AddButton
              label={"Nova questão"}
              onClick={() => setQuestionFormData({})}
            />
          )}
        </HStack>

        <VStack w={"100%"}>
          {checklist?.questions?.length >= 1 ? (
            <QuestionList
              questions={checklist.questions as ChecklistQuestionWithOptions[]}
              handleDelete={handleDeleteQuestion}
              handleDuplicate={handleDuplicateQuestion}
              handleReorder={handleModifyQuestions}
              handleUpdate={setQuestionFormData}
            />
          ) : (
            <ChecklistDetailsEmptyState
              handleAdd={() => setQuestionFormData({})}
            />
          )}
        </VStack>
      </motion.div>

      <Modal
        isOpen={questionFormData}
        onClose={() => setQuestionFormData(undefined)}
        title={
          questionFormData && "id" in questionFormData
            ? "Editar questão"
            : "Criar questão"
        }
      >
        <QuestionForm
          questionsLength={checklist?.questions?.length || 0}
          handleClose={() => setQuestionFormData(undefined)}
          checklist_id={checklist.id}
          handleSubmitQuestion={(question) => {
            const questionIndex = (checklist.questions || []).findIndex(
              (quest) => quest.id === question.id
            );

            if (questionIndex >= 0) {
              checklist.questions[questionIndex] = question;
              handleModifyQuestions(
                checklist.questions as ChecklistQuestionWithOptions[]
              );
              return;
            }

            handleModifyQuestions([
              ...((checklist.questions as ChecklistQuestionWithOptions[]) ||
                []),
              question,
            ]);
          }}
          question={questionFormData}
        />
      </Modal>
    </Center>
  );
};

export default ChecklistDetails;
