import { Center, HStack, Image, Select, Text, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { MutatingDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Icon from "src/components/base/Icon";
import { KanbanContext } from "src/contexts/KanbanContext";
import { ProjectContext } from "src/contexts/ProjectContext";
import ProjectsService from "src/services/projects";
import { Task, User } from "src/types";

const CompaniesForm: React.FC = () => {
  const { currentProject } = useContext(ProjectContext);
  const { kanbanData, updateTask, selectDialog } = useContext(KanbanContext);
  const [consultants, setConsultants] = useState({
    isLoading: true,
    data: [] as User[],
  });

  const defaultValues = {
    tasks: kanbanData?.data?.tasks as Task[],
  };
  const { control, reset } = useForm({ defaultValues });

  useEffect(() => {
    if (currentProject && currentProject.data) {
      try {
        ProjectsService.getConsultantsByProject(currentProject.data?.id).then(
          (data) => setConsultants({ data, isLoading: false })
        );
      } catch (err) {
        toast.error("Ocorreu um erro ao buscar os consultores");
      }
    }
  }, [currentProject]);

  useEffect(() => {
    if (kanbanData?.data) {
      reset({ tasks: kanbanData.data.tasks });
    }
  }, [kanbanData, reset]);

  const handleSubmitForm: SubmitHandler<Task> = async (task) => {
    await updateTask(task);
  };

  return consultants.isLoading ? (
    <Center>
      <MutatingDots
        height="100"
        width="100"
        color="#408EC5"
        secondaryColor="#408EC5"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        visible={true}
      />
    </Center>
  ) : (
    <VStack
      w={"100%"}
      border={"1px solid"}
      borderColor={"Gray.$400"}
      pl={"16px"}
      borderRadius={"12px"}
      spacing={0}
    >
      <Controller
        control={control}
        name="tasks"
        render={({ field: { value, onChange } }) => (
          <>
            {[{}, ...value].map((item: {} | Task, index) => {
              const task = "id" in item ? (item as Task) : undefined;

              const consultant = consultants.data.find(
                (consultant) => consultant.id === task?.consultant_id
              );

              return (
                <HStack
                  w={"100%"}
                  {...(!task && {
                    onClick: () => selectDialog({ key: "addCompany" }),
                  })}
                  cursor={task ? "inherit" : "pointer"}
                >
                  <Center
                    minW={"48px"}
                    minH={"48px"}
                    borderRadius={"50%"}
                    background={task ? "Gray.$300" : "Tertiary.pure"}
                  >
                    {task?.company.image_url ? (
                      <Image
                        src={task?.company.image_url}
                        borderRadius={"50%"}
                        objectFit={"cover"}
                        w={"48px"}
                        h={"48px"}
                      />
                    ) : (
                      <Icon
                        name={task ? "image" : "plus"}
                        color={task ? "Grey" : "white"}
                      />
                    )}
                  </Center>

                  <HStack
                    key={task?.id || index}
                    py={"24px"}
                    w={"100%"}
                    borderBottom={"1px solid"}
                    borderColor={
                      value.length === index ? "transparent" : "Gray.$400"
                    }
                    pr={"16px"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontWeight={task ? 400 : 600}
                      fontSize={"L_md"}
                      color={"Gray.$800"}
                    >
                      {task?.company.name || "Adicionar empresas"}
                    </Text>
                    {task && "id" in item && (
                      <HStack cursor={"pointer"}>
                        {consultants && consultants.data && (
                          <Select
                            mr={"18px !important"}
                            onChange={(e) => {
                              value[index - 1].consultant_id = e.target.value;
                              handleSubmitForm({
                                ...task,
                                consultant_id:
                                  task.consultant_id === ""
                                    ? null
                                    : task.consultant_id,
                              });
                              onChange(value);
                            }}
                            value={consultant?.id}
                            placeholder={"Selecione um consultor"}
                          >
                            {consultants.data?.map((consultant) => (
                              <option value={consultant.id}>
                                {consultant.name}
                              </option>
                            ))}
                          </Select>
                        )}
                      </HStack>
                    )}
                  </HStack>
                </HStack>
              );
            })}
          </>
        )}
      />
    </VStack>
  );
};

export default CompaniesForm;
