import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { Props } from "./types";
import ProfilePic from "../../../../components/ProfilePic";
import { uploadFileToBucket } from "../../../../utils";
import { toast } from "react-toastify";
import UserService from "../../../../services/user";
import { useNavigate } from "react-router-dom";
import TeamList from "./TeamList";
import ProjectList from "./ProjectsList";
import ReSendInvite from "./ReSendInvite";
import { ProtectedComponent } from "semente-js";
import { PERMISSIONS } from "src/permissions";

export const ListRightSide: React.FC<Props> = ({ company }) => {
  const [imageUrl, setImageUrl] = useState(company.image_url);
  const navigate = useNavigate();

  const handleChangeImage = async (file: File) => {
    try {
      const params = {
        folderName: "companies",
        id: company.id || "",
      };
      const image_url = await uploadFileToBucket(file, params);
      await UserService.update(company.id, { ...company, image_url });
      setImageUrl(image_url);
    } catch (err) {
      toast.error("Erro ao atualizar a empresa.");
    }
  };

  const handleRemoveImage = async () => {
    try {
      await UserService.update(company.id, { ...company, image_url: "" });
      setImageUrl(undefined);
    } catch (err) {
      toast.error("Erro ao atualizar o consultor.");
    }
  };

  const users = company.users_company_datas.map((data) => data.user) || [];
  const projects =
    company.project_company_data.map((data) => data.project) || [];

  return (
    <VStack w={"100%"} ml={"36px !important"}>
      <HStack w={"100%"} h={"100%"}>
        <ProfilePic
          imageUrl={imageUrl}
          onRemoveImage={handleRemoveImage}
          onSelectImage={handleChangeImage}
          rounded
        />

        <VStack
          pl={"24px"}
          w={"100%"}
          h={"100%"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
        >
          <Text
            fontSize={"RH_xxl"}
            fontFamily={"raleway"}
            fontWeight={600}
            mb={"0px !important"}
          >
            {company?.name}
          </Text>

          {company.phrase_solution && (
            <Text fontSize={"L_md"} fontWeight={400} mb={"0px !important"}>
              {company.phrase_solution}
            </Text>
          )}
        </VStack>
        <ProtectedComponent
          requiredClientRoles={[PERMISSIONS.COMPANY_UPDATE]}
          children={
            <Button
              onClick={() => navigate(`/companies/${company.id}`)}
              variant={"Secondary"}
              px={"24px"}
              minH={"36px"}
            >
              Editar empresa
            </Button>
          }
        />
      </HStack>

      {company.status !== "finished" && (
        <ReSendInvite company_id={company.id} />
      )}

      <TeamList representative_id={company.representative_id} users={users} />

      <ProjectList projects={projects} />
    </VStack>
  );
};
