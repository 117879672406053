import React, { useEffect } from "react";
import { Props } from "./types";
import { Button, VStack } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { Area } from "src/types";
import Input from "src/components/base/Input/Index";

export const CreateAreaForm: React.FC<Props> = ({ handleSubmitArea, area }) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: (area || {}) as Area,
  });

  useEffect(() => {
    reset(area);
  }, [area, reset]);

  return (
    <>
      <VStack
        spacing={"16px"}
        w={"100%"}
        alignItems={"flex-start"}
        px={"24px"}
        borderTop={"1px solid"}
        borderBottom={"1px solid"}
        borderColor={"Gray.$300"}
        py={"24px"}
      >
        <Controller
          name={"name"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <Input {...field} isInvalid={!!error} placeholder={"Nome"} />
          )}
        />
      </VStack>

      <Button
        m={"24px !important"}
        variant={"Primary"}
        minH={"48px !important"}
        onClick={handleSubmit(handleSubmitArea)}
      >
        {area && "id" in area ? "Salvar" : "Adicionar"}
      </Button>
    </>
  );
};
