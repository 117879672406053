import { Center, HStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { MutatingDots } from "react-loader-spinner";
import { ClientContext } from "src/contexts/ClientContext";
import { ListLeftSide } from "./LeftSide";
import { ListRightSide } from "./RightSide";

export const ClientsList: React.FC = () => {
  const { currentClient } = useContext(ClientContext);

  return (
    <HStack
      w={"100%"}
      pt={"24px"}
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
    >
      <ListLeftSide />

      {currentClient.isLoading ? (
        <Center w={"100%"} mt={"64px !important"}>
          <MutatingDots
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <>{currentClient && currentClient.data && <ListRightSide />}</>
      )}
    </HStack>
  );
};
