import { Center, HStack, Text, VStack } from "@chakra-ui/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MutatingDots } from "react-loader-spinner";
import { AddButton } from "src/components/AddButton";
import { ProjectContext } from "src/contexts/ProjectContext";
import { Project } from "src/types";
import ProjectList from "./ProjectList";
import { HomeEmptyState } from "src/components/HomeEmptyState";
import { motion } from "framer-motion";
import Input from "src/components/base/Input/Index";
import ProjectsService from "src/services/projects";
import Icon from "src/components/base/Icon";
import { UserContext } from "src/contexts/UserContext";
import { toast } from "react-toastify";

const SelectProject: React.FC = () => {
  const { showProjectDialog } = useContext(ProjectContext);
  const [timer, setTimer] = useState<any>(null);
  const { user } = useContext(UserContext);
  const observer = useRef<any>(null);
  const [page, setPage] = useState({
    current: 0,
    isLast: false,
    isLoading: false,
  });

  const [projects, setProjects] = useState({
    isLoading: true,
    total: 0,
    data: [] as Project[],
  });

  const [searchProjects, setSearchProjects] = useState<{
    isLoading: boolean;
    data?: Project[];
  }>();

  useEffect(() => {
    try {
      ProjectsService.getProjects({
        offset: page.current * 15,
        limit: 15,
      }).then((result) => {
        const data = [...projects.data, ...result.result];
        setProjects({
          isLoading: false,
          total: result.total,
          data,
        });

        setPage({
          ...page,
          isLoading: false,
          isLast: result.total === data.length,
        });
      });
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar os projetos");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.current]);

  const handleSearchProject = async (filter: string) => {
    if (filter) {
      setSearchProjects({ isLoading: true, data: undefined });
      setSearchProjects({
        isLoading: false,
        data: (await ProjectsService.getProjects({ filter })).result,
      });
    } else {
      setSearchProjects(undefined);
    }
  };

  const handleChangeInput = (e: string) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(setTimeout(() => handleSearchProject(e), 500));
  };

  const List = useMemo(
    () => (
      <>
        {(projects.data || searchProjects?.data) && (
          <ProjectList
            projects={(searchProjects?.data || projects.data) as any}
          />
        )}
      </>
    ),
    [searchProjects, projects]
  );

  const lastItem = useCallback(
    (node: React.ReactNode) => {
      if (projects.isLoading || page.isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !page.isLast) {
          setPage({
            current: page.current + 1,
            isLoading: true,
            isLast: false,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [projects, page]
  );

  return (
    <VStack w={"100%"} h={"100%"} px="80px" py="24px" spacing={"24px"}>
      <HStack
        w={"100%"}
        pb={"24px"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        borderBottom={"1px solid"}
        borderColor={"Gray.$400"}
      >
        <Text
          fontSize={"RD_md"}
          color={"Gray.$800"}
          fontFamily={"raleway"}
          fontWeight={600}
        >
          Projetos
        </Text>

        {user?.profile.name !== "consulting" && (
          <AddButton
            label={"Criar novo projeto"}
            onClick={() => showProjectDialog("create")}
          />
        )}
      </HStack>
      {projects.isLoading ? (
        <Center mt={"64px !important"} h={"100%"}>
          <MutatingDots
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <motion.div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
            flexDirection: "column",
          }}
          initial="initial"
          animate="animate"
        >
          {projects.data && projects.data.length >= 1 ? (
            <>
              <HStack w={"100%"} justifyContent={"space-between"}>
                <Input
                  placeholder={"Procurar projeto"}
                  width={"358px"}
                  onChange={(e) => handleChangeInput(e.target.value)}
                  icon={"search"}
                />

                <Text fontSize={"L_md"} color={"Gray.$700"} fontWeight={500}>
                  {searchProjects?.data?.length || projects.total} Projetos
                </Text>
              </HStack>

              {searchProjects && searchProjects.isLoading ? (
                <Center mt={"64px !important"} h={"100%"} w={"100%"}>
                  <MutatingDots
                    height="100"
                    width="100"
                    color="#408EC5"
                    secondaryColor="#408EC5"
                    radius="12.5"
                    ariaLabel="mutating-dots-loading"
                    visible={true}
                  />
                </Center>
              ) : (
                <>
                  {searchProjects?.data && searchProjects?.data?.length < 1 ? (
                    <Center w={"100%"} mt={"36px"}>
                      <VStack>
                        <Icon
                          name={"question-circle"}
                          color={"#0077CC"}
                          size={"64px"}
                        />
                        <Text
                          fontSize={"RH_xs"}
                          color={"Gray.$800"}
                          fontFamily={"raleway"}
                          fontWeight={500}
                        >
                          Nenhum resultado encontrado
                        </Text>
                      </VStack>
                    </Center>
                  ) : (
                    <>
                      {List}
                      {!searchProjects?.data && (
                        <Center ref={lastItem as any} w={"100%"} minH={"40px"}>
                          {page.isLoading && (
                            <MutatingDots
                              height="100"
                              width="100"
                              color="#408EC5"
                              secondaryColor="#408EC5"
                              radius="12.5"
                              ariaLabel="mutating-dots-loading"
                              visible={true}
                            />
                          )}
                        </Center>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <HomeEmptyState
              title="Nenhum projeto aqui ainda"
              description="Comece criando um projeto de consultoria do Caminho Empreendedor"
              {...(user?.profile.name !== "consulting" && {
                buttonLabel: "Criar novo projeto",
                handleCreate: () => showProjectDialog("create"),
              })}
            />
          )}
        </motion.div>
      )}
    </VStack>
  );
};

export default SelectProject;
