import {
  VStack,
  Text,
  HStack,
  Center,
  Image,
  Box,
  Button,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { searchSuperIcon } from "src/assets/images";
import { Test } from "src/types";
import { Icon } from "semente-js";
import { Props } from "../types";
import TestViewer from "./Test/Viewer";

const HypothesisViewer: React.FC<Props> = ({ current, onClickBack }) => {
  const [currentTest, setCurrentTest] = useState<Test | {}>();

  const hypothesisOptions = [
    {
      onclick: () => {},
      icon: "pen-solid",
      label: "Editar",
    },
    {
      onclick: () => {},
      icon: "redo",
      label: "Refinar",
    },
    {
      onclick: () => {},
      icon: "check-circle-solid",
      label: "Validar",
    },
  ];

  return (
    <VStack
      w={"100%"}
      h={"100%"}
      p={"24px"}
      alignItems={"flex-start"}
      minW={"400px"}
    >
      <HStack spacing={"8px"} mb={"0px !important"}>
        <Icon name={"arrow-left-alt"} size="18px" />
        <Text
          onClick={() => {
            onClickBack();
            setCurrentTest(undefined);
          }}
          cursor={"pointer"}
          fontWeight={500}
          fontSize={"L_md"}
        >
          Voltar para hipóteses
        </Text>
      </HStack>

      <VStack
        w={"100%"}
        h={"100%"}
        spacing={0}
        position={"relative"}
        alignItems={"flex-start"}
      >
        <TestViewer currentTest={currentTest as Test} />
        <Text
          fontWeight={600}
          fontSize={"RH_sm"}
          fontFamily={"Raleway"}
          whiteSpace={"nowrap"}
        >
          {current?.title}
        </Text>

        <HStack w={"100%"} my={"32px"}>
          {hypothesisOptions.map((option) => (
            <Center
              borderRadius={"12px"}
              cursor={"pointer"}
              bg={"Gray.$200"}
              py={"16px"}
              w={"100%"}
            >
              <VStack>
                <Icon name={option.icon as any} size="20px" />
                <Text fontWeight={500} fontSize={"L_md"} whiteSpace={"nowrap"}>
                  {option.label}
                </Text>
              </VStack>
            </Center>
          ))}
        </HStack>

        {current?.tests && current?.tests.length >= 1 ? (
          <VStack w={"100%"} alignItems={"flex-start"}>
            <Text fontWeight={600} fontSize={"RH_sm"} fontFamily={"Raleway"}>
              Testes
            </Text>

            <VStack w={"100%"} spacing={"16px"}>
              {current?.tests.map((test) => (
                <VStack
                  w={"100%"}
                  p={"16px"}
                  key={test.id}
                  border={"2px solid"}
                  borderRadius={"12px"}
                  borderColor={"Gray.$300"}
                  alignItems={"flex-start"}
                  spacing={0}
                >
                  <Center
                    py={"4px"}
                    px={"6px"}
                    background={"Gray.$200"}
                    borderRadius={"4px"}
                  >
                    <Text fontWeight={500} fontSize={"L_sm"}>
                      {test.status}
                    </Text>
                  </Center>

                  <Text
                    mt={"12px !important"}
                    fontWeight={500}
                    fontSize={"L_lg"}
                  >
                    {test.title}
                  </Text>
                  <Text
                    mt={"12px"}
                    fontWeight={400}
                    fontSize={"L_md"}
                    color={"Gray.$600"}
                  >
                    {test.description}
                  </Text>

                  <Box alignSelf={"end"} mt={"16px"}>
                    <Button
                      minH={"36px"}
                      fontWeight={500}
                      onClick={() => setCurrentTest(test)}
                    >
                      Abrir teste
                    </Button>
                  </Box>
                </VStack>
              ))}
            </VStack>
          </VStack>
        ) : (
          <Center h={"100%"} w={"100%"} m={"0px !important"}>
            <VStack maxW={"344px"}>
              <Image
                alt={"Ícone de uma lupa"}
                src={searchSuperIcon}
                width={"52px"}
                h={"64px"}
              />
              <Text fontWeight={600} fontFamily={"Raleway"} fontSize={"RH_sm"}>
                Nenhum teste cadastrado
              </Text>
              <Text
                mt={"8px !important"}
                textAlign={"center"}
                fontWeight={400}
                color={"Gray.$700"}
                fontSize={"L_sm"}
              >
                Solicite a membros da empresa criarem novos testes.
              </Text>
            </VStack>
          </Center>
        )}
      </VStack>
    </VStack>
  );
};

export default HypothesisViewer;
