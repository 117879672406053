import { Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { Icon } from "semente-js";
import ConsultancyCardsData from "./common";
import { Props } from "./types";

const ConsultancyCards: React.FC<Props> = ({ handleSelectCard }) => {
  return (
    <HStack w={"full"} spacing={"32px"}>
      {ConsultancyCardsData.map((card, idx) => (
        <VStack
          key={idx}
          p={"16px"}
          borderRadius={"16px"}
          background={"Gray.$200"}
          flex={1}
        >
          <HStack w={"full"} justifyContent={"space-between"}>
            <Center
              borderRadius={"8px"}
              w={"36px"}
              h={"36px"}
              bg={"Brand.pure"}
            >
              {card.image ? (
                <Image src={card.image} />
              ) : (
                <Icon name={card.icon as any} size="18px" color="#FFFFFF" />
              )}
            </Center>

            <button onClick={() => handleSelectCard(card.tab, card.query)}>
              <HStack>
                <Text color={"Brand.pure"} fontSize={"L_md"} fontWeight={600}>
                  Acessar
                </Text>
                <Icon name="angle-right" size="18px" color="#0077CC" />
              </HStack>
            </button>
          </HStack>

          <VStack spacing={0} alignItems={"flex-start"} w={"full"}>
            <Text color={"Gray.$800"} fontSize={"L_md"} fontWeight={600}>
              {card.title}
            </Text>

            <Text color={"Gray.$800"} fontSize={"L_sm"} fontWeight={400}>
              {card.description}
            </Text>
          </VStack>
        </VStack>
      ))}
    </HStack>
  );
};

export default ConsultancyCards;
