import { Button, Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import boardsImage from "../../../../../assets/images/enviroment/boards.svg";
import { Props } from "./types";
import React from "react";
import Icon from "src/components/base/Icon";

export const BoardEmptyState: React.FC<Props> = ({ handleCreate }) => {
  return (
    <VStack w={"100%"} alignItems={"flex-start"}>
      <Center mt={"36px !important"} w={"100%"}>
        <VStack>
          <Image
            src={boardsImage}
            w={"176px"}
            h={"88px"}
            alt={"Imagem de quadros"}
          />
          <Text
            mt={"16px !important"}
            fontSize={"L_md"}
            fontWeight={600}
            color={"Gray.$800"}
          >
            Crie quadros de consultoria
          </Text>

          <Text
            mt={"0px !important"}
            fontSize={"L_md"}
            fontWeight={400}
            fontFamily={"lato"}
            color={"Gray.$700"}
          >
            Organize as consultorias e acompanhe o progresso das empresas
            durante o projeto.
          </Text>

          {handleCreate && (
            <Button minH={"36px"} mt={"16px !important"} onClick={handleCreate}>
              <HStack spacing={2}>
                <Text fontSize={"L_sm"} color={"Gray.$800"} fontWeight={500}>
                  Criar quadro
                </Text>
                <Icon name={"arrow-right"} />
              </HStack>
            </Button>
          )}
        </VStack>
      </Center>
    </VStack>
  );
};
