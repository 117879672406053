import _axios from "../../..";
import { Company, LegalFormat } from "../../../../types";

const LegalFormatService = {
  getAll: async (): Promise<LegalFormat[]> =>
    (await _axios.get("legal-format")).data,

  create: async (body: string[], id: Company["id"]): Promise<void> =>
    (
      await _axios.patch(`company`, {
        id,
        legal_format_id: body[0],
      } as Partial<Company>)
    ).data,
};

export default LegalFormatService;
