import { Button, Center, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Props } from "./types";
import Icon from "src/components/base/Icon";

const ChecklistEmptyState: React.FC<Props> = ({ handleCreate, isTemplate }) => {
  return (
    <Center w={"100%"} py={"36px"} borderRadius={"16px"}>
      <VStack spacing={"16px"}>
        <Text fontWeight={600} fontSize={"L_md"} color={"Gray.$800"}>
          Não existem {isTemplate ? "templates de" : ""} checklists aqui ainda
        </Text>
        <Button minH={"36px"} onClick={handleCreate}>
          <HStack spacing={"12px"}>
            <Text fontWeight={600} fontSize={"L_sm"} color={"Gray.$800"}>
              Criar {isTemplate ? "template" : "checklist"} 
            </Text>
            <Icon name={"arrow-right"} />
          </HStack>
        </Button>
      </VStack>
    </Center>
  );
};

export default ChecklistEmptyState;
