import React from "react";
import { Props } from "./types";
import {
  Center,
  HStack,
  Image,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { createImageFromInitials } from "src/common";

const TeamList: React.FC<Props> = ({ users, representative_id }) => {
  const theme = useTheme();

  return (
    <VStack
      w={"100%"}
      mt={"32px !important"}
      spacing={"0px !important"}
      alignItems={"flex-start"}
      borderBottom={"1px solid"}
      borderColor={"Gray.$400"}
      pb={"36px"}
    >
      <Text
        fontSize={"RH_xs"}
        fontFamily={"raleway"}
        fontWeight={600}
        mb={"0px !important"}
      >
        Equipe
      </Text>
      <Text
        mt={"8px !important"}
        fontSize={"L_md"}
        fontWeight={400}
        color={"Gray.$700"}
        mb={"16px !important"}
      >
        Funcionários com acesso à empresa no app da Rama
      </Text>

      {users.length >= 1 ? (
        <>
          {users.map((user) => (
            <HStack
              key={user.id}
              w={"100%"}
              py={"12px"}
              px={"16px"}
              spacing={"16px"}
              justifyContent={"space-between"}
            >
              <HStack>
                <Center
                  w="48px"
                  h="48px"
                  overflow="hidden"
                  borderRadius="50%"
                  alignItems="center"
                  background={"#E3E5E8"}
                  justifyContent="center"
                >
                  <Image
                    src={
                      user.image_url ||
                      createImageFromInitials({
                        background: theme.colors.Tertiary.pure,
                        color: theme.colors.white,
                        name: user?.name.substring(0, 1) || "",
                        size: 36,
                        fontSize: 20,
                      })
                    }
                    width="100%"
                    height="100%"
                    borderRadius="60px"
                    objectFit={"cover"}
                  />
                </Center>

                <VStack alignItems={"flex-start"} spacing={0}>
                  <Text
                    fontSize={"L_md"}
                    fontWeight={600}
                    color={"Gray.$700"}
                    mb={"4px !important"}
                  >
                    {user.name}
                  </Text>
                  <Text fontSize={"L_md"} fontWeight={400} color={"Gray.$600"}>
                    {user.email}
                  </Text>
                </VStack>
              </HStack>

              {representative_id === user.id && (
                <Text
                  borderRadius={"4px"}
                  bg={"Gray.$800"}
                  py={"2px"}
                  px={"4px"}
                  color={"white"}
                  fontSize={"L_SM"}
                  fontWeight={500}
                >
                  Representante
                </Text>
              )}
            </HStack>
          ))}
        </>
      ) : (
        <Center w="100%" my={"24px"}>
          <VStack>
            <Text fontSize={"LLG"} fontWeight={600}>
              Essa empresa ainda não possuí nenhum funcionário cadastrado
            </Text>
            <Text fontSize={"LSM"} fontWeight={400}>
              Adicione novos membros a empresa
            </Text>
          </VStack>
        </Center>
      )}
    </VStack>
  );
};

export default TeamList;
