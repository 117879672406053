import { Hypothesis } from "../../types";
import axios from "../index";

const HypothesisService = {
  getByCompanyId: async (
    companyId: string,
    params?: Object
  ): Promise<Hypothesis[]> =>
    (await axios.get(`hypothesis/${companyId}`, { params })).data,

  getByPrimaryId: async (
    hypothesis_primary_id: string
  ): Promise<Hypothesis[]> =>
    (await axios.get(`hypothesis/primary/${hypothesis_primary_id}`)).data,

  create: async (
    company_id: string,
    newHypothesis: Partial<Hypothesis>
  ): Promise<Hypothesis> =>
    (await axios.post("hypothesis", { company_id, ...newHypothesis })).data,

  refinement: async (
    company_id: string,
    newHypothesis: Partial<Hypothesis>
  ): Promise<Hypothesis> =>
    (
      await axios.post("hypothesis/refinement", {
        company_id,
        ...newHypothesis,
      })
    ).data,

  update: async (
    hypothesis_id: Hypothesis["id"],
    newHypothesis: Partial<Hypothesis>
  ): Promise<Hypothesis> =>
    (await axios.put(`hypothesis/${hypothesis_id}`, newHypothesis)).data,

  delete: async (hypothesis_id: Hypothesis["id"]): Promise<Hypothesis> =>
    (await axios.delete(`hypothesis/${hypothesis_id}`)).data,
};

export default HypothesisService;
