import React, { useContext, useState, useEffect, useCallback } from "react";
import { Client, ClientWithProject, Project } from "../../types";
import { Modal } from "../../components/base/Modal";
import { UserContext } from "../UserContext";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import { ClientAddons, ClientDialogs } from "./const";
import ClientService from "src/services/clients";

export type DefaultClientType<T> = {
  client_id?: string;
  isLoading: boolean;
  addon?: string;
  data?: T;
};

export type ClientProps = {
  clients: DefaultClientType<Client[]>;
  refreshClients: (resetSelected?: boolean, force?: boolean) => Promise<void>;
  selectClient: (client_id: Client["id"]) => Promise<void>;
  currentClient: DefaultClientType<ClientWithProject>;
  showClientAddon: (
    addon: keyof typeof ClientAddons,
    client_id?: Client["id"]
  ) => void;
  showClientDialog: (
    dialog: keyof typeof ClientDialogs,
    title?: string
  ) => void;
  handleUpdateClient: (newClient: ClientWithProject) => Promise<void>;
  handleDeleteClient: (client_id: Client["id"]) => Promise<void>;
  handleCreateClient: (newClient: Client) => Promise<Client | undefined>;
  handleInsertInProjects: (
    projects: Project["id"][],
    client_id: Client["id"]
  ) => Promise<void>;
};

export const ClientContext = React.createContext<ClientProps>(
  {} as ClientProps
);

interface Props {
  children: React.ReactNode;
}

const ClientContextProvider = ({ children }: Props) => {
  const { user } = useContext(UserContext);
  const [addon, setAddon] = useState<{
    key: keyof typeof ClientAddons;
    Component: React.ReactNode;
  }>();
  const [dialog, setDialog] = useState<{
    Component: React.ReactNode;
    title?: string;
  }>();
  const [currentClient, setCurrentClient] = useState<
    ClientProps["currentClient"]
  >({ isLoading: true });
  const [clients, setClients] = useState<ClientProps["clients"]>({
    isLoading: true,
    data: undefined,
  });
  const { pathname } = useLocation();
  const { clientId } = useParams();

  const selectClient = useCallback(
    async (client_id: Client["id"]) => {
      setCurrentClient({
        ...currentClient,
        client_id: client_id,
        isLoading: true,
      });
      try {
        setCurrentClient({
          isLoading: false,
          client_id: client_id,
          data: await ClientService.getById(client_id),
        });
      } catch (err) {
        toast.error(`Ocorreu um erro ao buscar o cliente ${client_id}`);
      }
    },
    [currentClient]
  );

  const refreshClients = useCallback(
    async (resetSelected?: boolean) => {
      if (user) {
        try {
          setClients({
            ...clients,
            isLoading: true,
          });

          const clientsArr = await ClientService.getClients();
          setClients({
            isLoading: false,
            data: clientsArr,
          });

          if (
            (!currentClient?.data || resetSelected || clientId) &&
            clientsArr.length >= 1
          ) {
            selectClient(clientId || clientsArr[0].id);
          }
        } catch (err) {
          toast.error("Ocorreu um erro ao buscar os clientes");
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, user]
  );

  const showClientAddon = (
    addon: keyof typeof ClientAddons,
    client_id?: Client["id"]
  ) => {
    const client =
      client_id && clients.data
        ? clients.data.find((client) => client.id === client_id)
        : currentClient.data;

    if (currentClient.data) {
      setAddon({
        key: addon,
        Component: ClientAddons[addon]({
          client: client || currentClient.data,
          handleBack: () => setAddon(undefined),
        }),
      });
    }
  };

  const showClientDialog = (
    dialog: keyof typeof ClientDialogs,
    title?: string
  ) => {
    if (currentClient.data) {
      setDialog({
        Component: ClientDialogs[dialog]({
          client: currentClient.data,
          onClose: () => setDialog(undefined),
        }),
        title: title,
      });
    }
  };

  useEffect(() => {
    if (addon) {
      showClientAddon(addon.key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient]);

  const handleCreateClient = async (
    newClient: Client
  ): Promise<Client | undefined> => {
    try {
      const client = await ClientService.create(newClient);
      await refreshClients(false);
      selectClient(client.id);
      toast.success("Cliente criado com sucesso!");
      return client;
    } catch (err) {
      toast.error("Ocorreu um erro ao criar o cliente.");
    }
  };

  const handleUpdateClient = async (
    newClient: ClientWithProject
  ): Promise<void> => {
    if (clients && clients.data) {
      try {
        await ClientService.update(newClient.id, newClient);
        const index = clients.data.findIndex(
          (client) => client.id === newClient.id
        );
        clients.data[index] = { ...newClient };
        setClients(JSON.parse(JSON.stringify(clients)));

        if (currentClient.data && currentClient.data.id === newClient.id) {
          setCurrentClient({
            ...currentClient,
            data: newClient,
          });
        }
        toast.success("Cliente atualizado com sucesso!");
      } catch (err) {
        toast.error("Ocorreu um erro ao criar o cliente.");
      }
    }
  };

  const handleDeleteClient = async (id: Client["id"]) => {
    setClients({ ...clients, isLoading: true });
    try {
      await ClientService.delete(id);
      refreshClients(true);
      toast.success("Cliente removido com sucesso!");
    } catch (err) {
      toast.error("Ocorreu um erro ao remover o cliente.");
    }
  };

  const handleInsertInProjects = async (
    projects: Project["id"][],
    client_id: Client["id"]
  ): Promise<void> => {
    try {
      const projectData = await Promise.all(
        projects.map(
          async (project_id) =>
            await ClientService.insertInProject({ client_id, project_id })
        )
      );

      if (currentClient.data) {
        setCurrentClient({
          ...currentClient,
          data: { ...currentClient.data, projects: projectData },
        });
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao adicionar ao projeto");
    }
  };

  return (
    <ClientContext.Provider
      value={{
        clients,
        selectClient,
        currentClient,
        refreshClients,
        showClientAddon,
        showClientDialog,
        handleDeleteClient,
        handleCreateClient,
        handleUpdateClient,
        handleInsertInProjects,
      }}
    >
      <>
        <Modal
          isOpen={!!dialog}
          title={dialog?.title}
          onClose={() => setDialog(undefined)}
        >
          {dialog?.Component}
        </Modal>

        {addon ? addon.Component : children}
      </>
    </ClientContext.Provider>
  );
};

export const useClientContext = () => {
  const context = useContext(ClientContext);

  if (context) return context;

  throw new Error(
    "useClientContext must be used within a ClientContextProvider."
  );
};

export default ClientContextProvider;
