import { Center, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Button } from "semente-js";
import { hypothesesEmpty } from "src/assets/images";
import { Props } from "./types";

const HypothesesEmptyState: React.FC<Props> = ({ handleCreate }) => {
  return (
    <Center h={"100%"} w={"100%"} m={"0px !important"}>
      <VStack mt={"24px"}>
        <Image
          alt={"Ícone de uma lupa"}
          src={hypothesesEmpty}
          w={"120px"}
          h={"120px"}
        />
        <Text
          textAlign={"center"}
          fontWeight={600}
          fontFamily={"Raleway"}
          fontSize={"RH_sm"}
        >
          Tudo começa com uma hipótese...
        </Text>
        <Text
          mt={"0px !important"}
          mb={"16px"}
          textAlign={"center"}
          fontWeight={400}
          color={"Gray.$700"}
          fontSize={"L_sm"}
        >
          Comece criando uma hipótese que você quer validar na sua empresa e as
          visualize aqui
        </Text>
        <Button
          label={"Criar nova hipótese"}
          onClick={handleCreate}
          layout="rounded"
          color="gray"
          size="sm"
        />
      </VStack>
    </Center>
  );
};

export default HypothesesEmptyState;
