import { column } from "../../common";
import * as Sections from "./Sections";

const navOptions: {
  value: keyof typeof Sections;
  label: string;
  icon: string;
}[] = [
  {
    value: "consultancy",
    label: "Consultoria",
    icon: "comments-solid",
  },
  {
    value: "evolution",
    label: "Evolução",
    icon: "chart-line",
  },
  {
    value: "consultancies",
    label: "Visão Geral",
    icon: "globe",
  },
  {
    value: "checklists",
    label: "Checklists",
    icon: "file-alt-solid",
  },
  {
    value: "messages",
    label: "Mensagens",
    icon: "envelope-solid",
  },
  {
    value: "archives",
    label: "Documentos",
    icon: "file-blank-solid",
  },
  {
    value: "about",
    label: "Sobre",
    icon: "info-circle-solid",
  },
];

const omitTabs: {
  [key in column["position"]]?: Array<string>;
} = {
  start: ["hypotheses", "historic"],
};

export const getAvailableTabs = (position: column["position"]) =>
  navOptions.filter(
    (opt) => !omitTabs[position || "start"]?.includes(opt?.value as string)
  );
