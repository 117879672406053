import { Box, Center, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { MutatingDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Icon from "src/components/base/Icon";
import CompanyService from "src/services/company";
import * as ProfileServices from "../../../../services/company/Profile";
import * as Forms from "./Forms";
import { ProfileProps } from "./Forms/types";
import { Props } from "./types";

const CompanyProfile: React.FC<Props> = ({
  defaultValues,
  handleSubmitForm,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profileIndex, setProfileIndex] = useState(0);
  const [profileComponents, setProfileComponents] = useState<
    { Component: React.FC<ProfileProps>; data: any }[]
  >([]);

  const { control, handleSubmit } = useForm({ defaultValues });

  useEffect(() => {
    const FcForms: {
      [key in keyof typeof ProfileServices]: React.FC<ProfileProps>;
    } = {
      legal_format_service: Forms["LegalFormat"],
      market_sector_service: Forms["MarketSector"],
      revenue_models_service: Forms["RevenueModel"],
      business_models_service: Forms["BusinessModel"],
      investments_service: Forms["Investments"],
      sdgs_service: Forms["DevelopmentGoal"],
    };

    (async () => {
      setProfileComponents(
        await Promise.all(
          (Object.keys(FcForms) as Array<keyof typeof FcForms>).map(
            async (key) => {
              const data = await ProfileServices[key].getAll();
              const Component = FcForms[key];
              return { Component, data };
            }
          )
        )
      );
    })();
  }, [control]);

  const handleCreateProfile: SubmitHandler<typeof defaultValues> = async (
    values
  ) => {
    try {
      setIsLoading(true);
      await ProfileServices.business_models_service.create(
        values.company.business_models?.map(
          ({ business_model_id }) => business_model_id
        ),
        values.company.id
      );

      await ProfileServices.revenue_models_service.create(
        values.company.revenue_models?.map(
          ({ revenue_model_id }) => revenue_model_id
        ),
        values.company.id
      );

      await ProfileServices.sdgs_service.create(
        values.company.sdgs?.map(({ sdg_id }) => sdg_id),
        values.company.id
      );

      await CompanyService.update(
        { ...values.company, status: "finished" },
        values.id
      );

      handleSubmitForm();
    } catch (err) {
      setIsLoading(false);
      toast.error("Ocorreu um erro ao enviar os dados de perfil.");
    }
  };

  return (
    <>
      {profileComponents.length >= 2 && !isLoading ? (
        <VStack h={"100%"} w={"100%"}>
          <Center position={"relative"} w={"100%"} py={"16px"}>
            {profileIndex >= 1 && (
              <Box
                position={"absolute"}
                left={0}
                cursor={"pointer"}
                onClick={() => setProfileIndex(profileIndex - 1)}
              >
                <Icon size="22px" name={"angle-left"} />
              </Box>
            )}

            <Box
              w={"160px"}
              background={"Gray.$300"}
              h={"4px"}
              borderRadius={"500px"}
            >
              <Box
                w={"100%"}
                h={"100%"}
                maxW={`${
                  ((profileIndex + 1) / profileComponents.length) * 100
                }%`}
                borderRadius={"500px"}
                transition={"300ms all"}
                background={"Tertiary.dark"}
              />
            </Box>
          </Center>
          <Controller
            name="company"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                {(() => {
                  const Component = profileComponents[profileIndex].Component;
                  return (
                    <Component
                      data={profileComponents[profileIndex].data}
                      defaultValue={value}
                      handleSubmit={(e) => {
                        onChange({ ...value, ...e });

                        if (profileIndex === profileComponents.length - 1) {
                          handleSubmit(handleCreateProfile)();
                        } else {
                          setProfileIndex(profileIndex + 1);
                        }
                      }}
                    />
                  );
                })()}
              </>
            )}
          />
        </VStack>
      ) : (
        <Center flex={1} h={"100%"}>
          <MutatingDots
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      )}
    </>
  );
};

export default CompanyProfile;
