import { HStack, Image, Text, VStack, useTheme } from "@chakra-ui/react";
import React from "react";
import { createImageFromInitials } from "src/common";
import { Props } from "./types";
import Icon from "src/components/base/Icon";

const Card: React.FC<Props> = ({ project, handleClick }) => {
  const theme = useTheme();

  return (
    <VStack
      w={"100%"}
      border={"1px solid"}
      borderRadius={"12px"}
      borderColor={"Gray.$300"}
      p={"24px"}
      onClick={handleClick}
    >
      <HStack justifyContent={"flex-start"} w={"100%"}>
        <Image
          src={
            project.image_url ||
            createImageFromInitials({
              background: (theme.colors as any).Tertiary.pure,
              color: (theme.colors as any).white,
              name: project.name.substring(0, 2) || "",
              size: 48,
              fontSize: 20,
            })
          }
          alt={`Imagem do projeto: ${project.name}`}
          w={"48px"}
          h={"48px"}
          borderRadius={"50%"}
          objectFit={"cover"}
        />
        <VStack alignItems={"flex-start"} spacing={0}>
          <Text
            fontSize={"RH_xs"}
            color={"Gray.$800"}
            fontFamily={"raleway"}
            fontWeight={500}
          >
            {project.name}
          </Text>
          <Text fontSize={"L_sm"} color={"Gray.$700"} fontWeight={400}>
            {project.area.name}
          </Text>
        </VStack>
      </HStack>

      <HStack
        w={"100%"}
        mt={"38px !important"}
        justifyContent={"space-between"}
        spacing={"16px"}
      >
        <HStack>
          <HStack spacing={1}>
            <Icon name={"store-solid"} color={theme.colors.Gray.$600} />
            <Text fontSize={"L_sm"} color={"Gray.$600"} fontWeight={500}>
              {project?.companies?.length}
            </Text>
          </HStack>

          <HStack spacing={1}>
            <Icon name={"users-alt-solid"} color={theme.colors.Gray.$600} />
            <Text fontSize={"L_sm"} color={"Gray.$600"} fontWeight={500}>
              {project?.consultants?.length}
            </Text>
          </HStack>
        </HStack>
      </HStack>
    </VStack>
  );
};

export default Card;
