import _axios from "..";
import { Client, ClientData, ClientWithProject, Project } from "../../types";

export const ClientService = {
  insertInProject: async (body: {
    project_id: Project["id"];
    client_id: Client["id"];
  }): Promise<ClientData> => (await _axios.post("/client/project", body)).data,

  removeFromProject: async (
    clientDataId: ClientData["id"]
  ): Promise<ClientData> =>
    (await _axios.delete(`/client/project/${clientDataId}`)).data,

  getClients: async (params?: Object): Promise<Client[]> =>
    (await _axios.get("/clients", { params })).data,

  getById: async (id: Client["id"]): Promise<ClientWithProject> =>
    (await _axios.get(`/client/${id}`)).data,

  create: async (newProject: Partial<Client>): Promise<Client> =>
    (await _axios.post(`/client`, newProject)).data,

  update: async (
    id: Client["id"],
    newProject: Partial<Client>
  ): Promise<void> => (await _axios.patch(`/client/${id}`, newProject)).data,

  delete: async (id: Client["id"]): Promise<void> =>
    (await _axios.delete(`/client/${id}`)).data,
};

export default ClientService;
