import React, { useEffect } from "react";
import { Props } from "./types";
import { Button, Center, VStack } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { ChecklistWithQuestions } from "src/types";
import Input from "src/components/base/Input/Index";
import { MutatingDots } from "react-loader-spinner";

export const CreateChecklistForm: React.FC<Props> = ({
  handleSubmitChecklist,
  checklist,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: checklist as ChecklistWithQuestions,
  });

  useEffect(() => {
    reset(checklist);
  }, [checklist, reset]);

  return isSubmitting ? (
    <Center height={"170px"} w={"100%"}>
      <MutatingDots
        height="100"
        width="100"
        color="#408EC5"
        secondaryColor="#408EC5"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        visible={true}
      />
    </Center>
  ) : (
    <>
      <VStack
        spacing={"16px"}
        w={"100%"}
        alignItems={"flex-start"}
        px={"24px"}
        borderTop={"1px solid"}
        borderBottom={"1px solid"}
        borderColor={"Gray.$300"}
        py={"24px"}
      >
        <Controller
          name={"name"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <Input {...field} isInvalid={!!error} placeholder={"Nome"} />
          )}
        />
      </VStack>

      <Button
        m={"24px !important"}
        variant={"Primary"}
        minH={"48px !important"}
        onClick={handleSubmit(handleSubmitChecklist)}
      >
        {checklist && "id" in checklist ? "Salvar" : "Adicionar"}
      </Button>
    </>
  );
};
