import {
  Button,
  Center,
  HStack,
  Image,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { createImageFromInitials } from "../../../../common";
import { Props } from "./types";
import { ClientContext } from "src/contexts/ClientContext";
import { masks } from "src/components/base/Input/masks";
import Icon from "src/components/base/Icon";
import { ProtectedComponent } from "semente-js";
import { PERMISSIONS } from "src/permissions";

export const ListRightSide: React.FC<Props> = () => {
  const { currentClient, showClientDialog, showClientAddon } =
    useContext(ClientContext);
  const client = currentClient.data;
  const theme = useTheme();

  return (
    <VStack w={"100%"} ml={"36px !important"}>
      <HStack w={"100%"} h={"100%"} mb={"24px"} alignItems={"flex-start"}>
        <Center
          minW="120px"
          minH="120px"
          overflow="hidden"
          borderRadius="60px"
          alignItems="center"
          background={"#E3E5E8"}
          justifyContent="center"
        >
          <Image
            src={createImageFromInitials({
              background: (theme.colors as any).Tertiary.pure,
              color: (theme.colors as any).white,
              name: client?.name.substring(0, 1) || "",
              size: 124,
              fontSize: 64,
            })}
            width="100%"
            height="100%"
            borderRadius="60px"
            objectFit={"cover"}
          />
        </Center>

        <VStack
          pl={"24px"}
          w={"100%"}
          h={"100%"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          spacing={0}
        >
          <Text fontSize={"RH_xxl"} fontFamily={"raleway"} fontWeight={600}>
            {client?.name}
          </Text>

          <Text fontSize={"L_md"} fontWeight={400}>
            {masks["cnpj"](client?.cnpj || "")}
          </Text>

          <ProtectedComponent
            requiredClientRoles={[PERMISSIONS.CLIENT_UPDATE]}
            children={
              <Button
                minH={"36px"}
                mt={"16px !important"}
                onClick={() => showClientAddon("update")}
              >
                Editar cliente
              </Button>
            }
          />
        </VStack>
      </HStack>

      <VStack
        w={"100%"}
        mt={"32px !important"}
        spacing={"0px !important"}
        alignItems={"flex-start"}
      >
        <Text
          fontSize={"RH_xs"}
          fontFamily={"raleway"}
          fontWeight={600}
          mb={"0px !important"}
        >
          Projetos
        </Text>
        <Text
          mt={"8px !important"}
          fontSize={"L_md"}
          fontWeight={400}
          color={"Gray.$700"}
          mb={"16px !important"}
        >
          Lista de projetos a qual esse membro faz parte
        </Text>

        <VStack
          w={"100%"}
          border={"1px solid"}
          borderColor={"Gray.$400"}
          borderRadius={"12px"}
          spacing={"0px !important"}
          px={"16px"}
        >
          {client?.projects && client?.projects?.length >= 1 ? (
            <>
              {client.projects.map(({ project }, index) => (
                <HStack
                  key={project.id}
                  py={"16px"}
                  w={"100%"}
                  borderBottom={"1px solid"}
                  borderColor={
                    client.projects.length - 1 === index
                      ? "transparent"
                      : "Gray.$400"
                  }
                >
                  <Center
                    width="36px"
                    height="36px"
                    overflow="hidden"
                    borderRadius="60px"
                    alignItems="center"
                    background={project.image_url ? "transparent" : "#E3E5E8"}
                    justifyContent="center"
                  >
                    {project.image_url ? (
                      <Image
                        src={project.image_url}
                        width="100%"
                        height="100%"
                        borderRadius="60px"
                        objectFit={"cover"}
                      />
                    ) : (
                      <Icon name={"image"} size={"18px"} />
                    )}
                  </Center>

                  <VStack flex={1} alignItems={"flex-start"} spacing={"4px"}>
                    <Text fontSize={"L_md"} fontWeight={400}>
                      {project.name}
                    </Text>
                    <Text
                      fontSize={"L_md"}
                      fontWeight={400}
                      color={"Gray.$700"}
                    >
                      {project.boards.length} quadros
                    </Text>
                  </VStack>
                </HStack>
              ))}
            </>
          ) : (
            <VStack py={"16px"}>
              <Text
                fontWeight={600}
                fontFamily={"Raleway"}
                textAlign={"center"}
                fontSize={"L_MD"}
                mb={"12px"}
              >
                Esse cliente não faz parte de nenhum projeto
              </Text>
              <Text
                mt={"8px !important"}
                textAlign={"center"}
                fontWeight={400}
                color={"Gray.$700"}
                fontSize={"L_sm"}
                mb={"16px !important"}
              >
                Adicione essa cliente a um projeto
              </Text>

              <Button
                minH={"36px"}
                onClick={() =>
                  showClientDialog("addInProject", "Selecione o projeto")
                }
              >
                <HStack>
                  <Text>Adicionar a um projeto</Text>
                  <Icon name={"arrow-right"} />
                </HStack>
              </Button>
            </VStack>
          )}
        </VStack>
      </VStack>
    </VStack>
  );
};
