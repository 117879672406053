import React, { useRef, useState } from "react";
import { Props } from "./types";
import { HStack, Text, VStack, useOutsideClick } from "@chakra-ui/react";
import { Icon } from "semente-js";
import { TaskStatus } from "src/pages/Kanban/common";

const ConsultancyStatus: React.FC<Props> = ({ consultancy, handleChange }) => {
  const status = TaskStatus[consultancy?.status || "pending"];
  const menuRef = useRef<any>();
  const [isOpen, setIsOpen] = useState(false);

  useOutsideClick({
    ref: menuRef,
    handler: () => setIsOpen(false),
  });

  return (
    <VStack position={"relative"}>
      <button onClick={() => setIsOpen((p) => !p)}>
        <HStack
          p={2}
          spacing={3}
          borderRadius={"500px"}
          alignItems={"center"}
          border={"1px solid"}
          borderColor={status.bg}
        >
          <Icon name={status.icon} color={status.color} size={"18px"} />
          <Text fontWeight={600} fontSize={"L_lg"} color={status.color}>
            {status.label}
          </Text>

          <Icon name={"angle-down"} color={status.color} size={"18px"} />
        </HStack>
      </button>

      {isOpen && (
        <VStack
          ref={menuRef}
          position={"absolute"}
          py={2}
          right={0}
          top={"50px"}
          zIndex={10}
          bg={"white"}
          borderRadius={"12px"}
          boxShadow={"0px 2px 16px rgba(0, 0, 0, 0.12)"}
          alignItems={"flex-start"}
        >
          {(Object.keys(TaskStatus) as Array<keyof typeof TaskStatus>).map(
            (key) => {
              const status = TaskStatus[key];
              return (
                <HStack
                  px={"16px"}
                  py={"12px"}
                  spacing={4}
                  key={status.label}
                  cursor={"pointer"}
                  onClick={() => {
                    setIsOpen(false);
                    handleChange(key);
                  }}
                  w={"100%"}
                  _hover={{
                    bg: "#f1f1f1",
                  }}
                >
                  <Icon name={status.icon} color={status.color} size={"18px"} />
                  <Text
                    fontWeight={400}
                    fontSize={"L_md"}
                    color={status.color}
                    whiteSpace={"nowrap"}
                  >
                    {status.label}
                  </Text>
                </HStack>
              );
            }
          )}
        </VStack>
      )}
    </VStack>
  );
};

export default ConsultancyStatus;
