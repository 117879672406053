import { Center, HStack, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { MutatingDots } from "react-loader-spinner";
import { User, UserWithProjects } from "../../types";
import { toast } from "react-toastify";
import UserService from "../../services/user";
import { HomeEmptyState } from "../../components/HomeEmptyState";
import { AddButton } from "../../components/AddButton";
import InviteConsultants from "./InviteMember";
import { ListLeftSide } from "./List/LeftSide";
import { ListRightSide } from "./List/RightSide";
import { ProtectedComponent } from "semente-js";
import { PERMISSIONS } from "src/permissions";

export const Team: React.FC = () => {
  const [teamMembers, setTeamMembers] = useState({
    isLoading: true,
    data: [] as User[],
  });
  const [inviteIsOpen, setInviteIsOpen] = useState(false);

  const [currentUser, setCurrentUser] = useState({
    isLoading: true,
    data: {} as UserWithProjects,
  });
  const [page, setPage] = useState({
    current: 0,
    isLast: false,
    isLoading: false,
  });

  const handleSelectConsultant = async (user: User) => {
    setCurrentUser({ data: {} as UserWithProjects, isLoading: true });
    if (user) {
      try {
        setCurrentUser({
          isLoading: false,
          data: await UserService.getById(user.id),
        });
      } catch (err) {
        toast.error(`Ocorreu um erro ao buscar o consultor ${user.name}`);
      }
    }
  };

  useEffect(() => {
    UserService.getUsersPaginated({
      offset: page.current * 10,
      limit: 10,
      type: ["consulting", "manager"],
    }).then((data) => {
      if (!("id" in currentUser.data)) {
        handleSelectConsultant(data.result[0]);
      }

      const newUsers = [...teamMembers.data, ...data.result];

      setTeamMembers({
        data: newUsers,
        isLoading: false,
      });

      setPage({
        ...page,
        isLoading: false,
        isLast: newUsers.length === data.total,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.current]);

  const createLabel = "Adicionar novo membro";

  return (
    <VStack w={"100%"} px="80px" py="24px">
      <HStack
        w={"100%"}
        pb={"24px"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        borderBottom={"1px solid"}
        borderColor={"Gray.$400"}
      >
        <Text
          fontSize={"RD_md"}
          color={"Gray.$800"}
          fontFamily={"raleway"}
          fontWeight={600}
        >
          Equipe
        </Text>
        <ProtectedComponent
          requiredClientRoles={[PERMISSIONS.MEMBER_CREATION]}
          children={
            <AddButton
              label={createLabel}
              onClick={() => setInviteIsOpen(true)}
            />
          }
        />
      </HStack>
      {teamMembers.isLoading ? (
        <Center mt={"64px !important"}>
          <MutatingDots
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {teamMembers && teamMembers.data.length >= 1 ? (
            <HStack
              w={"100%"}
              pt={"24px"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
            >
              <ListLeftSide
                handleSelectConsultant={handleSelectConsultant}
                consultants={teamMembers.data}
                handleGetMore={() => {
                  setPage({
                    ...page,
                    isLoading: true,
                    current: page.current + 1,
                  });
                }}
                page={page}
              />

              {currentUser.isLoading ? (
                <Center w={"100%"} mt={"64px !important"}>
                  <MutatingDots
                    height="100"
                    width="100"
                    color="#408EC5"
                    secondaryColor="#408EC5"
                    radius="12.5"
                    ariaLabel="mutating-dots-loading"
                    visible={true}
                  />
                </Center>
              ) : currentUser ? (
                <ListRightSide
                  consultant={currentUser.data}
                  handleUpdateConsultant={() =>
                    handleSelectConsultant(currentUser.data)
                  }
                />
              ) : (
                <></>
              )}
            </HStack>
          ) : (
            <>
              <ProtectedComponent
                requiredClientRoles={[PERMISSIONS.MEMBER_CREATION]}
                children={
                  <HomeEmptyState
                    buttonLabel={createLabel}
                    description={
                      "As empresas podem ser cadastradas pelos empreendedores no app da Rama Semente."
                    }
                    title={"Nenhum consultor aqui ainda"}
                    handleCreate={() => setInviteIsOpen(true)}
                  />
                }
              />
            </>
          )}
        </motion.div>
      )}

      <InviteConsultants
        isOpen={inviteIsOpen}
        handleClose={() => setInviteIsOpen(false)}
      />
    </VStack>
  );
};
