import { StyledBoxInput, StyledTextInput, StyledPlaceHolder } from "./styles";
import { Text, useTheme } from "@chakra-ui/react";
import React, { forwardRef, useState, useRef, useEffect } from "react";
import { Props } from "./types";
import useCombinedRefs from "../../../hooks/useCombinedRefs";
import { motion, useAnimation } from "framer-motion";

const TextArea: React.FC<Props> = forwardRef((props, ref: any) => {
  const [InFocus, setInFocus] = useState(false);
  const innerRef = useRef<any>(null);
  const combinedRef = useCombinedRefs(ref, innerRef);
  const controls = useAnimation();
  const theme = useTheme();

  const getRandomDelay = () => -(Math.random() * 0.7 + 0.05);
  const randomDuration = () => Math.random() * 0.07 + 0.23;

  useEffect(() => {
    if (props.isInvalid) {
      controls.start("start");
    }
  }, [props.isInvalid, controls]);

  const variants = {
    start: () => ({
      rotate: [1, -1.4, 0],
      transition: {
        delay: getRandomDelay(),
        duration: randomDuration(),
      },
    }),
  };

  return (
    <StyledBoxInput {...props}>
      <motion.div variants={variants} animate={controls}>
        <StyledPlaceHolder
          variant={"text"}
          labelIsUpped={!!props.value || InFocus}
          onClick={() => combinedRef.current.focus()}
        >
          <Text
            flex={1}
            fontFamily={"lato"}
            color={
              props.isDisabled
                ? "Gray.$500"
                : props.isInvalid
                ? "Red.pure"
                : InFocus
                ? "Tertiary.pure"
                : "Gray.$700"
            }
            fontSize={props.value || InFocus ? "12px" : "16px"}
          >
            {props?.placeholder}
          </Text>
        </StyledPlaceHolder>

        {props.maxLength && (
          <Text
            top={2}
            right={4}
            position={"absolute"}
            fontFamily={"lato"}
            color={
              props.isDisabled
                ? "Gray.$500"
                : props.isInvalid
                ? "Red.pure"
                : InFocus
                ? "Tertiary.pure"
                : "Gray.$700"
            }
            fontSize={"12px"}
          >
            {props.value?.length || 0} / {props.maxLength}
          </Text>
        )}

        <StyledTextInput
          {...props}
          theme={theme}
          inInput={InFocus}
          ref={combinedRef}
          placeholder={undefined}
          isInvalid={props.isInvalid}
          onFocus={() => setInFocus(true)}
          onBlur={() => setInFocus(false)}
          readOnly={props.isDisabled}
          value={props.value}
        />
      </motion.div>
    </StyledBoxInput>
  );
});

export default TextArea;
