import _axios from "..";
import { Kanban } from "../../types";

export const KanbanService = {
  create: async (body: Partial<Kanban>): Promise<Kanban> =>
    (await _axios.post("/kanban", body)).data,

  getById: async (id: Kanban["id"]): Promise<Kanban> =>
    (await _axios.get(`/kanban/${id}`)).data,

  update: async (id: Kanban["id"], body: Partial<Kanban>): Promise<Kanban> =>
    (await _axios.patch(`/kanban/${id}`, body)).data,

  delete: async (id: Kanban["id"]): Promise<void> =>
    (await _axios.delete(`/kanban/${id}`)).data,
};

export default KanbanService;
