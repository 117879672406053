import React from "react";
import { Props } from "./types";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";

const TabNavigator: React.FC<Props> = ({ currentIn, handleClick, tabs }) => {
  return (
    <VStack
      borderBottom={"4px solid"}
      borderColor={"Gray.$300"}
      spacing={0}
      w={"100%"}
      alignItems={"flex-start"}
    >
      <HStack spacing={0}>
        {tabs.map((tab, index) => (
          <VStack
            p={"16px"}
            position={"relative"}
            spacing={0}
            cursor={"pointer"}
            onClick={() => handleClick(index)}
          >
            <Text
              transition={"all 300ms"}
              {...(currentIn === index && { color: "Brand.pure" })}
            >
              {tab.label}
            </Text>
            <Box
              background={currentIn === index ? "Brand.pure" : "Gray.$300"}
              transition={"all 300ms"}
              position={"absolute"}
              bottom={"-4px"}
              w={"100%"}
              h={"4px"}
            />
          </VStack>
        ))}
      </HStack>
    </VStack>
  );
};

export default TabNavigator;
