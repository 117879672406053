import _axios from "src/services";
import { CompanyPath } from "src/types";

export const CompanyPathService = {
  update: async (
    id: CompanyPath["id"],
    body: Partial<CompanyPath>
  ): Promise<CompanyPath> =>
    (await _axios.patch(`/company_path/${id}`, body)).data,

  create: async (body: Partial<CompanyPath>): Promise<CompanyPath> =>
    (await _axios.post(`/company_path`, body)).data,

  remove: async (path_id: CompanyPath["id"]): Promise<void> =>
    (await _axios.delete(`/company_path/${path_id}`)).data,
};

export default CompanyPathService;
