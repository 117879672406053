import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object

export const socket = io(process.env.REACT_APP_API_URL as string, {
  autoConnect: false,
  extraHeaders: {
    authorization: localStorage.getItem("accessToken") || "",
  },
});
